import { useField } from "formik";
import PropTypes from "prop-types";

// import MDInput from "components/MDInput";
import { TextField, styled } from "@mui/material";
import { secondaryColor } from "constants";

const StyledInput = styled(TextField)(() => ({
  background: "transparent",
  "& input": {
    color: "#fff",
    "&:-webkit-autofill": {
      backgroundColor: "red !important" /* Remove background color */,
      boxShadow: `0 0 0 30px ${secondaryColor} inset !important`,
      color: "#ff0" /* Remove box-shadow */,
    },
    "&:-webkit-autofill:active": {
      backgroundColor: "red !important" /* Remove background color */,
      boxShadow: `0 0 0 30px ${secondaryColor} inset !important`,
      color: "#ff0" /* Remove box-shadow */,
    },
    "&:-webkit-autofill:focus": {
      backgroundColor: "red !important" /* Remove background color */,
      boxShadow: `0 0 0 30px ${secondaryColor} inset !important`,
      color: "#ff0" /* Remove box-shadow */,
    },
    "&:-webkit-autofill:hover": {
      backgroundColor: "red !important" /* Remove background color */,
      boxShadow: `0 0 0 30px ${secondaryColor} inset !important`,
      color: "#ff0" /* Remove box-shadow */,
    },
  },
  "& textarea": {
    color: "#fff",
  },
}));

function FormikInput(props) {
  const { name, ...otherProps } = props;
  const [field, mata] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  return <StyledInput {...configTextfield} />;
}

FormikInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormikInput;
