import { Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { signInUrl } from "constants";

import heroImg from "../../assets/isomockup.png";
import SectionContainer from "../SectionContainer";
import FloatingDownloadBtn from "./FloatingDownloadBtn";

import "./Hero.css";
import WhatsappContact from "../ContactUs/WhatsappContact";

const BGOcean = () => (
  <div className="ocean">
    <div className="wave wave1" />
    <div className="wave wave2" />
    <div className="wave wave3" />
    <div className="wave wave4" />
  </div>
);

function HeroSection() {
  const { t } = useTranslation();

  return (
    <SectionContainer bgColor="secondary" className="hero-section-root" id="hero-section">
      <FloatingDownloadBtn />
      <div className="floating-whatsapp-contact">
        <WhatsappContact noNumber />
      </div>
      <Container>
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
        >
          <Grid item xs={12} md={6}>
            <MDTypography
              variant="h2"
              color="info"
              sx={{ fontSize: { xs: 30, lg: 43 }, mb: { xs: 1, lg: 3 } }}
            >
              {t("heroTitle")}
            </MDTypography>
            <MDTypography color="white" sx={{ fontSize: { xs: 16, lg: 20 } }}>
              {t("heroDescription")}
            </MDTypography>
            <MDButton
              href={signInUrl}
              sx={{
                my: { xs: 3, lg: 5 },
                height: 60,
                width: 260,
                fontSize: 17,
                zIndex: 1002,
              }}
              color="info"
            >
              {t("getStartedFree")}
            </MDButton>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox component="img" src={heroImg} alt="Hero Section" width="100%" />
          </Grid>
        </Grid>
      </Container>
      <BGOcean />
    </SectionContainer>
  );
}

export default HeroSection;
