import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";
import * as yup from "yup";
import MDTypography from "components/MDTypography";
import { Icon } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
import { useTranslation } from "react-i18next";
import {
  fetchDataAction,
  deleteDataAction,
  reset,
  addComplexDataAction,
  setAlert,
} from "store/features/coreSlice";
import UpdateRecord from "helpers/compare";
import PagesCount from "helpers/pages-count";

const JobTitles = () => {
  const { t } = useTranslation();
  const { jobTitles, jobTitlesMeta, resStatus, limit, allStandards, allTasks } = useSelector(
    (state) => state.core
  );

  const dispatch = useDispatch();

  const columns = [
    { Header: "title", accessor: "title", width: "45%", align: "center" },
    { Header: "users", accessor: "users", width: "45%", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [newArr, setNewArr] = useState([]);
  const [number, setNumber] = useState(1);
  const [pageOptions, setPageOption] = useState([]);

  const [initialValues, setInitialValues] = useState({
    id: null,
    title: null,
    description: null,
    standards: [],
    tasks: [],
  });
  const [successSB, setSuccessSB] = useState(false);
  const [data, setData] = useState([]);

  const closeSuccessSB = () => setSuccessSB(false);

  const onUpdateHandler = (name, values) => {
    const standardsData = [];
    values.standards.forEach((element) => {
      standardsData.push(element.standard_id);
    });
    const tasksData = [];
    values.tasks.forEach((element) => {
      tasksData.push(element.task_id);
    });
    setInitialValues({
      id: values.id,
      title: values.title,
      standards: standardsData,
      tasks: tasksData,
      description: values.description,
    });
    setOpen(true);
  };
  const onDeleteHandler = (name, values) => {
    if (values.users_job_titles.length === 0) {
      setOpenDeleteModal(true);
      setDeleteItemId(values);
    } else {
      // alert("has user");
      dispatch(
        setAlert({
          status: "error",
          content: t("hasUsers"),
        })
      );
    }
  };

  useEffect(() => {
    const payload = {
      url: `items/job_title?fields=*,users_job_titles.id,standards.id,tasks.id,standards.standard_id.id,standards.standard_id.title,tasks.task_id.id,tasks.task_id.title&meta=*&limit=${limit}&page=${number}`,
      varName: "jobTitles",
    };
    dispatch(fetchDataAction(payload));
    dispatch(
      fetchDataAction({
        url: `items/standards?fields=id,title`,
        varName: "allStandards",
      })
    );

    dispatch(
      fetchDataAction({
        url: `items/tasks?fields=id,title`,
        varName: "allTasks",
      })
    );
  }, [limit]);

  useEffect(
    () => () => {
      dispatch(reset(null));
    },
    []
  );

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `items/job_title?fields=*,users_job_titles.id,standards.id,tasks.id,standards.standard_id.id,standards.standard_id.title,tasks.task_id.id,tasks.task_id.title&meta=*&limit=${limit}&page=${num}`,
          varName: "jobTitles",
        })
      );
    }
  };

  useEffect(() => {
    let item = {};
    const Arr = [];

    if (jobTitles && jobTitles.length !== 0 && jobTitlesMeta) {
      const d = [];
      for (let i = 0; i < PagesCount(+jobTitlesMeta.filter_count, limit); i += 1) {
        d.push(i);
      }
      setPageOption(d);
      jobTitles.forEach((jobTitle) => {
        item = {};
        item.id = jobTitle.id;
        item.title = jobTitle.title;
        item.users = jobTitle.users_job_titles?.length;
        if (jobTitle.id !== 1)
          item.action = (
            <>
              <MDTypography
                style={{ padding: 20, color: "#3b94ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onUpdateHandler("jobTitles", jobTitle)}
              >
                <Icon fontSize="small">edit</Icon>
              </MDTypography>
              <MDTypography
                style={{ padding: 20, color: "#e9423f" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onDeleteHandler("jobTitles", jobTitle)}
              >
                <Icon fontSize="small">delete</Icon>
              </MDTypography>
            </>
          );
        Arr.push(item);
      });
      setNewArr(Arr);
    }
  }, [jobTitles, jobTitlesMeta]);

  useEffect(() => {
    if ((allStandards, allTasks))
      setData([
        {
          type: "TextField",
          name: "title",
          id: "title",
          label: "title",
          size: 12,
          title: "jobTitles",
        },
        {
          type: "TextArea",
          name: "description",
          id: "description",
          label: "description",
          maxRows: 4,
          minRows: 4,
          size: 12,
          title: "descriptions",
        },
        {
          type: "MSelect",
          optionTitle: "title",
          name: "standards",
          id: "standards",
          label: "standards",
          data: allStandards,
          size: 6,
          title: "standard",
        },
        {
          type: "MSelect",
          optionTitle: "title",
          name: "tasks",
          id: "tasks",
          label: "tasks",
          data: allTasks,
          size: 6,
          title: "task",
        },
      ]);
  }, [allStandards, allTasks]);

  const resetForm = () => {
    dispatch(
      fetchDataAction({
        url: `items/standards?fields=id,title`,
        varName: "allStandards",
      })
    );

    dispatch(
      fetchDataAction({
        url: `items/tasks?fields=id,title`,
        varName: "allTasks",
      })
    );
    setInitialValues({
      id: null,
      title: null,
      description: null,
      standards: [],
      tasks: [],
    });
  };

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      setSuccessSB(true);
      resetForm();
    }
  }, [resStatus]);

  const setPayload = (values) => {
    const payloadData = {};
    let standardArr = [];
    let taskArr = [];
    const lastData = values.id ? UpdateRecord(values, initialValues) : values;

    if (values.id === null) {
      standardArr = values.standards.length > 0 ? { add: values.standards } : null;
      taskArr = values.tasks.length > 0 ? { add: values.tasks } : null;
    } else {
      standardArr = lastData?.standards || null;
      taskArr = lastData?.tasks || null;
    }

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });

    delete lastData.standards;
    delete lastData.tasks;

    const req = {
      id: values.id,
      origin: {
        data: Object.keys(payloadData).length === 0 ? null : payloadData,
        table: "job_title",
      },
      related: [
        {
          table: "job_titles_standards",
          data: standardArr,
          column: "standard_id",
          origin_column: "job_title_id",
        },
        {
          table: "job_titles_tasks",
          data: taskArr,
          column: "task_id",
          origin_column: "job_title_id",
        },
      ],
      getInfo: {
        url: `items/job_title?fields=*,users_job_titles.id,standards.id,tasks.id,standards.standard_id.id,standards.standard_id.title,tasks.task_id.id,tasks.task_id.title&meta=*&limit=${limit}&page=${number}`,
        varName: "jobTitles",
      },
    };
    setInitialValues(values);

    dispatch(addComplexDataAction(req));
  };

  const setOpenModal = () => {
    if (open) resetForm();
    setOpen(!open);
  };

  const handleConfirmDelete = async () => {
    const arra = [];

    deleteItemId.standards.forEach((element) => {
      arra.push({
        url: "items/job_titles_standards",
        data: {
          id: element.id,
          status: "deleted",
        },
      });
    });
    deleteItemId.tasks.forEach((element) => {
      arra.push({
        url: "items/job_titles_tasks",
        data: {
          id: element.id,
          status: "deleted",
        },
      });
    });
    const payload = {
      url: `items/job_title`,
      data: {
        id: deleteItemId.id,
        status: "deleted",
      },
      varName: "jobTitles",
      related: arra,

      getInfo: {
        url: `items/job_title?fields=*,users_job_titles.id,standards.id,tasks.id,standards.standard_id.id,standards.standard_id.title,tasks.task_id.id,tasks.task_id.title&meta=*&limit=${limit}&page=${number}`,
        varName: "jobTitles",
      },
    };

    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  if (data.length === 0 || newArr === [] || !jobTitlesMeta) {
    return null;
  }

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("systemDashboard")}
      content={t("successfulProcess")}
      dateTime={t("recently")}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  return (
    <>
      <Main
        layout="simple"
        count={pageOptions}
        pageIndex={number - 1}
        allRowsCount={+jobTitlesMeta.filter_count}
        setPageNum={setPageNumber}
        openDeleteModal={openDeleteModal}
        handleConfirmDelete={handleConfirmDelete}
        setOpen={setOpenModal}
        setOpenDeleteModal={setOpenDeleteModal}
        open={open}
        name="jobTitles"
        setPayload={setPayload}
        data={data}
        initialValues={initialValues}
        title={t("addJobTitle")}
        columns={columns}
        rows={newArr}
        report={false}
        onUpdateHandler={onUpdateHandler}
        onDeleteHandler={onDeleteHandler}
        yupObj={{
          title: yup.string("Enter your Title").required("Title is required"),
        }}
      />
      {renderSuccessSB}
    </>
  );
};

export default JobTitles;
