import { Print } from "@mui/icons-material";
import { Button } from "@mui/material";
import PropTypes from "prop-types";

function InvoiceActions({ handlePrint }) {
  const buttons = [
    {
      text: "Print",
      icon: Print,
      onClick: handlePrint,
    },
    // {
    //   text: "Download",
    //   icon: Download,
    //   onClick: () => {},
    // },
    // {
    //   text: "Send",
    //   icon: Send,
    //   onClick: () => {},
    // },
  ];

  return (
    <div>
      {buttons.map(({ text, icon: Icon, onClick }) => (
        <Button
          variant="contained"
          color="primary"
          disableElevation
          style={{ fontSize: 14, color: "#fff", margin: "0 4px" }}
          onClick={onClick}
          key={text}
          size="small"
        >
          {text} <Icon fontSize="small" style={{ color: "#fff" }} />
        </Button>
      ))}
    </div>
  );
}

InvoiceActions.propTypes = {
  handlePrint: PropTypes.func.isRequired,
};

export default InvoiceActions;
