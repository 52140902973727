import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
import Page from "components/UI/Page";
import { Icon, Card } from "@mui/material";
import { useLocation } from "react-router-dom";
import { fetchDataAction, deleteDataAction } from "store/features/coreSlice";
import MDButton from "components/MDButton";
import DataModal from "components/UI/DataModal";
import DataTable from "components/UI/Table/DataTable";
import DeleteModal from "components/UI/DeleteModal";
import PagesCount from "helpers/pages-count";
import { useTranslation } from "react-i18next";
import PageForm from "./components/PageForm";
import FilterForm from "./components/FilterForm";
import dataVal from "./dataVal.json";

const Stores = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const baseUrl = `items/gifts?fields=*,store_id.gifts.count,store_id.gifts.id,store_id.distributions.count,store_id.distributions.returns.count,store_id.distributions.id,store_id.count,store_id.buying_id.id,store_id.buying_id.notes,store_id.buying_id.book_id.publisher_id.title,store_id.buying_id.book_id.publisher_id.id,store_id.buying_id.book_id.id,store_id.buying_id.accounts.id,store_id.buying_id.accounts.last_gift_id,store_id.buying_id.book_id.title,store_id.buying_id.buying_date,store_id.place_id.title,store_id.place_id.id,store_id.id,job_title_id.title,job_title_id.id,publisher_id.id,publisher_id.title,type_id.title,type_id.id,user_id.first_name,user_id.id`;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { giftsMeta, gifts, limit, simbleLayout } = useSelector((state) => state.core);
  const [url, setUrl] = useState(baseUrl);
  const [newArr, setNewArr] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [number, setNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const [route, setRoute] = useState(null);
  const [pageOptions, setPageOption] = useState([]);
  const [formContainer, setFormContainer] = useState(<h1>wait ...</h1>);

  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const setOpenModal = (container) => {
    setFormContainer(container);
    setOpen(true);
  };

  const handleOpen = () =>
    setOpenModal(<PageForm updateHandlerVal={null} pageNum={number} url={url} />);

  const onDeleteHandler = (name, values) => {
    setOpenDeleteModal(true);
    setDeleteItemId(values.id);
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+giftsMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    if (gifts && gifts.length !== 0) {
      gifts.forEach((gift) => {
        item = {};
        keys = Object.keys(gift);
        keys.forEach((element) => {
          item[element] = gift[element];
        });
        // item.id = gift.id;
        item.title = gift.store_id?.buying_id?.book_id?.title;
        item.store = gift.store_id?.place_id?.title;
        item.publisher = gift.publisher_id?.title;
        const getToWho = () => {
          let y;
          if (gift.user_id && gift.to_who) y = `${gift.user_id?.first_name},${gift.to_who}`;
          else if (gift.user_id) y = gift.user_id?.first_name;
          else y = gift.to_who;

          return y;
        };
        item.towho = getToWho();
        item.buyingDate = gift.store_id?.buying_id?.buying_date;
        item.maneger = gift.job_title_id?.title;
        item.publisher = gift.store_id?.buying_id.book_id?.publisher_id?.title;
        item.type = gift.type_id?.title;

        let bigNum = 0;
        gift.store_id.buying_id.accounts.forEach((account) => {
          if (bigNum < account.last_gift_id) bigNum = account.last_gift_id;
        });

        // if (item.store_id?.buying_id?.accounts.length === 0) {
        item.action = (
          <>
            {route.update && gift.id > bigNum && (
              <MDTypography
                style={{ padding: 20, color: "#3b94ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => {
                  if (simbleLayout) {
                    setOpenModal(
                      <PageForm
                        updateHandlerVal={{
                          name: "gifts",
                          value: gift,
                        }}
                        pageNum={number}
                        url={url}
                      />
                    );
                  }
                }}
              >
                <Icon fontSize="small">edit</Icon>
              </MDTypography>
            )}
            {route.delete && gift.id > bigNum && (
              <MDTypography
                style={{ padding: 20, color: "#e9423f" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onDeleteHandler("gifts", gift)}
              >
                <Icon fontSize="small">delete</Icon>
              </MDTypography>
            )}
            {route.create && (
              <MDTypography
                style={{ padding: 20, color: "#3b00ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => {
                  if (simbleLayout) {
                    setOpenModal(
                      <PageForm
                        updateHandlerVal={{
                          name: "gifts",
                          value: gift,
                          isCopy: true,
                        }}
                        pageNum={number}
                        url={url}
                      />
                    );
                  }
                }}
              >
                <Icon fontSize="small">content_copy</Icon>
              </MDTypography>
            )}
          </>
        );
        // } else {
        //   item.action = null;
        // }
        Arr.push(item);
      });
      setNewArr(Arr);
    } else {
      setNewArr([]);
    }
  };

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `${url}&meta=*&limit=${limit}&page=${num}`,
          varName: "gifts",
        })
      );
    }
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: "items/gifts",
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${number}`,
        varName: "gifts",
      },
    };
    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  const setSortByHandeller = (val) => {
    let urlVal = `${url}&meta=*&limit=${limit}&page=${number}`;

    urlVal += `&sort=${val.desc ? "-" : ""}${val.id}`;
    dispatch(
      fetchDataAction({
        url: urlVal,
        varName: "gifts",
      })
    );
  };

  useEffect(() => {
    const routeItem = userInfo.routes.filter(
      (x) =>
        `${x.route_name}` === location.pathname.split("/")[location.pathname.split("/").length - 1]
    )[0];
    setRoute(routeItem);
  }, []);

  useEffect(() => {
    if (gifts && route) {
      prepareTableRows();
    }
  }, [gifts, route]);

  if (newArr === []) {
    return null;
  }

  return (
    <Page>
      <Card style={{ padding: 20, position: "relative" }}>
        <DataModal
          handleClose={() => {
            setOpen(false);
          }}
          open={open}
        >
          {formContainer}
        </DataModal>
        <DeleteModal
          handleClose={handleDeleteModalClose}
          handleConfirmDelete={handleConfirmDelete}
          openDeleteModal={openDeleteModal}
        />
        <FilterForm pageNum={number} baseUrl={baseUrl} setUrl={setUrl} setNumber={setNumber} />

        {simbleLayout && route?.create && (
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleOpen}
            style={{ float: "left" }}
            size="large"
          >
            <Icon>add</Icon>&nbsp; {t("add")}
          </MDButton>
        )}
        <DataTable
          table={{ columns: dataVal.columns, rows: newArr }}
          item={false}
          pageOptions={pageOptions}
          isSorted
          isPagination
          entriesPerPage
          showTotalEntries
          noEndBorder
          search
          setPageNum={setPageNumber}
          setSortByHandeller={setSortByHandeller}
          pageIndex={number - 1}
          allRowsCount={giftsMeta ? +giftsMeta.filter_count : 0}
        />
      </Card>
    </Page>
  );
};

export default Stores;
