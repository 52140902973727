import { Container } from "@mui/material";
import MDBox from "components/MDBox";
import { useTranslation } from "react-i18next";
import trakibLogoAR from "assets/images/logos/trakib-logo-ar.svg";
import trakibLogoEN from "assets/images/logos/trakib-logo-en.svg";
import MDTypography from "components/MDTypography";

function Footer() {
  const { i18n } = useTranslation();

  return (
    <MDBox bgColor="secondary" py={8}>
      <Container>
        <MDBox display="flex" justifyContent="space-between">
          <MDBox
            component="img"
            src={i18n.dir() === "rtl" ? trakibLogoAR : trakibLogoEN}
            alt="Trakib"
            width={120}
            sx={{ objectFit: "contain", cursor: "pointer" }}
          />
          <MDTypography variant="body2" color="white">
            Copyright &copy; {new Date().getFullYear()},{" "}
            <span style={{ fontWeight: "bold" }}>trakib</span>
          </MDTypography>
        </MDBox>
      </Container>
    </MDBox>
  );
}

export default Footer;
