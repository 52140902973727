import React from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import { Delete as DeleteIcon, Upload } from "@mui/icons-material";
import PropTypes from "prop-types";
// import { importActionPostMethod } from "store/features/coreSlice";
// import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import sheetsIcon from "assets/sheets.png";

// import SheetModal from "./SheetModal";

function FileHeader({ file, onDelete, error, payload }) {
  // const dispatch = useDispatch();
  // const fileUrl = URL.createObjectURL(file);

  const submitHandler = () => {
    console.log("file upload submitHandler", payload);
    if (file) {
      const reader = new FileReader();
      reader.onload = (readerObj) => {
        const dataI = readerObj.target.result;
        const workbook = XLSX.read(dataI, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonSheet = XLSX.utils.sheet_to_json(worksheet, { raw: false });
        console.log("jsonSheet = ", jsonSheet);
        // dispatch(
        //   importActionPostMethod({
        //     ...payload,
        //     data: jsonSheet,
        //   })
        // );
      };

      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <List dense>
      <ListItem>
        <ListItemAvatar>
          <img
            src={sheetsIcon}
            alt={file.name}
            style={{ width: 60, height: 60, objectFit: "cover" }}
          />
        </ListItemAvatar>
        <ListItemText primary={file.name} />
        <ListItemSecondaryAction>
          {!error && (
            <>
              <IconButton
                edge="end"
                color="warning"
                aria-label="upload"
                onClick={submitHandler}
                sx={{ mx: 0.1 }}
              >
                <Upload />
              </IconButton>
              {/* <SheetModal file={file} /> */}
            </>
          )}
          <IconButton edge="end" aria-label="delete" onClick={() => onDelete(file)}>
            <DeleteIcon color="error" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}

FileHeader.defaultProps = {
  error: false,
  payload: null,
};

FileHeader.propTypes = {
  file: PropTypes.objectOf(PropTypes.any).isRequired,
  onDelete: PropTypes.func.isRequired,
  error: PropTypes.bool,
  payload: PropTypes.objectOf(PropTypes.any),
};

export default FileHeader;
