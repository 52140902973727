import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";
import * as yup from "yup";
import MDTypography from "components/MDTypography";
import { Icon } from "@mui/material";
// import MDSnackbar from "components/MDSnackbar";
import { useTranslation } from "react-i18next";
import {
  fetchDataAction,
  deleteDataAction,
  reset,
  addComplexDataAction,
  setAlert,
} from "store/features/coreSlice";
import UpdateRecord from "helpers/compare";
import PagesCount from "helpers/pages-count";

const Programs = () => {
  const baseUrl = `items/programs?fields=*,rankings.ranking_id.id,rankings.ranking_id.title,rankings.id,works.id,accounts.id`;
  const { t } = useTranslation();
  const { programs, programsMeta, resStatus, limit, allRankings } = useSelector(
    (state) => state.core
  );

  const dispatch = useDispatch();

  const columns = [
    { Header: "title", accessor: "title", width: "45%", align: "center" },
    { Header: "works", accessor: "works", width: "45%", align: "center" },
    { Header: "accounts", accessor: "accounts", width: "45%", align: "center" },
    { Header: "ranking", accessor: "rankings", width: "45%", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [newArr, setNewArr] = useState([]);
  const [number, setNumber] = useState(1);
  const [data, setData] = useState([]);
  const [pageOptions, setPageOption] = useState([]);

  const [initialValues, setInitialValues] = useState({
    id: null,
    title: null,
    description: null,
    rankings: [],
  });
  // const [successSB, setSuccessSB] = useState(false);
  // const closeSuccessSB = () => setSuccessSB(false);

  const onUpdateHandler = (name, values) => {
    const rankingsData = [];
    values.rankings.forEach((element) => {
      rankingsData.push(element.ranking_id);
    });

    setInitialValues({
      id: values.id,
      title: values.title,
      rankings: rankingsData,
      description: values.description,
    });
    setOpen(true);
  };

  const onDeleteHandler = (name, values) => {
    if (values.works.length === 0 && values.rankings.length === 0 && values.accounts.length === 0) {
      setOpenDeleteModal(true);
      setDeleteItemId(values);
    } else {
      dispatch(
        setAlert({
          status: "error",
          content: t("hasUsers"),
        })
      );
    }
  };

  useEffect(() => {
    const payload = {
      url: `${baseUrl}&meta=*&limit=${limit}&page=${number}`,
      varName: "programs",
    };
    dispatch(fetchDataAction(payload));

    dispatch(
      fetchDataAction({
        url: `items/research_rankings?fields=id,title`,
        varName: "allRankings",
      })
    );
  }, [limit]);

  useEffect(
    () => () => {
      dispatch(reset(null));
    },
    []
  );

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `${baseUrl}&meta=*&limit=${limit}&page=${num}`,
          varName: "programs",
        })
      );
    }
  };

  useEffect(() => {
    let item = {};
    const Arr = [];

    if (programs && programs.length !== 0 && programsMeta) {
      const d = [];
      for (let i = 0; i < PagesCount(+programsMeta.filter_count, limit); i += 1) {
        d.push(i);
      }
      setPageOption(d);
      programs.forEach((program) => {
        item = {};
        item.id = program.id;
        item.title = program.title;
        item.works = program.works.length;
        item.rankings = program.rankings.length;
        item.accounts = program.accounts.length;
        item.action = (
          <>
            <MDTypography
              style={{ padding: 20, color: "#3b94ee" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => onUpdateHandler("programs", program)}
            >
              <Icon fontSize="small">edit</Icon>
            </MDTypography>
            <MDTypography
              style={{ padding: 20, color: "#e9423f" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => onDeleteHandler("programs", program)}
            >
              <Icon fontSize="small">delete</Icon>
            </MDTypography>
          </>
        );
        Arr.push(item);
      });
      setNewArr(Arr);
    }
  }, [programs, programsMeta]);

  useEffect(() => {
    if (allRankings)
      setData([
        {
          type: "TextField",
          name: "title",
          id: "title",
          label: "title",
          size: 6,
          title: "program",
        },
        {
          type: "MSelect",
          optionTitle: "title",
          name: "rankings",
          id: "rankings",
          label: "rankings",
          data: allRankings,
          size: 6,
          title: "ranking",
        },
        {
          type: "TextArea",
          name: "description",
          id: "description",
          label: "description",
          maxRows: 4,
          minRows: 4,
          size: 12,
          title: "description",
        },
      ]);
  }, [allRankings]);

  const resetForm = () => {
    setInitialValues({
      id: null,
      title: null,
      description: null,
      rankings: [],
    });
    dispatch(
      fetchDataAction({
        url: `items/research_rankings?fields=id,title`,
        varName: "allRankings",
      })
    );
  };

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      resetForm();
    }
  }, [resStatus]);

  const setPayload = (values) => {
    const payloadData = {};
    let rankingArr = [];
    const lastData = values.id ? UpdateRecord(values, initialValues) : values;

    if (values.id === null) {
      rankingArr = values.rankings.length > 0 ? { add: values.rankings } : null;
    } else {
      rankingArr = lastData?.rankings || null;
    }

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });

    delete lastData.rankings;

    const req = {
      id: values.id,
      origin: {
        data: Object.keys(payloadData).length === 0 ? null : payloadData,
        table: "programs",
      },
      related: [
        {
          table: "programs_rankings",
          data: rankingArr,
          column: "ranking_id",
          origin_column: "program_id",
        },
      ],
      getInfo: {
        url: `${baseUrl}&meta=*&limit=${limit}&page=${number}`,
        varName: "programs",
      },
    };
    setInitialValues(values);

    dispatch(addComplexDataAction(req));
  };

  const setOpenModal = () => {
    if (open) resetForm();
    setOpen(!open);
  };

  const handleConfirmDelete = async () => {
    const arra = [];

    deleteItemId.rankings.forEach((element) => {
      arra.push({
        url: "items/programs_rankings",
        data: {
          id: element.id,
          status: "deleted",
        },
      });
    });

    const payload = {
      url: `items/programs`,
      data: {
        id: deleteItemId.id,
        status: "deleted",
      },
      related: arra,
      varName: "programs",
      getInfo: {
        url: `${baseUrl}&meta=*&limit=${limit}&page=${number}`,
        varName: "programs",
      },
    };

    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  if (data.length === 0 || newArr === [] || !programsMeta) {
    return null;
  }

  return (
    <Main
      layout="simple"
      count={pageOptions}
      pageIndex={number - 1}
      allRowsCount={+programsMeta.filter_count}
      setPageNum={setPageNumber}
      openDeleteModal={openDeleteModal}
      handleConfirmDelete={handleConfirmDelete}
      setOpen={setOpenModal}
      setOpenDeleteModal={setOpenDeleteModal}
      open={open}
      name="programs"
      setPayload={setPayload}
      data={data}
      initialValues={initialValues}
      title={t("addProgram")}
      columns={columns}
      rows={newArr}
      report={false}
      onUpdateHandler={onUpdateHandler}
      onDeleteHandler={onDeleteHandler}
      yupObj={{
        title: yup.string("Enter your Title").required("Title is required"),
      }}
    />
  );
};

export default Programs;
