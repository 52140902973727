// import { Icon } from "@mui/material";
import { Box } from "@mui/system";
import { Grid, Icon } from "@mui/material";

import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { deleteFileAction } from "store/features/coreSlice";
// import classes from "./index.css";

const ImgList = ({ files }) => {
  const dispatch = useDispatch();
  const { baseURL } = useSelector((state) => state.core);

  const getImg = (file) => {
    let u = "description";
    if (file.file_id.type === "application/pdf") u = "picture_as_pdf";
    else if (file.file_id.type === "application/x-zip") u = "folder_zip";
    else if (file.file_id.type === "application/vnd.rar") u = "folder_zip";
    return u;
  };
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {files?.map((file) => (
        <Grid item xs={2} key={file.id}>
          <MDButton
            variant="gradient"
            color="error"
            size="small"
            iconOnly
            onClick={() => {
              dispatch(
                deleteFileAction({
                  collection: file.collection,
                  id: file.id,
                  item_id: file.item_id,
                  file_id: file.file_id.id,
                  filesize: file.file_id.filesize,
                })
              );
            }}
          >
            <Icon>dangerous</Icon>
          </MDButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 100,
            }}
            onClick={() => {
              window.open(
                `${baseURL}assets/${file.file_id.id}?fit=cover&width=500&height=800&quality=80`,
                "_blank"
              );
            }}
          >
            {file.file_id.type === "image/jpeg" || file.file_id.type === "image/png" ? (
              <img
                src={`${baseURL}assets/${file.file_id.id}?fit=cover&width=80&height=100&quality=80`}
                alt=""
              />
            ) : (
              <MDTypography
                style={{ padding: 5, color: "#3b94ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
              >
                <Icon fontSize="large">{getImg(file)}</Icon>
              </MDTypography>
            )}
          </Box>
          <MDTypography style={{ textAlign: "center", fontSize: 16 }}>
            {file.file_id.filename_download}
          </MDTypography>
        </Grid>
      ))}
    </Grid>
  );
};

ImgList.propTypes = {
  files: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
};

export default ImgList;
