/**
=========================================================
* Trakib System - v2.1.0
=========================================================

* Product Page: https://trakib.comproduct/trakib-system-react
* Copyright 2022 trakib (https://trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Trakib System base styles
import borders from "assets/theme/base/borders";

// Trakib System helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;

const tableHead = {
  styleOverrides: {
    root: {
      display: "block",
      padding: `${pxToRem(16)} ${pxToRem(16)} 0  ${pxToRem(16)}`,
      borderRadius: `${borderRadius.xl} ${borderRadius.xl} 0 0`,
    },
  },
};

export default tableHead;
