import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

const AvatarWithOutImg = ({ name, email }) => (
  <MDBox alignItems="center" lineHeight={1}>
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      {email && <MDTypography variant="caption">{email}</MDTypography>}
    </MDBox>
  </MDBox>
);

AvatarWithOutImg.propTypes = {
  email: PropTypes.node,
  name: PropTypes.node.isRequired,
};

AvatarWithOutImg.defaultProps = {
  email: null,
};

export default AvatarWithOutImg;
