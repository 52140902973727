import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import PropTypes from "prop-types";

const Avatar = ({ image, name, email, dir, alt }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1} style={{ direction: dir }}>
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      {email && <MDTypography variant="caption">{email}</MDTypography>}
    </MDBox>
    {image && <MDAvatar src={image} alt={alt} name={name} size="sm" />}
  </MDBox>
);

Avatar.propTypes = {
  email: PropTypes.node,
  image: PropTypes.node,
  alt: PropTypes.string,
  name: PropTypes.node.isRequired,
  dir: PropTypes.node.isRequired,
};

Avatar.defaultProps = {
  email: null,
  image: null,
  alt: null,
};

export default Avatar;
