import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataForm from "components/UI/form/DataForm";
import { fetchDataAction } from "store/features/coreSlice";
import PropTypes from "prop-types";
import dataVal from "../../dataVal.json";

const FilterForm = ({ setNumber, setUrl, baseUrl }) => {
  const dispatch = useDispatch();
  const { filterjobtitles, filterstandards, filterusersjobtitles, limit } = useSelector(
    (state) => state.core
  );
  const [data, setData] = useState([]);
  const [filteredValues, setFilteredValues] = useState(null);
  const [jobTitleId, setJobTitleId] = useState(null);

  const [initialValues, setInitialValues] = useState(dataVal.initialValuesFilter);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "title",
        name: "job_title_id",
        id: "job_title_id",
        label: "job_title_id",
        data: filterjobtitles,
        size: 3,
        title: "jobTitle",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "standard_id",
        id: "standard_id",
        label: "standard_id",
        data: filterstandards,
        size: 3,
        title: "standard",
      },
      {
        type: "Select",
        optionTitle: "user_id",
        oterOptionTitle: "first_name",
        name: "users_jobtitles",
        id: "users_jobtitles",
        label: "users_jobtitles",
        secondOptionTitle: "job_title_id",
        secondOtherOptionTitle: "title",
        data: filterusersjobtitles,
        size: 3,
        title: "name",
      },
    ]);
  };

  useEffect(() => {
    if (filterjobtitles && filterstandards && filterusersjobtitles) orgData();
  }, [filterjobtitles, filterstandards, filterusersjobtitles]);

  const generateUrl = (values) => {
    let str = baseUrl;
    const keys = Object.keys(values);
    keys.forEach((element) => {
      if (values[element] && values[element] !== []) {
        if (typeof values[element] === "object") {
          if (element === "job_title_id") {
            str += `&filter[users_jobtitles.job_title_id.id]=${values[element].id}`;
          } else {
            str += `&filter[${element}.id]=${values[element].id}`;
          }
        } else {
          str += `&filter[${element}][_contains]=${values[element]}`;
        }
      }
    });
    return str;
  };

  const setPayload = (values) => {
    setFilteredValues(values);
    setNumber(1);
    if (values) setInitialValues(values);
    setUrl(generateUrl(values));
    dispatch(
      fetchDataAction({
        url: `${generateUrl(values)}&meta=*&limit=${limit}&page=${1}`,
        varName: "usersStandards",
      })
    );
  };

  const setFieldSelectedValue = (name, values) => {
    if (name === "job_title_id") {
      setJobTitleId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/users_job_title?fields=id,user_id.id,user_id.first_name,job_title_id.id,job_title_id.title&filter[job_title_id.id]=${values.id}`,
          varName: "filterusersjobtitles",
        })
      );

      dispatch(
        fetchDataAction({
          url: `items/standards?fields=id,title,jobtitles.job_title_id.id&filter[jobtitles.job_title_id.id]=${values.id}`,
          varName: "filterstandards",
        })
      );
    }
  };

  const onChangeHandler = (name, text) => {
    let urlVal;
    if (name === "job_title_id")
      dispatch(
        fetchDataAction({
          url: `items/job_title?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "filterjobtitles",
        })
      );

    if (name === "standard_id") {
      urlVal = `items/standards?fields=id,title,jobtitles.job_title_id.id&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (jobTitleId) urlVal += `&filter[jobtitles.job_title_id.id]=${jobTitleId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterstandards",
        })
      );
    }

    if (name === "users_jobtitles") {
      urlVal = `items/users_job_title?fields=id,user_id.id,user_id.first_name,job_title_id.id,job_title_id.title&filter[user_id.first_name][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (jobTitleId) urlVal += `&filter[job_titles.job_title_id.id]=${jobTitleId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterusersjobtitles",
        })
      );
    }
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      if (element === "jobtitles")
        dispatch(
          fetchDataAction({
            url: `items/job_title?fields=id,title&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
      else if (element === "usersjobtitles")
        dispatch(
          fetchDataAction({
            url: `items/users_job_title?fields=id,user_id.id,user_id.first_name,job_title_id.id,job_title_id.title&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
      else
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,title&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
    });
  };

  useEffect(() => {
    getData(dataVal.oterData);
  }, []);

  useEffect(() => {
    setPayload(filteredValues || initialValues);
  }, [limit]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      filter
      yupObj={{}}
      name="usersStandardsFilter"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeHandler}
    />
  );
};

FilterForm.propTypes = {
  setUrl: PropTypes.func.isRequired,
  setNumber: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};
export default FilterForm;
