import axios from "axios";
import localStoragePrefix from "helpers/config";

axios.defaults.baseURL = "https://system.trakib.com";

axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem(`${localStoragePrefix}token`);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axios;
