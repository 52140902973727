import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import MDButton from "components/MDButton";

export default function DeleteModal({ openDeleteModal, handleClose, handleConfirmDelete }) {
  const { t } = useTranslation();

  return (
    <div>
      <Dialog
        open={openDeleteModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("confirmDelete")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("confirmDelete")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose} variant="outlined" color="info">
            {t("cancel")}
          </MDButton>
          <MDButton onClick={handleConfirmDelete} variant="outlined" color="error">
            {t("ok")}
          </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DeleteModal.propTypes = {
  openDeleteModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirmDelete: PropTypes.func.isRequired,
};
