import AppBar from "./AppBar";

function NavBar() {
  return (
    <nav style={{ padding: "20px 0" }}>
      <AppBar />
    </nav>
  );
}

export default NavBar;
