import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
import Page from "components/UI/Page";
import { Icon, Card } from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  fetchDataAction,
  deleteDataAction,
  setAlert,
  addComplexDataAction,
} from "store/features/coreSlice";
import MDButton from "components/MDButton";
import DataModal from "components/UI/DataModal";
import DataTable from "components/UI/Table/DataTable";
import DeleteModal from "components/UI/DeleteModal";
import PagesCount from "helpers/pages-count";
import { useTranslation } from "react-i18next";
import ImportDB from "components/UI/ImportDB";
import PageForm from "./components/PageForm";
import FilterForm from "./components/FilterForm";
import dataVal from "./dataVal.json";

const Buying = () => {
  const baseUrl = `items/proposal?fields=*,distributions.id,book_id.id,book_id.title,book_id.publisher_id.id,book_id.publisher_id.title,currency_id.title,currency_id.id`;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.auth);
  const { proposalsMeta, proposals, limit, simbleLayout } = useSelector((state) => state.core);
  const [url, setUrl] = useState(baseUrl);
  const [newArr, setNewArr] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [number, setNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const [route, setRoute] = useState(null);
  const [pageOptions, setPageOption] = useState([]);
  const [formContainer, setFormContainer] = useState(<h1>wait ...</h1>);

  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const setOpenModal = (container) => {
    setFormContainer(container);
    setOpen(true);
  };

  const handleOpen = () => setOpenModal(<PageForm pageNum={number} url={url} />);

  const onDeleteHandler = (name, values) => {
    if (values.distributions.length === 0) {
      setOpenDeleteModal(true);
      setDeleteItemId(values.id);
    } else {
      dispatch(
        setAlert({
          status: "error",
          content: t("hasDistributions"),
        })
      );
    }
  };

  const changeStatus = (status, proposal) => {
    const req = {
      id: proposal.id,
      origin: {
        data: { status },
        table: "proposal",
      },
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${number}`,
        varName: "proposals",
      },
    };
    dispatch(addComplexDataAction(req));
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+proposalsMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    if (proposals && proposals.length !== 0) {
      proposals.forEach((proposal) => {
        item = {};
        keys = Object.keys(proposal);
        keys.forEach((element) => {
          item[element] = proposal[element];
        });
        item.title = proposal.book_id.title;
        item.price = `${proposal.cost} ${proposal.is_dolar ? "$" : ""}`;
        item.discount_percentage = proposal.discount_percentage;
        item.action = (
          <>
            {route.delete && (
              <MDTypography
                className="button-group"
                style={{ padding: 20, color: "#e9423f" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onDeleteHandler("proposals", proposal)}
              >
                <Icon fontSize="small">delete</Icon>
              </MDTypography>
            )}
            {route.delete && proposal.status === "draft" ? (
              <MDTypography
                className="button-group"
                style={{ padding: 20, color: "#e9423f" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => changeStatus("published", proposal)}
              >
                <Icon fontSize="small">public_off</Icon>
              </MDTypography>
            ) : (
              route.delete && (
                <MDTypography
                  style={{ padding: 20, color: "green" }}
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => changeStatus("draft", proposal)}
                >
                  <Icon fontSize="small">public</Icon>
                </MDTypography>
              )
            )}
          </>
        );
        Arr.push(item);
      });
      setNewArr(Arr);
    } else {
      setNewArr([]);
    }
  };

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `${url}&meta=*&limit=${limit}&page=${num}`,
          varName: "proposals",
        })
      );
    }
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: "items/proposal",
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${number}`,
        varName: "proposals",
      },
    };
    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  const setSortByHandeller = (val) => {
    let urlVal = `${url}&meta=*&limit=${limit}&page=${number}`;

    urlVal += `&sort=${val.desc ? "-" : ""}${val.id}`;
    dispatch(
      fetchDataAction({
        url: urlVal,
        varName: "proposals",
      })
    );
  };

  useEffect(() => {
    const routeItem = userInfo.routes.filter(
      (x) =>
        `${x.route_name}` === location.pathname.split("/")[location.pathname.split("/").length - 1]
    )[0];
    setRoute(routeItem);
  }, []);

  useEffect(() => {
    if (proposals && route) {
      prepareTableRows();
    }
  }, [proposals, route]);

  if (newArr === []) {
    return null;
  }

  return (
    <Page>
      <Card style={{ padding: 20, position: "relative" }}>
        <DataModal
          handleClose={() => {
            setOpen(false);
          }}
          open={open}
        >
          {formContainer}
        </DataModal>
        <DeleteModal
          handleClose={handleDeleteModalClose}
          handleConfirmDelete={handleConfirmDelete}
          openDeleteModal={openDeleteModal}
        />
        <FilterForm pageNum={number} baseUrl={baseUrl} setUrl={setUrl} setNumber={setNumber} />
        {simbleLayout && route?.create && (
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleOpen}
            style={{ float: "left" }}
            size="large"
          >
            <Icon>add</Icon>&nbsp; {t("add")}
          </MDButton>
        )}
        <DataTable
          table={{ columns: dataVal.columns, rows: newArr }}
          item={false}
          pageOptions={pageOptions}
          isSorted
          isPagination
          entriesPerPage
          showTotalEntries
          noEndBorder
          search
          setPageNum={setPageNumber}
          setSortByHandeller={setSortByHandeller}
          pageIndex={number - 1}
          allRowsCount={proposalsMeta ? +proposalsMeta.filter_count : 0}
        />
        <ImportDB
          payload={{
            url: `custom/importProposals`,
            varName: "importProposals",
            getInfo: {
              url: `${url}&meta=*&limit=${limit}&page=${number}`,
              varName: "proposals",
            },
          }}
        />
      </Card>
    </Page>
  );
};

export default Buying;
