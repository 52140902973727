import { WhatsApp } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

const whatsappNumber = "+905011373997";
const whatsAppUrl = `https://web.whatsapp.com/send?phone=${whatsappNumber}`;

function WhatsappContact({ noNumber }) {
  return (
    <IconButton color="info" onClick={() => window.open(whatsAppUrl, "_blank")} dir="ltr">
      {/* {!noNumber ? <WhatsApp color="info" /> : <WhatsApp fontSize="large" color="info" />} */}
      <WhatsApp color="info" className="whatsapp-icon" />

      {!noNumber && (
        <MDTypography variant="body2" color="info">
          {whatsappNumber}
        </MDTypography>
      )}
    </IconButton>
  );
}

WhatsappContact.defaultProps = {
  noNumber: false,
};

WhatsappContact.propTypes = {
  noNumber: PropTypes.bool,
};

export default WhatsappContact;
