import { Grid } from "@mui/material";
import Page from "components/UI/Page";
import Degrees from "./SubContent/Degrees";
import JobTitles from "./SubContent/JobTitles";
import Specializations from "./SubContent/Specializations";
import Standards from "./SubContent/Standards";
import Tasks from "./SubContent/Tasks";
import Universities from "./SubContent/Universities";

const UserSettings = () => (
  <Page multi>
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <JobTitles />
      </Grid>
      <Grid item xs={6}>
        <Standards />
      </Grid>
      <Grid item xs={6}>
        <Tasks />
      </Grid>
      <Grid item xs={6}>
        <Degrees />
      </Grid>
      <Grid item xs={6}>
        <Specializations />
      </Grid>
      <Grid item xs={6}>
        <Universities />
      </Grid>
    </Grid>
  </Page>
);

export default UserSettings;
