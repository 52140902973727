/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import localStoragePrefix from "helpers/config";
import axios from "axios";
import PrepareUrl from "helpers/prepare-url";
import Api from "../../services/api";
import { getUserInfoAction } from "./authSlice";

const initialState = {
  baseURL: "https://system.trakib.com/",
  // baseURL: "http://becamepossible.com:8055/",
  message: null,
  loading: false,
  loadingFiles: false,
  resStatus: undefined,
  limit: localStorage.getItem(`${localStoragePrefix}limit`)
    ? +localStorage.getItem(`${localStoragePrefix}limit`)
    : 10,
  simbleLayout: localStorage.getItem(`${localStoragePrefix}simbleLayout`) !== "notSimple",
  routesData: {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
      },
      {
        name: "Sales",
        key: "sales",
        route: "/dashboards/sales",
      },
    ],
  },
  pagesArr: [],
};

export const fetchDataAction = createAsyncThunk(
  "core/fetchDataAction",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { userInfo },
      } = getState();
      let url;
      if (
        (userInfo.admin_id === 1 &&
          payload.varName !== "publisherLibraryCharts" &&
          payload.varName !== "libraryCountryChart" &&
          payload.varName !== "inventoryLibrary") ||
        payload.varName === "roles" ||
        payload.varName === "settingsItem" ||
        payload.varName === "authorsTypes" ||
        payload.varName === "countries" ||
        payload.varName === "formcountries" ||
        payload.varName === "formresidence" ||
        payload.varName === "filterresidence" ||
        payload.varName === "filtercountries" ||
        payload.varName === "cities" ||
        payload.varName === "formcities" ||
        payload.varName === "filtercities" ||
        payload.varName === "degrees" ||
        payload.varName === "formdegrees" ||
        payload.varName === "filterdegrees" ||
        payload.varName === "messages" ||
        payload.varName === "sysMessages" ||
        payload.varName === "formtypes" ||
        payload.varName === "types" ||
        payload.varName === "filtertypes" ||
        payload.varName === "category" ||
        // payload.varName === "libraries" ||
        // payload.varName === "formlibraries" ||
        // payload.varName === "filterlibraries" ||
        payload.varName === "accountsItems" ||
        payload.varName === "formaccountsItems" ||
        payload.varName === "filteraccountsItems" ||
        payload.varName === "formpublishers" ||
        payload.varName === "filterpublishers" ||
        payload.varName === "publishers" ||
        payload.varName === "categories" ||
        payload.varName === "admins" ||
        payload.varName === "jobtitles" ||
        payload.varName === "formjobtitles" ||
        payload.varName === "formjobtitle" ||
        payload.varName === "formcojobtitle" ||
        payload.varName === "filterjobtitle" ||
        payload.varName === "filtercojobtitle" ||
        payload.varName === "filterjobtitles" ||
        payload.varName === "jobTitles" ||
        payload.varName === "formjobTitles" ||
        payload.varName === "filterjobTitles" ||
        payload.varName === "standards" ||
        payload.varName === "filterstandards" ||
        payload.varName === "formstandards" ||
        payload.varName === "tasks" ||
        payload.varName === "filtertasks" ||
        payload.varName === "formtasks" ||
        payload.varName === "specializations" ||
        payload.varName === "filterspecializations" ||
        payload.varName === "formspecializations" ||
        payload.varName === "universities" ||
        payload.varName === "formuniversities" ||
        payload.varName === "filteruniversities" ||
        payload.varName === "collections"
      ) {
        url = "";
      } else if (
        payload.varName === "salaries" ||
        payload.varName === "publisherLibraryCharts" ||
        payload.varName === "libraryCountryChart" ||
        payload.varName === "inventoryLibrary"
      ) {
        url = `?admin_id=${userInfo.admin_id}`;
      } else if (
        payload.varName === "inventoryAccounts" ||
        payload.varName === "myNotifications" ||
        payload.varName === "myLinks"
      ) {
        url = `&admin_id=${userInfo.admin_id}`;
      } else if (
        payload.varName === "users" ||
        payload.varName === "formusers" ||
        payload.varName === "filterusers" ||
        payload.varName === "users" ||
        payload.varName === "allUsers" ||
        payload.varName === "allusers" ||
        payload.varName === "allcousers" ||
        payload.varName === "formcousers" ||
        payload.varName === "filtercousers"
      ) {
        url = `&filter[admin_id][_eq]=${userInfo.admin_id}`;
      } else if (payload.varName === "settings") {
        url = `&filter[id][_eq]=${userInfo.admin_id}`;
      } else if (payload.varName === "activities") {
        url = `&filter[user][admin_id][_eq]=${userInfo.admin_id}`;
      } else {
        url = `&filter[user_created][admin_id][_eq]=${userInfo.admin_id}`;
      }
      const newUrl = PrepareUrl(payload.url);

      const { data } = await Api.get(`${newUrl}${url}`);
      const resPayload = {};
      resPayload.data = data.data;
      resPayload.meta = data.meta;
      resPayload.varName = payload.varName;
      return resPayload;
    } catch (error) {
      const message =
        error.response && error.response.data.errors.length > 0
          ? error.response.data.errors[0].message
          : error.message;
      if (message) {
        throw error;
      }
      return rejectWithValue(message);
    }
  }
);

export const fetchDataActionPostMethod = createAsyncThunk(
  "core/fetchDataActionPostMethod",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { userInfo },
      } = getState();
      // payload.data.uid = userInfo.id;
      // payload.data.adminId = userInfo.admin_id;
      const { data } = await Api.post(`${payload.url}`, {
        ...payload.data,
        adminId: userInfo.admin_id,
        uid: userInfo.id,
      });
      const resPayload = {};
      resPayload.data = data.data;
      resPayload.varName = payload.varName;
      return resPayload;
    } catch (error) {
      const message =
        error.response && error.response.data.errors.length > 0
          ? error.response.data.errors[0].message
          : error.message;

      if (message) {
        throw error;
      }
      return rejectWithValue(message);
    }
  }
);

export const importActionPostMethod = createAsyncThunk(
  "core/importActionPostMethod",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await Api.post(`${payload.url}`, payload.data);
      const resPayload = {};
      resPayload.data = data.data;
      resPayload.varName = payload.varName;
      window.location.reload();
      // await dispatch(fetchDataAction(payload.getInfo));
      return resPayload;
    } catch (error) {
      const message =
        error.response && error.response.data.errors.length > 0
          ? error.response.data.errors[0].message
          : error.message;

      if (message) {
        throw error;
      }
      return rejectWithValue(message);
    }
  }
);

export const addDataAction = createAsyncThunk(
  "core/addDataAction",
  // eslint-disable-next-line no-unused-vars
  async (payload, { _, rejectWithValue }) => {
    try {
      if (payload.data.id) {
        await Api.patch(`${payload.url}/${payload.data.id}`, payload.data);
      } else {
        await Api.post(payload.url, payload.data);
      }

      return true;
    } catch (error) {
      const message =
        error.response && error.response.data.errors.length > 0
          ? error.response.data.errors[0].message
          : error.message;
      return rejectWithValue(message);
    }
  }
);

export const addDepartureAction = createAsyncThunk(
  "core/addDepartureAction",
  // eslint-disable-next-line no-unused-vars
  async (payload, { _, rejectWithValue }) => {
    try {
      console.log("object object object object object");
      const { data } = await Api.get(
        `items/attendance?fields=id&filter[date][_eq]=${payload.date}&filter[user_id][_eq]=${payload.user_id}`
      );
      console.log(data.data[0].id);

      console.log(data.data[0].id);
      if (data.data.length > 0) {
        await Api.patch(`items/attendance/${data.data[0].id}`, {
          departure_hours: payload.departure_hours,
          departure_minutes: payload.departure_minutes,
        });
      }
      return true;
    } catch (error) {
      const message =
        error.response && error.response.data.errors.length > 0
          ? error.response.data.errors[0].message
          : error.message;
      return rejectWithValue(message);
    }
  }
);

export const fireLoader = createAsyncThunk("core/fireLoader", async (payload) => payload);

export const deleteFileAction = createAsyncThunk(
  "core/deleteFileAction",
  async (payload, { dispatch, rejectWithValue, getState }) => {
    try {
      const {
        auth: { userInfo },
      } = getState();

      await Api.delete(`items/all_files/${payload.id}`);
      await Api.delete(`files/${payload.file_id}`);
      await dispatch(
        fetchDataAction({
          url: `items/all_files?fields=id,collection,file_id.filesize,file_id.id,item_id,file_id,file_id.type,file_id.filename_download&filter[collection][_eq]=${payload.collection}&filter[item_id][_eq]=${payload.item_id}`,
          varName: `${payload.collection}ImgList`,
        })
      );
      const usedStorage = await Api.get(
        `items/admins/${userInfo.admin_id}?fields=available_storage`
      );

      if (+usedStorage.data.data.available_storage !== -1) {
        await Api.patch(`items/admins/${userInfo.admin_id}`, {
          available_storage: +usedStorage.data.data.available_storage + +payload.filesize,
        });
      }
      return { status: "success" };
    } catch (error) {
      const message =
        error.response && error.response.data.errors.length > 0
          ? error.response.data.errors[0].message
          : error.message;
      if (message) {
        throw error;
      }

      return rejectWithValue(message);
    }
  }
);

export const uploadFileAction = async (payload, adminId, baseURL) => {
  try {
    const usedStorage = await Api.get(`items/admins/${adminId}?fields=available_storage`);
    let b;
    // check free storage
    if (
      +usedStorage.data.data.available_storage > +payload.size ||
      +usedStorage.data.data.available_storage === -1
    ) {
      const formData = new FormData();
      formData.append("data", payload);
      const token = localStorage.getItem(`${localStoragePrefix}token`);

      const res = await axios.post(`${baseURL}files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      });

      // update used storage
      if (+usedStorage.data.data.available_storage !== -1) {
        await Api.patch(`items/admins/${adminId}`, {
          available_storage: +usedStorage.data.data.available_storage - +payload.size,
        });
      }

      b = res.data.data.id;
    } else {
      console.log("no storage available");
      b = null;
    }
    return b;
  } catch (error) {
    const message =
      error.response && error.response.data.errors.length > 0
        ? error.response.data.errors[0].message
        : error.message;
    if (message) {
      throw error;
    }
    return message;
  }
};

export const checkNewWorkItem = async (payload) => {
  let goOn;
  if (payload.origin.table === "works" && payload.origin.data.due_date) {
    const oldData = await Api.get(
      `items/works?fields=id&filter[due_date][_eq]=${payload.origin.data.due_date}&filter[work_status][_eq]=done&filter[user_id][_eq]=${payload.origin.data.user_id}`
    );
    if (oldData.data.data.length > 0) {
      goOn = false;
      alert("other works in this due date is done");
    } else goOn = true;
  } else {
    goOn = true;
  }
  return goOn;
};

export const addComplexDataAction = createAsyncThunk(
  "core/addComplexDataAction",
  async (payload, { dispatch, rejectWithValue, getState }) => {
    try {
      const {
        auth: { userInfo },
        core: { baseURL },
      } = getState();
      let data;
      if (payload.salaries) {
        data = await Api.post(`custom/addAccounts`, payload);
        await dispatch(fetchDataAction(payload.getInfo));
      } else if (payload.file?.multi) {
        const { file, ...clone } = payload;
        if (payload.file.file) {
          const resp1 = await uploadFileAction(payload.file.file, userInfo.admin_id, baseURL);
          if (resp1) payload.origin.data[payload.imgFieldName || "image"] = resp1;
        }
        data = await Api.post(`custom/complex`, { file: null, ...clone });
        // data = await Api.post(`custom/complex`, clone);
        if (payload.file?.files?.length > 0) {
          let resp;
          await dispatch(fireLoader({ loadingFiles: true }));

          for (const element of payload.file.files) {
            resp = await uploadFileAction(element, userInfo.admin_id, baseURL);
            if (resp) {
              const req = {
                file_id: resp,
                item_id: data.data.response,
                collection: payload.origin.table,
              };
              await Api.post(`items/all_files`, req);
            }
          }
          await dispatch(fireLoader({ loadingFiles: false }));
        }

        await dispatch(fetchDataAction(payload.getInfo));
      } else if (payload.file && payload.file.length > 0 && typeof payload.file === "object") {
        let goOn;
        if (payload.origin.table === "works" && payload.origin.data?.due_date) {
          goOn = await checkNewWorkItem(payload);
        } else {
          goOn = true;
        }

        if (goOn) {
          const { file, ...clone } = payload;
          data = await Api.post(`custom/complex`, clone);
          let resp;
          await dispatch(fireLoader({ loadingFiles: true }));
          await payload.file.forEach(async (element, index) => {
            resp = await uploadFileAction(element, userInfo.admin_id, baseURL);
            if (resp) {
              const req = {
                file_id: resp,
                item_id: data.data.response,
                collection: payload.origin.table,
              };
              await Api.post(`items/all_files`, req);
            }
            if (index + 1 === payload.file.length) {
              await dispatch(fireLoader({ loadingFiles: false }));
              await dispatch(fetchDataAction(payload.getInfo));
            }
          });
        }
      } else {
        let goOn;
        if (payload.origin.table === "works" && payload.origin.data.due_date) {
          goOn = await checkNewWorkItem(payload);
        } else {
          goOn = true;
        }
        if (goOn) {
          if ((payload.file && payload.file.length > 0) || (payload.file && payload.file.name)) {
            const resp = await uploadFileAction(payload.file, userInfo.admin_id, baseURL);
            if (resp) payload.origin.data[payload.imgFieldName || "image"] = resp;
          } else if (payload.origin.data) {
            delete payload.origin.data[payload.imgFieldName || "image"];
          }
          const { file, ...clone } = payload;
          data = await Api.post(`custom/complex`, { file: null, ...clone });
          await dispatch(fetchDataAction(payload.getInfo));
          if (payload.updateMyProfile) {
            await dispatch(getUserInfoAction(payload.id));
          }
        }
      }
      return data.data;
    } catch (error) {
      const message =
        error.response && error.response.data.errors.length > 0
          ? error.response.data.errors[0].message
          : error.message;
      if (message) {
        throw error;
      }
      return rejectWithValue(message);
    }
  }
);

export const deleteDataAction = createAsyncThunk(
  "core/deleteDataAction",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      payload.related?.forEach(async (element) => {
        // await Api.patch(`${element.url}/${element.data.id}`, element.data);
        await Api.delete(`${element.url}/${element.data.id}`);
      });
      // await Api.patch(`${payload.url}/${payload.data.id}`, payload.data);
      await Api.delete(`${payload.url}/${payload.data.id}`);
      await dispatch(fetchDataAction(payload.getInfo));
      return { status: "success" };
    } catch (error) {
      const message =
        error.response && error.response.data.errors.length > 0
          ? error.response.data.errors[0].message
          : error.message;
      if (message) {
        throw error;
      }

      return rejectWithValue(message);
    }
  }
);

export const coreSlice = createSlice({
  name: "core",
  initialState,
  reducers: {
    reset: (state, action) => {
      action.payload?.data?.forEach((item) => {
        state[action.payload.alias + item] = null;
      });
      state.worksImgList = null;
      state.accountsImgList = null;
      state.distributionsImgList = null;
      state.inventoryImgList = null;
      state.booksImgList = null;
    },
    closeAlert: (state) => {
      state.resStatus = null;
    },
    setAlert: (state, action) => {
      state.resStatus = action.payload;
    },
    setRowsNum: (state, action) => {
      localStorage.setItem(`${localStoragePrefix}limit`, action.payload);
      state.limit = action.payload;
    },
    setSimbleLayout: (state) => {
      localStorage.setItem(
        `${localStoragePrefix}simbleLayout`,
        !state.simbleLayout ? "simple" : "notSimple"
      );
      state.simbleLayout = !state.simbleLayout;
    },
    setPagesArr: (state, action) => {
      state.pagesArr = action.payload;
    },
  },
  extraReducers: {
    [fetchDataAction.pending]: (state) => ({ ...state, loading: true }),
    [fetchDataAction.fulfilled]: (state, { payload }) => {
      const stateArr = {
        ...state,
        loading: false,
      };
      const { varName, data, meta } = payload;
      stateArr[varName] = data;
      if (meta) stateArr[`${varName}Meta`] = meta;
      console.log(varName, stateArr);
      return stateArr;
    },
    [fetchDataAction.rejected]: (state, { error: { message } }) => ({
      ...state,
      message,
      loading: false,
    }),
    [fetchDataActionPostMethod.pending]: (state) => ({ ...state, loading: true }),
    [fetchDataActionPostMethod.fulfilled]: (state, { payload }) => {
      const stateArr = {
        ...state,
        loading: false,
      };
      const { varName, data, meta } = payload;
      stateArr[varName] = data;
      if (meta) stateArr[`${varName}Meta`] = meta;
      return stateArr;
    },
    [fetchDataActionPostMethod.rejected]: (state, { error: { message } }) => ({
      ...state,
      message,
      loading: false,
    }),
    [importActionPostMethod.pending]: (state) => ({ ...state, loading: true }),
    [importActionPostMethod.fulfilled]: (state, { payload }) => {
      const stateArr = {
        ...state,
        loading: false,
      };
      const { varName, data, meta } = payload;
      stateArr[varName] = data;
      if (meta) stateArr[`${varName}Meta`] = meta;
      return stateArr;
    },
    [importActionPostMethod.rejected]: (state, { error: { message } }) => ({
      ...state,
      message,
      loading: false,
    }),
    [addDataAction.pending]: (state) => ({
      ...state,
      loading: true,
      resStatus: null,
    }),
    [addDataAction.fulfilled]: (state) => ({
      ...state,
      loading: false,
      resStatus: {
        status: "success",
        content: "successfulProcess",
      },
    }),
    [addDataAction.rejected]: (state, { error: { message } }) => ({
      ...state,
      message,
      loading: false,
    }),
    [addDepartureAction.pending]: (state) => ({
      ...state,
      loading: true,
      resStatus: null,
    }),
    [addDepartureAction.fulfilled]: (state) => ({
      ...state,
      loading: false,
      resStatus: {
        status: "success",
        content: "successfulProcess",
      },
    }),
    [addDepartureAction.rejected]: (state, { error: { message } }) => ({
      ...state,
      message,
      loading: false,
    }),
    [addComplexDataAction.pending]: (state) => ({
      ...state,
      loading: true,
      resStatus: null,
    }),
    [addComplexDataAction.fulfilled]: (state, { payload }) => ({
      ...state,
      loading: false,
      resStatus: {
        status: payload.status,
        content: "successfulProcess",
      },
    }),
    [addComplexDataAction.rejected]: (state, { error: { message } }) => ({
      ...state,
      message,
      loading: false,
    }),
    [deleteDataAction.pending]: (state) => ({
      ...state,
      loading: true,
      resStatus: null,
    }),
    [deleteDataAction.fulfilled]: (state, { payload }) => ({
      ...state,
      loading: false,
      resStatus: {
        status: payload.status,
        content: "successfulProcess",
        // state.loadingFiles
        //   ? "successful process but wait for uploading files"
        //   : "successful process",
      },
    }),
    [deleteDataAction.rejected]: (state, { error: { message } }) => ({
      ...state,
      message,
      loading: false,
    }),
    [deleteFileAction.pending]: (state) => ({
      ...state,
      loading: true,
      resStatus: null,
    }),
    [deleteFileAction.fulfilled]: (state, { payload }) => ({
      ...state,
      loading: false,
      resStatus: {
        status: payload.status,
        content: "successful process",
      },
    }),
    [deleteFileAction.rejected]: (state, { error: { message } }) => ({
      ...state,
      message,
      loading: false,
    }),
    [fireLoader.fulfilled]: (state, { payload }) => ({
      ...state,
      loadingFiles: payload.loadingFiles,
      resStatus: {
        status: "success",
        content: payload.loadingFiles
          ? "successful process but wait for uploading files"
          : "successful process",
      },
    }),
  },
});

export const { closeAlert, reset, setRowsNum, setSimbleLayout, setPagesArr, setAlert } =
  coreSlice.actions;

export default coreSlice.reducer;
