import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
import Page from "components/UI/Page";
import { Card, Icon } from "@mui/material";
import { useLocation } from "react-router-dom";
import { fetchDataAction, deleteDataAction } from "store/features/coreSlice";
import PropTypes from "prop-types";
import Avatar from "components/UI/Table/avatarWithOutImg";
import MDButton from "components/MDButton";
import DataModal from "components/UI/DataModal";
import DataTable from "components/UI/Table/DataTable";
import DeleteModal from "components/UI/DeleteModal";
import PagesCount from "helpers/pages-count";
import { useTranslation } from "react-i18next";
import PageForm from "./components/PageForm";
import dataVal from "./dataVal.json";
import FilterForm from "./components/FilterForm";

const Works = ({ userId }) => {
  const baseUrl = `items/works?fields=id,parent_id,program_id.id,research.id,unit_id.id,department_id.id,job_title_id.id,co_job_title_id.id,program_id.title,research.title,unit_id.title,department_id.title,job_title_id.title,co_job_title_id.title,user_id.id,user_id.first_name,user_id.avatar,co_user_id.id,co_user_id.first_name,co_user_id.avatar,task_id.id,task_id.title,work_status,estimated_value,achievement_percentage,other_achievement_percentage,evaluation_percentage,debt,pages,notes,other_notes,commissioning_date,target_date,completion_date,review_date,accreditation_date,due_date,is_dolar,is_repeat,account_id.id`;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.auth);
  const { worksMeta, works, limit, simbleLayout } = useSelector((state) => state.core);
  const [url, setUrl] = useState(baseUrl);
  const [workTypeId, setWorkTypeId] = useState("co_user_id");
  const [newArr, setNewArr] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [number, setNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const [route, setRoute] = useState(null);
  const [pageOptions, setPageOption] = useState([]);
  const [formContainer, setFormContainer] = useState(<h1>wait ...</h1>);

  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const resetForm = () => {
    // setFormContainer(<h1>wait ...</h1>);
  };

  const setOpenModal = (container) => {
    resetForm();
    setFormContainer(container);
    setOpen(true);
  };

  const handleOpen = () =>
    setOpenModal(
      <PageForm
        resetData={resetForm}
        updateHandlerVal={null}
        pageNum={number}
        url={url}
        userId={userId}
        workTypeId={workTypeId}
      />
    );

  const onDeleteHandler = (name, values) => {
    // if (values.inventories.length === 0 && values.returns.length === 0) {
    setOpenDeleteModal(true);
    setDeleteItemId(values.id);
    // } else {
    //   alert("has inventories and gifts");
    // }
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+worksMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    if (works && works.length !== 0) {
      works.forEach((work) => {
        item = {};
        keys = Object.keys(work);
        keys.forEach((element) => {
          item[element] = work[element];
        });

        item.research = (
          <Avatar dir="ltr" name={item.program_id?.title || ""} email={item.research?.title} />
        );
        item.unit = item.unit_id?.title;
        item.department = (
          <Avatar dir="ltr" name={item.unit_id?.title} email={item.department_id?.title} />
        );
        item.jobtitle = item.job_title_id?.title;
        item.user = (
          <Avatar
            dir="ltr"
            name={item.user_id?.first_name || ""}
            email={item.job_title_id?.title}
          />
        );
        item.coUser = (
          <Avatar
            dir="ltr"
            name={item.co_user_id?.first_name || ""}
            email={item.co_job_title_id?.title}
          />
        );
        item.achievementPercentage = (
          <Avatar
            dir="ltr"
            name={`${item.achievement_percentage || 0}%` || ""}
            email={item.completion_date}
          />
        );

        item.department = (
          <Avatar dir="ltr" name={item.unit_id?.title || ""} email={item.department_id?.title} />
        );

        item.task = (
          <Avatar
            dir="ltr"
            name={item.task_id?.title || ""}
            email={`${work.estimated_value || ""} ${work.is_dolar ? "$" : ""}`}
          />
        );
        if (
          (item.work_status === "done" && !item.account_id?.id) ||
          (item.work_status !== "done" && item.account_id?.id)
        ) {
          item.color = "red";
        } else if (item.work_status === "in_progress") {
          item.color = "#eea4bd";
        } else if (item.work_status === "in_review") {
          item.color = "#ffd9a9";
        } else if (item.work_status === "in_approval") {
          item.color = "#a5c1e5";
        } else if (item.work_status === "in_paid") {
          item.color = "#9cdd9e";
        }
        item.action =
          item.work_status !== "done" ? (
            <>
              {route.update && (
                <MDTypography
                  style={{ padding: 20, color: "#3b94ee" }}
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => {
                    if (simbleLayout) {
                      setOpenModal(
                        <PageForm
                          resetData={resetForm}
                          updateHandlerVal={{ name: "works", value: work }}
                          pageNum={number}
                          url={url}
                          userId={userId}
                          workTypeId={workTypeId}
                        />
                      );
                    }
                  }}
                >
                  <Icon fontSize="small">edit</Icon>
                </MDTypography>
              )}
              {route.delete && (
                <MDTypography
                  style={{ padding: 20, color: "#e9423f" }}
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => onDeleteHandler("works", work)}
                >
                  <Icon fontSize="small">delete</Icon>
                </MDTypography>
              )}
              {route.create && (
                <MDTypography
                  style={{ padding: 20, color: "#3b00ee" }}
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => {
                    if (simbleLayout) {
                      setOpenModal(
                        <PageForm
                          resetData={resetForm}
                          updateHandlerVal={{ name: "works", value: work, isCopy: true }}
                          pageNum={number}
                          url={url}
                          userId={userId}
                          workTypeId={workTypeId}
                        />
                      );
                    }
                  }}
                >
                  <Icon fontSize="small">content_copy</Icon>
                </MDTypography>
              )}
            </>
          ) : (
            <>
              {route.update && (
                <MDTypography
                  style={{ padding: 20, color: "#3b94ee" }}
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => {
                    if (simbleLayout) {
                      setOpenModal(
                        <PageForm
                          resetData={resetForm}
                          updateHandlerVal={{ name: "works", value: work }}
                          pageNum={number}
                          url={url}
                          userId={userId}
                          workTypeId={workTypeId}
                        />
                      );
                    }
                  }}
                >
                  <Icon fontSize="small">edit</Icon>
                </MDTypography>
              )}
              {route.create && (
                <MDTypography
                  style={{ padding: 20, color: "#3b00ee" }}
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => {
                    if (simbleLayout) {
                      setOpenModal(
                        <PageForm
                          resetData={resetForm}
                          updateHandlerVal={{ name: "works", value: work, isCopy: true }}
                          pageNum={number}
                          url={url}
                          userId={userId}
                          workTypeId={workTypeId}
                        />
                      );
                    }
                  }}
                >
                  <Icon fontSize="small">content_copy</Icon>
                </MDTypography>
              )}
            </>
          );

        Arr.push(item);
      });
      setNewArr(Arr);
    } else {
      setNewArr([]);
    }
  };

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `${url}&meta=*&limit=${limit}&page=${num}`,
          varName: "works",
        })
      );
    }
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: "items/works",
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${number}`,
        varName: "works",
      },
    };
    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  const setSortByHandeller = (val) => {
    let urlVal = `${url}&meta=*&limit=${limit}&page=${number}`;

    urlVal += `&sort=${val.desc ? "-" : ""}${val.id}`;
    dispatch(
      fetchDataAction({
        url: urlVal,
        varName: "works",
      })
    );
  };

  useEffect(() => {
    const routeItem = userInfo.routes.filter(
      (x) =>
        `${x.route_name}` === location.pathname.split("/")[location.pathname.split("/").length - 1]
    )[0];
    setRoute(routeItem);
  }, []);

  useEffect(() => {
    if (works && route) {
      prepareTableRows();
    }
  }, [works, route]);

  if (newArr === []) {
    return null;
  }

  return (
    <Page>
      <Card style={{ padding: 20, position: "relative" }}>
        <DataModal
          handleClose={() => {
            resetForm();
            setOpen(false);
          }}
          open={open}
        >
          {formContainer}
        </DataModal>
        <DeleteModal
          handleClose={handleDeleteModalClose}
          handleConfirmDelete={handleConfirmDelete}
          openDeleteModal={openDeleteModal}
        />
        <FilterForm
          pageNum={number}
          baseUrl={baseUrl}
          setUrl={setUrl}
          setWorkTypeId={setWorkTypeId}
          userId={userId}
          setNumber={setNumber}
        />
        {simbleLayout && route?.create && (
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleOpen}
            style={{ float: "left" }}
            size="large"
          >
            <Icon>add</Icon>&nbsp; {t("add")}
          </MDButton>
        )}
        <DataTable
          table={{ columns: dataVal.columns, rows: newArr }}
          item={false}
          pageOptions={pageOptions}
          isSorted
          isPagination
          entriesPerPage
          showTotalEntries
          noEndBorder
          search
          setPageNum={setPageNumber}
          setSortByHandeller={setSortByHandeller}
          pageIndex={number - 1}
          allRowsCount={worksMeta ? +worksMeta.filter_count : 0}
        />
      </Card>
    </Page>
  );
};

Works.propTypes = {
  userId: PropTypes.string,
};

Works.defaultProps = {
  userId: null,
};

export default Works;
