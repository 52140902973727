import { useMemo, useEffect, useState, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Autocomplete from "@mui/material/Autocomplete";

// Became Possible components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Became Possible example components
// import TablePaginationDemo from "components/UI/Table/TablePaginationDemo";
// import Report from "components/UI/Report";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Invoice from "components/UI/reporting/Invoice";
import { setRowsNum } from "store/features/coreSlice";
import MDPagination from "components/MDPagination";
import DataTableHeadCell from "./DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  // isPagination,
  pagination,
  isSorted,
  noEndBorder,
  setPageNum,
  // count,
  allRowsCount,
  pageIndex,
  pageOptions,
  setSortByHandeller,
  repFields,
  total,
  report,
  all,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { limit } = useSelector((state) => state.core);

  // const defaultValue = limit ? limit : 10;
  const [defaultValue, setDefaultValue] = useState(limit);

  useEffect(() => {
    setDefaultValue(all ? 10000 : limit);
  }, [limit]);

  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25", "10000"];

  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);
  const ref = useRef();

  const tableInstance = useTable(
    {
      columns,
      data,
      // initialState: { pageIndex: 0 },
      manualSortBy: true,
      // disableSortBy: true,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    canPreviousPage,
    previousPage,
    nextPage,
    canNextPage,
    rows,
    page,
    // pageOptions,
    setPageSize,
    gotoPage,
    setGlobalFilter,
    state: { pageSize, globalFilter, sortBy },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => {
    dispatch(setRowsNum(value));
    setPageSize(value);
  };

  useEffect(() => {
    if (sortBy[0]) setSortByHandeller(sortBy[0]);
  }, [sortBy]);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => {
        setPageNum(Number(option) + 1);
        gotoPage(Number(option));
      }}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) => {
    setPageNum(Number(value) + 1);
    gotoPage(Number(value));
  };
  // value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => {
    setPageNum(Number(value.value - 1) + 1);
    gotoPage(Number(value.value - 1));
  };
  // gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }
    return sortedValue;
  };

  // const handleMultiSortBy = (value) => {
  //   setSortByHandeller(value.id);
  // };
  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  return (
    <>
      <TableContainer sx={{ boxShadow: "none" }} ref={ref} id="main">
        {entriesPerPage || canSearch ? (
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            {entriesPerPage && (
              <MDBox display="flex" alignItems="center">
                <Autocomplete
                  disableClearable
                  value={pageSize.toString()}
                  options={entries}
                  onChange={(event, newValue) => {
                    setEntriesPerPage(parseInt(newValue, 10));
                  }}
                  size="small"
                  sx={{ width: "5rem" }}
                  renderInput={(params) => <MDInput {...params} />}
                />
                <MDTypography variant="caption" color="secondary">
                  &nbsp;&nbsp;entries per page
                </MDTypography>
              </MDBox>
            )}
            {canSearch && (
              <MDBox width="12rem" ml="auto">
                <MDInput
                  placeholder="Search..."
                  value={search}
                  size="small"
                  fullWidth
                  onChange={({ currentTarget }) => {
                    setSearch(search);
                    onSearchChange(currentTarget.value);
                  }}
                />
              </MDBox>
            )}
          </MDBox>
        ) : null}
        <Table {...getTableProps()}>
          <MDBox component="thead">
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <DataTableHeadCell
                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                    // sorted={setSortedValue(column)}
                    sorted={setSortedValue(column)}
                    sort={column.sort || false}
                    // onClick={() => handleMultiSortBy(column)}
                  >
                    {t(column.render("Header"))}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </MDBox>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, key) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <DataTableBodyCell
                      width={cell.column.width}
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                      bgColor={row.original.color}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <MDBox
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
        >
          {showTotalEntries && (
            <MDBox mb={{ xs: 3, sm: 0 }}>
              <MDTypography variant="button" color="secondary" fontWeight="regular">
                Showing {entriesStart} to {entriesEnd} of {allRowsCount} entries
              </MDTypography>
            </MDBox>
          )}
          {pageOptions.length > 1 && (
            <MDPagination
              variant={pagination.variant ? pagination.variant : "gradient"}
              color={pagination.color ? pagination.color : "info"}
            >
              {canPreviousPage && (
                <MDPagination item onClick={() => previousPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                </MDPagination>
              )}
              {renderPagination.length > 6 ? (
                <MDBox width="5rem" mx={1}>
                  <MDInput
                    inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                    value={customizedPageOptions[pageIndex]}
                    onChange={(handleInputPagination, handleInputPaginationValue)}
                  />
                </MDBox>
              ) : (
                renderPagination
              )}
              {/* {renderPagination.length > 0 && <MDBox>{renderPagination}</MDBox>} */}
              {canNextPage && (
                <MDPagination item onClick={() => nextPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                </MDPagination>
              )}
            </MDPagination>
          )}
        </MDBox>
        {/* 
        {isPagination && (
          <MDBox
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
          >
            <TablePaginationDemo
              setPageNum={setPageNum}
              count={count}
              isPagination={isPagination}
            />
          </MDBox>
        )} */}
      </TableContainer>
      {table.rows && columns.length > 0 && report && (
        <Invoice
          rows={table.rows}
          repFields={repFields || columns}
          total={total}
          invoiceVersion="B"
          reportingStyle={{ type: "report", version: "B" }}
          reportingContent={{ rows, total, repFields: repFields || columns }}
        />
      )}
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25, 10000] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  repFields: null,
  isPagination: true,
  all: false,
  report: true,
  allRowsCount: null,
  total: null,
  setPageNum: () => null,
  pageIndex: 0,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  pageIndex: PropTypes.number,
  report: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  isPagination: PropTypes.bool,
  all: PropTypes.bool,
  setPageNum: PropTypes.func,
  allRowsCount: PropTypes.number,
  pageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSortByHandeller: PropTypes.func.isRequired,
  repFields: PropTypes.node,
  total: PropTypes.objectOf(PropTypes.any),
};

export default DataTable;
