import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
import Page from "components/UI/Page";
import { Icon, Card } from "@mui/material";
import { useLocation } from "react-router-dom";
import { fetchDataAction, deleteDataAction } from "store/features/coreSlice";
import MDButton from "components/MDButton";
import DataModal from "components/UI/DataModal";
import DataTable from "components/UI/Table/DataTable";
import DeleteModal from "components/UI/DeleteModal";
import PagesCount from "helpers/pages-count";
import { useTranslation } from "react-i18next";
import PageForm from "./components/PageForm";
import dataVal from "./dataVal.json";

const UsersGifts = () => {
  const baseUrl = `items/users_gifts?fields=*,program_id.id,program_id.title,job_title_id.title,job_title_id.id,type_id.title,type_id.id,user_id.first_name,user_id.id`;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.auth);
  const { usersGiftsMeta, usersGifts, limit, simbleLayout } = useSelector((state) => state.core);
  const url = baseUrl;
  const [newArr, setNewArr] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [number, setNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const [route, setRoute] = useState(null);
  const [pageOptions, setPageOption] = useState([]);
  const [formContainer, setFormContainer] = useState(<h1>wait ...</h1>);

  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const setOpenModal = (container) => {
    setFormContainer(container);
    setOpen(true);
  };

  const handleOpen = () =>
    setOpenModal(<PageForm updateHandlerVal={null} pageNum={number} url={url} />);

  const onDeleteHandler = (name, values) => {
    setOpenDeleteModal(true);
    setDeleteItemId(values.id);
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+usersGiftsMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    if (usersGifts && usersGifts.length !== 0) {
      usersGifts.forEach((gift) => {
        item = {};
        keys = Object.keys(gift);
        keys.forEach((element) => {
          item[element] = gift[element];
        });
        item.towho = gift.user_id?.first_name;
        item.jobTitle = gift.job_title_id?.title;
        item.program = gift.program_id?.title;
        item.type = gift.type_id?.title;
        item.debt = `${gift.debt}${gift.is_dolar ? "$" : ""}`;
        if (item.work_status !== "done") {
          item.action = (
            <>
              {route.update && (
                <MDTypography
                  style={{ padding: 20, color: "#3b94ee" }}
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => {
                    if (simbleLayout) {
                      setOpenModal(
                        <PageForm
                          updateHandlerVal={{ name: "gifts", value: gift }}
                          pageNum={number}
                          url={url}
                        />
                      );
                    }
                  }}
                >
                  <Icon fontSize="small">edit</Icon>
                </MDTypography>
              )}
              {route.delete && (
                <MDTypography
                  style={{ padding: 20, color: "#e9423f" }}
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => onDeleteHandler("gifts", gift)}
                >
                  <Icon fontSize="small">delete</Icon>
                </MDTypography>
              )}
              {route.create && (
                <MDTypography
                  style={{ padding: 20, color: "#3b00ee" }}
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => {
                    if (simbleLayout) {
                      setOpenModal(
                        <PageForm
                          updateHandlerVal={{ name: "gifts", value: gift, isCopy: true }}
                          pageNum={number}
                          url={url}
                        />
                      );
                    }
                  }}
                >
                  <Icon fontSize="small">content_copy</Icon>
                </MDTypography>
              )}
            </>
          );
        } else {
          item.action = route.create && (
            <MDTypography
              style={{ padding: 20, color: "#3b00ee" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => {
                if (simbleLayout) {
                  setOpenModal(
                    <PageForm
                      updateHandlerVal={{ name: "gifts", value: gift, isCopy: true }}
                      pageNum={number}
                      url={url}
                    />
                  );
                }
              }}
            >
              <Icon fontSize="small">content_copy</Icon>
            </MDTypography>
          );
        }
        Arr.push(item);
      });
      setNewArr(Arr);
    } else {
      setNewArr([]);
    }
  };

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `${url}&meta=*&limit=${limit}&page=${num}`,
          varName: "usersGifts",
        })
      );
    }
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: "items/users_gifts",
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${number}`,
        varName: "usersGifts",
      },
    };
    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  const setSortByHandeller = (val) => {
    let urlVal = `${url}&meta=*&limit=${limit}&page=${number}`;

    urlVal += `&sort=${val.desc ? "-" : ""}${val.id}`;
    dispatch(
      fetchDataAction({
        url: urlVal,
        varName: "usersGifts",
      })
    );
  };

  useEffect(() => {
    dispatch(
      fetchDataAction({
        url: `${url}&meta=*&limit=${limit}&page=${number}`,
        varName: "usersGifts",
      })
    );
  }, [limit]);

  useEffect(() => {
    const routeItem = userInfo.routes.filter(
      (x) =>
        `${x.route_name}` === location.pathname.split("/")[location.pathname.split("/").length - 1]
    )[0];
    setRoute(routeItem);
  }, []);

  useEffect(() => {
    if (usersGifts && route) {
      prepareTableRows();
    }
  }, [usersGifts, route]);

  if (newArr === []) {
    return null;
  }

  return (
    <Page>
      <Card style={{ padding: 20, position: "relative" }}>
        {" "}
        <DataModal
          handleClose={() => {
            setOpen(false);
          }}
          open={open}
        >
          {formContainer}
        </DataModal>
        <DeleteModal
          handleClose={handleDeleteModalClose}
          handleConfirmDelete={handleConfirmDelete}
          openDeleteModal={openDeleteModal}
        />
        {simbleLayout && route?.create && (
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleOpen}
            style={{ float: "left" }}
            size="large"
          >
            <Icon>add</Icon>&nbsp; {t("add")}
          </MDButton>
        )}
        <DataTable
          table={{ columns: dataVal.columns, rows: newArr }}
          item={false}
          pageOptions={pageOptions}
          isSorted
          isPagination
          entriesPerPage
          showTotalEntries
          noEndBorder
          search
          setPageNum={setPageNumber}
          setSortByHandeller={setSortByHandeller}
          pageIndex={number - 1}
          allRowsCount={usersGiftsMeta ? +usersGiftsMeta.filter_count : 0}
        />
      </Card>
    </Page>
  );
};

export default UsersGifts;
