import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/authSlice";
import coreReducer from "./features/coreSlice";
import invoiceReducer from "./features/invoiceSlice";
import reportingReducer from "./features/reportingSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    core: coreReducer,
    invoiceReducer,
    reportingReducer,
  },
});
export default store;
