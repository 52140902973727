import { LinearProgress } from "@mui/material";
import React from "react";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

import FileHeader from "./FileHeader";

function UploadError({ file, onDelete, errors }) {
  return (
    <>
      <FileHeader file={file} onDelete={onDelete} error />

      <LinearProgress variant="determinate" value={50} color="error" />

      {errors.map((error) => (
        <div key={Math.random().toString()}>
          <MDTypography variant="body2" color="error">
            {error.message}
          </MDTypography>
        </div>
      ))}
    </>
  );
}

UploadError.propTypes = {
  file: PropTypes.objectOf(PropTypes.any).isRequired,
  onDelete: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

export default UploadError;
