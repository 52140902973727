import { useState } from "react";
import { Container, Toolbar, Box, AppBar as MUIAppBar } from "@mui/material";
import { useSelector } from "react-redux";

import Drawer from "./Drawer";
import AppBarLogo from "./components/AppBarLogo";
import DrawerLinks from "./components/DrawerLinks";
import NavLinks from "./components/NavLinks";
import UserMenu from "./components/UserMenu";
import AuthButton from "./components/AuthButton";

const pages = [
  { title: "features", href: "#trakib-features" },
  { title: "whyTrakib", href: "#why-trakib" },
  { title: "contactUs", href: "#contact-us" },
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

export default function AppBar() {
  const { userInfo } = useSelector((state) => state.auth);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  return (
    <Box>
      <MUIAppBar position="static">
        <Container>
          <Toolbar disableGutters sx={{ display: "flex", justifyContent: "space-between" }}>
            <AppBarLogo sx={{ display: { xs: "none", md: "flex" } }} />
            <DrawerLinks
              anchorElNav={anchorElNav}
              handleDrawerToggle={handleDrawerToggle}
              handleCloseNavMenu={handleCloseNavMenu}
              pages={pages}
            />
            <AppBarLogo sx={{ display: { xs: "flex", md: "none" } }} />

            <NavLinks pages={pages} />
            {userInfo ? (
              <UserMenu
                anchorElUser={anchorElUser}
                handleOpenUserMenu={handleOpenUserMenu}
                handleCloseUserMenu={handleCloseUserMenu}
                settings={settings}
              />
            ) : (
              <AuthButton />
            )}
          </Toolbar>
        </Container>
      </MUIAppBar>
      <Drawer handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
    </Box>
  );
}
