import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataForm from "components/UI/form/DataForm";
import { fetchDataAction } from "store/features/coreSlice";
import PropTypes from "prop-types";
import dataVal from "../../dataVal.json";

const FilterForm = ({ setNumber, setUrl, baseUrl }) => {
  const dispatch = useDispatch();
  const { filterbooks, filterpublishers, filterstoresPlaces, limit } = useSelector(
    (state) => state.core
  );
  const { userInfo } = useSelector((state) => state.auth);

  const [data, setData] = useState([]);
  const [filteredValues, setFilteredValues] = useState(null);
  const [publisherId, setPublisherId] = useState(null);
  const [bookId, setBookId] = useState(null);
  const [initialValues, setInitialValues] = useState(dataVal.initialValuesFilter);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "title",
        name: "publisher_id",
        id: "publisher_id",
        label: "publisher_id",
        data: filterpublishers,
        size: 3,
        title: "publisher",
      },

      {
        type: "Select",
        optionTitle: "title",
        name: "book_id",
        id: "book_id",
        label: "book_id",
        data: filterbooks,
        size: 3,
        title: "book",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "place_id",
        id: "place_id",
        label: "place_id",
        data: filterstoresPlaces,
        size: 3,
        title: "place",
      },
    ]);
  };

  useEffect(() => {
    if (filterbooks && filterpublishers && filterstoresPlaces) orgData();
  }, [filterbooks, filterpublishers, filterstoresPlaces]);

  const generateUrl = (values) => {
    let str = baseUrl;
    const keys = Object.keys(values);
    keys.forEach((element) => {
      if (values[element] && values[element] !== []) {
        if (typeof values[element] === "object") {
          if (element === "book_id")
            str += `&filter[buying_id.${element}.id]=${values[element].id}`;
          else if (element === "publisher_id") {
            str += `&filter[buying_id.book_id.publisher_id.id]=${values[element].id}`;
          } else str += `&filter[${element}.id]=${values[element].id}`;
        } else {
          str += `&filter[${element}]=${values[element]}`;
        }
      }
    });
    return str;
  };

  const setFieldSelectedValue = (name, values) => {
    if (name === "publisher_id") {
      setPublisherId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/books?fields=id,title,publisher_id&filter[publisher_id]=${values.id}`,
          varName: "filterbooks",
        })
      );
    }
    if (name === "book_id") {
      dispatch(
        fetchDataAction({
          url: `items/buying?fields=id,buying_date,stores.count,stores.id,count,notes&filter[book_id]=${values.id}`,
          varName: "filterbuying",
        })
      );
      setBookId(values.id);
    }
  };

  const onChangeHandler = (name, text) => {
    if (name === "publisher_id") {
      dispatch(
        fetchDataAction({
          url: `items/publishers?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "filterpublishers",
        })
      );
    }

    let urlVal;
    if (name === "book_id") {
      urlVal = `items/books?fields=id,title&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (publisherId) urlVal += `&filter[publisher_id]=${publisherId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterbooks",
        })
      );
    }

    if (name === "buying_id") {
      urlVal = `items/buying?fields=id,buying_date&filter[buying_date][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (bookId) urlVal += `&filter[book_id]=${bookId}`;

      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterbuying",
        })
      );
    }
  };

  const setPayload = (values) => {
    setFilteredValues(values);
    setNumber(1);
    if (values) setInitialValues(values);
    setUrl(generateUrl(values));

    dispatch(
      fetchDataAction({
        url: `${generateUrl(values)}&meta=*&limit=${limit}&page=${1}`,
        varName: "stores",
      })
    );
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      let urlVal;
      if (element === "stores_places") {
        urlVal = `items/${element}?fields=id,title&limit=${limit}`;
        if (!userInfo.all_stores) urlVal += `&filter[users][user_id][_eq]=${userInfo.id}`;
        dispatch(
          fetchDataAction({
            url: urlVal,
            varName: `filterstoresPlaces`,
          })
        );
      } else
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,title&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
    });
  };

  useEffect(() => {
    getData(dataVal.oterData);
  }, []);

  useEffect(() => {
    setPayload(filteredValues || initialValues);
  }, [limit]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      filter
      yupObj={{}}
      name="researchFilter"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeHandler}
    />
  );
};

FilterForm.propTypes = {
  setUrl: PropTypes.func.isRequired,
  setNumber: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};
export default FilterForm;
