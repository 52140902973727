import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataForm from "components/UI/form/DataForm";
import { fetchDataAction } from "store/features/coreSlice";
import PropTypes from "prop-types";

const FilterForm = ({ setNumber, setUrl, baseUrl }) => {
  const dispatch = useDispatch();
  const { filterusers, filtercollections, limit } = useSelector((state) => state.core);
  const [data, setData] = useState([]);
  const [filteredValues, setFilteredValues] = useState(null);

  const actions = [
    { title: "create", id: "create" },
    { title: "update", id: "update" },
    { title: "delete", id: "delete" },
    { title: "login", id: "login" },
  ];

  const initFilter = {
    collection: null,
    user: null,
    action: null,
  };

  const [initialValues, setInitialValues] = useState(initFilter);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "collection",
        name: "collection",
        id: "collection",
        label: "collection",
        data: filtercollections,
        size: 3,
        title: "collections",
      },
      {
        type: "Select",
        optionTitle: "first_name",
        name: "user",
        id: "user",
        label: "user",
        data: filterusers,
        size: 3,
        title: "users",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "action",
        id: "action",
        label: "action",
        data: actions,
        size: 3,
        title: "actions",
      },
    ]);
  };

  useEffect(() => {
    if (filterusers && filtercollections) {
      orgData();
    }
  }, [filterusers, filtercollections]);

  const generateUrl = (values) => {
    let str = baseUrl;
    const keys = Object.keys(values);
    keys.forEach((element) => {
      if (values[element] && values[element] !== []) {
        if (element === "collection") str += `&filter[${element}]=${values[element].collection}`;
        else if (typeof values[element] === "object") {
          str += `&filter[${element}][_eq]=${values[element].id}`;
        }
      }
    });
    return str;
  };

  const onChangeHandler = (name, text) => {
    if (name === "user")
      dispatch(
        fetchDataAction({
          url: `users?fields=id,first_name&filter[first_name][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "filterusers",
        })
      );
  };

  const setPayload = (values) => {
    setFilteredValues(values);
    setNumber(1);
    if (values) setInitialValues(values);
    setUrl(generateUrl(values));
    dispatch(
      fetchDataAction({
        url: `${generateUrl(values)}&meta=*&limit=${limit}&page=${1}`,
        varName: "activities",
      })
    );
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      if (element === "users")
        dispatch(
          fetchDataAction({
            url: `${element}?fields=id,first_name&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
      else if (element === "collections")
        dispatch(
          fetchDataAction({
            url: `collections?limit=-1`,
            varName: `filter${element}`,
          })
        );
    });
  };

  useEffect(() => {
    getData(["users", "collections"]);
  }, []);

  useEffect(() => {
    setPayload(filteredValues || initialValues);
  }, [limit]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      filter
      yupObj={{}}
      name="activitiesFilter"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      onChange={onChangeHandler}
    />
  );
};

FilterForm.propTypes = {
  setUrl: PropTypes.func.isRequired,
  setNumber: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};
export default FilterForm;
