import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
import Page from "components/UI/Page";
import { Icon, Card } from "@mui/material";
import { useLocation } from "react-router-dom";
import { fetchDataAction } from "store/features/coreSlice";
import MDButton from "components/MDButton";
import DataModal from "components/UI/DataModal";
import DataTable from "components/UI/Table/DataTable";
import PagesCount from "helpers/pages-count";
import { useTranslation } from "react-i18next";
import Avatar from "components/UI/Table/avatarWithOutImg";
import ImportDB from "components/UI/ImportDB";
import PageForm from "./components/PageForm";
import FilterForm from "./components/FilterForm";
import dataVal from "./dataVal.json";

const Stores = () => {
  const { userInfo } = useSelector((state) => state.auth);
  let baseUrl = `items/inventory?fields=*,accounts.debt,accounts.id,distribution_id.id,distribution_id.proposal_id.discount_percentage,distribution_id.proposal_id.cost,distribution_id.proposal_id.is_dolar,distribution_id.notes,distribution_id.returns.count,distribution_id.inventories.count,distribution_id.inventories.id,distribution_id.count,distribution_id.library_id.id,distribution_id.library_id.title,distribution_id.store_id.buying_id.book_id.id,distribution_id.store_id.buying_id.book_id.title,distribution_id.store_id.buying_id.book_id.publisher_id.id,distribution_id.store_id.buying_id.book_id.publisher_id.title,distribution_id.store_id.buying_id.buying_date,distribution_id.store_id.buying_id.accounts.last_inventory_id,distribution_id.store_id.place_id.title,distribution_id.store_id.place_id.id,distribution_id.store_id.id,distribution_id.distribution_date`;
  if (!userInfo.all_libraries)
    baseUrl += `&filter[distribution_id][library_id][users][user_id][_eq]=${userInfo.id}`;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { inventoriesMeta, inventories, limit, simbleLayout } = useSelector((state) => state.core);
  const [url, setUrl] = useState(baseUrl);
  const [newArr, setNewArr] = useState([]);
  // const [openDeleteModal, setOpenDeleteModal] = useState(false);
  // const [deleteItemId, setDeleteItemId] = useState(false);
  const [number, setNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const [route, setRoute] = useState(null);
  const [pageOptions, setPageOption] = useState([]);
  const [formContainer, setFormContainer] = useState(<h1>wait ...</h1>);
  const [total, setTotal] = useState(null);

  // const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const setOpenModal = (container) => {
    setFormContainer(container);
    setOpen(true);
  };

  const handleOpen = () =>
    setOpenModal(<PageForm updateHandlerVal={null} pageNum={number} url={url} />);

  // const onDeleteHandler = (name, values) => {
  //   if (values.accounts.length === 0) {
  //     setOpenDeleteModal(true);
  //     setDeleteItemId(values.id);
  //   } else {
  //     dispatch(
  //       setAlert({
  //         status: "error",
  //         content: t("hasAccounts"),
  //       })
  //     );
  //   }
  // };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    let totalInventoryProposalCost = 0;
    let totalCount = 0;
    for (let i = 0; i < PagesCount(+inventoriesMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    let psign = null;
    let isEqual = true;
    let sign;
    let count;
    if (inventories && inventories.length !== 0) {
      inventories.forEach((inventory, index) => {
        if (index === 0) psign = inventory.distribution_id.proposal_id.is_dolar;
        sign = inventory.distribution_id?.proposal_id?.is_dolar;
        if (psign !== sign) {
          isEqual = false;
        }
        item = {};
        count = 0;
        keys = Object.keys(inventory);
        keys.forEach((element) => {
          item[element] = inventory[element];
        });
        item.title = inventory.distribution_id?.store_id.buying_id.book_id.title;
        item.store = inventory.distribution_id?.store_id.place_id.title;
        // item.library = inventory.distribution_id?.library_id.title;
        item.distributionCount = inventory.distribution_id?.count;
        // item.distributionDate = inventory.distribution_id?.distribution_date;
        //
        inventory.distribution_id?.returns.forEach((element) => {
          count += element.count;
        });
        item.return = count;
        item.proposalPrice = `${inventory.distribution_id?.proposal_id.cost} ${
          inventory.distribution_id?.proposal_id.is_dolar ? "$" : ""
        }`;
        item.proposalDiscountPercentage =
          inventory.distribution_id?.proposal_id.discount_percentage;
        // item.date = inventory.inventory_date;
        let bigNum = 0;
        inventory.distribution_id?.store_id.buying_id.accounts.forEach((account) => {
          if (bigNum < account.last_inventory_id) bigNum = account.last_inventory_id;
        });
        // const x = inventory.distribution_id?.proposal_id.cost;
        // item.inventoryProposalCost = item.count * (x - (x * item.proposalDiscountPercentage) / 100);
        item.inventoryProposalCost = item.accounts[0]?.debt;

        item.library = (
          <Avatar
            dir="ltr"
            name={inventory.distribution_id?.library_id.title || ""}
            email={inventory.distribution_id?.distribution_date}
          />
        );

        item.action = (
          <>
            {route.update && inventory.id > bigNum && (
              <MDTypography
                style={{ padding: 20, color: "#3b94ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => {
                  if (simbleLayout) {
                    setOpenModal(
                      <PageForm
                        updateHandlerVal={{
                          name: "inventories",
                          value: inventory,
                        }}
                        pageNum={number}
                        url={url}
                      />
                    );
                  }
                }}
              >
                <Icon fontSize="small">edit</Icon>
              </MDTypography>
            )}
            {/* {route.delete && inventory.id > bigNum && (
              <MDTypography
                style={{ padding: 20, color: "#e9423f" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onDeleteHandler("inventories", inventory)}
              >
                <Icon fontSize="small">delete</Icon>
              </MDTypography>
            )} */}
            {route.create && (
              <MDTypography
                style={{ padding: 20, color: "#3b00ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => {
                  if (simbleLayout) {
                    setOpenModal(
                      <PageForm
                        updateHandlerVal={{
                          name: "inventories",
                          value: inventory,
                          isCopy: true,
                        }}
                        pageNum={number}
                        url={url}
                      />
                    );
                  }
                }}
              >
                <Icon fontSize="small">content_copy</Icon>
              </MDTypography>
            )}
          </>
        );
        totalInventoryProposalCost += +item.inventoryProposalCost;
        totalCount += +inventory.count;

        if (index === inventories.length - 1 && !isEqual) {
          totalInventoryProposalCost = null;
        } else if (index === inventories.length - 1) {
          totalInventoryProposalCost += inventory.distribution_id.proposal_id.is_dolar ? "$" : "";
        }

        Arr.push(item);
      });
      setTotal({ inventoryProposalCost: totalInventoryProposalCost, count: totalCount });

      setNewArr(Arr);
    } else {
      setNewArr([]);
    }
  };

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `${url}&meta=*&limit=${limit}&page=${num}`,
          varName: "inventories",
        })
      );
    }
  };

  // const handleConfirmDelete = async () => {
  //   const payload = {
  //     url: "items/inventory",
  //     data: {
  //       id: deleteItemId,
  //       status: "deleted",
  //     },
  //     getInfo: {
  //       url: `${url}&meta=*&limit=${limit}&page=${number}`,
  //       varName: "inventories",
  //     },
  //   };
  //   dispatch(deleteDataAction(payload));
  //   setOpenDeleteModal(false);
  // };

  const setSortByHandeller = (val) => {
    let urlVal = `${url}&meta=*&limit=${limit}&page=${number}`;

    urlVal += `&sort=${val.desc ? "-" : ""}${val.id}`;
    dispatch(
      fetchDataAction({
        url: urlVal,
        varName: "inventories",
      })
    );
  };

  useEffect(() => {
    const routeItem = userInfo.routes.filter(
      (x) =>
        `${x.route_name}` === location.pathname.split("/")[location.pathname.split("/").length - 1]
    )[0];
    setRoute(routeItem);
  }, []);

  useEffect(() => {
    if (inventories && route) {
      prepareTableRows();
    }
  }, [inventories, route]);

  if (newArr === []) {
    return null;
  }

  return (
    <Page>
      <Card style={{ padding: 20, position: "relative" }}>
        <DataModal
          handleClose={() => {
            setOpen(false);
          }}
          open={open}
        >
          {formContainer}
        </DataModal>
        <FilterForm pageNum={number} baseUrl={baseUrl} setUrl={setUrl} setNumber={setNumber} />

        {simbleLayout && route?.create && (
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleOpen}
            style={{ float: "left" }}
            size="large"
          >
            <Icon>add</Icon>&nbsp; {t("add")}
          </MDButton>
        )}
        <DataTable
          table={{ columns: dataVal.columns, rows: newArr }}
          item={false}
          total={total}
          pageOptions={pageOptions}
          isSorted
          isPagination
          entriesPerPage
          showTotalEntries
          noEndBorder
          search
          setPageNum={setPageNumber}
          setSortByHandeller={setSortByHandeller}
          pageIndex={number - 1}
          allRowsCount={inventoriesMeta ? +inventoriesMeta.filter_count : 0}
        />
        <ImportDB
          payload={{
            url: `custom/importInventories`,
            varName: "importInventories",
            getInfo: {
              url: `${url}&meta=*&limit=${limit}&page=${number}`,
              varName: "inventories",
            },
          }}
        />
      </Card>
    </Page>
  );
};

export default Stores;
