import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import UpdateRecord from "helpers/compare";
import DataForm from "components/UI/form/DataForm";

import { addComplexDataAction, fetchDataAction, reset } from "store/features/coreSlice";
import PropTypes from "prop-types";
import moment from "moment";

import dataVal from "../../dataVal.json";

const PageForm = ({ updateHandlerVal, url, pageNum }) => {
  const dispatch = useDispatch();

  const { formjobTitles, formtypes, formusers, formprograms, limit } = useSelector(
    (state) => state.core
  );

  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);
  const [filesData, setFilesData] = useState(undefined);
  const [jobTitleId, setJobTitleId] = useState(null);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "title",
        name: "type_id",
        id: "type_id",
        label: "type_id",
        data: formtypes,
        size: 3,
        title: "item",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "job_title_id",
        id: "job_title_id",
        label: "job_title_id",
        data: formjobTitles,
        size: 3,
        title: "jobTitle",
      },
      {
        type: "Select",
        optionTitle: "first_name",
        name: "user_id",
        id: "user_id",
        label: "user_id",
        data: formusers,
        size: 3,
        title: "towho",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "program_id",
        id: "program_id",
        label: "program_id",
        data: formprograms,
        size: 3,
        title: "program",
      },
      {
        type: "TextField",
        name: "debt",
        id: "debt",
        label: "debt",
        size: 3,
        title: "debt",
      },
      {
        type: "chk",
        name: "is_dolar",
        id: "is_dolar",
        label: "is_dolar",
        size: 4,
        title: "$",
      },
      {
        type: "Date",
        name: "due_date",
        id: "due_date",
        label: "due_date",
        size: 3,
        title: "dueDate",
      },
      {
        type: "TextArea",
        name: "notes",
        id: "notes",
        label: "notes",
        maxRows: 4,
        minRows: 4,
        size: 9,
        title: "notes",
      },
    ]);
  };

  const resetForm = () => {
    setFilesData(undefined);
  };

  const setFieldSelectedValue = (name, values) => {
    if (name === "job_title_id") {
      setJobTitleId(values.id);
      dispatch(
        fetchDataAction({
          url: `users?fields=id,first_name,last_name&filter[job_titles.job_title_id.id]=${values.id}`,
          varName: "formusers",
        })
      );
    }
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      if (element === "jobTitles") {
        dispatch(
          fetchDataAction({
            url: `items/job_title?fields=id,title&limit=${limit}`,
            varName: `form${element}`,
          })
        );
      } else if (element === "users") {
        dispatch(
          fetchDataAction({
            url: `${element}?fields=id,first_name&limit=${limit}`,
            varName: `form${element}`,
          })
        );
      } else if (element === "types") {
        dispatch(
          fetchDataAction({
            url: `items/gifts_types?fields=id,title&limit=${limit}`,
            varName: `formtypes`,
          })
        );
      } else {
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,title&limit=${limit}`,
            varName: `form${element}`,
          })
        );
      }
    });
  };

  const onChangeSelectInputs = (name, text) => {
    if (name === "job_title_id")
      dispatch(
        fetchDataAction({
          url: `items/job_title?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "formjobTitles",
        })
      );

    if (name === "type_id")
      dispatch(
        fetchDataAction({
          url: `items/gifts_types?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "formtypes",
        })
      );
    let urlVal;
    if (name === "user_id") {
      urlVal = `users?fields=id,first_name&filter[first_name][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (jobTitleId) urlVal += `&filter[job_titles.job_title_id.id]=${jobTitleId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formusers",
        })
      );
    }

    if (name === "program_id")
      dispatch(
        fetchDataAction({
          url: `items/programs?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "formprograms",
        })
      );
  };

  const onUpdateHandler = (name, values, copy) => {
    if (values.program_id)
      onChangeSelectInputs("program_id", values.program_id ? values.program_id.title : " ");
    if (values.type_id)
      onChangeSelectInputs("type_id", values.type_id ? values.type_id.title : " ");
    if (values.job_title_id) {
      setFieldSelectedValue("job_title_id", values.job_title_id);
      onChangeSelectInputs("job_title_id", values.job_title_id ? values.job_title_id.title : " ");
    }
    // if (values.user_id)
    //   onChangeSelectInputs("user_id", values.user_id ? values.user_id.first_name : " ");

    setInitialValues({
      id: copy ? null : values.id,
      due_date: values.due_date,
      debt: values.debt,
      is_dolar: values.is_dolar,
      user_id: values.user_id,
      job_title_id: values.job_title_id,
      type_id: values.type_id,
      program_id: values.program_id,
      notes: values.notes,
    });
  };

  const setPayload = ({ files, ...values }) => {
    const payloadData = {};
    const lastData = values.id ? UpdateRecord(values, initialValues) : values;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });
    payloadData.due_date = moment(values.due_date).endOf("month").format("YYYY-MM-DD");
    const req = {
      id: values.id,
      file: null,
      origin: {
        data: Object.keys(payloadData).length === 0 ? null : payloadData,
        table: "users_gifts",
      },
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "usersGifts",
      },
    };
    setInitialValues(values);
    dispatch(addComplexDataAction(req));
    resetForm();
  };

  useEffect(() => {
    if (updateHandlerVal) {
      onUpdateHandler(updateHandlerVal.name, updateHandlerVal.value, updateHandlerVal.isCopy);
    } else {
      getData(dataVal.oterData);
    }
    return () => {
      resetForm();
      dispatch(
        reset({
          alias: "form",
          data: dataVal.oterData,
        })
      );
    };
  }, []);

  useEffect(() => {
    if (formjobTitles && formtypes && formusers && formprograms) orgData();
  }, [formjobTitles, formusers, formtypes, formprograms]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      yupObj={{
        type_id: yup.mixed("required").required("required").nullable(),
        debt: yup
          .number("must be number")
          .required("required")
          .typeError("must be a number")
          .positive("must be greater than zero")
          .nullable(),
        due_date: yup.string("required").required("required").nullable(),
      }}
      resetData={resetForm}
      name="users_gifts"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeSelectInputs}
      imgList={filesData}
      defaultInitialValues={dataVal.initialValues}
    />
  );
};

PageForm.propTypes = {
  updateHandlerVal: PropTypes.objectOf(PropTypes.any),
  pageNum: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

PageForm.defaultProps = {
  updateHandlerVal: null,
};
export default PageForm;
