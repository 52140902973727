import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reportTableFields: null,
};

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    setReportTableFieldsAction: (state, action) => {
      state.reportTableFields = action.payload;
    },
  },
});

export const { setReportTableFieldsAction } = invoiceSlice.actions;

export default invoiceSlice.reducer;
