/**
=========================================================
* Trakib System - v2.1.0
=========================================================

* Product Page: https://trakib.comproduct/trakib-system-react
* Copyright 2022 trakib (https://trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Grid } from "@mui/material";
import Page from "components/UI/Page";
import ProfilesList from "examples/Lists/ProfilesListNotification";
import Calendar from "layouts/applications/calendar";
// import Kanban from "layouts/applications/kanban";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { fetchDataAction } from "store/features/coreSlice";

function Sales() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { myNotifications, myLinks } = useSelector((state) => state.core);
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(
      fetchDataAction({
        url: `custom/notifications?userid=${userInfo.id}&table=notifications`,
        varName: "myNotifications",
      })
    );
    dispatch(
      fetchDataAction({
        url: `custom/notifications?userid=${userInfo.id}&table=links`,
        varName: "myLinks",
      })
    );
  }, []);

  return (
    <Page>
      {/* <Kanban /> */}
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          lg={6}
          style={{
            paddingRight: 60,
            paddingLeft: 60,
          }}
        >
          {myLinks && (
            <ProfilesList
              title={t("links")}
              profiles={myLinks}
              shadow={false}
              style={{
                backgroundColor: "#fff",
              }}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          style={{
            paddingRight: 60,
            paddingLeft: 60,
          }}
        >
          {myNotifications && (
            <ProfilesList
              title={t("notifications")}
              profiles={myNotifications}
              shadow={false}
              style={{
                backgroundColor: "#fff",
              }}
            />
          )}
        </Grid>
      </Grid>
      <Calendar />
    </Page>
  );
}

export default Sales;
