import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
import Page from "components/UI/Page";
import { Icon, Card } from "@mui/material";
import { useLocation } from "react-router-dom";
import { fetchDataAction, deleteDataAction, setAlert } from "store/features/coreSlice";
import MDButton from "components/MDButton";
import DataModal from "components/UI/DataModal";
import DataTable from "components/UI/Table/DataTable";
import DeleteModal from "components/UI/DeleteModal";
import PagesCount from "helpers/pages-count";
import { useTranslation } from "react-i18next";
import Avatar from "components/UI/Table/avatarWithOutImg";
import ImportDB from "components/UI/ImportDB";
import PageForm from "./components/PageForm";
import FilterForm from "./components/FilterForm";
import dataVal from "./dataVal.json";

const Stores = () => {
  const { userInfo } = useSelector((state) => state.auth);
  let baseUrl = `items/distributions?fields=*,inventories.count,returns.count,store_id.gifts.count,store_id.gifts.id,store_id.distributions.count,store_id.distributions.returns.count,store_id.distributions.id,store_id.count,store_id.buying_id.notes,store_id.buying_id.id,store_id.buying_id.book_id.id,store_id.buying_id.book_id.title,store_id.buying_id.book_id.publisher_id.id,store_id.buying_id.book_id.publisher_id.title,store_id.buying_id.buying_date,store_id.place_id.title,store_id.place_id.id,store_id.id,proposal_id.discount_percentage,proposal_id.is_dolar,proposal_id.cost,proposal_id.id,library_id.id,library_id.title`;
  if (!userInfo.all_libraries) baseUrl += `&filter[library_id][users][user_id][_eq]=${userInfo.id}`;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { distributionsMeta, distributions, limit, simbleLayout } = useSelector(
    (state) => state.core
  );
  const [url, setUrl] = useState(baseUrl);
  const [newArr, setNewArr] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [number, setNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const [route, setRoute] = useState(null);
  const [pageOptions, setPageOption] = useState([]);
  const [formContainer, setFormContainer] = useState(<h1>wait ...</h1>);
  const [total, setTotal] = useState(null);

  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const setOpenModal = (container) => {
    setFormContainer(container);
    setOpen(true);
  };

  const handleOpen = () =>
    setOpenModal(<PageForm updateHandlerVal={null} pageNum={number} url={url} />);

  const onDeleteHandler = (name, values) => {
    if (values.inventories.length === 0 && values.returns.length === 0) {
      setOpenDeleteModal(true);
      setDeleteItemId(values.id);
    } else {
      dispatch(
        setAlert({
          status: "error",
          content: t("hasInventoriesOrGifts"),
        })
      );
    }
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    let totalLibraryBalanceProposalCost = 0;
    let totalBalance = 0;
    let totalCount = 0;
    let totalInventoriesCount = 0;
    let totalReturnsCount = 0;
    const d = [];
    for (let i = 0; i < PagesCount(+distributionsMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    let psign = null;
    let isEqual = true;
    let sign;
    if (distributions && distributions.length !== 0) {
      distributions.forEach((distribution, index) => {
        if (index === 0) psign = distribution.proposal_id.is_dolar;
        sign = distribution.proposal_id.is_dolar;
        if (psign !== sign) {
          isEqual = false;
        }
        item = {};
        let inventoriesCount = 0;
        let returnsCount = 0;
        keys = Object.keys(distribution);
        keys.forEach((element) => {
          item[element] = distribution[element];
        });
        distribution.inventories.forEach((element) => {
          inventoriesCount += element.count;
        });

        distribution.returns.forEach((element) => {
          returnsCount += element.count;
        });
        item.inventoriesCount = inventoriesCount;
        item.returnsCount = returnsCount;
        item.balance = item.count - (inventoriesCount + returnsCount);
        // item.title = distribution.store_id.buying_id.book_id.title;
        item.store = distribution.store_id.place_id.title;
        // item.library = distribution.library_id.title;
        // item.buyingDate = distribution.store_id.buying_id.buying_date;
        item.proposalPrice = `${distribution.proposal_id.cost} ${
          distribution.proposal_id.is_dolar ? "$" : ""
        }`;

        item.proposalDiscountPercentage = distribution.proposal_id.discount_percentage;
        const x = distribution.proposal_id.cost;
        item.libraryBalanceProposalCost0 = (
          item.balance *
          (x - (x * item.proposalDiscountPercentage) / 100)
        )?.toFixed(2);
        item.libraryBalanceProposalCost = `${item.libraryBalanceProposalCost0}
         ${distribution.proposal_id.is_dolar ? "$" : ""}`;

        item.title = (
          <Avatar
            dir="ltr"
            name={distribution.store_id.buying_id.book_id.title || ""}
            email={distribution.store_id.buying_id.buying_date}
          />
        );

        item.library = (
          <Avatar
            dir="ltr"
            name={distribution.library_id.title || ""}
            email={distribution.distribution_date}
          />
        );

        item.action = (
          <>
            {route.update && (
              <MDTypography
                style={{ padding: 20, color: "#3b94ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => {
                  if (simbleLayout) {
                    setOpenModal(
                      <PageForm
                        updateHandlerVal={{
                          name: "distributions",
                          value: distribution,
                        }}
                        pageNum={number}
                        url={url}
                      />
                    );
                  }
                }}
              >
                <Icon fontSize="small">edit</Icon>
              </MDTypography>
            )}
            {route.delete && (
              <MDTypography
                style={{ padding: 20, color: "#e9423f" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onDeleteHandler("distributions", distribution)}
              >
                <Icon fontSize="small">delete</Icon>
              </MDTypography>
            )}
            {route.create && (
              <MDTypography
                style={{ padding: 20, color: "#3b00ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => {
                  if (simbleLayout) {
                    setOpenModal(
                      <PageForm
                        updateHandlerVal={{
                          name: "distributions",
                          value: distribution,
                          isCopy: true,
                        }}
                        pageNum={number}
                        url={url}
                      />
                    );
                  }
                }}
              >
                <Icon fontSize="small">content_copy</Icon>
              </MDTypography>
            )}
          </>
        );

        totalLibraryBalanceProposalCost += +item.libraryBalanceProposalCost0;
        totalBalance += +item.balance;
        totalCount += +item.count;
        totalInventoriesCount += +item.inventoriesCount;
        totalReturnsCount += +item.returnsCount;
        if (index === distributions.length - 1 && !isEqual) {
          totalLibraryBalanceProposalCost = null;
        } else if (index === distributions.length - 1) {
          totalLibraryBalanceProposalCost += distribution.proposal_id.is_dolar ? "$" : "";
        }

        Arr.push(item);
      });
      setTotal({
        libraryBalanceProposalCost: totalLibraryBalanceProposalCost,
        balance: totalBalance,
        count: totalCount,
        inventoriesCount: totalInventoriesCount,
        returnsCount: totalReturnsCount,
      });
      setNewArr(Arr);
    } else {
      setNewArr([]);
    }
  };

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `${url}&meta=*&limit=${limit}&page=${num}`,
          varName: "distributions",
        })
      );
    }
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: "items/distributions",
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${number}`,
        varName: "distributions",
      },
    };
    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  const setSortByHandeller = (val) => {
    let urlVal = `${url}&meta=*&limit=${limit}&page=${number}`;

    urlVal += `&sort=${val.desc ? "-" : ""}${val.id}`;
    dispatch(
      fetchDataAction({
        url: urlVal,
        varName: "distributions",
      })
    );
  };

  useEffect(() => {
    const routeItem = userInfo.routes.filter(
      (x) =>
        `${x.route_name}` === location.pathname.split("/")[location.pathname.split("/").length - 1]
    )[0];
    setRoute(routeItem);
  }, []);

  useEffect(() => {
    if (distributions && route) {
      prepareTableRows();
    }
  }, [distributions, route]);

  if (newArr === []) {
    return null;
  }

  return (
    <Page>
      <Card style={{ padding: 20, position: "relative" }}>
        <DataModal
          handleClose={() => {
            setOpen(false);
          }}
          open={open}
        >
          {formContainer}
        </DataModal>
        <DeleteModal
          handleClose={handleDeleteModalClose}
          handleConfirmDelete={handleConfirmDelete}
          openDeleteModal={openDeleteModal}
        />
        <FilterForm pageNum={number} baseUrl={baseUrl} setUrl={setUrl} setNumber={setNumber} />

        {simbleLayout && route?.create && (
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleOpen}
            style={{ float: "left" }}
            size="large"
          >
            <Icon>add</Icon>&nbsp; {t("add")}
          </MDButton>
        )}
        <DataTable
          table={{ columns: dataVal.columns, rows: newArr }}
          item={false}
          total={total}
          pageOptions={pageOptions}
          isSorted
          isPagination
          entriesPerPage
          showTotalEntries
          noEndBorder
          search
          setPageNum={setPageNumber}
          setSortByHandeller={setSortByHandeller}
          pageIndex={number - 1}
          allRowsCount={distributionsMeta ? +distributionsMeta.filter_count : 0}
        />
        <ImportDB
          payload={{
            url: `custom/importDistributions`,
            varName: "importDistributions",
            getInfo: {
              url: `${url}&meta=*&limit=${limit}&page=${number}`,
              varName: "distributions",
            },
          }}
        />
      </Card>
    </Page>
  );
};

export default Stores;
