import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Page from "components/UI/Page";
import { Card } from "@mui/material";
import { useLocation } from "react-router-dom";
import { fetchDataAction } from "store/features/coreSlice";
import DataTable from "components/UI/Table/DataTable";
import PagesCount from "helpers/pages-count";
import FilterForm from "./components/FilterForm";

const InsertBook = () => {
  const baseUrl = `items/libraries?fields=id,title,city_id.title,city_id.country_id.title,distributions.count,distributions.distribution_date,distributions.library_id.id,distributions.library_id.title,distributions.proposal_id.discount_percentage,distributions.proposal_id.cost,distributions.proposal_id.is_dolar,distributions.inventories.inventory_date,distributions.inventories.count,distributions.returns.count,distributions.store_id.buying_id.book_id.publisher_id.id,distributions.store_id.buying_id.book_id.id&filter[distributions][id][_nnull]=true`;
  const dispatch = useDispatch();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.auth);
  const { librariesRepMeta, librariesRep, limit } = useSelector((state) => state.core);

  const [url, setUrl] = useState(baseUrl);
  const [number, setNumber] = useState(1);
  const [route, setRoute] = useState(null);
  const [pageOptions, setPageOption] = useState([]);
  const [columns, setColumns] = useState([]);
  const [newArr, setNewArr] = useState([]);
  const [publisherId, setPublisherId] = useState(null);

  const prepareTableRows = () => {
    let item;
    let distributionCount;
    let inventoryCount;
    let returnCount;

    let booksArr;
    let publishersArr;
    let endDistributionDate;
    let endInventoryDate;
    const Arr = [];
    const columnsArr = [
      {
        Header: "title",
        accessor: "title",
        align: "center",
      },
      {
        Header: "country",
        accessor: "country",
        align: "center",
      },
      {
        Header: "city",
        accessor: "city",
        align: "center",
      },
      {
        Header: "distributionCount",
        accessor: "distributionCount",
        align: "center",
      },
      {
        Header: "inventoryCount",
        accessor: "inventoryCount",
        align: "center",
      },
      {
        Header: "returnCount",
        accessor: "returnCount",
        align: "center",
      },
      {
        Header: "balance",
        accessor: "balance",
        align: "center",
      },
      {
        Header: "titleCount",
        accessor: "bookCount",
        align: "center",
      },
      // {
      //   Header: "publisherCount",
      //   accessor: "publisherCount",
      //   align: "center",
      // },
      {
        Header: "endDistributionDate",
        accessor: "endDistributionDate",
        align: "center",
      },
      {
        Header: "endInventoryDate",
        accessor: "endInventoryDate",
        align: "center",
      },
    ];
    if (librariesRep && librariesRep.length !== 0) {
      const d = [];
      for (let i = 0; i < PagesCount(+librariesRepMeta.filter_count, limit); i += 1) {
        d.push(i);
      }
      setPageOption(d);
      librariesRep.forEach((library) => {
        item = {};
        distributionCount = 0;
        inventoryCount = 0;
        returnCount = 0;
        booksArr = [];
        publishersArr = [];
        endDistributionDate = 0;
        endInventoryDate = 0;
        item.id = library.id;
        item.title = library.title;
        item.country = library.city_id?.country_id.title;
        item.city = library.city_id?.title;
        library.distributions.forEach((distribution) => {
          if (publisherId) {
            if (distribution.store_id.buying_id.book_id.publisher_id.id === publisherId) {
              distributionCount += distribution.count;
              distribution.inventories.forEach((inventory) => {
                inventoryCount += inventory.count;
                if (Date.parse(inventory.inventory_date) > Date.parse(endInventoryDate)) {
                  endInventoryDate = inventory.inventory_date;
                }
              });
              distribution.returns.forEach((returnItem) => {
                returnCount += returnItem.count;
              });
              if (
                booksArr.filter((book) => distribution.store_id.buying_id.book_id.id === book)
                  .length === 0
              ) {
                booksArr.push(distribution.store_id.buying_id.book_id.id);
              }
              if (
                publishersArr.filter(
                  (publisher) =>
                    distribution.store_id.buying_id.book_id.publisher_id.id === publisher
                ).length === 0
              ) {
                publishersArr.push(distribution.store_id.buying_id.book_id.publisher_id.id);
              }
              if (Date.parse(distribution.distribution_date) > Date.parse(endDistributionDate)) {
                endDistributionDate = distribution.distribution_date;
              }
            }
          } else {
            distributionCount += distribution.count;
            distribution.inventories.forEach((inventory) => {
              inventoryCount += inventory.count;
              if (Date.parse(inventory.inventory_date) > Date.parse(endInventoryDate)) {
                endInventoryDate = inventory.inventory_date;
              }
            });
            distribution.returns.forEach((returnItem) => {
              returnCount += returnItem.count;
            });
            if (
              booksArr.filter((book) => distribution.store_id.buying_id.book_id.id === book)
                .length === 0
            ) {
              booksArr.push(distribution.store_id.buying_id.book_id.id);
            }
            if (
              publishersArr.filter(
                (publisher) => distribution.store_id.buying_id.book_id.publisher_id.id === publisher
              ).length === 0
            ) {
              publishersArr.push(distribution.store_id.buying_id.book_id.publisher_id.id);
            }
            if (Date.parse(distribution.distribution_date) > Date.parse(endDistributionDate)) {
              endDistributionDate = distribution.distribution_date;
            }
          }
        });
        item.distributionCount = distributionCount;
        item.inventoryCount = inventoryCount;
        item.returnCount = returnCount;
        item.balance = distributionCount - (inventoryCount + returnCount);
        item.bookCount = booksArr.length;
        item.publisherCount = publishersArr.length;
        item.endDistributionDate = endDistributionDate;
        item.endInventoryDate = endInventoryDate;
        Arr.push(item);
      });
      setColumns(columnsArr);
      setNewArr(Arr);
    } else {
      setNewArr([]);
    }
  };

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `${url}&meta=*&limit=${limit}&page=${num}`,
          varName: "librariesRep",
        })
      );
    }
  };

  useEffect(() => {
    const routeItem = userInfo.routes.filter(
      (x) =>
        `${x.route_name}` === location.pathname.split("/")[location.pathname.split("/").length - 1]
    )[0];
    setRoute(routeItem);
  }, []);

  useEffect(() => {
    if (librariesRep && route) {
      prepareTableRows();
    }
  }, [librariesRep, route]);

  if (newArr === []) {
    return null;
  }

  return (
    <Page>
      <Card style={{ padding: 20, position: "relative" }}>
        <FilterForm
          pageNum={number}
          baseUrl={baseUrl}
          setUrl={setUrl}
          setNumber={setNumber}
          setPublisherId={setPublisherId}
        />

        <DataTable
          table={{ columns, rows: newArr }}
          item={false}
          pageOptions={pageOptions}
          isSorted
          isPagination
          entriesPerPage
          showTotalEntries
          noEndBorder
          search
          setPageNum={setPageNumber}
          pageIndex={number - 1}
          allRowsCount={librariesRepMeta ? +librariesRepMeta.filter_count : 0}
          setSortByHandeller={() => {}}
        />
      </Card>
    </Page>
  );
};

export default InsertBook;
