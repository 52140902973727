import { useState, useEffect } from "react";

// react-router components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Became Possible components
import MDBox from "components/MDBox";
// import MDInput from "components/MDInput";

// Became Possible example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarSocialIconButton,
  navbarMobileMenu,
  navbarDesktopMenu,
} from "components/UI/Navbars/DashboardNavbar/styles";

// Became Possible context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import { logoutAction } from "store/features/authSlice";
// import localStoragePrefix from "helpers/config";
import { useSelector, useDispatch } from "react-redux";
// import { signInUrl } from "constants";

// import i18n from "../../../../i18n";

function DashboardNavbar({ absolute, light, isMini }) {
  const { settingsItem } = useSelector((state) => state.core);

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const dispatchItem = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () =>
  //   setOpenConfigurator(dispatch, {
  //     value: !openConfigurator,
  //     table: "config",
  //   });
  const { pathname } = useLocation();

  const handleConfiguratorOpen = () => {
    setOpenConfigurator(dispatch, {
      value: !openConfigurator,
      table: userInfo.routes.filter(
        (x) => `${x.route_name}` === pathname.split("/")[pathname.split("/").length - 1]
      )[0]?.route_name,
    });
  };
  // const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  // const { i18n } = useTranslation();

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  //   localStorage.setItem(`${localStoragePrefix}USER_LANG`, lng);
  //   document.body.dir = i18n.dir();
  //   // theme.direction = i18n.dir();
  //   window.location.reload();
  // };
  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const logoutHandller = () => {
    dispatchItem(logoutAction());
    window.location.reload();
  };

  const goTo = (link) => {
    window.open(link, "_blank");
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              {settingsItem?.facebook && (
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarSocialIconButton}
                  style={{ margin: 5 }}
                  onClick={() => goTo(settingsItem?.facebook)}
                >
                  <i className="fa fa-facebook" aria-hidden="true" />
                </IconButton>
              )}
              {settingsItem?.twitter && (
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarSocialIconButton}
                  style={{ margin: 5 }}
                  onClick={() => goTo(settingsItem?.twitter)}
                >
                  <i className="fa fa-twitter" aria-hidden="true" />
                </IconButton>
              )}
              {settingsItem?.website && (
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarSocialIconButton}
                  style={{ margin: 5 }}
                  onClick={() => goTo(settingsItem?.website)}
                >
                  <i className="fa fa-internet-explorer" aria-hidden="true" />
                </IconButton>
              )}
              {settingsItem?.instagram && (
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  style={{ margin: 5 }}
                  sx={navbarSocialIconButton}
                  onClick={() => goTo(settingsItem?.instagram)}
                >
                  <i className="fa fa-instagram" aria-hidden="true" />
                </IconButton>
              )}
              {settingsItem?.youtube && (
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarSocialIconButton}
                  style={{ margin: 5 }}
                  onClick={() => goTo(settingsItem?.youtube)}
                >
                  <i className="fa fa-youtube" aria-hidden="true" />
                </IconButton>
              )}

              {settingsItem?.telegram && (
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarSocialIconButton}
                  style={{ margin: 5 }}
                  onClick={() => goTo(settingsItem?.telegram)}
                >
                  <i className="fa fa-telegram" aria-hidden="true" />
                </IconButton>
              )}

              {settingsItem?.likedin && (
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarSocialIconButton}
                  style={{ margin: 5 }}
                  onClick={() => goTo(settingsItem?.likedin)}
                >
                  <i className="fa fa-linkedin" aria-hidden="true" />
                </IconButton>
              )}
            </MDBox>
            <MDBox pr={20}>{/* <MDInput label="Search here" /> */}</MDBox>
            <MDBox color={light ? "white" : "inherit"}>
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={() => changeLanguage(i18n.language === "ar" ? "en" : "ar")}
              >
                <Icon sx={iconsStyle}>translate</Icon>
              </IconButton> */}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={logoutHandller}
              >
                <Icon sx={iconsStyle}>power</Icon>
              </IconButton>
              {/* <Link to={signInUrl}>
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link> */}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon sx={iconsStyle}>notifications</Icon>
              </IconButton> */}
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
