import PropTypes from "prop-types";
// import Backdrop from "@mui/material/Backdrop";
// import CircularProgress from "@mui/material/CircularProgress";
// import { useSelector } from "react-redux";
import { Card, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { useTranslation } from "react-i18next";
// import { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DataTable from "./Table/DataTable";
import DataModal from "./DataModal";
import DataForm from "./form/DataForm";
import DeleteModal from "./DeleteModal";
import CardList from "./CardsList";
import DefaultInfoCard from "./Cards/InfoCards/DefaultInfoCard";

const Main = ({
  setOpen,
  setOpenDeleteModal,
  columns,
  rows,
  yupObj,
  open,
  name,
  data,
  initialValues,
  setFieldSelectedValue,
  setPayload,
  handleConfirmDelete,
  openDeleteModal,
  title,
  layout,
  setPageNum,
  count,
  allRowsCount,
  pageIndex,
  onChange,
  yupObjFilter,
  nameFilter,
  dataFilter,
  initialValuesFilter,
  setPayloadFilter,
  filter,
  setSortByHandeller,
  enableCreate,
  view,
  repFields,
  list,
  debt,
  message,
  isPagination,
  imgList,
  total,
  all,
  report,
  info,
  defaultInitialValues,
}) => {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDeleteModalClose = () => setOpenDeleteModal(false);
  // const { loading, loadingFiles } = useSelector((state) => state.core);
  const { t } = useTranslation();
  // const location = useLocation();
  // const { messages } = useSelector((state) => state.core);
  // const [message1, setMessage] = useState("");

  // useEffect(() => {
  //   if (messages) {
  //     const messageVal = messages.filter(
  //       (x) =>
  //         `${x.route_name}` ===
  //         location.pathname.split("/")[location.pathname.split("/").length - 1]
  //     )[0];
  //     // let mes;
  //     if (messageVal) {
  //       setMessage(messageVal.text.split("-"));
  //     }
  //   }
  // }, []);

  return (
    <Card style={{ padding: 20, position: "relative" }}>
      {/* {message1 && message1.length > 0 && (
        <Alert severity="warning">
          {message1.map((n) => n && <MDTypography key={Math.random()}>- {n}</MDTypography>)}
        </Alert>
      )} */}
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || loadingFiles}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      {layout === "simple" && enableCreate && (
        <MDButton variant="gradient" color="info" onClick={handleOpen} size="large">
          <Icon>add</Icon>&nbsp; {title}
        </MDButton>
      )}

      {dataFilter && dataFilter.length > 0 && filter && (
        <DataForm
          formTitle={t("search")}
          yupObj={yupObjFilter}
          name={nameFilter}
          data={dataFilter}
          initialValues={initialValuesFilter}
          setPayload={setPayloadFilter}
          setFieldSelectedValue={setFieldSelectedValue}
          onChange={onChange}
          debt={debt}
          filter
        />
      )}
      {info.length > 0 && (
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          sx={{ m: 2, p: 3 }}
        >
          {info.map((dataItem) => (
            <MDBox
              width="100%"
              mr={{ xs: 0, sm: 3 }}
              mb={{ xs: 3, sm: 0 }}
              key={`${dataItem.title}+${dataItem.value}`}
            >
              <DefaultInfoCard
                icon={dataItem.icon}
                title={dataItem.title}
                description={dataItem.description}
                value={dataItem.value}
              />
            </MDBox>
          ))}
        </Grid>
      )}
      {list ? (
        <CardList table={{ columns, rows }} />
      ) : (
        <DataTable
          search
          table={{ columns, rows }}
          repFields={repFields}
          isSorted
          isPagination={isPagination}
          entriesPerPage
          showTotalEntries
          noEndBorder
          setPageNum={setPageNum}
          pageOptions={count}
          allRowsCount={allRowsCount}
          pageIndex={pageIndex}
          total={total}
          setSortByHandeller={setSortByHandeller}
          report={report}
          all={all}
        />
      )}
      {data && data.length > 0 && (
        <>
          {layout !== "simple" ? (
            <DataForm
              message={message}
              enableCreate={enableCreate}
              view={view}
              yupObj={yupObj}
              name={name}
              data={data}
              initialValues={initialValues}
              setPayload={setPayload}
              setFieldSelectedValue={setFieldSelectedValue}
              onChange={onChange}
              debt={debt}
              imgList={imgList}
              defaultInitialValues={defaultInitialValues}
            />
          ) : (
            <DataModal handleClose={handleClose} open={open} style={{ position: "relative" }}>
              {/* <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading || loadingFiles}
              >
                <CircularProgress color="inherit" />
              </Backdrop> */}
              <DataForm
                message={message}
                enableCreate={enableCreate}
                view={view}
                yupObj={yupObj}
                name={name}
                data={data}
                initialValues={initialValues}
                setPayload={setPayload}
                setFieldSelectedValue={setFieldSelectedValue}
                onChange={onChange}
                debt={debt}
                imgList={imgList}
                defaultInitialValues={defaultInitialValues}
              />
            </DataModal>
          )}
          <DeleteModal
            handleClose={handleDeleteModalClose}
            handleConfirmDelete={handleConfirmDelete}
            openDeleteModal={openDeleteModal}
          />
        </>
      )}
    </Card>
  );
};

Main.propTypes = {
  setOpen: PropTypes.func,
  setOpenDeleteModal: PropTypes.func,
  columns: PropTypes.PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired, //
  rows: PropTypes.PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired, //
  yupObj: PropTypes.objectOf(PropTypes.any), //
  open: PropTypes.bool,
  name: PropTypes.string.isRequired,
  data: PropTypes.PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])), //
  initialValues: PropTypes.objectOf(PropTypes.any), //
  setFieldSelectedValue: PropTypes.func,
  setPayload: PropTypes.func, //
  handleConfirmDelete: PropTypes.func, //
  openDeleteModal: PropTypes.bool,
  title: PropTypes.string.isRequired,
  layout: PropTypes.string,
  setPageNum: PropTypes.func,
  count: PropTypes.arrayOf(PropTypes.number),
  allRowsCount: PropTypes.number,
  pageIndex: PropTypes.number,
  debt: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  yupObjFilter: PropTypes.objectOf(PropTypes.any),
  total: PropTypes.objectOf(PropTypes.any),
  nameFilter: PropTypes.string,
  dataFilter: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])),
  initialValuesFilter: PropTypes.objectOf(PropTypes.any),
  defaultInitialValues: PropTypes.objectOf(PropTypes.any),
  setPayloadFilter: PropTypes.func,
  // onChangeFilter: PropTypes.func,
  filter: PropTypes.bool,
  isPagination: PropTypes.bool,
  all: PropTypes.bool,
  setSortByHandeller: PropTypes.func,
  enableCreate: PropTypes.bool,
  view: PropTypes.bool,
  // file: PropTypes.bool,
  report: PropTypes.bool,
  list: PropTypes.bool,
  info: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])),
  repFields: PropTypes.node,
  message: PropTypes.string,
  imgList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])),
};

Main.defaultProps = {
  total: null,
  layout: null,
  data: [],
  initialValues: null,
  setFieldSelectedValue: () => null,
  setOpenDeleteModal: () => null,
  setOpen: () => null,
  yupObjFilter: null,
  defaultInitialValues: null,
  nameFilter: null,
  dataFilter: null,
  initialValuesFilter: null,
  setPayloadFilter: () => null,
  setPayload: () => null,
  // onChangeFilter: () => null,
  enableCreate: true,
  view: true,
  handleConfirmDelete: () => null,
  repFields: null,
  onChange: () => null,
  filter: false,
  setSortByHandeller: () => null,
  openDeleteModal: false,
  yupObj: null,
  open: false,
  // file: false,
  report: true,
  list: false,
  info: [],
  debt: null,
  message: null,
  isPagination: true,
  all: false,
  count: null,
  allRowsCount: null,
  pageIndex: 0,
  setPageNum: () => null,
  imgList: [],
};

export default Main;
