import moment from "moment";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

function InvoiceMetaVersionB() {
  function ccyFormat(num) {
    return `${num.toFixed(2)}`;
  }

  const mainTitleSX = {
    fontWeight: "bold",
    color: "#75799D",
  };

  return (
    <MDBox display="flex">
      <MDBox mx={8}>
        <MDTypography variant="caption" sx={mainTitleSX}>
          Total Amount
        </MDTypography>
        <MDTypography variant="body2">${ccyFormat(700)}</MDTypography>
        <MDTypography mt={3} variant="caption" sx={mainTitleSX}>
          Paid
        </MDTypography>
        <MDTypography variant="body2">${ccyFormat(300)}</MDTypography>
      </MDBox>
      <MDBox>
        <MDTypography variant="caption" sx={mainTitleSX}>
          Balance Due
        </MDTypography>
        <MDTypography variant="body1">${ccyFormat(400)}</MDTypography>

        <MDTypography
          mt={3}
          sx={{
            background: "#eef8fd",
            display: "inline-block",
            padding: "2px 8px",
            fontSize: 14,
            color: "#75799D",
          }}
        >
          Due Date: {moment(new Date()).format("DD/MM/YYYY")}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default InvoiceMetaVersionB;
