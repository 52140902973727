import PropTypes from "prop-types";
import TextFieldControl from "./inputs/TextFieldControl";
import TextAreaControl from "./inputs/TextAreaControl";
import CustomSimPleAutoComPlete from "./inputs/CustomSimPleAutoComPlete";
import CustomMultipleSimPleAutoComPlete from "./inputs/CustomMultipleSimPleAutoComPlete";
import CheckBoxControl from "./inputs/CheckBoxControl";
import UploadButton from "./inputs/UploadButton";
import DatePickerControl from "./inputs/DatePickersControl";
import ControlledRadioButtonsGroup from "./inputs/RadioButtonControl";
import MDDropzone from "./inputs/MDDropzone";
import EditorControl from "./inputs/EditorControl";

function FormikControl(props) {
  const { control, ...rest } = props;

  switch (control) {
    case "TextField":
      return <TextFieldControl {...rest} />;
    case "TextArea":
      return <TextAreaControl {...rest} />;
    case "Select":
      return <CustomSimPleAutoComPlete {...rest} />;
    case "MSelect":
      return <CustomMultipleSimPleAutoComPlete {...rest} />;
    case "chk":
      return <CheckBoxControl {...rest} />;
    case "Img":
      return <UploadButton {...rest} />;
    case "Date":
      return <DatePickerControl {...rest} />;
    case "radio":
      return <ControlledRadioButtonsGroup {...rest} />;
    case "Editor":
      return <EditorControl {...rest} />;
    case "MImg":
      return <MDDropzone {...rest} options={{ addRemoveLinks: true }} />;
    default:
      return null;
  }
}

FormikControl.propTypes = {
  control: PropTypes.string.isRequired,
};

export default FormikControl;
