import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const MultipleSelectChip = ({ data, optionTitle, setTableFields, tableFields }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(tableFields);

  const autoChangeHandler = (event, newValue) => {
    setValue(newValue);
    setTableFields(newValue);
  };

  return (
    <Autocomplete
      multiple
      disablePortal
      isOptionEqualToValue={(option, item) => option[optionTitle] === item[optionTitle]}
      onChange={autoChangeHandler}
      value={value}
      options={data}
      sx={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} />}
      renderOption={(props, option) => (
        <li {...props} key={option[optionTitle]}>
          {t(option[optionTitle])}
        </li>
      )}
      getOptionLabel={(option) => t(option[optionTitle])}
    />
  );
};

MultipleSelectChip.propTypes = {
  data: PropTypes.PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
  optionTitle: PropTypes.node.isRequired,
  setTableFields: PropTypes.func.isRequired,
  tableFields: PropTypes.PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
};

export default MultipleSelectChip;
