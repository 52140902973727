import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
import Page from "components/UI/Page";
import { Icon, Card } from "@mui/material";
import { useLocation } from "react-router-dom";
import { fetchDataAction, deleteDataAction, setAlert } from "store/features/coreSlice";
import MDButton from "components/MDButton";
import DataModal from "components/UI/DataModal";
import DataTable from "components/UI/Table/DataTable";
import DeleteModal from "components/UI/DeleteModal";
import PagesCount from "helpers/pages-count";
import { useTranslation } from "react-i18next";
import ImportDB from "components/UI/ImportDB";
import PageForm from "./components/PageForm";
import FilterForm from "./components/FilterForm";
import dataVal from "./dataVal.json";

const Stores = () => {
  const { userInfo } = useSelector((state) => state.auth);
  let baseUrl = `items/stores?fields=*,buying_id.count,buying_id.notes,buying_id.stores.count,buying_id.stores.id,buying_id.id,buying_id.buying_date,buying_id.book_id.id,buying_id.book_id.publisher_id.title,buying_id.book_id.publisher_id.id,buying_id.book_id.title,buying_id.buying_date,place_id.title,place_id.id,distributions.inventories.count,distributions.returns.count,distributions.count,gifts.count`;
  if (!userInfo.all_stores) baseUrl += `&filter[place_id][users][user_id][_eq]=${userInfo.id}`;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { storesMeta, stores, limit, simbleLayout } = useSelector((state) => state.core);
  const [url, setUrl] = useState(baseUrl);
  const [newArr, setNewArr] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [number, setNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const [route, setRoute] = useState(null);
  const [pageOptions, setPageOption] = useState([]);
  const [formContainer, setFormContainer] = useState(<h1>wait ...</h1>);

  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const setOpenModal = (container) => {
    setFormContainer(container);
    setOpen(true);
  };

  const handleOpen = () =>
    setOpenModal(<PageForm updateHandlerVal={null} pageNum={number} url={url} />);

  const onDeleteHandler = (name, values) => {
    if (values.distributions.length === 0 && values.gifts.length === 0) {
      setOpenDeleteModal(true);
      setDeleteItemId(values.id);
    } else
      dispatch(
        setAlert({
          status: "error",
          content: t("hasDistributionsOrGifts"),
        })
      );
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+storesMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);

    if (stores && stores.length !== 0) {
      stores.forEach((store) => {
        let distributionsCount = 0;
        let giftsCount = 0;
        let inventoriesCount = 0;
        let returnsCount = 0;
        item = {};
        keys = Object.keys(store);
        keys.forEach((element) => {
          item[element] = store[element];
        });

        store.distributions.forEach((element) => {
          distributionsCount += element.count;
          element.inventories.forEach((element1) => {
            inventoriesCount += element1.count;
          });
          element.returns.forEach((element1) => {
            returnsCount += element1.count;
          });
        });
        store.gifts.forEach((element) => {
          giftsCount += element.count;
        });
        item.distributionsCount = distributionsCount - returnsCount;
        item.giftsCount = giftsCount;
        item.inventoriesCount = inventoriesCount;
        // item.returnsCount = returnsCount;
        item.libraryBalance = distributionsCount - (inventoriesCount + returnsCount);
        item.balance = store.count + returnsCount - (distributionsCount + giftsCount);
        item.title = store.buying_id.book_id.title;
        item.buyingDate = store.buying_id.buying_date;
        item.store = store.place_id.title;
        item.action = (
          <>
            <MDTypography
              style={{ padding: 20, color: "#3b94ee" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => {
                if (simbleLayout) {
                  setOpenModal(
                    <PageForm
                      updateHandlerVal={{ name: "stores", value: store }}
                      pageNum={number}
                      url={url}
                    />
                  );
                }
              }}
            >
              <Icon fontSize="small">edit</Icon>
            </MDTypography>
            <MDTypography
              style={{ padding: 20, color: "#e9423f" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => onDeleteHandler("stores", store)}
            >
              <Icon fontSize="small">delete</Icon>
            </MDTypography>
            {route.create && (
              <MDTypography
                style={{ padding: 20, color: "#3b00ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => {
                  if (simbleLayout) {
                    setOpenModal(
                      <PageForm
                        updateHandlerVal={{ name: "stores", value: store, isCopy: true }}
                        pageNum={number}
                        url={url}
                      />
                    );
                  }
                }}
              >
                <Icon fontSize="small">content_copy</Icon>
              </MDTypography>
            )}
          </>
        );
        Arr.push(item);
      });
      setNewArr(Arr);
    } else {
      setNewArr([]);
    }
  };

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `${url}&meta=*&limit=${limit}&page=${num}`,
          varName: "stores",
        })
      );
    }
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: "items/stores",
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${number}`,
        varName: "stores",
      },
    };
    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  const setSortByHandeller = (val) => {
    let urlVal = `${url}&meta=*&limit=${limit}&page=${number}`;

    urlVal += `&sort=${val.desc ? "-" : ""}${val.id}`;
    dispatch(
      fetchDataAction({
        url: urlVal,
        varName: "stores",
      })
    );
  };

  useEffect(() => {
    const routeItem = userInfo.routes.filter(
      (x) =>
        `${x.route_name}` === location.pathname.split("/")[location.pathname.split("/").length - 1]
    )[0];
    setRoute(routeItem);
  }, []);

  useEffect(() => {
    if (stores && route) {
      prepareTableRows();
    }
  }, [stores, route]);

  if (newArr === []) {
    return null;
  }

  return (
    <Page>
      <Card style={{ padding: 20, position: "relative" }}>
        <DataModal
          handleClose={() => {
            setOpen(false);
          }}
          open={open}
        >
          {formContainer}
        </DataModal>
        <DeleteModal
          handleClose={handleDeleteModalClose}
          handleConfirmDelete={handleConfirmDelete}
          openDeleteModal={openDeleteModal}
        />
        <FilterForm pageNum={number} baseUrl={baseUrl} setUrl={setUrl} setNumber={setNumber} />

        {simbleLayout && route?.create && (
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleOpen}
            style={{ float: "left" }}
            size="large"
          >
            <Icon>add</Icon>&nbsp; {t("add")}
          </MDButton>
        )}
        <DataTable
          table={{ columns: dataVal.columns, rows: newArr }}
          item={false}
          pageOptions={pageOptions}
          isSorted
          isPagination
          entriesPerPage
          showTotalEntries
          noEndBorder
          search
          setPageNum={setPageNumber}
          setSortByHandeller={setSortByHandeller}
          pageIndex={number - 1}
          allRowsCount={storesMeta ? +storesMeta.filter_count : 0}
        />
        <ImportDB
          payload={{
            url: `custom/importStores`,
            varName: "importStores",
            getInfo: {
              url: `${url}&meta=*&limit=${limit}&page=${number}`,
              varName: "stores",
            },
          }}
        />
      </Card>
    </Page>
  );
};

export default Stores;
