import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { signInUrl } from "constants";
import { useTranslation } from "react-i18next";
import SectionContainer from "./SectionContainer";

function SpecificFeatures() {
  const { t } = useTranslation();

  return (
    //   <ActionSection
    //   title={t(downloadTrakibManualContent[`${i18n.language}title`])}
    //   body={t(downloadTrakibManualContent[`${i18n.language}body`])}
    //   btnText={t(downloadTrakibManualContent[`${i18n.language}BtnText`])}
    //   Icon={<Download sx={{ width: 30, height: 30 }} />}
    // />
    <SectionContainer bgColor="secondary" sx={{ px: { xs: 3, lg: 8 }, borderRadius: 7 }}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={7} lg={6}>
          <MDTypography
            color="info"
            variant="h2"
            sx={{ fontSize: { xs: 22, md: 27, lg: 40 }, mb: { xs: 3, lg: 4 } }}
          >
            {t("integratedSysFor")} <br />
            {t("bookPublishers&workOwners")}
          </MDTypography>
          <MDTypography color="white" variant="body1" sx={{ fontSize: { xs: 20, md: 18, lg: 20 } }}>
            {t("trakibFeaturesBrief")}
          </MDTypography>
        </Grid>
        {/* <Grid item xs={12} lg={6}>
          <MDTypography color="info" variant="h3" mb={4}>
            {t("integratedSysFor")} <br />
            {t("bookPublishers&workOwners")}
          </MDTypography>
          <MDTypography color="white" variant="body1">
            {t("trakibFeaturesBrief")}
          </MDTypography>
        </Grid> */}

        <Grid item container xs={12} md={5} lg={6} justifyContent="flex-end">
          <MDButton
            href={signInUrl}
            color="info"
            sx={{
              width: { xs: "100%", lg: 300 },
              height: 62,
              fontSize: { xs: 17, lg: 20 },
            }}
          >
            {t("getStartedFree")}
          </MDButton>
        </Grid>
      </Grid>
    </SectionContainer>
  );
}

export default SpecificFeatures;
