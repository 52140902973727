import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";

import registerSvg from "./img/register.svg";

const content = {
  entitle: "One of us ?",
  artitle: "أنت عضــو بالفعــل؟",
  enbody: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis, ex ratione. Aliquid!",
  arbody: "مرحبا بعودتك",
};

function RightPanel({ setSignUpMode }) {
  const { t, i18n } = useTranslation();

  return (
    <MDBox className={`panel right-panel ${i18n.dir() === "rtl" && "rtl-dir"}`}>
      <MDBox className="content">
        <h3>{content[`${i18n.language}title`]}</h3>
        <p>{content[`${i18n.language}body`]} </p>
        <button
          type="button"
          className="btn transparent"
          id="sign-in-btn"
          onClick={() => setSignUpMode(false)}
        >
          {t("signin")}
        </button>
      </MDBox>
      <img src={registerSvg} className="image" alt="" />
    </MDBox>
  );
}

RightPanel.propTypes = {
  setSignUpMode: PropTypes.func.isRequired,
};

export default RightPanel;
