// import { ContactMailOutlined } from "@mui/icons-material";
import { Container, Grid } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import MDBox from "components/MDBox";
import ContactForm from "./ContactForm";
import contactUs from "../../assets/contactus.svg";

function ContactUs() {
  const { i18n } = useTranslation();

  return (
    <MDBox py={8} bgColor="secondary" id="contact-us">
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6} container alignItems="center" justifyContent="center">
            <MDBox
              component="img"
              src={contactUs}
              alt="Contact us"
              width="80%"
              sx={{
                background: "#03dd74",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                borderRadius: 5,
                transform: `scaleX(${i18n.dir() === "rtl" ? -1 : 1})`,
              }}
            />
            {/* <ContactMailOutlined color="primary" style={{ width: "75%", height: "75%" }} /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactForm />
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default memo(ContactUs);
