import { AddBusiness, Email, Lock, Person } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { signUpAction } from "store/features/authSlice";
// import { Typography } from "@mui/material";

import AuthFormikInput from "./AuthFormikInput";
// import AuthProviders from "./AuthProviders";
import "./auth-form.css";

const signUpFormFields = [
  {
    name: "title",
    label: "company_name",
    Icon: AddBusiness,
  },
  {
    name: "first_name",
    label: "admin_first_name",
    Icon: Person,
  },
  {
    name: "last_name",
    label: "admin_last_name",
    Icon: Person,
  },
  {
    name: "email",
    label: "admin_email",
    type: "email",

    Icon: Email,
  },

  {
    name: "password",
    label: "password",
    type: "password",
    Icon: Lock,
  },

  {
    name: "confirmPassword",
    label: "confirmPassword",
    type: "password",
    Icon: Lock,
  },
];

const signUpInitialValues = {
  title: "",
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const signUpValidationSchema = Yup.object().shape({
  title: Yup.string().required("required field"),
  first_name: Yup.string().required("required field"),
  last_name: Yup.string().required("required field"),

  email: Yup.string().email("wrong email").required("required field"),
  password: Yup.string().required("required field"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), ""], "Passwords must match")
    .required("Required"),
});

function SignUpForm() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentDate = moment();
  let futureMonth = moment(currentDate).add(1, "M");
  const futureMonthEnd = moment(futureMonth).endOf("month");

  if (
    currentDate.date() !== futureMonth.date() &&
    futureMonth.isSame(futureMonthEnd.format("YYYY-MM-DD"))
  ) {
    futureMonth = futureMonth.add(1, "d");
  }

  const onSubmit = (values) => {
    const payload = {};

    Object.keys(signUpInitialValues).forEach((key) => {
      if (key !== "confirmPassword") payload[key] = values[key];
      // key !== "confirmPassword" && (payload[key] = values[key]);
    });
    payload.expiry_date = futureMonth.format("YYYY-MM-DD");

    console.log("payload = ", payload);
    dispatch(signUpAction(payload));
  };

  return (
    <Formik
      initialValues={signUpInitialValues}
      validationSchema={signUpValidationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      <Form noValidate className="sign-up-form">
        <h2 className="title">{t("signup")}</h2>

        {signUpFormFields.map(({ label, ...field }) => (
          <AuthFormikInput placeholder={t(label)} {...field} key={label} />
        ))}
        <button type="submit" className="btn">
          {t("signup")}
        </button>
        {/* <p className="social-text">{t("signUpWithSocailMedia")}</p> */}
        {/* <AuthProviders /> */}
      </Form>
    </Formik>
  );
}

export default SignUpForm;
