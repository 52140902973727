import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import DataForm from "components/UI/form/DataForm";

import { addComplexDataAction, fetchDataAction, reset } from "store/features/coreSlice";
import PropTypes from "prop-types";
import UpdateRecord from "helpers/compare";
import dataVal from "../../dataVal.json";

const PageForm = ({ updateHandlerVal, url, pageNum }) => {
  const dispatch = useDispatch();

  const {
    authors,
    categories,
    translators,
    reviewers,
    investigators,
    editors,
    publishers,
    limit,
    booksImgList,
    baseURL,
  } = useSelector((state) => state.core);

  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);
  const [filesData, setFilesData] = useState(undefined);

  const resetForm = () => {
    setFilesData(undefined);
  };

  const orgData = () => {
    setData([
      {
        type: "TextField",
        name: "title",
        id: "title",
        label: "title",
        size: 4,
        title: "title",
      },

      {
        type: "TextField",
        name: "depositnumber",
        id: "depositnumber",
        label: "depositnumber",
        size: 4,
        title: "depositNumber",
      },
      {
        type: "TextField",
        name: "internationalnumber",
        id: "internationalnumber",
        label: "internationalnumber",
        size: 4,
        title: "internationalNumber",
      },
      {
        type: "TextField",
        name: "copyrights",
        id: "copyrights",
        label: "copyrights",
        size: 4,
        title: "copyrights",
      },
      {
        type: "TextField",
        name: "contract_copies_number",
        id: "contract_copies_number",
        label: "contract_copies_number",
        size: 4,
        title: "contractCopiesNumber",
      },
      // {
      //   type: "MSelect",
      //   optionTitle: "title",
      //   name: "editors_id",
      //   id: "editors_id",
      //   label: "editors_id",
      //   data: editors,
      //   size: 4,
      //   title: "editors",
      // },
      // {
      //   type: "MSelect",
      //   optionTitle: "title",
      //   name: "translators_id",
      //   id: "translators_id",
      //   label: "translators_id",
      //   data: translators,
      //   size: 4,
      //   title: "translators",
      // },
      {
        type: "MSelect",
        optionTitle: "title",
        name: "authors_id",
        id: "authors_id",
        label: "authors_id",
        data: authors,
        size: 4,
        title: "authors",
      },
      {
        type: "MSelect",
        optionTitle: "title",
        name: "categories_id",
        id: "categories_id",
        label: "categories_id",
        data: categories,
        size: 4,
        title: "categories",
      },
      // {
      //   type: "MSelect",
      //   optionTitle: "title",
      //   name: "reviewers_id",
      //   id: "reviewers_id",
      //   label: "reviewers_id",
      //   data: reviewers,
      //   size: 4,
      //   title: "reviewers",
      // },
      // {
      //   type: "MSelect",
      //   optionTitle: "title",
      //   name: "investigators_id",
      //   id: "investigators_id",
      //   label: "investigators_id",
      //   data: investigators,
      //   size: 4,
      //   title: "investigators",
      // },
      {
        type: "Select",
        optionTitle: "title",
        name: "publisher_id",
        id: "publisher_id",
        label: "publisher_id",
        data: publishers,
        size: 4,
        title: "publisher",
      },
      {
        type: "Date",
        name: "contract_date",
        id: "contract_date",
        label: "contract_date",
        size: 4,
        title: "contractDate",
      },
      {
        type: "Date",
        name: "contract_finishing_date",
        id: "contract_finishing_date",
        label: "contract_finishing_date",
        size: 4,
        title: "contractFinishingDate",
      },
      {
        type: "Date",
        name: "publish_before_date",
        id: "publish_before_date",
        label: "publish_before_date",
        size: 4,
        title: "publishBeforeDate",
      },
      {
        type: "TextArea",
        name: "description",
        id: "description",
        label: "description",
        maxRows: 4,
        minRows: 4,
        size: 6,
        title: "Summary",
      },
      {
        type: "TextArea",
        name: "notes",
        id: "notes",
        label: "notes",
        maxRows: 4,
        minRows: 4,
        size: 6,
        title: "notes",
      },
      {
        type: "Img",
        name: "file",
        id: "file",
        label: "file",
        size: 4,
        title: "image",
      },
      {
        type: "MImg",
        name: "files",
        id: "files",
        label: "files",
        size: 8,
        title: "image",
      },
    ]);
  };

  const onUpdateHandler = (name, values, copy) => {
    if (!copy)
      dispatch(
        fetchDataAction({
          url: `items/all_files?fields=id,collection,file_id.filesize,file_id.id,item_id,file_id.type,file_id.filename_download&filter[collection]=books&filter[item_id]=${values.id}`,
          varName: "booksImgList",
        })
      );
    const editorsData = [];
    values.editors_id?.forEach((element) => {
      editorsData.push(element.editor_id);
    });
    const authorsData = [];
    values.authors_id?.forEach((element) => {
      authorsData.push(element.author_id);
    });
    const categoriesData = [];
    values.categories_id?.forEach((element) => {
      categoriesData.push(element.category_id);
    });
    const translatorsData = [];
    values.translators_id?.forEach((element) => {
      translatorsData.push(element.translator_id);
    });
    const investigatorsData = [];
    values.investigators_id?.forEach((element) => {
      investigatorsData.push(element.investigator_id);
    });
    const reviewersData = [];
    values.reviewers_id?.forEach((element) => {
      reviewersData.push(element.reviewer_id);
    });
    setInitialValues({
      id: copy ? null : values.id,
      title: values.title,
      depositnumber: values.depositnumber,
      internationalnumber: values.internationalnumber,
      file:
        values.file && `${baseURL}assets/${values.file}?fit=cover&width=100&height=200&quality=80`,
      authors_id: authorsData,
      categories_id: categoriesData,
      translators_id: translatorsData,
      investigators_id: investigatorsData,
      publisher_id: values.publisher_id,
      reviewers_id: reviewersData,
      editors_id: editorsData,
      description: values.description,
      contract_date: values.contract_date,
      contract_copies_number: values.contract_copies_number,
      contract_finishing_date: values.contract_finishing_date,
      publish_before_date: values.publish_before_date,
      copyrights: values.copyrights,
      notes: values.notes,
    });
  };

  const setPayload = ({ files, ...values }) => {
    const payloadData = {};
    let investigatorsArr = [];
    let authorsArr = [];
    let categoriesArr = [];
    let translatorsArr = [];
    let editorsArr = [];
    let reviewersArr = [];

    const lastData = values.id ? UpdateRecord(values, initialValues) : values;
    if (initialValues.status?.value !== values.status?.value)
      payloadData.status = values.status.value;

    if (values.id === null) {
      editorsArr = values.editors_id.length > 0 ? { add: values.editors_id } : null;
      authorsArr = values.authors_id.length > 0 ? { add: values.authors_id } : null;
      categoriesArr = values.categories_id.length > 0 ? { add: values.categories_id } : null;
      investigatorsArr =
        values.investigators_id.length > 0 ? { add: values.investigators_id } : null;
      translatorsArr = values.translators_id.length > 0 ? { add: values.translators_id } : null;
      reviewersArr = values.reviewers_id.length > 0 ? { add: values.reviewers_id } : null;
    } else {
      editorsArr = lastData?.editors_id || null;
      authorsArr = lastData?.authors_id || null;
      categoriesArr = lastData?.categories_id || null;
      investigatorsArr = lastData?.investigators_id || null;
      translatorsArr = lastData?.translators_id || null;
      reviewersArr = lastData?.reviewers_id || null;
    }
    delete lastData.status;
    delete lastData.editors_id;
    delete lastData.translators_id;
    delete lastData.authors_id;
    delete lastData.categories_id;
    delete lastData.investigators_id;
    delete lastData.reviewers_id;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });

    const req = {
      imgFieldName: "file",
      id: values.id,
      file: {
        multi: true,
        file: typeof values.file === "object" ? values.file : null,
        files,
      },
      origin: {
        data: Object.keys(payloadData).length === 0 ? null : payloadData,
        table: "books",
      },
      related: [
        {
          table: "books_investigators",
          data: investigatorsArr,
          column: "investigator_id",
          origin_column: "book_id",
        },
        {
          table: "books_authors",
          data: authorsArr,
          column: "author_id",
          origin_column: "book_id",
        },
        {
          table: "books_categories",
          data: categoriesArr,
          column: "category_id",
          origin_column: "book_id",
        },
        {
          table: "books_editors",
          data: editorsArr,
          column: "editor_id",
          origin_column: "book_id",
        },
        {
          table: "books_reviewers",
          data: reviewersArr,
          column: "reviewer_id",
          origin_column: "book_id",
        },
        {
          table: "books_translators",
          data: translatorsArr,
          column: "translator_id",
          origin_column: "book_id",
        },
      ],
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "books",
      },
    };
    dispatch(addComplexDataAction(req));
    resetForm();
  };

  useEffect(() => {
    if (updateHandlerVal) {
      onUpdateHandler(updateHandlerVal.name, updateHandlerVal.value, updateHandlerVal.isCopy);
    }
    return () => {
      resetForm();
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (authors && translators && investigators && reviewers && editors && publishers && categories)
      orgData();
  }, [authors, translators, investigators, reviewers, editors, publishers, categories]);

  useEffect(() => {
    setFilesData(booksImgList);
  }, [booksImgList]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      yupObj={{
        title: yup.string("required").required("required").nullable(),
        authors_id: yup.array().min(1, "at least 1").required("required"),
        categories_id: yup.array().min(1, "at least 1").required("required"),
        publisher_id: yup.mixed("required").required("required").nullable(),
        contract_copies_number: yup
          .number("must be number")
          .typeError("must be a number")
          .positive("must be greater than zero")
          .nullable(),
        copyrights: yup
          .number("must be number")
          .typeError("must be a number")
          .positive("must be greater than zero")
          .nullable(),
      }}
      name="books"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      defaultInitialValues={dataVal.initialValues}
      imgList={filesData}
      resetData={resetForm}
    />
  );
};

PageForm.propTypes = {
  updateHandlerVal: PropTypes.objectOf(PropTypes.any),
  url: PropTypes.string.isRequired,
  pageNum: PropTypes.number.isRequired,
};

PageForm.defaultProps = {
  updateHandlerVal: null,
};
export default PageForm;
