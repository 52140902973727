import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataForm from "components/UI/form/DataForm";
import { fetchDataAction } from "store/features/coreSlice";
import PropTypes from "prop-types";
import dataVal from "../../dataVal.json";

const FilterForm = ({ setUrl, baseUrl, setNumber }) => {
  const dispatch = useDispatch();
  const {
    filterspecializations,
    filterdegrees,
    filteruniversities,
    filtercountries,
    filterresidence,
    limit,
  } = useSelector((state) => state.core);
  const [data, setData] = useState([]);
  const [filteredValues, setFilteredValues] = useState(null);

  const [initialValues, setInitialValues] = useState(dataVal.initialValuesFilter);

  const orgData = () => {
    setData([
      {
        type: "TextField",
        name: "first_name",
        id: "first_name",
        label: "first_name",
        size: 3,
        title: "fullName",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "degree_id",
        id: "degree_id",
        label: "degree_id",
        data: filterdegrees,
        size: 3,
        title: "degree",
      },
      {
        type: "MSelect",
        optionTitle: "title",
        name: "specializations",
        id: "specializations",
        label: "specializations",
        data: filterspecializations,
        size: 3,
        title: "specializations",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "university_id",
        id: "university_id",
        label: "university_id",
        data: filteruniversities,
        size: 3,
        title: "university",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "country_id",
        id: "country_id",
        label: "country_id",
        data: filtercountries,
        size: 3,
        title: "nationality",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "residence",
        id: "residence",
        label: "residence",
        data: filterresidence,
        size: 3,
        title: "residence",
      },
    ]);
  };

  useEffect(() => {
    if (
      filterspecializations &&
      filterdegrees &&
      filteruniversities &&
      filtercountries &&
      filterresidence
    ) {
      orgData();
    }
  }, [filterspecializations, filterdegrees, filteruniversities, filtercountries, filterresidence]);

  const generateUrl = (values) => {
    let str = baseUrl;

    const keys = Object.keys(values);
    keys.forEach((element) => {
      if (values[element] && values[element] !== []) {
        if (Array.isArray(values[element])) {
          if (element === "job_titles") {
            values[element].forEach((element1) => {
              str += `&filter[${element}.job_title_id][in]=${element1.id}`;
            });
          }
          if (element === "specializations") {
            values[element].forEach((element1) => {
              str += `&filter[${element}.specialization_id][in]=${element1.id}`;
            });
          }
        } else if (typeof values[element] === "object") {
          str += `&filter[${element}.id]=${values[element].id}`;
        } else {
          str += `&filter[${element}][_contains]=${values[element]}`;
        }
      }
    });

    return str;
  };

  const setPayload = (values) => {
    setFilteredValues(values);
    setNumber(1);
    if (values) setInitialValues(values);
    setUrl(generateUrl(values));
    dispatch(
      fetchDataAction({
        url: `${generateUrl(values)}&meta=*&limit=${limit}&page=${1}`,
        varName: "users",
      })
    );
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      if (element === "jobtitles") {
        dispatch(
          fetchDataAction({
            url: `items/job_title?fields=id,title&limit=${limit}`,
            varName: `filterjobtitle`,
          })
        );
      } else if (element === "stores") {
        dispatch(
          fetchDataAction({
            url: "items/stores_places?fields=id,title",
            varName: `filter${element}`,
          })
        );
      } else if (element === "residence") {
        dispatch(
          fetchDataAction({
            url: "items/countries?fields=id,title",
            varName: `filter${element}`,
          })
        );
      } else {
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,title&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
      }
    });
  };

  useEffect(() => {
    getData(dataVal.oterData);
  }, []);

  useEffect(() => {
    setPayload(filteredValues || initialValues);
  }, [limit]);

  const onChangeSelectInputs = (name, text) => {
    if (name === "job_title_id") {
      dispatch(
        fetchDataAction({
          url: `items/job_title?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "filterjobtitle",
        })
      );
    } else if (name === "specializations") {
      dispatch(
        fetchDataAction({
          url: `items/specializations?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "filterspecializations",
        })
      );
    } else if (name === "degree_id") {
      dispatch(
        fetchDataAction({
          url: `items/degrees?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "filterdegrees",
        })
      );
    } else if (name === "university_id") {
      dispatch(
        fetchDataAction({
          url: `items/universities?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "filteruniversities",
        })
      );
    } else if (name === "country_id") {
      dispatch(
        fetchDataAction({
          url: `items/countries?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "filtercountries",
        })
      );
    } else if (name === "residence") {
      dispatch(
        fetchDataAction({
          url: `items/countries?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "filterresidence",
        })
      );
    } else if (name === "libraries") {
      dispatch(
        fetchDataAction({
          url: `items/libraries?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "filterlibraries",
        })
      );
    } else if (name === "stores") {
      dispatch(
        fetchDataAction({
          url: `items/stores_places?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "filterstores",
        })
      );
    }
  };

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      filter
      yupObj={{}}
      name="worksFilter"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      onChange={onChangeSelectInputs}
    />
  );
};

FilterForm.propTypes = {
  setUrl: PropTypes.func.isRequired,
  setNumber: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};
export default FilterForm;
