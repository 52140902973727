import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const CustomSimPleAutoComPlete = ({
  setFieldValue,
  setFieldTouched,
  name,
  setFieldSelectedValue,
  data,
  optionTitle,
  optionTitle1 = null,
  onChange,
  oterOptionTitle = null,
  oterOptionTitle1 = null,
  title,
  optionTitlecond,
  secondOptionTitle,
  secondOtherOptionTitle,
  // secondOtherOptionTitle1,
  optionTitleText,
  optionTitle1Text,
  disable,
  value,
}) => {
  const [inputValue, setInputValue] = useState(value);

  const autoChangeHandler = (event, newValue) => {
    setFieldValue(name, newValue);
    setFieldTouched(name, newValue);
    setInputValue(newValue);
    if (newValue) setFieldSelectedValue(name, newValue);
  };

  const autoChangeHandler0 = (event) => {
    setFieldValue(name, null);
    setFieldTouched(name, null);
    setInputValue(null);
    onChange(name, event.target.value);
  };

  useEffect(() => {
    if (data && data.filter((x) => inputValue && x.id === inputValue.id).length === 0) {
      setFieldValue(name, null);
      setFieldTouched(name, null);
      setInputValue(null);
    }
  }, [data]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const generateItem = (option) => {
    let t = `${option[optionTitle]}${option[optionTitlecond] ? optionTitleText : ""}`;
    if (typeof option[optionTitle] === "object") {
      if (typeof option[optionTitle][oterOptionTitle] === "object")
        t = option[optionTitle][oterOptionTitle][oterOptionTitle1];
      else t = option[optionTitle][oterOptionTitle];
    }
    return t;
  };

  return (
    <Autocomplete
      disablePortal
      onChange={autoChangeHandler}
      value={inputValue}
      isOptionEqualToValue={(option, item) => option === item || option.id === item.id}
      disabled={disable}
      options={data}
      sx={{ width: "100%" }}
      name={name}
      renderInput={(params) => (
        <TextField {...params} onChange={autoChangeHandler0} label={title} />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {generateItem(option)}
          {option[secondOptionTitle] && `  -  ${option[secondOptionTitle][secondOtherOptionTitle]}`}
          {optionTitle1 &&
            option[optionTitle1] &&
            `  -  ${option[optionTitle1]}${optionTitle1Text}`}
        </li>
      )}
      getOptionLabel={(option) => {
        const y = generateItem(option);
        let txt = "";
        if (optionTitle1 && option[optionTitle1]) {
          txt = `${y}-${option[optionTitle1]}${optionTitle1Text}`;
        } else if (option[secondOptionTitle]) {
          txt = `${y}-  ${option[secondOptionTitle][secondOtherOptionTitle]}`;
        } else txt = y;
        return txt;
      }}
    />
  );
};

CustomSimPleAutoComPlete.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.objectOf(PropTypes.any),
  setFieldSelectedValue: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
  optionTitle: PropTypes.string,
  optionTitle1: PropTypes.string,
  oterOptionTitle: PropTypes.string,
  oterOptionTitle1: PropTypes.string,
  optionTitlecond: PropTypes.string,
  optionTitleText: PropTypes.string,
  optionTitle1Text: PropTypes.string,
  secondOptionTitle: PropTypes.string,
  secondOtherOptionTitle: PropTypes.string,
  // secondOtherOptionTitle1: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  disable: PropTypes.bool,
};

CustomSimPleAutoComPlete.defaultProps = {
  value: null,
  optionTitle: null,
  optionTitle1: null,
  oterOptionTitle: null,
  oterOptionTitle1: null,
  optionTitlecond: null,
  secondOptionTitle: null,
  secondOtherOptionTitle: null,
  // secondOtherOptionTitle1: null,
  optionTitleText: null,
  optionTitle1Text: null,
  setFieldSelectedValue: () => null,
  disable: false,
};

export default CustomSimPleAutoComPlete;
