const PrepareUrl = (url) => {
  try {
    let newUrl = "";
    const urlAsArray = url.split("&");
    let elementAfterReplace;
    let elementAfterReplace0;
    urlAsArray.forEach((element) => {
      if (element.search("filter") !== -1) {
        elementAfterReplace0 = element.replaceAll(".", "][");

        if (element.search("[in]") !== -1) {
          elementAfterReplace = elementAfterReplace0.replaceAll("[in]", "[_in]");
        } else if (element.search("[gt]") !== -1) {
          elementAfterReplace = elementAfterReplace0.replaceAll("[gt]", "[_gt]");
        } else if (element.search("[lt]") !== -1) {
          elementAfterReplace = elementAfterReplace0.replaceAll("[lt]", "[_lt]");
        } else {
          elementAfterReplace = elementAfterReplace0.replaceAll("=", "[_eq]=");
        }
      } else {
        elementAfterReplace = element;
      }
      if (newUrl === "") newUrl = elementAfterReplace;
      else newUrl = `${newUrl}&${elementAfterReplace}`;
    });
    return newUrl;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export default PrepareUrl;
