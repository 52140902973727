import * as React from "react";
import { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PropTypes from "prop-types";

export default function BasicDatePicker({
  setFieldValue,
  setFieldTouched,
  name,
  value,
  id,
  title,
  onChange,
  disable,
}) {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          renderInput={(props) => (
            <TextField {...props} style={{ width: "100%" }} onKeyDown={onKeyDown} />
          )}
          disabled={disable}
          // label="DatePicker"
          label={title}
          id={id}
          name={name}
          value={inputValue}
          inputFormat="DD-MM-YYYY"
          onChange={(newValue) => {
            if (newValue) {
              setInputValue(moment(newValue.$d).format("YYYY-MM-DD"));
              setFieldValue(name, moment(newValue.$d).format("YYYY-MM-DD"));
              setFieldTouched(name, moment(newValue.$d).format("YYYY-MM-DD"));
              onChange(name, moment(newValue.$d).endOf("month").format("YYYY-MM-DD"));
            }
          }}
        />
      </LocalizationProvider>
    </LocalizationProvider>
  );
}

BasicDatePicker.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.node,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  disable: PropTypes.bool,
};

BasicDatePicker.defaultProps = {
  value: null,
  disable: false,
};
