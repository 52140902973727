import { useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import LoginForm from "./LoginForm";
import SignUpForm from "./SignUpForm";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";
import "./auth-form.css";
import "./auth-form-rtl.css";

function AuthForm() {
  const { i18n } = useTranslation();
  const [signUpMode, setSignUpMode] = useState(false);

  return (
    <Box
      className={`auth-container ${signUpMode && "sign-up-mode"} ${
        i18n.dir() === "rtl" && "rtl-dir"
      }`}
    >
      <Box className="panels-container">
        {/*  ===================== start forms ===================== */}
        <Box className="forms-container">
          <Box className={`signin-signup ${i18n.dir() === "rtl" && "rtl-dir"}`}>
            <LoginForm />

            <SignUpForm />
          </Box>
        </Box>
        {/*  ===================== end forms ===================== */}

        {/* ===================== start panels ===================== */}
        <LeftPanel setSignUpMode={setSignUpMode} />
        <RightPanel setSignUpMode={setSignUpMode} />

        {/* ===================== end panels ===================== */}
      </Box>
    </Box>
  );
}

export default AuthForm;
