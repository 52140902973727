import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataForm from "components/UI/form/DataForm";
import { fetchDataAction } from "store/features/coreSlice";
import PropTypes from "prop-types";
import dataVal from "../../dataVal.json";

const FilterForm = ({ setNumber, setUrl, baseUrl }) => {
  const dispatch = useDispatch();
  const { filterprograms, filterrankings, limit } = useSelector((state) => state.core);
  const [data, setData] = useState([]);
  const [filteredValues, setFilteredValues] = useState(null);
  const [programId, setProgramId] = useState(null);

  const initFilter = {
    id: null,
    title: null,
    program_id: null,
    ranking_id: null,
  };

  const [initialValues, setInitialValues] = useState(initFilter);

  const orgData = () => {
    const filterArr = [
      {
        type: "Select",
        optionTitle: "title",
        name: "program_id",
        id: "program_id",
        label: "program_id",
        data: filterprograms,
        size: 3,
        title: "program",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "ranking_id",
        id: "ranking_id",
        label: "ranking_id",
        data: filterrankings,
        size: 3,
        title: "ranking",
      },
      {
        type: "TextField",
        name: "title",
        id: "title",
        label: "title",
        size: 3,
        title: "title",
      },
    ];
    setData(filterArr);
  };

  useEffect(() => {
    if (filterprograms !== undefined && filterrankings !== undefined) {
      orgData();
    }
  }, [filterrankings, filterprograms]);

  const generateUrl = (values) => {
    let str = baseUrl;
    const keys = Object.keys(values);
    keys.forEach((element) => {
      if (values[element] && values[element] !== []) {
        if (typeof values[element] === "object") {
          str += `&filter[${element}.id]=${values[element].id}`;
        } else {
          str += `&filter[${element}][_contains]=${values[element]}`;
        }
      }
    });
    return str;
  };

  const setFieldSelectedValue = (name, values) => {
    if (name === "program_id") {
      setProgramId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/research_rankings?fields=id,title,programs.program_id.id&filter[programs.program_id.id]=${values.id}`,
          varName: "filterrankings",
        })
      );
    }
  };

  const onChangeHandler = (name, text) => {
    let urlVal;
    if (name === "program_id")
      dispatch(
        fetchDataAction({
          url: `items/programs?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "filterprograms",
        })
      );

    if (name === "ranking_id") {
      urlVal = `items/research_rankings?fields=id,title,programs.program_id.id&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (programId) urlVal += `&filter[programs.program_id.id]=${programId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterrankings",
        })
      );
    }
  };

  const setPayload = (values) => {
    setFilteredValues(values);
    setNumber(1);
    if (values) setInitialValues(values);
    setUrl(generateUrl(values));
    dispatch(
      fetchDataAction({
        url: `${generateUrl(values)}&meta=*&limit=${limit}&page=${1}`,
        varName: "research",
      })
    );
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      if (element === "research_rankings")
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,title&limit=${limit}`,
            varName: `filterrankings`,
          })
        );
      else
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,title&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
    });
  };

  useEffect(() => {
    getData(dataVal.oterData);
  }, []);

  useEffect(() => {
    setPayload(filteredValues || initialValues);
  }, [limit]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      filter
      yupObj={{}}
      name="researchFilter"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeHandler}
    />
  );
};

FilterForm.propTypes = {
  setUrl: PropTypes.func.isRequired,
  setNumber: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};
export default FilterForm;
