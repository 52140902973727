import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const CustomSimPleAutoComPlete = ({
  setFieldValue,
  setFieldTouched,
  name,
  value,
  setFieldSelectedValue,
  data,
  optionTitle,
  onChange,
  title,
  disable,
}) => {
  const [inputValue, setInputValue] = useState([]);

  // useEffect(() => {
  //   if (value !== undefined) {
  //     setInputValue(value);
  //   }
  // }, [value]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const autoChangeHandler = (event, newValue) => {
    setFieldValue(name, newValue);
    setFieldTouched(name, newValue);
    setInputValue(newValue);

    if (newValue) setFieldSelectedValue(name, newValue);
  };

  const autoChangeHandler0 = (event) => {
    onChange(name, event.target.value);
  };
  return (
    <Autocomplete
      multiple
      disablePortal
      onChange={autoChangeHandler}
      disabled={disable}
      value={inputValue}
      isOptionEqualToValue={(option, item) => option === item || option.id === item.id}
      options={data}
      sx={{ width: "100%" }}
      name={name}
      renderInput={(params) => (
        <TextField {...params} onChange={autoChangeHandler0} label={title} />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option[optionTitle]}
        </li>
      )}
      getOptionLabel={(option) => option[optionTitle] || []}
    />
  );
};

CustomSimPleAutoComPlete.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  setFieldSelectedValue: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
  optionTitle: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])),
  title: PropTypes.string.isRequired,
  disable: PropTypes.bool,
};

CustomSimPleAutoComPlete.defaultProps = {
  value: [],
  optionTitle: null,
  disable: false,
  setFieldSelectedValue: () => null,
};

export default CustomSimPleAutoComPlete;
