import MDBox from "components/MDBox";
import PropTypes from "prop-types";

function SectionContainer({ children, sx, ...rest }) {
  return (
    <MDBox {...rest} sx={{ py: { xs: 4, lg: 8 }, ...sx }}>
      {children}
    </MDBox>
  );
}

SectionContainer.defaultProps = {
  sx: null,
};

SectionContainer.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.objectOf(PropTypes.any),
};

export default SectionContainer;
