import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

export default function CheckBoxControl({
  setFieldValue,
  setFieldTouched,
  name,
  value,
  id,
  title,
  disable,
}) {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (event) => {
    setInputValue(event.target.checked);
    setFieldValue(name, event.target.checked);
    setFieldTouched(name, event.target.checked);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          {...label}
          checked={inputValue}
          onChange={handleChange}
          id={id}
          name={name}
          disabled={disable}
        />
      }
      label={title}
    />
  );
}

CheckBoxControl.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  disable: PropTypes.bool,
};

CheckBoxControl.defaultProps = {
  value: false,
  disable: false,
};
