import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Page from "components/UI/Page";
import { Card, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import { fetchDataAction } from "store/features/coreSlice";
import DataTable from "components/UI/Table/DataTable";
import PagesCount from "helpers/pages-count";
import { useTranslation } from "react-i18next";
import Avatar from "components/UI/Table/avatarWithOutImg";
import MDBox from "components/MDBox";
import DefaultInfoCard from "components/UI/Cards/InfoCards/DefaultInfoCard";
import FilterForm from "./components/FilterForm";

const InsertBook = () => {
  const baseUrl = `items/buying?fields=id,count,cost,is_dolar,buying_date,discount_percentage,distribution_cost,book_id.title,book_id.publisher_id.id,book_id.publisher_id.title,accounts.debt,accounts.last_inventory_id,stores.count,stores.place_id.title,stores.distributions.count,stores.distributions.library_id.id,stores.distributions.library_id.title,stores.distributions.proposal_id.discount_percentage,stores.distributions.proposal_id.cost,stores.distributions.proposal_id.is_dolar,stores.gifts.count,stores.distributions.inventories.accounts.debt,stores.distributions.inventories.count,stores.distributions.inventories.id,stores.distributions.returns.count`;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.auth);
  const { buyingsRepMeta, buyingsRep, limit, distributionReportTotal, settingsItem } = useSelector(
    (state) => state.core
  );

  const [url, setUrl] = useState(baseUrl);
  const [number, setNumber] = useState(1);
  const [route, setRoute] = useState(null);
  const [pageOptions, setPageOption] = useState([]);
  const [columns, setColumns] = useState([]);
  const [total, setTotal] = useState(null);
  const [libraryId, setLibraryId] = useState(null);
  const [rows, setRows] = useState([]);
  const [info, setInfo] = useState([]);

  useEffect(() => {
    if (distributionReportTotal) {
      setInfo([
        {
          icon: "credit_score",
          title: t("totalInventoryProposalCost"),
          description: "",
          value: distributionReportTotal.totalinventoryProposalCost?.toFixed(2),
        },
        {
          icon: "payments",
          title: t("totalPenefit"),
          description: "",
          value: distributionReportTotal.totalpenefit?.toFixed(2),
        },
        {
          icon: "books",
          title: t("totalLibraryBalanceProposalCost"),
          description: "",
          value: distributionReportTotal.totallibraryBalanceProposalCost?.toFixed(2),
        },
      ]);
    }
  }, [distributionReportTotal]);

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let totallibraryBalanceProposalCost = 0;
    let totalpenefit = 0;
    let totalinventoryProposalCost = 0;
    let totalinventoryDistributionCost = 0;
    let totalinventoryBuyingCost = 0;
    let totallibraryBalance = 0;
    let totalbuyingBalance = 0;
    let totalgiftCount = 0;
    let totalinventoryCount = 0;
    let totaldistributionCount = 0;
    let columnsArr = [
      {
        Header: "book",
        accessor: "book",
        align: "center",
      },
      {
        Header: "buyingCount",
        accessor: "buyingCount",
        align: "center",
      },
      {
        Header: "buyingCost",
        accessor: "buyingCost",
        align: "center",
      },
      {
        Header: "buyingDiscountPercentage",
        accessor: "buyingDiscountPercentage",
        align: "center",
      },
      {
        Header: "distributionCount",
        accessor: "distributionCount",
        align: "center",
      },
      {
        Header: "inventoryCount",
        accessor: "inventoryCount",
        align: "center",
      },
      {
        Header: "giftCount",
        accessor: "giftCount",
        align: "center",
      },
      {
        Header: "libraryBalance",
        accessor: "libraryBalance",
        align: "center",
      },
      {
        Header: "buyingBalance",
        accessor: "buyingBalance",
        align: "center",
      },
    ];
    if (buyingsRep && buyingsRep.length !== 0) {
      const d = [];
      for (let i = 0; i < PagesCount(+buyingsRepMeta.filter_count, limit); i += 1) {
        d.push(i);
      }
      setPageOption(d);
      buyingsRep.forEach((buying) => {
        item = {};
        let distributionCount = 0;
        let inventoryCount = 0;
        let returnCount = 0;
        let giftCount = 0;
        let inventoryProposalCost = 0;
        let libraryBalanceProposalCost = 0;
        let indivInventoryCount;
        let indivReturnCount;
        let inventoryDebtCount;
        let lastInventoryId = 0;
        let inventoryBuyingCost = 0;
        let count;

        inventoryDebtCount = 0;
        item.id = buying.id;
        item.book = (
          <Avatar dir="ltr" name={buying.book_id.title} email={buying.book_id.publisher_id.title} />
        );

        item.buyingCount = (
          <Avatar dir="ltr" name={buying.count || ""} email={buying.buying_date} />
        );
        item.buyingCost = `${buying.cost} ${buying.is_dolar ? "$" : ""}`;
        item.buyingDiscountPercentage = buying.discount_percentage;

        buying.accounts.forEach((account) => {
          if (account.last_inventory_id > lastInventoryId)
            lastInventoryId = account.last_inventory_id;
          inventoryBuyingCost += +account.debt;
        });

        buying.stores.forEach((store) => {
          count = store.count;
          store.distributions.forEach((distribution) => {
            count -= distribution.count;
            distribution.returns.forEach((returnItem) => {
              count += returnItem.count;
            });
          });

          store.gifts.forEach((gift) => {
            giftCount += gift.count;
            count -= gift.count;
          });

          item[store.place_id.title] = count;

          const filterArr = columnsArr.filter(
            (columnArr) => columnArr.accessor === store.place_id.title
          );
          if (filterArr.length === 0)
            columnsArr.push({
              Header: store.place_id.title,
              accessor: store.place_id.title,
              width: "8%",
              align: "center",
            });

          store.distributions.forEach((distribution) => {
            indivInventoryCount = 0;
            indivReturnCount = 0;
            if (libraryId) {
              if (distribution.library_id?.id === libraryId.id) {
                distributionCount += distribution.count;

                distribution.inventories.forEach((inventory) => {
                  inventoryCount += inventory.count;
                  indivInventoryCount += inventory.count;
                  if (inventory.id > lastInventoryId) {
                    inventoryDebtCount += inventory.count;
                  }
                  inventoryProposalCost += inventory.accounts[0] ? +inventory.accounts[0].debt : 0;
                });

                distribution.returns.forEach((returnItem) => {
                  returnCount += returnItem.count;
                  indivReturnCount += returnItem.count;
                });

                libraryBalanceProposalCost += distribution.proposal_id.is_dolar
                  ? (distribution.count - (indivInventoryCount + indivReturnCount)) *
                    (distribution.proposal_id.cost * settingsItem.dolar -
                      distribution.proposal_id.cost *
                        settingsItem.dolar *
                        (distribution.proposal_id.discount_percentage / 100))
                  : (distribution.count - (indivInventoryCount + indivReturnCount)) *
                    (distribution.proposal_id.cost -
                      distribution.proposal_id.cost *
                        (distribution.proposal_id.discount_percentage / 100));
              }
            } else {
              distributionCount += distribution.count;

              distribution.inventories.forEach((inventory) => {
                inventoryCount += inventory.count;
                indivInventoryCount += inventory.count;
                if (inventory.id > lastInventoryId) {
                  inventoryDebtCount += inventory.count;
                }
                inventoryProposalCost += inventory.accounts[0] ? +inventory.accounts[0].debt : 0;
              });

              distribution.returns.forEach((returnItem) => {
                returnCount += returnItem.count;
                indivReturnCount += returnItem.count;
              });
              libraryBalanceProposalCost += distribution.proposal_id.is_dolar
                ? (distribution.count - (indivInventoryCount + indivReturnCount)) *
                  (distribution.proposal_id.cost * settingsItem.dolar -
                    distribution.proposal_id.cost *
                      settingsItem.dolar *
                      (distribution.proposal_id.discount_percentage / 100))
                : (distribution.count - (indivInventoryCount + indivReturnCount)) *
                  (distribution.proposal_id.cost -
                    distribution.proposal_id.cost *
                      (distribution.proposal_id.discount_percentage / 100));
            }
          });
        });

        item.distributionCount = distributionCount - returnCount;
        item.inventoryCount = inventoryCount;
        item.giftCount = giftCount;
        item.libraryBalance = distributionCount - (returnCount + inventoryCount);
        item.buyingBalance = buying.count + returnCount - distributionCount - giftCount;
        let r = 0;
        if (inventoryDebtCount > 0) {
          r = buying.is_dolar
            ? inventoryDebtCount *
              (buying.cost * settingsItem.dolar -
                buying.cost * settingsItem.dolar * (buying.discount_percentage / 100))
            : inventoryDebtCount * (buying.cost - buying.cost * (buying.discount_percentage / 100));
        }

        item.inventoryBuyingCost = +inventoryBuyingCost + r;
        item.inventoryDistributionCost = inventoryCount * buying.distribution_cost;
        item.inventoryProposalCost = inventoryProposalCost;
        item.penefit = (
          inventoryProposalCost -
          (item.inventoryBuyingCost + item.inventoryDistributionCost)
        )?.toFixed(2);
        item.libraryBalanceProposalCost = +(
          Math.round(libraryBalanceProposalCost * 100) / 100
        ).toFixed(2);
        totallibraryBalanceProposalCost += item.libraryBalanceProposalCost;
        totalpenefit += +item.penefit;
        totalinventoryProposalCost += +item.inventoryProposalCost;
        totalinventoryDistributionCost += item.inventoryDistributionCost;
        totalinventoryBuyingCost += item.inventoryBuyingCost;
        totallibraryBalance += item.libraryBalance;
        totalbuyingBalance += item.buyingBalance;
        totalgiftCount += item.giftCount;
        totalinventoryCount += +item.inventoryCount;
        totaldistributionCount += +item.distributionCount;
        Arr.push(item);
      });
      columnsArr = [
        ...columnsArr,
        {
          Header: "inventoryBuyingCost",
          accessor: "inventoryBuyingCost",
          align: "center",
        },
        {
          Header: "inventoryDistributionCost",
          accessor: "inventoryDistributionCost",
          align: "center",
        },
        {
          Header: "inventoryProposalCost",
          accessor: "inventoryProposalCost",
          align: "center",
        },
        {
          Header: "penefit",
          accessor: "penefit",
          align: "center",
        },
        {
          Header: "libraryBalanceProposalCost1",
          accessor: "libraryBalanceProposalCost",
          align: "center",
        },
      ];
      setColumns(columnsArr);
      setRows(Arr);
      setTotal({
        libraryBalanceProposalCost: totallibraryBalanceProposalCost,
        penefit: totalpenefit,
        inventoryProposalCost: totalinventoryProposalCost,
        inventoryDistributionCost: totalinventoryDistributionCost,
        inventoryBuyingCost: totalinventoryBuyingCost,
        libraryBalance: totallibraryBalance,
        buyingBalance: totalbuyingBalance,
        giftCount: totalgiftCount,
        inventoryCount: totalinventoryCount,
        distributionCount: totaldistributionCount,
      });
    } else {
      setRows([]);
    }
  };

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `${url}&meta=*&limit=${limit}&page=${num}`,
          varName: "buyingsRep",
        })
      );
    }
  };

  useEffect(() => {
    const routeItem = userInfo.routes.filter(
      (x) =>
        `${x.route_name}` === location.pathname.split("/")[location.pathname.split("/").length - 1]
    )[0];
    setRoute(routeItem);
  }, []);

  useEffect(() => {
    if (buyingsRep && route) {
      prepareTableRows();
    }
  }, [buyingsRep, route]);

  if (rows === []) {
    return null;
  }

  return (
    <Page>
      <Card style={{ padding: 20, position: "relative" }}>
        <FilterForm
          pageNum={number}
          baseUrl={baseUrl}
          setUrl={setUrl}
          setNumber={setNumber}
          setLibraryId={setLibraryId}
        />
        {info.length > 0 && (
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            sx={{ m: 2, p: 3 }}
          >
            {info.map((dataItem) => (
              <MDBox
                width="100%"
                mr={{ xs: 0, sm: 3 }}
                mb={{ xs: 3, sm: 0 }}
                key={`${dataItem.title}+${dataItem.value}`}
              >
                <DefaultInfoCard
                  icon={dataItem.icon}
                  title={dataItem.title}
                  description={dataItem.description}
                  value={dataItem.value}
                />
              </MDBox>
            ))}
          </Grid>
        )}

        <DataTable
          table={{ columns, rows }}
          total={total}
          item={false}
          pageOptions={pageOptions}
          isSorted
          isPagination
          entriesPerPage
          showTotalEntries
          noEndBorder
          search
          setPageNum={setPageNumber}
          pageIndex={number - 1}
          allRowsCount={buyingsRepMeta ? +buyingsRepMeta.filter_count : 0}
          setSortByHandeller={() => {}}
        />
      </Card>
    </Page>
  );
};

export default InsertBook;
