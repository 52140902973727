/**
=========================================================
* Trakib System - v2.1.0
=========================================================

* Product Page: https://trakib.comproduct/trakib-system-react
* Copyright 2022 trakib (https://trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Icon } from "@mui/material";
import Card from "@mui/material/Card";

// Trakib System components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Trakib System examples
import DefaultItem from "examples/Items/DefaultItem";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function NextEvents({ inProgressTasks }) {
  const { t } = useTranslation();
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          {t("inProgressTasks")}
        </MDTypography>
      </MDBox>
      {inProgressTasks.length > 0 ? (
        inProgressTasks.map((x) => (
          <MDBox p={2} key={x.id}>
            <DefaultItem color={x.color} icon="paid" title={x.title} description={x.description} />
          </MDBox>
        ))
      ) : (
        <MDTypography
          style={{ padding: 20, color: "#3b94ee" }}
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <Icon fontSize="small">hourglass_bottom</Icon>
        </MDTypography>
      )}
    </Card>
  );
}

// Typechecking props for the NextEvents
NextEvents.propTypes = {
  inProgressTasks: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
};

export default NextEvents;
