import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Drawer as MUDrawer,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const pages = [
  { title: "services", href: "#" },
  { title: "whyTrakib", href: "#trakib-features" },
  { title: "contactUs", href: "#" },
];

const drawerWidth = 240;

function Drawer({ handleDrawerToggle, mobileOpen }) {
  const { t } = useTranslation();

  const drawer = (
    <MDBox onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <MDTypography variant="h6" sx={{ my: 2 }} color="info">
        Trakib
      </MDTypography>
      <Divider />
      <List>
        {pages.map(({ title, href }) => (
          <ListItem key={Math.random().toString()} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }} href={href}>
              <ListItemText primary={t(title)} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </MDBox>
  );

  return (
    <MUDrawer
      // container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: "block", md: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
          // background: (theme) => theme.palette.info.main,
        },
      }}
    >
      {drawer}
    </MUDrawer>
  );
}

Drawer.propTypes = {
  mobileOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
};

export default Drawer;
