// import { Grid } from "@mui/material";
import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import InvoiceReceiverVersionB from "../recipient-info/InvoiceRecipientVersionB";
import InvoideSenderVersionB from "../sender-info/InvoideSenderVersionB";
import InvoiceMetaData from "../InvoiceMetaData";
import InvoiceDetails from "../InvoiceDetails";
import ReportDetails from "../ReportDetails";

function InvoiceTemplateB({ rows, repFields, total }) {
  const renderDetails = () =>
    false ? (
      <InvoiceDetails rows={rows} repFields={repFields} />
    ) : (
      <ReportDetails rows={rows} repFields={repFields} total={total} />
    );

  return (
    <>
      <InvoideSenderVersionB />
      <hr style={{ height: 1, border: "none", background: "#eee", margin: "10px 0" }} />

      <MDBox display="flex" justifyContent="space-between">
        <InvoiceReceiverVersionB />

        {false && <InvoiceMetaData invoiceVersion="B" />}
      </MDBox>
      {/* <Grid container>
        <Grid item xs={7}>
          <InvoiceReceiverVersionB />
        </Grid>

        <Grid item container xs={5}>
          <InvoiceMetaData invoiceVersion="B" />
        </Grid>
      </Grid> */}
      <MDBox my={3} />
      {renderDetails()}
      {/* <InvoiceDetails rows={rows} repFields={repFields} /> */}
    </>
  );
}

InvoiceTemplateB.defaultProps = {
  total: null,
};

InvoiceTemplateB.propTypes = {
  rows: PropTypes.PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
  repFields: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
  total: PropTypes.objectOf(PropTypes.any),
};

export default InvoiceTemplateB;
