import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function InvoiceRecipientVersionA() {
  return (
    <MDBox>
      <MDTypography>Billed To</MDTypography>

      <MDTypography variant="body2">client comp</MDTypography>
      <MDTypography variant="body2">st, address</MDTypography>
      <MDTypography variant="body2">city, zip code</MDTypography>
      <MDTypography variant="body2">country</MDTypography>
    </MDBox>
  );
}

export default InvoiceRecipientVersionA;
