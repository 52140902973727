import { Box, Grid, LinearProgress } from "@mui/material";
// import { useFormikContext } from 'formik';
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import { useDispatch } from 'react-redux';
// import { postItemAction } from 'src/store/actions/appSettings.actions';
import FileHeader from "./FileHeader";

function SingleFileUploadWithProgress({ file, onDelete, approveSubmit, payload }) {
  const [progress, setProgress] = useState(10);
  // const dispatch = useDispatch();

  // const { values } = useFormikContext();

  const singleFileOptions = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentage = Math.floor((loaded / total) * 100);
      // const percentage = Math.floor(((loaded / 1000) * 100) / (total / 1000));
      setProgress(percentage);
      // setSingleProgress(percentage);
    },
  };

  console.log("progress = ", progress);
  useEffect(() => {
    if (approveSubmit) {
      console.log("singleFileOptions = ", singleFileOptions);
      // dispatch(postItemAction("sliders", { file }, singleFileOptions));
      // const handler = () => {
      // };
    }

    // handleSubmit(handler);
    // dispatch(postItemAction('sliders',file))
  }, [approveSubmit]);

  // useEffect(() => {
  //   dispatch(postItemAction('sliders',file))
  // }, [])

  // useEffect(() => {
  //   async function upload() {
  //     const url = await uploadFile(file, setProgress);
  //     onUpload(file, url);
  //   }

  //   upload();
  // }, []);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((oldProgress) => {
  //       if (oldProgress === 100) {
  //         return 0;
  //       }
  //       const diff = Math.random() * 10;
  //       return Math.min(oldProgress + diff, 100);
  //     });
  //   }, 500);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  useEffect(() => {
    console.log("new file added", file);
  }, []);

  return (
    <Grid
      item
      sx={{
        background: (theme) => theme.palette.secondary.main,
        color: "#fff",
        borderRadius: 2,
        padding: 2,
      }}
    >
      <FileHeader file={file} onDelete={onDelete} payload={payload} />
      <Box sx={{ width: "100%" }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
    </Grid>
  );
}

SingleFileUploadWithProgress.defaultProps = {
  approveSubmit: false,
  payload: null,
};

SingleFileUploadWithProgress.propTypes = {
  file: PropTypes.objectOf(PropTypes.any).isRequired,
  onDelete: PropTypes.func.isRequired,
  approveSubmit: PropTypes.bool,
  payload: PropTypes.objectOf(PropTypes.any),
};

export default SingleFileUploadWithProgress;

// function uploadFile(file, onProgress) {
//   const url = 'https://api.cloudinary.com/v1_1/demo/image/upload';
//   const key = 'docs_upload_example_us_preset';

//   return new Promise((res, rej) => {
//     const xhr = new XMLHttpRequest();
//     xhr.open('POST', url);

//     xhr.onload = () => {
//       const resp = JSON.parse(xhr.responseText);
//       res(resp.secure_url);
//     };
//     xhr.onerror = (evt) => rej(evt);
//     xhr.upload.onprogress = (event) => {
//       if (event.lengthComputable) {
//         const percentage = (event.loaded / event.total) * 100;
//         onProgress(Math.round(percentage));
//       }
//     };

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('upload_preset', key);

//     xhr.send(formData);
//   });
// }
