import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UpdateRecord from "helpers/compare";
import DataForm from "components/UI/form/DataForm";

import { addComplexDataAction } from "store/features/coreSlice";
import PropTypes from "prop-types";
import dataVal from "../../dataVal.json";

const PageForm = ({ updateHandlerVal, pageNum, url }) => {
  const dispatch = useDispatch();

  const { limit } = useSelector((state) => state.core);

  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);

  const orgData = () => {
    setData([
      {
        type: "TextArea",
        name: "notes",
        id: "notes",
        label: "notes",
        maxRows: 4,
        minRows: 4,
        size: 12,
        title: "notes",
      },
    ]);
  };

  const onUpdateHandler = (values, copy) => {
    setInitialValues({
      id: copy ? null : values.id,
      notes: values.notes,
    });
  };

  const setPayload = ({ files, ...values }) => {
    const payloadData = {};
    const lastData = values.id ? UpdateRecord(values, initialValues) : values;
    if (initialValues.type?.value !== values.type?.value || !values.id)
      payloadData.type = values.type.value;

    delete lastData.type;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });

    const req = {
      imgFieldName: "file",
      id: values.id,
      file: files,
      origin: {
        data: Object.keys(payloadData).length === 0 ? null : payloadData,
        table: "attendance",
      },
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "attendances",
      },
    };
    setInitialValues(values);
    dispatch(addComplexDataAction(req));
  };

  useEffect(() => {
    if (updateHandlerVal) {
      onUpdateHandler(updateHandlerVal.value, updateHandlerVal.isCopy);
    }
  }, []);

  useEffect(() => {
    orgData();
  }, []);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      yupObj={{}}
      name="attendance"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      defaultInitialValues={dataVal.initialValues}
    />
  );
};

PageForm.propTypes = {
  updateHandlerVal: PropTypes.objectOf(PropTypes.any),
  pageNum: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

PageForm.defaultProps = {
  updateHandlerVal: null,
};
export default PageForm;
