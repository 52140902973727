import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";
import * as yup from "yup";
import MDTypography from "components/MDTypography";
import Page from "components/UI/Page";
import { Grid, Icon } from "@mui/material";
import { addComplexDataAction, fetchDataAction, reset } from "store/features/coreSlice";
import { useTranslation } from "react-i18next";
import dataVal from "./dataVal.json";

const Salaries = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { salaries, resStatus, simbleLayout } = useSelector((state) => state.core);

  const [newArr, setNewArr] = useState([]);
  const [data, setData] = useState([]);
  const [resp, setResp] = useState(null);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);
  const [open, setOpen] = useState(false);

  const resetForm = () => {
    setResp(null);
    setInitialValues(dataVal.initialValues);
  };

  const setOpenModal = () => {
    if (open) resetForm();
    setOpen(!open);
  };

  const orgData = () => {
    setData([
      {
        type: "Date",
        name: "delivery_date",
        id: "delivery_date",
        label: "delivery_date",
        size: 4,
        title: "deliveryDate",
      },
      {
        type: "TextArea",
        name: "notes",
        id: "notes",
        label: "notes",
        maxRows: 4,
        minRows: 4,
        size: 8,
        title: "notes",
      },
    ]);
  };

  const onUpdateHandler = (values) => {
    setResp(values);
    if (simbleLayout) {
      setOpen(true);
    }
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    if (salaries && salaries.length !== 0) {
      salaries.forEach((account) => {
        item = {};
        keys = Object.keys(account);
        keys.forEach((element) => {
          item[element] = account[element];
        });
        item.id = account.key;
        item.action = (
          <MDTypography
            style={{ padding: 20, color: "#3b94ee" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => onUpdateHandler(account)}
          >
            <Icon fontSize="small">edit</Icon>
          </MDTypography>
        );

        Arr.push(item);
      });
      setNewArr(Arr);
    } else {
      setNewArr([]);
    }
  };

  const setPayload = (values) => {
    const req = {
      salaries: true,
      origin: {
        data: {
          debt: (+resp.giftDebt || 0) + (+resp.debt || 0),
          user_id: resp.userId,
          notes: values.notes,
          due_date: resp.dueDate,
          date: values.delivery_date,
          type: "S",
          item_id: 1,
          description: `${t("debt")}: ${resp.debt || 0} ${t("gifts")}:${resp.giftDebt || 0}`,
        },
        table: "accounts",
      },
      works: resp.works,
      gifts: resp.gifts,
      getInfo: {
        url: `custom/accounts`,
        varName: "salaries",
      },
    };
    dispatch(addComplexDataAction(req));
    setOpen(false);
  };

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      resetForm();
    }
  }, [resStatus]);

  useEffect(() => {
    // get user data
    dispatch(
      fetchDataAction({
        url: `custom/accounts`,
        varName: "salaries",
      })
    );
    orgData();

    return () => {
      setNewArr([]);
      dispatch(reset(null));
    };
  }, []);

  useEffect(() => {
    if (salaries) {
      prepareTableRows();
    }
  }, [salaries]);

  if (data.length === 0 || newArr === [] || !salaries) {
    return null;
  }
  return (
    <Page>
      <Grid item xs={12}>
        <Main
          message={
            resp
              ? `${t("debtUserName")}: ${resp.name} ${t("debt")}: ${resp.debt || 0} ${t("gifts")}:${
                  resp.giftDebt || 0
                } ${t("total")}:${(+resp.giftDebt || 0) + (+resp.debt || 0)} `
              : null
          }
          count={[0]}
          pageIndex={1}
          all
          enableCreate={!!resp}
          layout={simbleLayout ? "simple" : null}
          title={t("add")}
          setOpen={setOpenModal}
          open={open}
          isPagination={false}
          name="salaries"
          setPayload={setPayload}
          data={data}
          initialValues={initialValues}
          columns={dataVal.columns}
          rows={newArr}
          onUpdateHandler={onUpdateHandler}
          yupObj={{
            delivery_date: yup.date("required").required("required").nullable(),
          }}
        />
      </Grid>
    </Page>
  );
};

export default Salaries;
