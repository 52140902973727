import PropTypes from "prop-types";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

import MDBox from "components/MDBox";
import InvoiceModal from "./InvoiceModal";
import InvoiceTemplateA from "./templates/InvoiceTemplateA";
import InvoiceTemplateB from "./templates/InvoiceTemplateB";
// import { useSelector } from "react-redux";

function Invoice({ invoiceVersion, rows, repFields, total }) {
  // const { recipientInfo } = useSelector((state) => state.reportingReducer);

  const createReportingAction = async () => {
    // if (recipientInfo) {
    //   dispatchEvent(disableRecipientUIAction())
    // }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: createReportingAction,
  });

  const renderInvoice = () => {
    switch (invoiceVersion) {
      case "A":
        return <InvoiceTemplateA rows={rows} />;
      case "B":
        return <InvoiceTemplateB rows={rows} repFields={repFields} total={total} />;
      default:
        return <InvoiceTemplateA rows={rows} />;
    }
  };

  return (
    <InvoiceModal handlePrint={handlePrint} repFields={repFields}>
      <MDBox p={3} ref={componentRef}>
        {renderInvoice()}
      </MDBox>
    </InvoiceModal>
  );
}

Invoice.defaultProps = {
  invoiceVersion: "A",
  total: null,
};

Invoice.propTypes = {
  invoiceVersion: PropTypes.string,
  rows: PropTypes.PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
  repFields: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
  total: PropTypes.objectOf(PropTypes.any),
};

export default Invoice;
