// import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import i18next from "i18next";
import translationsEN from "../locales/en.json";
import translationsAR from "../locales/ar.json";

const resources = {
  ar: {
    translation: translationsAR,
  },
  en: {
    translation: translationsEN,
  },
};

if (!localStorage.getItem("i18nextLng")) {
  localStorage.setItem("i18nextLng", "ar");
}

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    lang: "ar",
    // fallbackLng: "en", // default language
    fallbackLng: "ar", // default language
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18next;
