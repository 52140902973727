import { InputLabel } from "@mui/material";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

export default function TextAreaControl({
  name,
  minRows,
  maxRows,
  placeholder,
  id,
  setFieldValue,
  setFieldTouched,
  title,
  disable,
  value,
}) {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
    setFieldValue(name, event.target.value);
    setFieldTouched(name, event.target.value);
  };

  return (
    <>
      <InputLabel id="demo-simple-select-label">{title}</InputLabel>
      <TextareaAutosize
        disabled={disable}
        aria-label="minimum height"
        variant="standard"
        minRows={minRows}
        style={{ width: "100%" }}
        maxRows={maxRows}
        placeholder={placeholder}
        id={id}
        name={name}
        value={inputValue || ""}
        onChange={handleChange}
      />
    </>
  );
}
TextAreaControl.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.node,
  title: PropTypes.string.isRequired,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  disable: PropTypes.bool,
};

TextAreaControl.defaultProps = {
  value: null,
  minRows: 3,
  maxRows: 3,
  placeholder: null,
  disable: false,
};
