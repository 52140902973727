import PropTypes from "prop-types";

import InvoiceMetaVersionA from "./InvoiceMetaVersionA";
import InvoiceMetaVersionB from "./InvoiceMetaVersionB";

function InvoiceMetaData({ invoiceVersion }) {
  const renderMetaData = () => {
    switch (invoiceVersion) {
      case "A":
        return <InvoiceMetaVersionA />;

      default:
        return <InvoiceMetaVersionB />;
    }
  };

  return <>{renderMetaData()}</>;
}

InvoiceMetaData.defaultProps = {
  invoiceVersion: "A",
};

InvoiceMetaData.propTypes = {
  invoiceVersion: PropTypes.string,
};

export default InvoiceMetaData;
