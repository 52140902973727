import { useTranslation } from "react-i18next";
import { Download } from "@mui/icons-material";

import ActionSection from "./ActionSection";

const downloadTrakibManualContent = {
  entitle: "Download <br /> Trakib Profile",
  artitle: "تحميل <br /> دليل تراكيب",
  enbody:
    "Get the full manual for trakib to know more about its features. It's customized for book publishers & Work Owners",
  arbody:
    "احصل على الدليل الكامل لبرنامج تراكيب لتعرف المزيد عن مميزاته. يخدم دور النشر & أصحاب الاعمال",
  enBtnText: "Download Trakib Manual",
  arBtnText: "تحميل دليل تراكيب",
};
function DownloadTrakibPortfolio() {
  const { t, i18n } = useTranslation();

  return (
    <ActionSection
      title={t(downloadTrakibManualContent[`${i18n.language}title`])}
      body={t(downloadTrakibManualContent[`${i18n.language}body`])}
      btnText={t(downloadTrakibManualContent[`${i18n.language}BtnText`])}
      Icon={<Download sx={{ width: 30, height: 30 }} />}
    />
  );
}

export default DownloadTrakibPortfolio;
