/**
=========================================================
* Trakib System - v2.1.0
=========================================================

* Product Page: https://trakib.comproduct/trakib-system-react
* Copyright 2022 trakib (https://trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Trakib System components
import MDBox from "components/MDBox";

// Trakib System examples
import EventCalendar from "examples/Calendar";

// Calendar application components
import NextEvents from "layouts/applications/calendar/components/NextEvents";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataActionPostMethod } from "store/features/coreSlice";
import moment from "moment";
import DefaultInfoCard from "components/UI/Cards/InfoCards/DefaultInfoCard";
import { useTranslation } from "react-i18next";

function Calendar() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { events, inProgressTasks } = useSelector((state) => state.core);

  useEffect(() => {
    dispatch(
      fetchDataActionPostMethod({
        url: `custom/inProgressTasks`,
        varName: "inProgressTasks",
        data: {},
      })
    );
  }, []);

  const onMonthChangeHandller = (evt) => {
    dispatch(
      fetchDataActionPostMethod({
        url: `custom/calendar`,
        varName: "events",
        data: {
          from: moment(evt.start).format("YYYY-MM-DD"),
          to: moment(evt.end).format("YYYY-MM-DD"),
        },
      })
    );
  };

  const handleDateClick = (evt) => {
    console.log(evt);
  };

  return (
    <MDBox pb={3}>
      {inProgressTasks && (
        <MDBox mb={5}>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            sx={{ m: 2, p: 3 }}
          >
            <MDBox width="100%" mr={{ xs: 0, sm: 3 }} mb={{ xs: 3, sm: 0 }}>
              <DefaultInfoCard icon="work" title={t("tasksNum")} value={inProgressTasks.tasksNum} />
            </MDBox>
            <MDBox width="100%" mr={{ xs: 0, sm: 3 }} mb={{ xs: 3, sm: 0 }}>
              <DefaultInfoCard
                icon="laptop"
                title={t("coTasksNum")}
                value={inProgressTasks.coTasksNum}
              />
            </MDBox>
            <MDBox width="100%" mr={{ xs: 0, sm: 3 }} mb={{ xs: 3, sm: 0 }}>
              <DefaultInfoCard
                icon="paid"
                title={t("accountsDebt")}
                value={inProgressTasks.accountsDebt}
              />
            </MDBox>
            <MDBox width="100%" mr={{ xs: 0, sm: 3 }} mb={{ xs: 3, sm: 0 }}>
              <DefaultInfoCard
                icon="emoji_events"
                title={t("achievementPercentageChart")}
                value={`${inProgressTasks.achievementPercentage}%`}
                // value="70%"
              />
            </MDBox>
          </Grid>
        </MDBox>
      )}
      <Grid container spacing={3} mb={5}>
        <Grid item xs={12} xl={9} sx={{ height: "max-content" }}>
          {useMemo(
            () => (
              <EventCalendar
                plugins={["dayGridPlugin", "interactionPlugin", "timeGridPlugin"]}
                initialView="dayGridMonth"
                initialDate={moment().endOf("month").format("YYYY-MM-DD")}
                events={events}
                selectable
                // editable
                dateClick={handleDateClick}
                datesSet={onMonthChangeHandller}
              />
            ),
            [events]
          )}
        </Grid>
        <Grid item xs={12} xl={3}>
          <MDBox mb={3} style={{ maxHeight: 850, overflow: "auto" }}>
            {inProgressTasks && <NextEvents inProgressTasks={inProgressTasks.tasks} />}
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Calendar;
