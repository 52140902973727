import AuthForm from "./AuthForm";
// import HSlidingAuthForm from "./HSlidingAuthForm";
// import VSlidingAuthForm from "./VSlidingAuthForm";

function SignIn() {
  return <AuthForm />;
  // return <HSlidingAuthForm />;
  // return false ? <HSlidingAuthForm /> : <VSlidingAuthForm />;
}

export default SignIn;
