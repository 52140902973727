import PropTypes from "prop-types";
import { useFormikContext } from "formik";

import MDButton from "components/MDButton";

export default function FormikButton({ disabled, text, ...props }) {
  const { submitForm, isValid } = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  };

  const configButton = {
    variant: "contained",
    color: "info",
    fullWidth: true,
    disabled: !isValid || disabled,

    onClick: handleSubmit,
  };

  return (
    <MDButton {...props} {...configButton}>
      {text}
    </MDButton>
  );
}

FormikButton.defaultProps = {
  disabled: false,
};

FormikButton.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
};
