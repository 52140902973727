import { Grid } from "@mui/material";
import Page from "components/UI/Page";
import Departments from "./SubContent/Departments";
import Programs from "./SubContent/Programs";
import ResearchRankings from "./SubContent/ResearchRankings";
import Units from "./SubContent/Units";

const ManagmentSettings = () => (
  <Page multi>
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <Programs />
      </Grid>
      <Grid item xs={6}>
        <ResearchRankings />
      </Grid>
      <Grid item xs={6}>
        <Units />
      </Grid>
      <Grid item xs={6}>
        <Departments />
      </Grid>
    </Grid>
  </Page>
);

export default ManagmentSettings;
