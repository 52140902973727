import * as React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

function ccyFormat(num, isDollar) {
  const nFormat = new Intl.NumberFormat();
  const currency = isDollar ? "$" : "";
  return `${currency}${nFormat.format(num)}`;
}

function calcDiscountedPrice({ unit, discountPercentage, qty }) {
  return qty * unit * (discountPercentage / 100);
}

function priceRow({ unit, discountPercentage, qty }) {
  return qty * unit - calcDiscountedPrice({ unit, discountPercentage, qty });
}

function subtotal(items) {
  return items
    .map((item) => ({
      unit: item.cost,
      qty: item.count,
      discountPercentage: item.discount_percentage,
    }))
    .reduce(
      (sum, { unit, discountPercentage, qty }) => sum + priceRow({ unit, discountPercentage, qty }),
      0
    );
}
export default function InvoiceDetails({ rows }) {
  const invoiceSubtotal = subtotal(rows);
  const invoiceTotal = invoiceSubtotal;

  // console.log("myRows = ", rows);
  // console.log("invoiceSubtotal = ", invoiceSubtotal);
  // console.log("invoiceTotal = ", invoiceTotal);
  return (
    <TableContainer>
      <Table sx={{ minWidth: 700 }}>
        <TableHead style={{ display: "table-header-group" }}>
          <TableRow>
            <TableCell align="center" colSpan={4}>
              Details
            </TableCell>
            <TableCell align="right">Price</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Desc</TableCell>
            <TableCell align="right">Qty.</TableCell>
            <TableCell align="right">Unit</TableCell>
            <TableCell align="right">Discount %</TableCell>
            <TableCell align="right">Sum</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(
            (row) =>
              row.Header !== "action" && (
                <TableRow key={Math.random().toString()}>
                  <TableCell>{row.book_id.title}</TableCell>
                  <TableCell align="right">{row.count}</TableCell>
                  <TableCell align="right">{row.cost}</TableCell>
                  <TableCell align="right">{row.discount_percentage}</TableCell>
                  <TableCell align="right">
                    {ccyFormat(
                      priceRow({
                        unit: +row.cost,
                        discountPercentage: +row.discount_percentage,
                        qty: +row.count,
                      }),
                      row.is_dolar
                    )}
                  </TableCell>
                  {/* <TableCell align="right">{ccyFormat(+row.cost)}</TableCell> */}
                </TableRow>
              )
          )}
          {/* {rows.map((row) => (
            <TableRow key={row.desc}>
              <TableCell>{row.desc}</TableCell>
              <TableCell align="right">{row.qty}</TableCell>
              <TableCell align="right">{row.unit}</TableCell>
              <TableCell align="right">{ccyFormat(row.price)}</TableCell>
            </TableRow>
          ))} */}
          <TableRow>
            <TableCell rowSpan={4} />
            <TableCell colSpan={3}>Subtotal</TableCell>
            <TableCell align="right">{ccyFormat(invoiceSubtotal)}</TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>Tax</TableCell>
            <TableCell align="right">{`${(TAX_RATE * 100).toFixed(0)} %`}</TableCell>
            <TableCell align="right">{ccyFormat(invoiceTaxes)}</TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell colSpan={3}>Total</TableCell>
            <TableCell align="right">{ccyFormat(invoiceTotal)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

InvoiceDetails.propTypes = {
  rows: PropTypes.PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
  // tableFields: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
  // total: PropTypes.objectOf(PropTypes.any),
};
