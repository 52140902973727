import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataForm from "components/UI/form/DataForm";
import { fetchDataAction, fetchDataActionPostMethod } from "store/features/coreSlice";
import PropTypes from "prop-types";
import dataVal from "../../dataVal.json";

const FilterForm = ({ setNumber, setUrl, baseUrl, setLibraryId }) => {
  const dispatch = useDispatch();
  const { filterbooks, filterlibraries, filterpublishers, limit } = useSelector(
    (state) => state.core
  );
  const [data, setData] = useState([]);
  const [filteredValues, setFilteredValues] = useState(null);
  const [publisherId, setPublisherId] = useState(null);

  const [initialValues, setInitialValues] = useState(dataVal.initialValuesFilter);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "title",
        name: "book_id",
        id: "book_id",
        label: "book_id",
        data: filterbooks,
        size: 4,
        title: "book",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "publisher_id",
        id: "publisher_id",
        label: "publisher_id",
        data: filterpublishers,
        size: 2,
        title: "publisher",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "library_id",
        id: "library_id",
        label: "library_id",
        data: filterlibraries,
        size: 2,
        title: "libraries",
      },
      {
        type: "Date",
        name: "start_date",
        id: "start_date",
        label: "start_date",
        size: 2,
        title: "startBuyingDate",
      },
      {
        type: "Date",
        name: "end_date",
        id: "end_date",
        label: "end_date",
        size: 2,
        title: "endBuyingDate",
      },
    ]);
  };

  useEffect(() => {
    if (filterbooks && filterlibraries && filterpublishers) orgData();
  }, [filterbooks, filterlibraries, filterpublishers]);

  const generateUrl = (values) => {
    let str = baseUrl;
    const keys = Object.keys(values);
    keys.forEach((element) => {
      if (values[element]) {
        if (element === "book_id") str += `&filter[${element}.id]=${values[element].id}`;
        else if (element === "publisher_id")
          str += `&filter[book_id.publisher_id.id]=${values[element].id}`;
        else if (element === "library_id") {
          setLibraryId(values.library_id);
          str += `&filter[stores.distributions.library_id.id]=${values[element].id}`;
        }
      }
    });

    if (values.start_date && values.end_date)
      str += `&filter[buying_date][_between]=${values.start_date} , ${values.end_date}`;
    else if (values.start_date) str += `&filter[buying_date][gt]=${values.start_date}`;
    else if (values.end_date) str += `&filter[buying_date][lt]=${values.end_date}`;
    return str;
  };

  const setFieldSelectedValue = (name, values) => {
    if (name === "publisher_id") {
      setPublisherId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/books?fields=id,title,publisher_id&filter[publisher_id]=${values.id}`,
          varName: "filterbooks",
        })
      );
    }
  };

  const onChangeHandler = (name, text) => {
    if (name === "publisher_id") {
      dispatch(
        fetchDataAction({
          url: `items/publishers?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "filterpublishers",
        })
      );
    }

    let url;
    if (name === "book_id") {
      url = `items/books?fields=id,title&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (publisherId) url += `&filter[publisher_id]=${publisherId}`;

      dispatch(
        fetchDataAction({
          url,
          varName: "filterbooks",
        })
      );
    }

    if (name === "library_id")
      dispatch(
        fetchDataAction({
          url: `items/libraries?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "filterlibraries",
        })
      );
  };

  const setPayload = (values) => {
    setFilteredValues(values);
    setNumber(1);
    if (values) setInitialValues(values);
    setUrl(generateUrl(values));
    dispatch(
      fetchDataAction({
        url: `${generateUrl(values)}&meta=*&limit=${limit}&page=${1}`,
        varName: "buyingsRep",
      })
    );
    dispatch(
      fetchDataActionPostMethod({
        url: `custom/getDistributionReportTotal`,
        varName: "distributionReportTotal",
        data: values,
      })
    );
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      dispatch(
        fetchDataAction({
          url: `items/${element}?fields=id,title&limit=${limit}`,
          varName: `filter${element}`,
        })
      );
    });
  };

  useEffect(() => {
    getData(dataVal.oterData);
  }, []);

  useEffect(() => {
    setPayload(filteredValues || initialValues);
  }, [limit]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      filter
      yupObj={{}}
      name="researchFilter"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeHandler}
    />
  );
};

FilterForm.propTypes = {
  setUrl: PropTypes.func.isRequired,
  setLibraryId: PropTypes.func.isRequired,
  setNumber: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};
export default FilterForm;
