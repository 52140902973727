import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataForm from "components/UI/form/DataForm";
import { fetchDataAction } from "store/features/coreSlice";
import moment from "moment";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import * as yup from "yup";
import dataVal from "../../dataVal.json";

const FilterForm = ({ setUrl, baseUrl, setWorkTypeId, userId, setNumber }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const status = [
    { id: "in_progress", title: t("inProgress") },
    { id: "in_review", title: t("inReview") },
    { id: "in_approval", title: t("inApproval") },
    { id: "in_paid", title: t("inPaid") },
    { id: "done", title: t("done") },
    { id: "canceled", title: t("canceled") },
  ];
  const workTypes = [
    { id: "user_id", title: t("charged") },
    { id: "co_user_id", title: t("coordinator") },
  ];
  const dateTypes = [
    { id: "commissioning_date", title: t("commissioningDate") },
    { id: "target_date", title: t("targetDate") },
    { id: "completion_date", title: t("completionDate") },
    { id: "review_date", title: t("reviewDate") },
    { id: "accreditation_date", title: t("accreditationDate") },
    { id: "due_date", title: t("dueDate") },
  ];
  const {
    filtertasks,
    filterusers,
    filterjobtitle,
    filtercousers,
    filtercojobtitle,
    filterdepartments,
    filterunits,
    filterprograms,
    limit,
  } = useSelector((state) => state.core);
  const [data, setData] = useState([]);
  const [filteredValues, setFilteredValues] = useState(null);
  const [unitId, setUnitId] = useState(null);
  const [programId, setProgramId] = useState(null);
  const [jobtitleId, setJobtitleId] = useState(null);
  const [cojobtitleId, setCoJobtitleId] = useState(null);

  const initFilter = {
    id: null,
    program_id: null,
    research: null,
    unit_id: null,
    department_id: null,
    job_title_id: null,
    user_id: null,
    task_id: null,
    work_status: null,
    estimated_value: null,
    achievement_percentage: null,
    other_achievement_percentage: null,
    evaluation_percentage: null,
    debt: null,
    pages: null,
    notes: null,
    other_notes: null,
    commissioning_date: null,
    target_date: null,
    completion_date: null,
    review_date: null,
    accreditation_date: null,
    due_date: null,
    is_dolar: false,
    is_repeat: false,
    // parent_id: null,
    date_type: { id: "target_date", title: t("targetDate") },
    date_from: userId
      ? moment().subtract(2, "months").startOf("month").format("YYYY-MM-DD")
      : moment().startOf("month").format("YYYY-MM-DD"),
    date_to: moment().endOf("month").format("YYYY-MM-DD"),
    work_type: { id: "co_user_id", title: t("coordinator") },
  };

  const [initialValues, setInitialValues] = useState(initFilter);

  const orgData = () => {
    const filterArr = [
      {
        type: "Select",
        optionTitle: "title",
        name: "program_id",
        id: "program_id",
        label: "program_id",
        data: filterprograms,
        size: 3,
        title: "program",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "unit_id",
        id: "unit_id",
        label: "unit_id",
        data: filterunits,
        size: 3,
        title: "unit",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "department_id",
        id: "department_id",
        label: "department_id",
        data: filterdepartments,
        size: 3,
        title: "department",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "co_job_title_id",
        id: "co_job_title_id",
        label: "co_job_title_id",
        data: filtercojobtitle,
        size: 3,
        title: "coJobTitleId",
      },
      {
        type: "Select",
        optionTitle: "first_name",
        name: "co_user_id",
        id: "co_user_id",
        label: "co_user_id",
        data: filtercousers,
        size: 3,
        title: "fullName",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "job_title_id",
        id: "job_title_id",
        label: "job_title_id",
        data: filterjobtitle,
        size: 3,
        title: "jobTitle",
      },
      {
        type: "Select",
        optionTitle: "first_name",
        name: "user_id",
        id: "user_id",
        label: "user_id",
        data: filterusers,
        size: 3,
        title: "fullName",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "task_id",
        id: "task_id",
        label: "task_id",
        data: filtertasks,
        size: 3,
        title: "task",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "work_status",
        id: "work_status",
        label: "work_status",
        data: status,
        size: 3,
        title: "status",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "date_type",
        id: "date_type",
        label: "date_type",
        data: dateTypes,
        size: 3,
        title: "dateType",
      },
      {
        type: "Date",
        name: "date_from",
        id: "date_from",
        label: "date_from",
        size: 3,
        title: "from",
      },
      {
        type: "Date",
        name: "date_to",
        id: "date_to",
        label: "date_to",
        size: 3,
        title: "to",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "work_type",
        id: "work_type",
        label: "work_type",
        size: 4,
        title: "workType",
        data: workTypes,
      },
    ];
    if (!userId) {
      delete filterArr[12];
    }
    setData(filterArr);
  };

  useEffect(() => {
    if (
      filtertasks !== undefined &&
      filterusers !== undefined &&
      filterjobtitle !== undefined &&
      filtercousers !== undefined &&
      filtercojobtitle !== undefined &&
      filterdepartments !== undefined &&
      filterprograms !== undefined &&
      filterunits !== undefined
    ) {
      orgData();
    }
  }, [
    filtertasks,
    filterusers,
    filterjobtitle,
    filtercousers,
    filtercojobtitle,
    filterdepartments,
    filterunits,
    filterprograms,
  ]);

  const generateUrl = (values) => {
    let str = baseUrl;

    if (userId) {
      str = `${baseUrl}&filter[${values.work_type.id}.id]=${userId}`;
    }

    const keys = Object.keys(values);
    keys.forEach((element) => {
      if (values[element] && values[element] !== []) {
        if (
          element !== "work_type" &&
          typeof values[element] === "object" &&
          element !== "date_type"
        ) {
          if (element === "work_status") {
            str += `&filter[${element}]=${values[element].id}`;
          } else {
            str += `&filter[${element}.id]=${values[element].id}`;
          }
        } else if (
          element !== "date_from" &&
          element !== "date_to" &&
          element !== "date_type" &&
          element !== "work_type"
        ) {
          str += `&filter[${element}][_contains]=${values[element]}`;
        }
      }
    });
    if (values.date_from && values.date_to && values.date_type) {
      str += `&filter[${values.date_type.id}][_between]=${values.date_from},${values.date_to}`;
    }

    return str;
  };

  const setPayload = (values) => {
    setFilteredValues(values);
    setWorkTypeId(values.work_type.id);
    setNumber(1);
    if (values) setInitialValues(values);
    setUrl(generateUrl(values));
    dispatch(
      fetchDataAction({
        url: `${generateUrl(values)}&meta=*&limit=${limit}&page=${1}`,
        varName: "works",
      })
    );
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      if (element === "users") {
        dispatch(
          fetchDataAction({
            url: `${element}?fields=id,first_name&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
      } else if (element === "cousers") {
        dispatch(
          fetchDataAction({
            url: `users?fields=id,first_name&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
      } else if (element === "jobtitle") {
        dispatch(
          fetchDataAction({
            url: `items/job_title?fields=id,title&limit=${limit}`,
            varName: `filterjobtitle`,
          })
        );
      } else if (element === "cojobtitle") {
        dispatch(
          fetchDataAction({
            url: `items/job_title?fields=id,title&limit=${limit}`,
            varName: `filtercojobtitle`,
          })
        );
      } else {
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,title&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
      }
    });
  };

  useEffect(() => {
    getData(dataVal.oterData);
  }, []);

  useEffect(() => {
    setPayload(filteredValues || initialValues);
  }, [limit]);

  const onChangeSelectInputs = (name, text = " ") => {
    let urlVal;
    if (name === "program_id") {
      dispatch(
        fetchDataAction({
          url: `items/programs?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "filterprograms",
        })
      );
    } else if (name === "research") {
      urlVal = `items/researches?fields=id,title&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (programId) urlVal += `&filter[program_id]=${programId}`;

      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterresearches",
        })
      );
    } else if (name === "unit_id") {
      urlVal = `items/units?fields=id,title&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterunits",
        })
      );
    } else if (name === "department_id") {
      urlVal = `items/departments?fields=id,title&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (unitId) urlVal += `&filter[unit_id]=${unitId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterdepartments",
        })
      );
    } else if (name === "job_title_id") {
      dispatch(
        fetchDataAction({
          url: `items/job_title?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "filterjobtitle",
        })
      );
    } else if (name === "co_job_title_id") {
      dispatch(
        fetchDataAction({
          url: `items/job_title?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "filtercojobtitle",
        })
      );
    } else if (name === "user_id") {
      urlVal = `users?fields=id,first_name,job_titles.job_title_id.id&filter[first_name][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (jobtitleId) urlVal += `&filter[job_titles.job_title_id.id]=${jobtitleId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterusers",
        })
      );
    } else if (name === "co_user_id") {
      urlVal = `users?fields=id,first_name,job_titles.job_title_id.id&filter[first_name][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (cojobtitleId) urlVal += `&filter[job_titles.job_title_id.id]=${cojobtitleId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filtercousers",
        })
      );
    } else if (name === "task_id") {
      urlVal = `items/tasks?fields=id,title,job_titles.job_title_id.id&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (jobtitleId) urlVal += `&filter[job_titles.job_title_id.id]=${jobtitleId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filtertasks",
        })
      );
    }
  };

  const setFieldSelectedValue = (name, values) => {
    if (name === "job_title_id") {
      setJobtitleId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/tasks?fields=id,title,job_titles.job_title_id.id&filter[job_titles.job_title_id.id]=${values.id}`,
          varName: "filtertasks",
        })
      );
      dispatch(
        fetchDataAction({
          url: `users?fields=id,first_name,job_titles.job_title_id.id&filter[job_titles.job_title_id.id]=${values.id}`,
          varName: "filterusers",
        })
      );
    } else if (name === "unit_id") {
      setUnitId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/departments?fields=id,title,unit_id&filter[unit_id]=${values.id}`,
          varName: "filterdepartments",
        })
      );
    } else if (name === "program_id") {
      setProgramId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/researches?fields=id,title,program_id&filter[program_id]=${values.id}`,
          varName: "filterresearches",
        })
      );
    } else if (name === "co_job_title_id") {
      setCoJobtitleId(values.id);
      dispatch(
        fetchDataAction({
          url: `users?fields=id,first_name,job_titles.job_title_id.id&filter[job_titles.job_title_id.id]=${values.id}`,
          varName: "filtercousers",
        })
      );
    }
  };

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      filter
      yupObj={
        userId
          ? {
              date_type: yup.mixed("required").required("required").nullable(),
              work_type: yup.mixed("required").required("required").nullable(),
              date_from: yup.string("required").required("required").nullable(),
              date_to: yup.string("required").required("required").nullable(),
            }
          : {
              date_type: yup.mixed("required").required("required").nullable(),
              date_from: yup.string("required").required("required").nullable(),
              date_to: yup.string("required").required("required").nullable(),
            }
      }
      name="worksFilter"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeSelectInputs}
    />
  );
};

FilterForm.propTypes = {
  setUrl: PropTypes.func.isRequired,
  setWorkTypeId: PropTypes.func.isRequired,
  setNumber: PropTypes.func.isRequired,
  userId: PropTypes.string,
  baseUrl: PropTypes.string.isRequired,
};

FilterForm.defaultProps = {
  userId: null,
};
export default FilterForm;
