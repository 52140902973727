import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataForm from "components/UI/form/DataForm";
import { fetchDataAction } from "store/features/coreSlice";
import PropTypes from "prop-types";

const FilterForm = ({ baseUrl }) => {
  const dispatch = useDispatch();
  const { filterjobtitle, limit } = useSelector((state) => state.core);
  const [data, setData] = useState([]);
  const [filteredValues, setFilteredValues] = useState(null);

  const initFilter = {
    job_title_id: { id: 4, title: "منسق مشروعات" },
  };

  const [initialValues, setInitialValues] = useState(initFilter);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "title",
        name: "job_title_id",
        id: "job_title_id",
        label: "job_title_id",
        data: filterjobtitle,
        size: 3,
        title: "jobTitle",
      },
    ]);
  };

  useEffect(() => {
    if (filterjobtitle) {
      orgData();
    }
  }, [filterjobtitle]);

  const generateUrl = (values) => {
    let str = baseUrl;
    console.log(baseUrl);
    const keys = Object.keys(values);
    keys.forEach((element) => {
      if (values[element] && values[element] !== []) {
        if (typeof values[element] === "object") {
          str += `?jobtitleId=${values[element].id}`;
        }
      }
    });
    console.log(str);
    return str;
  };

  const onChangeHandler = (name, text) => {
    if (name === "job_title_id") {
      dispatch(
        fetchDataAction({
          url: `items/job_title?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "filterjobtitle",
        })
      );
    }
  };

  const setPayload = (values) => {
    setFilteredValues(values);
    if (values) setInitialValues(values);
    dispatch(
      fetchDataAction({
        url: `${generateUrl(values)}`,
        varName: "userEvaluations",
      })
    );
  };

  const getData = () => {
    dispatch(
      fetchDataAction({
        url: `items/job_title?fields=id,title&limit=${limit}`,
        varName: `filterjobtitle`,
      })
    );
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setPayload(filteredValues || initialValues);
  }, [limit]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      filter
      yupObj={{}}
      name="userEvaluationsFilter"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      onChange={onChangeHandler}
    />
  );
};

FilterForm.propTypes = {
  baseUrl: PropTypes.string.isRequired,
};
export default FilterForm;
