import { Grid } from "@mui/material";

import MDBox from "components/MDBox";
import InvoiceReceiverVersionA from "../recipient-info/InvoiceRecipientVersionA";
import InvoideSenderVersionA from "../sender-info/InvoideSenderVersionA";
import InvoiceDetails from "../InvoiceDetails";
import InvoiceMetaData from "../InvoiceMetaData";

function InvoiceTemplateA() {
  return (
    <>
      <InvoideSenderVersionA />
      <hr style={{ height: 1, border: "none", background: "#eee", margin: "10px 0" }} />
      <InvoiceReceiverVersionA />
      <hr style={{ height: 1, border: "none", background: "#eee", margin: "10px 0" }} />
      <Grid container>
        <Grid item xs={2}>
          <InvoiceMetaData invoiceVersion="A" />
        </Grid>

        <Grid item xs={10}>
          <MDBox my={3} />
          <InvoiceDetails />
        </Grid>
      </Grid>
    </>
  );
}

export default InvoiceTemplateA;
