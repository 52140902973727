import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
import Page from "components/UI/Page";
import { Icon, Card } from "@mui/material";
import { useLocation } from "react-router-dom";
import { fetchDataAction } from "store/features/coreSlice";
import DataModal from "components/UI/DataModal";
import DataTable from "components/UI/Table/DataTable";
import PagesCount from "helpers/pages-count";
import PageForm from "./components/PageForm";
import FilterForm from "./components/FilterForm";
import dataVal from "./dataVal.json";

const AttendanceReport = () => {
  const baseUrl = `items/attendance?fields=*,user_id.first_name`;
  const dispatch = useDispatch();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.auth);
  const { attendancesMeta, attendances, limit, simbleLayout } = useSelector((state) => state.core);
  const [url, setUrl] = useState(baseUrl);
  const [newArr, setNewArr] = useState([]);
  const [number, setNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const [route, setRoute] = useState(null);
  const [pageOptions, setPageOption] = useState([]);
  const [formContainer, setFormContainer] = useState(<h1>wait ...</h1>);

  const resetForm = () => {
    // setFormContainer(<h1>wait ...</h1>);
  };

  const setOpenModal = (container) => {
    resetForm();
    setFormContainer(container);
    setOpen(true);
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+attendancesMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    if (attendances && attendances.length !== 0) {
      attendances.forEach((attendance) => {
        item = {};
        keys = Object.keys(attendance);
        keys.forEach((element) => {
          item[element] = attendance[element];
        });
        item.name = attendance.user_id?.first_name;
        item.attendance = `${attendance.attendance_hours}:${attendance.attendance_minutes}`;
        item.departure =
          attendance.departure_hours &&
          `${attendance.departure_hours}:${attendance.departure_minutes}`;
        if (route.update) {
          item.action = (
            <MDTypography
              style={{ padding: 20, color: "#3b94ee" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => {
                if (simbleLayout) {
                  setOpenModal(
                    <PageForm
                      resetData={resetForm}
                      updateHandlerVal={{ name: "attendances", value: attendance }}
                      pageNum={number}
                      url={url}
                    />
                  );
                }
              }}
            >
              <Icon fontSize="small">edit</Icon>
            </MDTypography>
          );
        }
        Arr.push(item);
      });
      setNewArr(Arr);
    } else {
      setNewArr([]);
    }
  };

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `${url}&meta=*&limit=${limit}&page=${num}`,
          varName: "attendances",
        })
      );
    }
  };

  const setSortByHandeller = (val) => {
    let urlVal = `${url}&meta=*&limit=${limit}&page=${number}`;

    urlVal += `&sort=${val.desc ? "-" : ""}${val.id}`;
    dispatch(
      fetchDataAction({
        url: urlVal,
        varName: "attendances",
      })
    );
  };

  useEffect(() => {
    const routeItem = userInfo.routes.filter(
      (x) =>
        `${x.route_name}` === location.pathname.split("/")[location.pathname.split("/").length - 1]
    )[0];
    setRoute(routeItem);
  }, []);

  useEffect(() => {
    if (attendances && route) {
      prepareTableRows();
    }
  }, [attendances, route]);

  if (newArr === []) {
    return null;
  }

  return (
    <Page>
      <Card style={{ padding: 20, position: "relative" }}>
        <DataModal
          handleClose={() => {
            resetForm();
            setOpen(false);
          }}
          open={open}
        >
          {formContainer}
        </DataModal>
        <FilterForm pageNum={number} baseUrl={baseUrl} setUrl={setUrl} setNumber={setNumber} />
        <DataTable
          table={{ columns: dataVal.columns, rows: newArr }}
          item={false}
          pageOptions={pageOptions}
          isSorted
          isPagination
          entriesPerPage
          showTotalEntries
          noEndBorder
          search
          setPageNum={setPageNumber}
          setSortByHandeller={setSortByHandeller}
          pageIndex={number - 1}
          allRowsCount={attendancesMeta ? +attendancesMeta.filter_count : 0}
        />
      </Card>
    </Page>
  );
};

export default AttendanceReport;
