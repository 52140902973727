import { Grid, styled } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { addDataAction } from "store/features/coreSlice";
import MDBox from "components/MDBox";
import FormikButton from "./FormikButton";
import FormikInput from "./FormikInput";
import WhatsappContact from "./WhatsappContact";

const initialValues = {
  full_name: "",
  email: "",
  message: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email("wrong email").required("required field"),
  full_name: Yup.string().required("required field"),
  message: Yup.string().required("required field"),
});

const StyledCenter = styled(MDBox)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: 20,
}));

const formFields = [
  {
    name: "full_name",
    label: "full_name",
    Component: FormikInput,
  },
  {
    name: "email",
    label: "email",
    Component: FormikInput,
  },
  {
    name: "message",
    label: "message",
    multiline: true,
    rows: 4,
    Component: FormikInput,
  },
];
function ContactForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    const payload = { url: "items/contact_us", data: values };

    // console.log("payload = ", payload);
    dispatch(addDataAction(payload));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      <Form noValidate>
        <Grid container spacing={2}>
          {formFields?.map(({ Component, label, ...rest }) => (
            <Grid key={Math.random().toString()} item xs={12}>
              <Component label={t(label)} fullWidth {...rest} />
            </Grid>
          ))}
        </Grid>
        <StyledCenter>
          <WhatsappContact />
          <FormikButton
            text={t("submit")}
            sx={{ height: { xs: 55, md: 60, fontSize: 17, borderRadius: 6, marginTop: 7 } }}
          />
        </StyledCenter>
      </Form>
    </Formik>
  );
}

export default ContactForm;
