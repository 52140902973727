import { Grid, Container, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { fetchDataAction } from "store/features/coreSlice";
import MDBox from "components/MDBox";
import FormikButton from "./FormUI/FormikButton";
import FormikInput from "./FormUI/FormikInput";
import FormikSelect from "./FormUI/FormikSelect";

const validationSchema = Yup.object().shape({
  comp_name: Yup.string().required("Required"),
  st_address: Yup.string().required("Required"),
  zip_code: Yup.string().required("Required"),
  city: Yup.object().nullable().required("Required"),
});

const StyledCenter = styled(MDBox)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export default function RecipientForm({ recipientInitalValues, setRecipentInfo, handleClose }) {
  const { t } = useTranslation();
  const [formFields, setFormFields] = useState();
  // const dispatch = useDispatch();
  const { cities } = useSelector((state) => state.core);

  const dispatch = useDispatch();

  const onSubmit = (values) => {
    const payload = {
      comp_name: values.comp_name,
      st_address: values.st_address,
      zip_code: values.zip_code,
      city: values.city.id,
    };

    console.log("payload = ", payload);
    setRecipentInfo(values);
    // dispatch(addDataAction({ url: "items/invoices", data: payload }));
    handleClose();
  };

  useEffect(() => {
    const payload = {
      url: `items/cities?fields=id,title,country_id.id,country_id.title,libraries.id&meta=*&limit=${-1}`,
      varName: "cities",
    };
    dispatch(fetchDataAction(payload));
  }, []);

  useEffect(() => {
    if (cities) {
      setFormFields([
        {
          name: "comp_name",
          label: "comp_name",
          Component: FormikInput,
        },
        {
          name: "st_address",
          label: "st_address",
          Component: FormikInput,
        },
        {
          name: "zip_code",
          label: "zip_code",
          Component: FormikInput,
        },
        {
          name: "city",
          label: "city",
          Component: FormikSelect,
          options: cities,
        },
      ]);
    }
  }, [cities]);
  return (
    <Container>
      <Formik
        initialValues={recipientInitalValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        <Form noValidate>
          <Grid container spacing={2}>
            {formFields?.map(({ Component, label, ...rest }) => (
              <Grid key={Math.random().toString()} item xs={12} sm={6}>
                <Component label={t(label)} fullWidth {...rest} />
              </Grid>
            ))}
          </Grid>
          <StyledCenter>
            <FormikButton
              text={t("submit")}
              style={{
                // width: "30%",
                borderRadius: 6,
                marginTop: 50,
              }}
            />
          </StyledCenter>
        </Form>
      </Formik>
    </Container>
  );
}

RecipientForm.propTypes = {
  recipientInitalValues: PropTypes.objectOf(PropTypes.any).isRequired,
  setRecipentInfo: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};
