import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataForm from "components/UI/form/DataForm";
import { fetchDataAction } from "store/features/coreSlice";
import PropTypes from "prop-types";
import dataVal from "../../dataVal.json";

const FilterForm = ({ setNumber, setUrl, baseUrl }) => {
  const dispatch = useDispatch();
  const { limit, filterbooks, filterpublishers, filterlibraries, filterdistributions } =
    useSelector((state) => state.core);
  const { userInfo } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [filteredValues, setFilteredValues] = useState(null);
  const [publisherId, setPublisherId] = useState(null);
  const [bookId, setBookId] = useState(null);
  const [libraryId, setLibraryId] = useState(null);
  const [initialValues, setInitialValues] = useState(dataVal.initialValuesFilter);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "title",
        name: "publisher_id",
        id: "publisher_id",
        label: "publisher_id",
        data: filterpublishers,
        size: 4,
        title: "publisher",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "book_id",
        id: "book_id",
        label: "book_id",
        data: filterbooks,
        size: 3,
        title: "book",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "library_id",
        id: "library_id",
        label: "library_id",
        data: filterlibraries,
        size: 3,
        title: "libraries",
      },
      {
        type: "Select",
        optionTitle: "distribution_date",
        name: "distribution_id",
        id: "distribution_id",
        label: "distribution_id",
        data: filterdistributions,
        size: 3,
        title: "distribution",
      },
      {
        type: "Date",
        name: "return_date",
        id: "return_date",
        label: "return_date",
        size: 3,
        title: "returnDate",
      },
    ]);
  };

  useEffect(() => {
    if (filterbooks && filterpublishers && filterdistributions && filterlibraries) orgData();
  }, [filterbooks, filterpublishers, filterdistributions, filterlibraries]);

  const generateUrl = (values) => {
    let str = baseUrl;
    const keys = Object.keys(values);
    keys.forEach((element) => {
      if (values[element] && values[element] !== []) {
        if (typeof values[element] === "object") {
          if (element === "book_id")
            str += `&filter[distribution_id.store_id.buying_id.${element}.id]=${values[element].id}`;
          else if (element === "publisher_id") {
            str += `&filter[distribution_id.store_id.buying_id.book_id.publisher_id.id]=${values[element].id}`;
          } else if (element === "library_id")
            str += `&filter[distribution_id.${element}.id]=${values[element].id}`;
          else str += `&filter[${element}.id]=${values[element].id}`;
        } else {
          str += `&filter[${element}]=${values[element]}`;
        }
      }
    });
    return str;
  };

  const setFieldSelectedValue = (name, values) => {
    if (name === "publisher_id") {
      setPublisherId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/books?fields=id,title,publisher_id&filter[publisher_id]=${values.id}`,
          varName: "filterbooks",
        })
      );
    }

    let urlVal;
    if (name === "book_id") {
      setBookId(values.id);
      urlVal = `items/distributions?fields=id,distribution_date,notes,inventories.count,inventories.id,returns.count,returns.id,count,store_id.place_id.title,store_id.place_id.id,store_id.buying_id.book_id&filter[store_id.buying_id.book_id]=${values.id}`;
      if (libraryId) urlVal += `&filter[library_id]=${libraryId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterdistributions",
        })
      );
    } else if (name === "library_id") {
      setLibraryId(values.id);
      urlVal = `items/distributions?fields=id,distribution_date,notes,inventories.count,inventories.id,returns.count,returns.id,count,store_id.place_id.title,store_id.place_id.id,store_id.buying_id.book_id,library_id.users.user_id,library_id.id&filter[library_id][id]=${values.id}`;
      if (bookId) urlVal += `&filter[store_id.buying_id.book_id]=${bookId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterdistributions",
        })
      );
    }
  };

  const onChangeHandler = (name, text) => {
    if (name === "publisher_id") {
      dispatch(
        fetchDataAction({
          url: `items/publishers?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "filterpublishers",
        })
      );
    }

    let urlVal;
    if (name === "book_id") {
      urlVal = `items/books?fields=id,title&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (publisherId) urlVal += `&filter[publisher_id]=${publisherId}`;

      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterbooks",
        })
      );
    }

    if (name === "library_id") {
      urlVal = `items/libraries?fields=id,title&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (!userInfo.all_libraries) urlVal += `&filter[users][user_id][_eq]=${userInfo.id}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterlibraries",
        })
      );
    }
    if (name === "distribution_id") {
      urlVal = `items/distributions?fields=id,distribution_date,notes,store_id.buying_id.book_id,inventories.count,inventories.id,returns.count,returns.id,count&filter[distribution_date][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (bookId) urlVal += `&filter[store_id.buying_id.book_id]=${bookId}`;
      if (libraryId) urlVal += `&filter[library_id]=${libraryId}`;

      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterdistributions",
        })
      );
    }
  };

  const getData = (dataNeeded) => {
    let urlVal;
    dataNeeded.forEach((element) => {
      if (element === "proposal")
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,cost,discount_percentage,is_dolar&filter[status]=published&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
      else if (element === "libraries") {
        urlVal = `items/libraries?fields=id,title&limit=${limit}`;
        if (!userInfo.all_libraries) urlVal += `&filter[users][user_id][_eq]=${userInfo.id}`;
        dispatch(
          fetchDataAction({
            url: urlVal,
            varName: `filter${element}`,
          })
        );
      } else if (element === "distributions")
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,distribution_date,notes,inventories.count,inventories.id,returns.count,returns.id,count,store_id.place_id.title,store_id.place_id.id&limit=${limit}&filter[library_id][users][user_id][_eq]=${userInfo.id}`,
            varName: `filter${element}`,
          })
        );
      else
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,title&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
    });
  };

  const setPayload = (values) => {
    setFilteredValues(values);
    setNumber(1);
    if (values) setInitialValues(values);
    setUrl(generateUrl(values));
    dispatch(
      fetchDataAction({
        url: `${generateUrl(values)}&meta=*&limit=${limit}&page=${1}`,
        varName: "returns",
      })
    );
  };

  useEffect(() => {
    getData(dataVal.oterData);
  }, []);

  useEffect(() => {
    setPayload(filteredValues || initialValues);
  }, [limit]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      filter
      name="returnFilter"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeHandler}
    />
  );
};

FilterForm.propTypes = {
  setUrl: PropTypes.func.isRequired,
  setNumber: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};
export default FilterForm;
