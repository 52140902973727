import { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { KeyboardArrowDownOutlined, Translate } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import localStoragePrefix from "helpers/config";

const langs = [
  { name: "English", key: "en" },
  { name: "العربية", key: "ar" },
  // { id: "3", name: "Turkish", key: "tr" },
];

// const lang = "en";
function LanguagesMenu() {
  const { i18n } = useTranslation();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElNav(null);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem(`${localStoragePrefix}USER_LANG`, lng);
    document.body.dir = i18n.dir();
    // theme.direction = i18n.dir();
    window.location.reload();
  };

  const selectLanguageHandler = (selectedLang) => {
    handleCloseMenu();
    // setSelectedLang(selectedLang);
    changeLanguage(selectedLang);
  };

  return (
    <MDBox>
      <Button
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenMenu}
        color="white"
        sx={{ color: "white !important" }}
        startIcon={<Translate color="info" sx={{ cursor: "pointer" }} />}
        endIcon={<KeyboardArrowDownOutlined color="info" />}
      >
        {i18n.language}
      </Button>

      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseMenu}
        // sx={{
        //   display: { xs: "block", md: "none" },
        // }}
      >
        {langs.map(({ name, key }) => (
          <MenuItem key={key} onClick={() => selectLanguageHandler(key)}>
            <MDTypography textAlign="center" variant="body2">
              {name}
            </MDTypography>
          </MenuItem>
        ))}
      </Menu>
    </MDBox>
  );
}

export default LanguagesMenu;
