import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import DataForm from "components/UI/form/DataForm";
import { addComplexDataAction, fetchDataAction, reset, setAlert } from "store/features/coreSlice";
import PropTypes from "prop-types";
import UpdateRecord from "helpers/compare";
import { useTranslation } from "react-i18next";
import dataVal from "../../dataVal.json";

const PageForm = ({ updateHandlerVal, pageNum, url }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    limit,
    formbooks,
    formpublishers,
    formbuying,
    formstores,
    formjobtitles,
    formtypes,
    formusers,
  } = useSelector((state) => state.core);

  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);
  const [publisherId, setPublisherId] = useState(null);
  const [bookId, setBookId] = useState(null);
  const [buyingId, setBuyingId] = useState(null);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "title",
        name: "publisher_id",
        id: "publisher_id",
        label: "publisher_id",
        data: formpublishers,
        size: 3,
        title: "givingOrder",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "book_id",
        id: "book_id",
        label: "book_id",
        data: formbooks,
        size: 3,
        title: "book",
      },
      {
        type: "Select",
        optionTitle: "buying_date",
        optionTitle1: "notes",
        name: "buying_id",
        id: "buying_id",
        label: "buying_id",
        data: formbuying,
        size: 3,
        title: "buying",
      },
      {
        type: "Select",
        optionTitle: "place_id",
        oterOptionTitle: "title",
        name: "store_id",
        id: "store_id",
        label: "store_id",
        data: formstores,
        size: 3,
        title: "store",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "type_id",
        id: "type_id",
        label: "type_id",
        data: formtypes,
        size: 3,
        title: "item",
      },
      {
        type: "Select",
        optionTitle: "first_name",
        name: "user_id",
        id: "user_id",
        label: "user_id",
        data: formusers,
        size: 3,
        title: "towho",
      },
      {
        type: "TextField",
        name: "to_who",
        id: "to_who",
        label: "to_who",
        size: 3,
        title: "towho",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "job_title_id",
        id: "job_title_id",
        label: "job_title_id",
        data: formjobtitles,
        size: 3,
        title: "givingOrderName",
      },
      {
        type: "TextField",
        name: "count",
        id: "count",
        label: "count",
        size: 3,
        title: "count",
      },
      {
        type: "Date",
        name: "gift_date",
        id: "gift_date",
        label: "gift_date",
        size: 3,
        title: "giftDate",
      },
      {
        type: "TextArea",
        name: "notes",
        id: "notes",
        label: "notes",
        maxRows: 4,
        minRows: 4,
        size: 9,
        title: "notes",
      },
    ]);
  };

  const resetForm = () => {
    setPublisherId(null);
    setBookId(null);
    setBuyingId(null);
  };

  const setFieldSelectedValue = (name, values) => {
    if (name === "publisher_id") {
      setPublisherId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/books?fields=id,title,publisher_id&filter[publisher_id]=${values.id}`,
          varName: "formbooks",
        })
      );
    }
    if (name === "book_id") {
      setBookId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/buying?fields=id,notes,buying_date&filter[book_id]=${values.id}`,
          varName: "formbuying",
        })
      );
    } else if (name === "buying_id") {
      setBuyingId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/stores?fields=id,count,place_id.title,place_id.id,distributions.count,distributions.returns.count,distributions.id,gifts.count,gifts.id,buying_id.buying_date&filter[buying_id]=${values.id}`,
          varName: "formstores",
        })
      );
    }
  };

  const onChangeSelectInputs = (name, text) => {
    if (name === "publisher_id") {
      dispatch(
        fetchDataAction({
          url: `items/publishers?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "formpublishers",
        })
      );
    }
    let urlVal;
    if (name === "book_id") {
      urlVal = `items/books?fields=id,title&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (publisherId) urlVal += `&filter[publisher_id]=${publisherId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formbooks",
        })
      );
    }

    if (name === "buying_id") {
      urlVal = `items/buying?fields=id,notes,buying_date&filter[buying_date][_eq]=${text}&limit=${limit}`;
      if (bookId) urlVal += `&filter[book_id]=${bookId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formbuying",
        })
      );
    }

    if (name === "store_id") {
      urlVal = `items/stores?fields=id,count,place_id.title,place_id.id,distributions.count,distributions.returns.count,distributions.id,gifts.count,gifts.id,buying_id.buying_date&filter[place_id.title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (buyingId) urlVal += `&filter[buying_id]=${buyingId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formstores",
        })
      );
    }

    if (name === "job_title_id")
      dispatch(
        fetchDataAction({
          url: `items/job_title?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "formjobtitles",
        })
      );

    if (name === "type_id")
      dispatch(
        fetchDataAction({
          url: `items/gifts_types?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "formtypes",
        })
      );

    if (name === "user_id")
      dispatch(
        fetchDataAction({
          url: `users?fields=id,first_name&filter[first_name][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "formusers",
        })
      );
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      if (element === "users")
        dispatch(
          fetchDataAction({
            url: `${element}?fields=id,first_name&limit=${limit}`,
            varName: `form${element}`,
          })
        );
      else if (element === "buying")
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,buying_date,notes&limit=${limit}`,
            varName: `form${element}`,
          })
        );
      else if (element === "stores")
        dispatch(
          fetchDataAction({
            url: `items/stores?fields=id,place_id.title,place_id.id,count,distributions.count,gifts.count,gifts.id,distributions.returns.count,distributions.id,buying_id.buying_date&limit=${limit}`,
            varName: `form${element}`,
          })
        );
      else if (element === "job_title")
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,title&limit=${limit}`,
            varName: `formjobtitles`,
          })
        );
      else if (element === "gifts_types")
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,title&limit=${limit}`,
            varName: `formtypes`,
          })
        );
      else
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,title&limit=${limit}`,
            varName: `form${element}`,
          })
        );
    });
  };

  const onUpdateHandler = (name, values, copy) => {
    if (values.publisher_id) {
      setFieldSelectedValue("publisher_id", values.publisher_id);
      onChangeSelectInputs("publisher_id", values.publisher_id.title);
    }

    if (values.store_id.buying_id.book_id) {
      setFieldSelectedValue("book_id", values.store_id.buying_id.book_id);
      onChangeSelectInputs("book_id", values.store_id.buying_id.book_id.title);
    }

    if (values.store_id?.buying_id) {
      setFieldSelectedValue("buying_id", values.store_id.buying_id);
      onChangeSelectInputs("buying_id", values.store_id.buying_id.buying_date);
    }

    if (values.user_id) {
      onChangeSelectInputs("user_id", values.user_id.first_name);
    }

    if (values.job_title_id) {
      onChangeSelectInputs("job_title_id", values.job_title_id.title);
    }

    if (values.type_id) {
      onChangeSelectInputs("type_id", values.type_id.title);
    }

    setInitialValues({
      id: copy ? null : values.id,
      book_id: values.store_id.buying_id.book_id,
      buying_id: values.store_id.buying_id,
      publisher_id: values.publisher_id,
      count: values.count,
      store_id: values.store_id,
      gift_date: values.gift_date,
      to_who: values.to_who,
      user_id: values.user_id,
      job_title_id: values.job_title_id,
      type_id: values.type_id,
      notes: values.notes,
    });
  };

  const setPayload = (values) => {
    let count = 0;

    values.store_id.distributions.forEach((element) => {
      element.returns.forEach((element1) => {
        count -= element1.count;
      });
      count += element.count;
    });

    values.store_id.gifts.forEach((element) => {
      if (element.id === values.id) {
        count -= element.count;
      }
      count += element.count;
    });

    if (values.gift_date < values.store_id.buying_id.buying_date) {
      // alert("date problem");
      dispatch(
        setAlert({
          status: "error",
          content: t("dateError"),
        })
      );
    } else if (values.store_id.count - (+values.count + count) >= 0) {
      const payloadData = {};
      const lastData = values.id ? UpdateRecord(values, initialValues) : values;

      Object.keys(lastData).forEach((key) => {
        if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
        else if (values.id || lastData[key]) payloadData[key] = lastData[key];
      });

      delete payloadData.book_id;
      // delete payloadData.publisher_id;
      delete payloadData.buying_id;
      const req = {
        id: values.id,
        file: null,
        origin: {
          data: Object.keys(payloadData).length === 0 ? null : payloadData,
          table: "gifts",
        },
        getInfo: {
          url: `${url}&meta=*&limit=${limit}&page=${pageNum}`,
          varName: "gifts",
        },
      };
      setInitialValues(values);

      dispatch(addComplexDataAction(req));
    } else
      dispatch(
        setAlert({
          status: "error",
          content: t("countError"),
        })
      );
    // alert("count problem");
  };

  useEffect(() => {
    if (updateHandlerVal) {
      onUpdateHandler(updateHandlerVal.name, updateHandlerVal.value, updateHandlerVal.isCopy);
    } else {
      getData(dataVal.oterData);
    }
    return () => {
      resetForm();
      dispatch(
        reset({
          alias: "form",
          data: ["books", "publishers", "buying", "stores", "jobtitle", "types", "users"],
        })
      );
    };
  }, []);

  useEffect(() => {
    if (
      formbooks &&
      formpublishers &&
      formbuying &&
      formstores &&
      formjobtitles &&
      formtypes &&
      formusers
    ) {
      orgData();
    }
  }, [formbooks, formpublishers, formbuying, formstores, formjobtitles, formtypes, formusers]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      yupObj={{
        store_id: yup.mixed("required").required("required").nullable(),
        publisher_id: yup.mixed("required").required("required").nullable(),
        type_id: yup.mixed("required").required("required").nullable(),
        gift_date: yup.string("required").required("required").nullable(),
        count: yup
          .number("must be number")
          .required("required")
          .typeError("must be a number")
          .positive("must be greater than zero")
          .nullable(),
      }}
      resetData={resetForm}
      name="gifts"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeSelectInputs}
      defaultInitialValues={dataVal.initialValues}
    />
  );
};

PageForm.propTypes = {
  updateHandlerVal: PropTypes.objectOf(PropTypes.any),
  pageNum: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

PageForm.defaultProps = {
  updateHandlerVal: null,
};
export default PageForm;
