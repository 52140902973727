import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MultipleSelectChip from "components/UI/MutlipleSelect";
import { setReportTableFieldsAction } from "store/features/invoiceSlice";

import InvoiceActions from "./InvoiceActions";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function InvoiceModal({ handlePrint, repFields, children }) {
  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const [tableFields, setTableFields] = React.useState(repFields);
  // const { reportTableFields } = useSelector((state) => state.reportTableFields);
  const dispatch = useDispatch();

  const setTableFields = (payload) => {
    dispatch(setReportTableFieldsAction(payload));
  };

  React.useEffect(() => {
    setTableFields(repFields);
  }, []);

  return (
    <div>
      <MDButton color="info" style={{ margin: 5 }} variant="outlined" onClick={handleClickOpen}>
        {t("createInvoice")}
      </MDButton>

      <MultipleSelectChip
        data={repFields}
        optionTitle="Header"
        setTableFields={setTableFields}
        tableFields={repFields}
      />

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative", mb: 4 }} color="secondary">
          <MDBox px={8}>
            <Toolbar>
              <IconButton edge="start" color="info" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <div style={{ flexGrow: 1 }} />

              <InvoiceActions handlePrint={handlePrint} />
            </Toolbar>
          </MDBox>
        </AppBar>
        <MDBox px={10}>{children}</MDBox>
      </Dialog>
    </div>
  );
}

InvoiceModal.defaultProps = {
  children: null,
};
InvoiceModal.propTypes = {
  repFields: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
  handlePrint: PropTypes.func.isRequired,
  children: PropTypes.node,
};
