/**
=========================================================
* Trakib System - v2.1.0
=========================================================

* Product Page: https://trakib.comproduct/trakib-system-react
* Copyright 2022 trakib (https://trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Trakib System are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Trakib System layouts
// import Analytics from "layouts/dashboards/analytics";
// import Sales from "layouts/dashboards/sales";
// import ProfileOverview from "layouts/pages/profile/profile-overview";
// import ProfileOverview from "layouts/sys-users/profile";
// import AllProjects from "layouts/pages/profile/all-projects";
// import NewUser from "layouts/pages/users/new-user";
// import Settings from "layouts/pages/account/settings";
// import Billing from "layouts/pages/account/billing";
// import Invoice from "layouts/pages/account/invoice";
// import Timeline from "layouts/pages/projects/timeline";
// import PricingPage from "layouts/pages/pricing-page";
// import Widgets from "layouts/pages/widgets";
// import RTL from "layouts/pages/rtl";
// import Charts from "layouts/pages/charts";
// import Notifications from "layouts/pages/notifications";
// import Kanban from "layouts/applications/kanban";
// import Wizard from "layouts/applications/wizard";
// import DataTables from "layouts/applications/data-tables";
// import Calendar from "layouts/applications/calendar";
// import NewProduct from "layouts/ecommerce/products/new-product";
// import EditProduct from "layouts/ecommerce/products/edit-product";
// import ProductPage from "layouts/ecommerce/products/product-page";
// import OrderList from "layouts/ecommerce/orders/order-list";
// import OrderDetails from "layouts/ecommerce/orders/order-details";
// import SignInBasic from "layouts/authentication/sign-in-new/basic";
// import SignInBasic from "layouts/authentication/sign-in";
// import SignInCover from "layouts/authentication/sign-in-new/cover";
// import SignInIllustration from "layouts/authentication/sign-in-new/illustration";
// import SignUpCover from "layouts/authentication/sign-up/cover";
// import ResetCover from "layouts/authentication/reset-password/cover";

// Trakib System components
// import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
// import profilePicture from "assets/images/team-3.jpg";
import BookStoreSettings from "layouts/book-store/book-store-settings";
import Buying from "layouts/book-store/Buying";
import Proposals from "layouts/book-store/Proposals";
import AdminPermissions from "layouts/sys-admin/admin-permissions";
import AdminActivities from "layouts/sys-admin/admin-activities";
import AdminSettings from "layouts/sys-admin/admin-settings";
import Stores from "layouts/book-store/Stores";
import Gifts from "layouts/book-store/Gifts";
import Returns from "layouts/book-store/Returns";
import Inventory from "layouts/book-store/Inventory";
import Distribution from "layouts/book-store/Distribusion";
import InsertUser from "layouts/sys-users/insert-user";
import UserSettings from "layouts/sys-users/user-settings";
import InsertBook from "layouts/books/insert-book";
// import BookSettings from "layouts/books/book-settings";
// import LibrarySettings from "layouts/libraries/library-settings";
// import InsertLibrary from "layouts/libraries/insert-library";
import InsertLibrary from "layouts/libraries";
import DistributionReport from "layouts/reports/distribution-report";
import LibraryReport from "layouts/reports/library-report";
import UserEvaluationReport from "layouts/reports/user-evaluation-report";
import AttendanceReport from "layouts/reports/attendance-report";
import AccountSettings from "layouts/accounts/account-settings";
import InsertAccount from "layouts/accounts/insert-account";
import UsersStandards from "layouts/sys-users/users-standards";
import ManagmentSettings from "layouts/management/managment-settings";
import Researches from "layouts/management/researches";
import Works from "layouts/management/works";
import Tracking from "layouts/management/tracking";
import Salaries from "layouts/accounts/salaries";
import UsersGifts from "layouts/accounts/users-gifts";
import Notifications from "layouts/sys-admin/admin-notifications";
import Links from "layouts/sys-admin/admin-links";
import Messages from "layouts/system/messages";

// import Fair from "layouts/book-store/Fair";

const routes = [
  {
    type: "collapse",
    name: "admin",
    key: "sys-admin",
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    collapse: [
      {
        name: "settings",
        key: "admin-settings",
        route: "/sys-admin/admin-settings",
        component: <AdminSettings />,
      },
      {
        name: "messages",
        key: "messages",
        route: "/system/messages",
        component: <Messages />,
      },
      {
        name: "activities",
        key: "admin-activities",
        route: "/sys-admin/admin-activities",
        component: <AdminActivities />,
      },

      {
        table: "job_title_routes",
        name: "permissions",
        key: "admin-permissions",
        route: "/sys-admin/admin-permissions",
        component: <AdminPermissions />,
      },
      {
        name: "notifications",
        key: "admin-notifications",
        route: "/sys-admin/admin-notifications",
        component: <Notifications />,
      },
      {
        name: "links",
        key: "admin-links",
        route: "/sys-admin/admin-links",
        component: <Links />,
      },
    ],
  },
  {
    type: "collapse",
    name: "users",
    key: "sys-users",
    icon: <Icon fontSize="medium">person</Icon>,
    collapse: [
      {
        name: "insert-user",
        key: "insert-user",
        route: "/sys-users/insert-user",
        component: <InsertUser />,
      },
      {
        name: "users-standards",
        key: "users-standards",
        route: "/sys-users/users-standards",
        component: <UsersStandards />,
      },
      {
        name: "user-settings",
        key: "user-settings",
        route: "/sys-users/user-settings",
        component: <UserSettings />,
      },
    ],
  },
  {
    type: "collapse",
    name: "accounts",
    key: "accounts",
    icon: <Icon fontSize="medium">account_balance_wallet</Icon>,
    collapse: [
      {
        name: "insert-account",
        key: "insert-account",
        route: "/accounts/insert-account",
        component: <InsertAccount />,
      },
      {
        name: "salaries",
        key: "salaries",
        route: "/accounts/salaries",
        component: <Salaries />,
      },
      {
        name: "users-gifts",
        key: "users-gifts",
        route: "/accounts/users-gifts",
        component: <UsersGifts />,
      },
      {
        name: "account-settings",
        key: "account-settings",
        route: "/accounts/account-settings",
        component: <AccountSettings />,
      },
    ],
  },
  {
    type: "collapse",
    name: "management",
    key: "managment",
    icon: <Icon fontSize="medium">work</Icon>,
    collapse: [
      {
        name: "works",
        key: "works",
        route: "/sys-managment/works",
        component: <Works />,
      },
      {
        name: "tracking",
        key: "tracking",
        route: "/sys-managment/tracking",
        component: <Tracking />,
      },
      {
        name: "researches",
        key: "researches",
        route: "/managment/researches",
        component: <Researches />,
      },
      {
        name: "managment-settings",
        key: "managment-settings",
        route: "/managment/managment-settings",
        component: <ManagmentSettings />,
      },
    ],
  },
  { type: "divider", key: "divider-distributions" },
  {
    type: "collapse",
    name: "book-store",
    key: "book-store",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "insert-book",
        key: "insert-book",
        route: "/book-store/insert-book",
        component: <InsertBook />,
      },
      // {
      //   name: "fair",
      //   key: "fair",
      //   route: "/book-store/fair",
      //   component: <Fair />,
      // },
      {
        name: "buying",
        key: "buying",
        route: "/book-store/buying",
        component: <Buying />,
      },
      {
        table: "stores",
        name: "stores",
        key: "stores",
        route: "/book-store/stores",
        component: <Stores />,
      },
      {
        name: "proposal",
        key: "proposals",
        table: "proposals",
        route: "/book-store/proposals",
        component: <Proposals />,
      },
      {
        table: "distributions",
        name: "distribution",
        key: "distribution",
        route: "/book-store/distribution",
        component: <Distribution />,
      },
      {
        table: "libraries",
        name: "insert-library",
        key: "insert-library",
        route: "/book-store/insert-library",
        component: <InsertLibrary />,
      },
      {
        name: "setting",
        key: "setting",
        route: "/book-store/setting",
        component: <BookStoreSettings />,
      },
    ],
  },
  { type: "divider", key: "divider-inventories" },
  {
    type: "collapse",
    name: "book-store-inventory",
    key: "book-store-inventory",
    icon: <Icon fontSize="medium">paid</Icon>,
    collapse: [
      {
        table: "inventory",
        name: "inventory",
        key: "inventory",
        route: "/book-store-inventory/inventory",
        component: <Inventory />,
      },
      {
        table: "returns",
        name: "returns",
        key: "returns",
        route: "/book-store-inventory/returns",
        component: <Returns />,
      },
      {
        table: "gifts",
        name: "gifts",
        key: "gifts",
        route: "/book-store-inventory/gifts",
        component: <Gifts />,
      },
    ],
  },
  { type: "divider", key: "divider-reports" },
  {
    type: "collapse",
    name: "reports",
    key: "reports",
    icon: <Icon fontSize="medium">report</Icon>,
    collapse: [
      {
        name: "distribution-report",
        key: "distribution-report",
        route: "/reports/distribution-report",
        component: <DistributionReport />,
      },
      {
        name: "library-report",
        key: "library-report",
        route: "/reports/library-report",
        component: <LibraryReport />,
      },
      {
        name: "user-evaluation-report",
        key: "user-evaluation-report",
        route: "/reports/user-evaluation-report",
        component: <UserEvaluationReport />,
      },
      {
        name: "attendance-report",
        key: "attendance-report",
        route: "/reports/attendance-report",
        component: <AttendanceReport />,
      },
    ],
  },
];

export default routes;
