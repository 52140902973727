import Card from "@mui/material/Card";
// Became Possible components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Became Possible example components
import TimelineItem from "examples/Timeline/TimelineItem";

import PropTypes from "prop-types";

function OrdersOverview({ title, data }) {
  if (!data || data.length === 0) return null;

  const getIcon = (f) => {
    let r = f;
    if (f === "authenticate") r = "do_not_disturb_on";
    else if (f === "soft-delete") r = "delete";
    return r;
  };

  const getColor = (q) => {
    let f = "warning";
    if (q === "create") f = "success";
    else if (q === "delete" || q === "soft-delete") f = "error";
    return f;
  };

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        {data.map((dataItem) => (
          <TimelineItem
            key={dataItem.id}
            color={getColor(dataItem.action)}
            icon={getIcon(dataItem.action)}
            title={
              `${dataItem.action} - ${
                dataItem.collection.replace("directus_", "")
                // dataItem.collection !== "directus_users" ? dataItem.collection : `users`
              } - ${dataItem.user ? dataItem.user.first_name : ""} `
              // : `${dataItem.action} - users - ${
              //     dataItem.user ? dataItem.user.first_name : ""
              //   } ${dataItem.user ? dataItem.user.last_name : ""}`
            }
            dateTime={`on item ${dataItem.item} at ${dataItem.timestamp}`}
          />
        ))}
      </MDBox>
    </Card>
  );
}

OrdersOverview.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])),
};
OrdersOverview.defaultProps = {
  data: null,
};

export default OrdersOverview;
