import { useTranslation } from "react-i18next";
import MDTypography from "components/MDTypography";

function StrokedLogoText() {
  const { t, i18n } = useTranslation();
  return (
    <div className={`animated-trakib-container ${i18n.dir() === "rtl" && "rtl-dir"}`}>
      <MDTypography variant="h2" className="animated-trakib">
        {t("trakib")}
      </MDTypography>
      <MDTypography variant="h2" className="animated-trakib">
        {t("trakib")}
      </MDTypography>
    </div>
  );
}

export default StrokedLogoText;
