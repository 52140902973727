import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

import MDTypography from "components/MDTypography";

function UserMenu({ anchorElUser, handleOpenUserMenu, handleCloseUserMenu, settings }) {
  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem key={setting} onClick={handleCloseUserMenu}>
            <MDTypography textAlign="center">{setting}</MDTypography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

UserMenu.defaultProps = {
  anchorElUser: null,
};

UserMenu.propTypes = {
  anchorElUser: PropTypes.objectOf(PropTypes.any),
  // anchorElUser: PropTypes.element,
  handleOpenUserMenu: PropTypes.func.isRequired,
  handleCloseUserMenu: PropTypes.func.isRequired,
  settings: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default UserMenu;
