import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
import Page from "components/UI/Page";
import { Card, Icon } from "@mui/material";
import { useLocation } from "react-router-dom";
import { fetchDataAction, deleteDataAction } from "store/features/coreSlice";
import MDButton from "components/MDButton";
import DataModal from "components/UI/DataModal";
import DataTable from "components/UI/Table/DataTable";
import DeleteModal from "components/UI/DeleteModal";
import PagesCount from "helpers/pages-count";
import { useTranslation } from "react-i18next";
import PageForm from "./components/PageForm";
import dataVal from "./dataVal.json";

const Permissions = () => {
  const baseUrl = `items/job_title_routes?fields=id,route_name,job_title_id.id,job_title_id.title,create,update,delete`;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.auth);
  const { jobTitleRoutes, jobTitleRoutesMeta, limit, simbleLayout, pagesArr } = useSelector(
    (state) => state.core
  );
  const [newArr, setNewArr] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [number, setNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const [route, setRoute] = useState(null);
  const [pageOptions, setPageOption] = useState([]);
  const [formContainer, setFormContainer] = useState(<h1>wait ...</h1>);

  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const setOpenModal = (container) => {
    setFormContainer(container);
    setOpen(true);
  };

  const handleOpen = () =>
    setOpenModal(<PageForm updateHandlerVal={null} pageNum={number} url={baseUrl} />);

  const onDeleteHandler = (name, values) => {
    setOpenDeleteModal(true);
    setDeleteItemId(values.id);
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];

    if (jobTitleRoutes && route) {
      const d = [];
      // jobTitleRoutesMeta.page_count;
      for (let i = 0; i < PagesCount(+jobTitleRoutesMeta.filter_count, limit); i += 1) {
        d.push(i);
      }
      setPageOption(d);
      jobTitleRoutes.forEach((routeItem) => {
        item = {};
        item.id = routeItem.id;
        item.title = pagesArr.filter((j) => j.id === routeItem.route_name)[0]?.title;
        item.job_title_id = routeItem.job_title_id.title;
        item.create = routeItem.create && (
          <MDTypography
            style={{ padding: 20, color: "#3b94ee" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            <Icon fontSize="small">done</Icon>
          </MDTypography>
        );
        item.update = routeItem.update && (
          <MDTypography
            style={{ padding: 20, color: "#3b94ee" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            <Icon fontSize="small">done</Icon>
          </MDTypography>
        );
        item.delete = routeItem.delete && (
          <MDTypography
            style={{ padding: 20, color: "#3b94ee" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            <Icon fontSize="small">done</Icon>
          </MDTypography>
        );
        item.action = (
          <>
            {route.update && (
              <MDTypography
                style={{ padding: 20, color: "#3b94ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => {
                  if (simbleLayout) {
                    setOpenModal(
                      <PageForm
                        updateHandlerVal={{
                          name: "jobTitleRoutes",
                          value: routeItem,
                        }}
                        pageNum={number}
                        url={baseUrl}
                      />
                    );
                  }
                }}
              >
                <Icon fontSize="small">edit</Icon>
              </MDTypography>
            )}
            {route.delete && (
              <MDTypography
                style={{ padding: 20, color: "#e9423f" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onDeleteHandler("jobTitleRoutes", routeItem)}
              >
                <Icon fontSize="small">delete</Icon>
              </MDTypography>
            )}
          </>
        );
        Arr.push(item);
      });
      setNewArr(Arr);
    }
  };

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `${baseUrl}&meta=*&limit=${limit}&page=${num}`,
          varName: "jobTitleRoutes",
        })
      );
    }
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: "items/job_title_routes",
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      getInfo: {
        url: `${baseUrl}&meta=*&limit=${limit}&page=${number}`,
        varName: "jobTitleRoutes",
      },
    };
    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  const setSortByHandeller = (val) => {
    let urlVal = `${baseUrl}&meta=*&limit=${limit}&page=${number}`;

    urlVal += `&sort=${val.desc ? "-" : ""}${val.id}`;
    dispatch(
      fetchDataAction({
        url: urlVal,
        varName: "jobTitleRoutes",
      })
    );
  };

  useEffect(() => {
    const routeItem = userInfo.routes.filter(
      (x) =>
        `${x.route_name}` === location.pathname.split("/")[location.pathname.split("/").length - 1]
    )[0];
    setRoute(routeItem);
  }, []);

  useEffect(() => {
    const payload = {
      url: `items/job_title_routes?fields=id,route_name,job_title_id.id,job_title_id.title,create,update,delete&meta=*&limit=${limit}&page=${number}`,
      varName: "jobTitleRoutes",
    };
    dispatch(fetchDataAction(payload));
  }, [limit]);

  useEffect(() => {
    if (jobTitleRoutes) {
      prepareTableRows();
    }
  }, [jobTitleRoutes]);

  if (newArr === []) {
    return null;
  }

  return (
    <Page>
      <Card style={{ padding: 20, position: "relative" }}>
        <DataModal
          handleClose={() => {
            setOpen(false);
          }}
          open={open}
        >
          {formContainer}
        </DataModal>
        <DeleteModal
          handleClose={handleDeleteModalClose}
          handleConfirmDelete={handleConfirmDelete}
          openDeleteModal={openDeleteModal}
        />
        {simbleLayout && route?.create && (
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleOpen}
            style={{ float: "left" }}
            size="large"
          >
            <Icon>add</Icon>&nbsp; {t("add")}
          </MDButton>
        )}
        <DataTable
          table={{ columns: dataVal.columns, rows: newArr }}
          item={false}
          pageOptions={pageOptions}
          isSorted
          isPagination
          entriesPerPage
          showTotalEntries
          noEndBorder
          search
          setPageNum={setPageNumber}
          setSortByHandeller={setSortByHandeller}
          pageIndex={number - 1}
          allRowsCount={jobTitleRoutesMeta ? +jobTitleRoutesMeta.filter_count : 0}
        />
      </Card>
    </Page>
  );
};

export default Permissions;
