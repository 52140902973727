import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataForm from "components/UI/form/DataForm";
import { fetchDataAction } from "store/features/coreSlice";
import moment from "moment";
import PropTypes from "prop-types";
import dataVal from "../../dataVal.json";

const FilterForm = ({ setNumber, setUrl, baseUrl }) => {
  const dispatch = useDispatch();

  const { filterusers, limit } = useSelector((state) => state.core);
  const [data, setData] = useState([]);
  const [filteredValues, setFilteredValues] = useState(null);

  const initFilter = {
    user_id: null,
    date_from: moment().subtract(0, "months").startOf("month").format("YYYY-MM-DD"),
    date_to: moment().subtract(0, "months").endOf("month").format("YYYY-MM-DD"),
  };

  const [initialValues, setInitialValues] = useState(initFilter);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "first_name",
        name: "user_id",
        id: "user_id",
        label: "user_id",
        data: filterusers,
        size: 3,
        title: "users",
      },
      {
        type: "Date",
        name: "date_from",
        id: "date_from",
        label: "date_from",
        size: 3,
        title: "from",
      },
      {
        type: "Date",
        name: "date_to",
        id: "date_to",
        label: "date_to",
        size: 3,
        title: "to",
      },
    ]);
  };

  useEffect(() => {
    if (filterusers) {
      orgData();
    }
  }, [filterusers]);

  const generateUrl = (values) => {
    let str = `${baseUrl}`;
    const keys = Object.keys(values);
    keys.forEach((element) => {
      if (values[element] && values[element] !== []) {
        if (typeof values[element] === "object") {
          if (element === "type") {
            str += `&filter[${element}]=${values[element].value}`;
          } else {
            str += `&filter[${element}.id]=${values[element].id}`;
          }
        } else if (element !== "date_from" && element !== "date_to") {
          str += `&filter[${element}][_contains]=${values[element]}`;
        }
      }
    });
    if (values.date_from && values.date_to) {
      str += `&filter[date][_between]=${values.date_from},${values.date_to}`;
    }

    return str;
  };

  const onChangeSelectInputs = (name, text) => {
    if (name === "user_id") {
      dispatch(
        fetchDataAction({
          url: `users?fields=id,first_name&filter[first_name][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "filterusers",
        })
      );
    }
  };

  const setPayload = (values) => {
    setFilteredValues(values);
    setNumber(1);

    if (values) setInitialValues(values);
    setUrl(generateUrl(values));
    dispatch(
      fetchDataAction({
        url: `${generateUrl(values)}&meta=*&limit=${limit}&page=${1}`,
        varName: "attendances",
      })
    );
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      if (element === "users") {
        dispatch(
          fetchDataAction({
            url: `${element}?fields=id,first_name&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
      }
    });
  };

  useEffect(() => {
    getData(dataVal.oterData);
  }, []);

  useEffect(() => {
    setPayload(filteredValues || initialValues);
  }, [limit]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      filter
      yupObj={{}}
      name="attendanceFilter"
      data={data}
      initialValues={initialValues}
      onChange={onChangeSelectInputs}
      setPayload={setPayload}
    />
  );
};

FilterForm.propTypes = {
  setUrl: PropTypes.func.isRequired,
  setNumber: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

export default FilterForm;
