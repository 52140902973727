import Page from "components/UI/Page";
import { Grid } from "@mui/material";
import AccountItems from "./SubContent/AccountItems";

const AccountSettings = () => (
  <Page>
    <Grid item xs={6}>
      <AccountItems />
    </Grid>
  </Page>
);

export default AccountSettings;
