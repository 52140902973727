import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Page from "components/UI/Page";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OrdersOverview from "components/UI/OrdersOverview";
import { fetchDataAction } from "store/features/coreSlice";
// import DataForm from "components/UI/form/DataForm";

import { Card } from "@mui/material";
import TablePaginationDemo from "components/UI/Table/TablePaginationDemo";
import FilterForm from "./components/FilterForm";

const Activities = () => {
  const baseUrl = `activity?fields=*,user.id,user.admin_id,user.first_name,user.last_name&sort=-timestamp`;
  const [url, setUrl] = useState(baseUrl);
  const [number, setNumber] = useState(1);

  const { t } = useTranslation();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.auth);
  const { limit, activities, activitiesMeta } = useSelector((state) => state.core);
  const [route, setRoute] = useState(null);

  useEffect(() => {
    const routeItem = userInfo.routes.filter(
      (x) =>
        `${x.route_name}` === location.pathname.split("/")[location.pathname.split("/").length - 1]
    )[0];
    setRoute(routeItem);
  }, []);

  const dispatch = useDispatch();

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `${url}&meta=*&limit=${limit}&page=${num}`,
          varName: "activities",
        })
      );
    }
  };

  if (!route && !activities && !activitiesMeta) {
    return null;
  }

  return (
    <Page>
      <Card style={{ padding: 20, position: "relative", width: "100%" }}>
        <FilterForm pageNum={number} baseUrl={baseUrl} setUrl={setUrl} setNumber={setNumber} />

        <OrdersOverview title={t("activity")} data={activities} />
        {activitiesMeta && (
          <TablePaginationDemo count={+activitiesMeta.filter_count} setPageNum={setPageNumber} />
        )}
      </Card>
    </Page>
  );
};

export default Activities;
