import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";
import * as yup from "yup";
import MDTypography from "components/MDTypography";
import { Icon, Grid } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
import Page from "components/UI/Page";
import {
  fetchDataAction,
  deleteDataAction,
  reset,
  addComplexDataAction,
} from "store/features/coreSlice";
import PagesCount from "helpers/pages-count";
import { useTranslation } from "react-i18next";
import dataVal from "./dataVal.json";

const Messages = () => {
  const { sysMessages, sysMessagesMeta, resStatus, limit, pagesArr } = useSelector(
    (state) => state.core
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [newArr, setNewArr] = useState([]);
  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);
  const [number, setNumber] = useState(1);
  const [pageOptions, setPageOption] = useState([]);

  const onUpdateHandler = (name, values) => {
    setInitialValues({
      id: values.id,
      text: values.text,
      route_name: pagesArr.filter((item) => item.id === values.route_name)[0],
    });
    setOpen(true);
  };

  const onDeleteHandler = (name, values) => {
    setOpenDeleteModal(true);
    setDeleteItemId(values.id);
  };

  useEffect(
    () => () => {
      dispatch(reset(null));
    },
    []
  );

  useEffect(() => {
    const payload = {
      url: `items/sys_messages?fields=id,text,route_name&meta=*&limit=${limit}&page=${number}`,
      varName: "sysMessages",
    };
    dispatch(fetchDataAction(payload));
  }, [limit]);

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `items/sys_messages?fields=id,text,route_name&meta=*&limit=${limit}&page=${num}`,
          varName: "sysMessages",
        })
      );
    }
  };

  useEffect(() => {
    let item = {};
    const Arr = [];

    if (sysMessages) {
      const d = [];
      for (let i = 0; i < PagesCount(+sysMessagesMeta.filter_count, limit); i += 1) {
        d.push(i);
      }
      setPageOption(d);
      sysMessages.forEach((routeItem) => {
        item = {};
        item.id = routeItem.id;
        item.title = routeItem.route_name;
        item.text = routeItem.text;
        // item.text = <div dangerouslySetInnerHTML={{ __html: routeItem.text }} />;
        item.action = (
          <>
            <MDTypography
              style={{ padding: 20, color: "#3b94ee" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => onUpdateHandler("sysMessages", routeItem)}
            >
              <Icon fontSize="small">edit</Icon>
            </MDTypography>
            <MDTypography
              style={{ padding: 20, color: "#e9423f" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => onDeleteHandler("sysMessages", routeItem)}
            >
              <Icon fontSize="small">delete</Icon>
            </MDTypography>
          </>
        );
        Arr.push(item);
      });
      setNewArr(Arr);
    }
  }, [sysMessages]);

  useEffect(() => {
    setData([
      {
        type: "Select",
        optionTitle: "title",
        name: "route_name",
        id: "route_name",
        label: "route_name",
        data: pagesArr,
        size: 6,
        title: "route name",
      },
      {
        // type: "Editor",
        type: "TextArea",
        name: "text",
        id: "text",
        label: "text",
        maxRows: 8,
        minRows: 8,
        size: 12,
        title: "text",
      },
    ]);
  }, []);

  const resetForm = () => {
    setInitialValues({
      id: null,
      route_name: null,
      text: null,
    });
  };

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      setSuccessSB(true);
      resetForm();
    }
  }, [resStatus]);

  const setPayload = (values) => {
    values.route_name = values.route_name.id;
    const req = {
      id: values.id,
      origin: {
        data: values,
        table: "sys_messages",
      },
      getInfo: {
        url: `items/sys_messages?fields=id,text,route_name&meta=*&limit=${limit}&page=${number}`,
        varName: "sysMessages",
      },
    };
    setInitialValues(values);
    dispatch(addComplexDataAction(req));
  };

  const setOpenModal = () => {
    if (open)
      setInitialValues({
        id: null,
        route_name: null,
        text: null,
      });
    setOpen(!open);
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: `items/sys_messages`,
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      varName: "sysMessages",
      getInfo: {
        url: `items/sys_messages?fields=id,text,route_name&meta=*&limit=${limit}&page=${number}`,
        varName: "sysMessages",
      },
    };

    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  if (data.length === 0 || newArr === [] || !sysMessagesMeta) {
    return null;
  }

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("systemDashboard")}
      content={t("successfulProcess")}
      dateTime={t("recently")}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  return (
    <Page>
      <Grid item xs={12}>
        <Main
          enableCreate
          count={pageOptions}
          pageIndex={number - 1}
          allRowsCount={+sysMessagesMeta.filter_count}
          setPageNum={setPageNumber}
          openDeleteModal={openDeleteModal}
          handleConfirmDelete={handleConfirmDelete}
          setOpen={setOpenModal}
          setOpenDeleteModal={setOpenDeleteModal}
          open={open}
          name="sysMessages"
          setPayload={setPayload}
          data={data}
          initialValues={initialValues}
          title="Add City"
          columns={dataVal.columns}
          rows={newArr}
          onUpdateHandler={onUpdateHandler}
          onDeleteHandler={onDeleteHandler}
          defaultInitialValues={dataVal.initialValues}
          yupObj={{
            route_name: yup.mixed("required").required("required").nullable(),
            text: yup.mixed("required").required("required").nullable(),
          }}
        />
        {renderSuccessSB}
      </Grid>
    </Page>
  );
};

export default Messages;
