import { useSelector } from "react-redux";
import Page from "components/UI/Page";
import { useEffect, useState } from "react";
import Avatar from "components/UI/Table/avatarWithOutImg";
import DataTable from "components/UI/Table/DataTable";

import { Card } from "@mui/material";
import FilterForm from "./components/FilterForm";

const UserEvaluationReport = () => {
  const baseUrl = `custom/userEvaluationRep`;

  const { userEvaluations } = useSelector((state) => state.core);

  const [newArr, setNewArr] = useState([]);

  const [columns, setColumns] = useState([]);

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const coulmnsItems = [
      {
        Header: "id",
        accessor: "id",
        align: "center",
      },
      {
        Header: "name",
        accessor: "info",
        align: "center",
      },
    ];
    if (userEvaluations && userEvaluations.length !== 0) {
      userEvaluations.forEach((userEvaluation) => {
        item = {};
        keys = Object.keys(userEvaluation);
        keys.forEach((element) => {
          item[element] = userEvaluation[element];
        });
        item.id = userEvaluation.userJobtitleId;
        item.info = (
          <Avatar
            dir="ltr"
            name={item.value[0]?.users_jobtitles.user_id.first_name || ""}
            email={item.value[0]?.users_jobtitles.job_title_id.title}
          />
        );

        item.value.forEach((element) => {
          item[element.standard_id.title] = element.value;
          if (coulmnsItems.filter((c) => c.Header === element.standard_id.title).length === 0)
            coulmnsItems.push({
              Header: element.standard_id.title,
              accessor: element.standard_id.title,
              align: "center",
            });
        });

        Arr.push(item);
      });
      setNewArr(Arr);
      console.log(coulmnsItems);
      setColumns(coulmnsItems);
    } else {
      setNewArr([]);
    }
  };

  useEffect(() => {
    if (userEvaluations) {
      prepareTableRows();
    }
  }, [userEvaluations]);

  if (newArr === []) {
    return null;
  }

  return (
    <Page>
      <Card style={{ padding: 20, position: "relative", width: "100%" }}>
        <FilterForm baseUrl={baseUrl} />

        <DataTable
          setSortByHandeller={() => {}}
          table={{ columns, rows: newArr }}
          pageOptions={[1]}
          item={false}
          isSorted
          isPagination
          entriesPerPage
          showTotalEntries
          noEndBorder
          search
        />
      </Card>
    </Page>
  );
};

export default UserEvaluationReport;
