import { Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { FolderSpecialOutlined, Inventory2Outlined, StoreOutlined } from "@mui/icons-material";
// import stat2 from "../../assets/2.svg";
// import stat3 from "../../assets/3.svg";
import stats from "../../assets/Artboard 5.png";
import SectionContainer from "../SectionContainer";

const roles = [
  {
    entitle: "Distribution Reports",
    artitle: "تقارير التوزيع",
    Icon: FolderSpecialOutlined,
    color: "secondary",
  },
  {
    entitle: "Libraries Reports",
    artitle: "تقارير المكتبات",
    Icon: StoreOutlined,
    color: "secondary",
  },
  {
    entitle: "Financial Reports",
    artitle: "تقارير الحسابات",
    Icon: Inventory2Outlined,
    color: "secondary",
  },
];

const reportsSection = {
  entitle: "Reports and Statistics",
  artitle: "تقارير وإحصائيات",
  enbody:
    "Following up on the workflow from the beginning of purchasing books and placing them in stores, setting price offers, distributing them to libraries or donating them, then inventorying them or returning them accurately in detailed schedules.",
  arbody:
    "متابعة خط سير العمل من اول شراء الكتب وتسكينها في المخازن ووضع عروض أسعار وتوزيعها على المكتبات أو إهداءها ثم جردها او ارتجاعها بشكل دقيق في جداول تفصيلية.",
};

function ReportsAndStats() {
  const { i18n } = useTranslation();

  return (
    <SectionContainer>
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <MDBox
              component="img"
              src={stats}
              alt="Reporting System"
              width="100%"
              sx={{
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                // "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MDTypography color="secondary" variant="h2" mb={2}>
              {reportsSection[`${i18n.language}title`]}
            </MDTypography>
            <MDTypography variant="body1" color="secondary" sx={{ width: "88%", mb: 4 }}>
              {reportsSection[`${i18n.language}body`]}
            </MDTypography>
            {roles.map(({ [`${i18n.language}title`]: title, Icon, color }) => (
              <MDBox display="flex" alignItems="center" key={title} mb={1.3}>
                <Icon fontSize="medium" sx={{ color }} />
                <MDTypography variant="body2" color="secondary" sx={{ mx: 1, fontWeight: "bold" }}>
                  {title}
                </MDTypography>
              </MDBox>
            ))}
          </Grid>
        </Grid>
      </Container>
    </SectionContainer>
  );
}

export default ReportsAndStats;
