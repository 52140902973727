import { useSelector } from "react-redux";

import Works from "../works";

const Tracking = () => {
  const { userInfo } = useSelector((state) => state.auth);

  return <Works userId={userInfo.id} />;
};

export default Tracking;
