import { styled } from "@mui/material";
import MDBox from "components/MDBox";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import TrakibFeatures from "./components/TrakibFeatures";
import Permissions from "./components/trakib-features/Permissions";
import EasyUI from "./components/trakib-features/EasyUI";
import WhyTrakib from "./components/trakib-features/WhyTrakib";
import TrakibClients from "./components/TrakibClients";
import ReportsAndStats from "./components/trakib-features/ReportsAndStats";
import Footer from "./components/Footer";
// import ContactUs from "./components/C";
import DownloadTrakibPortfolio from "./components/DownloadTrakibPortfolio";
import ContactUs from "./components/ContactUs";

import "./Home.css";

const StyledDivider = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  background: theme.palette.secondary.main,
  "& div": {
    background: theme.palette.info.main,
    width: "80%",
    height: 1,
  },
}));

function HomePage() {
  return (
    <MDBox>
      <MDBox bgColor="secondary">
        <Header />
      </MDBox>

      <HeroSection />
      <MDBox sx={{ height: 50 }} />
      <DownloadTrakibPortfolio />
      <TrakibFeatures />
      <Permissions />
      <MDBox my={3} />
      <ReportsAndStats />
      <EasyUI />
      <WhyTrakib />
      <TrakibClients />
      <ContactUs />
      <StyledDivider>
        <div />
      </StyledDivider>
      <Footer />
    </MDBox>
  );
}

export default HomePage;
