import MDBox from "components/MDBox";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import trakibLogoAR from "assets/images/logos/trakib-logo-ar.svg";
import trakibLogoEN from "assets/images/logos/trakib-logo-en.svg";

function AppBarLogo({ sx }) {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <MDBox
      component="img"
      src={i18n.dir() === "rtl" ? trakibLogoAR : trakibLogoEN}
      alt="Trakib"
      width={120}
      sx={{ objectFit: "contain", cursor: "pointer", mr: 1, ...sx }}
      onClick={() => navigate("/")}
    />
  );
}

AppBarLogo.defaultProps = {
  sx: {},
};

AppBarLogo.propTypes = {
  sx: PropTypes.objectOf(PropTypes.any),
};

export default AppBarLogo;
