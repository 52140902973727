import { Grid } from "@mui/material";
import Page from "components/UI/Page";
import GiftItems from "./SubContent/GiftItems";
import Places from "./SubContent/Places";
import Authors from "./SubContent/Authors";
import Publishers from "./SubContent/Publishers";
import Cities from "./SubContent/Cities";
import Countries from "./SubContent/Countries";
import Categories from "./SubContent/Categories";

const BookStoreSettings = () => (
  <Page>
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6}>
        <Places />
      </Grid>
      <Grid item xs={6}>
        <GiftItems />
      </Grid>
      <Grid item xs={6}>
        <Cities />
      </Grid>
      <Grid item xs={6}>
        <Countries />
      </Grid>
      <Grid item xs={6}>
        <Authors />
      </Grid>
      <Grid item xs={6}>
        <Publishers />
      </Grid>
      <Grid item xs={6}>
        <Categories />
      </Grid>
    </Grid>
  </Page>
);

export default BookStoreSettings;
