import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import MultipleFileUpload from "./MultipleFileUpload";

function ImportDB({ payload }) {
  return (
    <Grid container spacing={2}>
      <MultipleFileUpload payload={payload} />
    </Grid>
  );
}

ImportDB.defaultProps = {
  payload: null,
};

ImportDB.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
};

export default ImportDB;
