import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MDEditor from "components/MDEditor";

// const { TextField } = require("@mui/material");

const EditorControl = ({
  value,
  name,
  setFieldValue,
  setFieldTouched,
  id,
  title,
  onChange,
  disable,
}) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (text) => {
    setInputValue(text);
    setFieldValue(name, text);
    setFieldTouched(name, text);
    onChange(name, text);
  };

  return (
    <MDEditor
      variant="outlined"
      disabled={disable}
      label={title}
      style={{ direction: "ltr" }}
      modules={{
        toolbar: [
          [{ header: "1" }, { header: "2" }, { font: [] }],
          [{ size: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
          ["link", "image", "video"],
          ["clean"],
          [{ direction: "rtl" }],
        ],
      }}
      fullWidth
      id={id}
      name={name}
      value={inputValue}
      onChange={handleChange}
    />
  );
};

EditorControl.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.node,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  disable: PropTypes.bool,
};

EditorControl.defaultProps = {
  value: null,
  disable: false,
};

export default EditorControl;
