import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import DataForm from "components/UI/form/DataForm";

import { addComplexDataAction, fetchDataAction, reset, setAlert } from "store/features/coreSlice";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import dataVal from "../../dataVal.json";

const PageForm = ({ updateHandlerVal, pageNum, url }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { formjobtitles, formusers, limit } = useSelector((state) => state.core);

  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);
  const [jobTitleId, setJobTitleId] = useState(null);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "title",
        name: "job_title_id",
        id: "job_title_id",
        label: "job_title_id",
        data: formjobtitles,
        size: 3,
        title: "jobTitle",
      },
      {
        type: "Select",
        optionTitle: "first_name",
        name: "user_id",
        id: "user_id",
        label: "user_id",
        data: formusers,
        size: 3,
        title: "fullName",
      },
      {
        type: "TextField",
        name: "title",
        id: "title",
        label: "title",
        size: 3,
        title: "title",
      },
      {
        type: "chk",
        name: "to_all",
        id: "to_all",
        label: "to_all",
        size: 3,
        title: "toAll",
      },
      {
        type: "TextArea",
        name: "details",
        id: "details",
        label: "details",
        size: 12,
        title: "details",
      },
      // {
      //   type: "chk",
      //   name: "show",
      //   id: "show",
      //   label: "show",
      //   size: 4,
      //   title: "showSender",
      // },
    ]);
  };

  const resetForm = () => {
    setJobTitleId(null);
    orgData();
  };

  const setFieldSelectedValue = (name, values) => {
    if (name === "job_title_id") {
      setJobTitleId(values.id);

      dispatch(
        fetchDataAction({
          url: `users?fields=id,first_name,job_titles.job_title_id.id&filter[job_titles.job_title_id.id]=${values.id}`,
          varName: "formusers",
        })
      );
    }
  };

  const onChangeSelectInputs = (name, text) => {
    let urlVal;
    if (name === "job_title_id")
      dispatch(
        fetchDataAction({
          url: `items/job_title?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "formjobtitles",
        })
      );

    if (name === "user_id") {
      urlVal = `users?fields=id,first_name,job_titles.job_title_id.id&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (jobTitleId) urlVal += `&filter[job_titles.job_title_id.id]=${jobTitleId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formusers",
        })
      );
    }
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      if (element === "users")
        dispatch(
          fetchDataAction({
            url: `${element}?fields=id,first_name&limit=${limit}`,
            varName: `form${element}`,
          })
        );
      else if (element === "jobtitles")
        dispatch(
          fetchDataAction({
            url: `items/job_title?fields=id,title&limit=${limit}`,
            varName: `form${element}`,
          })
        );
      else
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,title&limit=${limit}`,
            varName: `form${element}`,
          })
        );
    });
  };

  const onUpdateHandler = (name, values, copy) => {
    if (values.job_title_id) {
      setFieldSelectedValue("job_title_id", values.job_title_id);
      onChangeSelectInputs("job_title_id", values.job_title_id.title);
    } else if (values.user_id) {
      onChangeSelectInputs("user_id", values.user_id.first_name);
    } else {
      getData(dataVal.oterData);
    }

    setInitialValues({
      id: copy ? null : values.id,
      user_id: values.user_id,
      job_title_id: values.job_title_id,
      details: values.details,
      to_all: values.to_all,
      show: values.show,
      title: values.title,
    });
  };

  const setPayload = (values) => {
    if (!values.job_title_id && !values.user_id && !values.to_all) {
      dispatch(
        setAlert({
          status: "error",
          content: t("toWho"),
        })
      );
    } else {
      const req = {
        id: values.id,
        file: null,
        origin: {
          data: {
            id: values.id,
            user_id: values.user_id ? values.user_id.id : null,
            job_title_id: values.job_title_id ? values.job_title_id.id : null,
            details: values.details,
            to_all: values.to_all,
            show: values.show,
            title: values.title,
          },
          table: "notifications",
        },
        getInfo: {
          url: `${url}&meta=*&limit=${limit}&page=${pageNum}`,
          varName: "notification",
        },
      };
      setInitialValues(values);

      dispatch(addComplexDataAction(req));
      resetForm();
    }
  };

  useEffect(() => {
    if (updateHandlerVal) {
      onUpdateHandler(updateHandlerVal.name, updateHandlerVal.value, updateHandlerVal.isCopy);
    } else {
      getData(dataVal.oterData);
    }
    return () => {
      resetForm();
      dispatch(reset({ alias: "form", data: dataVal.oterData }));
    };
  }, []);

  useEffect(() => {
    if (formjobtitles && formusers) orgData();
  }, [formjobtitles, formusers]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      yupObj={{
        title: yup.string("Enter your value").required("value is required").nullable(),
        details: yup.string("Enter your value").required("value is required").nullable(),
      }}
      resetData={resetForm}
      name="notifications"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeSelectInputs}
      defaultInitialValues={dataVal.initialValues}
    />
  );
};

PageForm.propTypes = {
  updateHandlerVal: PropTypes.objectOf(PropTypes.any),
  pageNum: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

PageForm.defaultProps = {
  updateHandlerVal: null,
};
export default PageForm;
