import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import DataForm from "components/UI/form/DataForm";

import { addComplexDataAction, fetchDataAction, reset } from "store/features/coreSlice";
import PropTypes from "prop-types";
import dataVal from "../../dataVal.json";

const PageForm = ({ updateHandlerVal, pageNum, url }) => {
  const dispatch = useDispatch();

  const { formjobtitles, formstandards, formusersjobtitles, limit } = useSelector(
    (state) => state.core
  );

  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);
  const [jobTitleId, setJobTitleId] = useState(null);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "title",
        name: "job_title_id",
        id: "job_title_id",
        label: "job_title_id",
        data: formjobtitles,
        size: 3,
        title: "jobTitle",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "standard_id",
        id: "standard_id",
        label: "standard_id",
        data: formstandards,
        size: 3,
        title: "standard",
      },
      {
        type: "Select",
        optionTitle: "user_id",
        oterOptionTitle: "first_name",
        secondOptionTitle: "job_title_id",
        secondOtherOptionTitle: "title",
        name: "users_jobtitles",
        id: "users_jobtitles",
        label: "users_jobtitles",
        data: formusersjobtitles,
        size: 3,
        title: "name",
      },
      {
        type: "TextField",
        name: "value",
        id: "value",
        label: "value",
        size: 3,
        title: "value",
      },
    ]);
  };

  const resetForm = () => {
    setJobTitleId(null);
    orgData();
  };

  const setFieldSelectedValue = (name, values) => {
    if (name === "job_title_id") {
      setJobTitleId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/users_job_title?fields=id,user_id.id,user_id.first_name,job_title_id.id,job_title_id.title&filter[job_title_id.id]=${values.id}`,
          varName: "formusersjobtitles",
        })
      );

      dispatch(
        fetchDataAction({
          url: `items/standards?fields=id,title,jobtitles.job_title_id.id&filter[jobtitles.job_title_id.id]=${values.id}`,
          varName: "formstandards",
        })
      );
    }
  };

  const onChangeSelectInputs = (name, text, jTitle) => {
    let urlVal;
    if (name === "job_title_id")
      dispatch(
        fetchDataAction({
          url: `items/job_title?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "formjobtitles",
        })
      );

    if (name === "standard_id") {
      urlVal = `items/standards?fields=id,title,jobtitles.job_title_id.id&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (jobTitleId) urlVal += `&filter[jobtitles.job_title_id.id]=${jobTitleId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formstandards",
        })
      );
    }

    if (name === "users_jobtitles") {
      urlVal = `items/users_job_title?fields=id,user_id.id,user_id.first_name,job_title_id.id,job_title_id.title&filter[user_id.first_name][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (jobTitleId || jTitle) urlVal += `&filter[job_title_id.id]=${jobTitleId || jTitle}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formusersjobtitles",
        })
      );
    }
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      if (element === "jobtitles")
        dispatch(
          fetchDataAction({
            url: `items/job_title?fields=id,title&limit=${limit}`,
            varName: `form${element}`,
          })
        );
      else if (element === "usersjobtitles")
        dispatch(
          fetchDataAction({
            url: `items/users_job_title?fields=id,user_id.id,user_id.first_name,job_title_id.id,job_title_id.title&limit=${limit}`,
            varName: `form${element}`,
          })
        );
      else
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,title&limit=${limit}`,
            varName: `form${element}`,
          })
        );
    });
  };

  const onUpdateHandler = (name, values, copy) => {
    // setFieldSelectedValue("job_title_id", values.users_jobtitles.job_title_id);
    onChangeSelectInputs("job_title_id", values.users_jobtitles.job_title_id.title);
    setJobTitleId(values.users_jobtitles.job_title_id.id);
    onChangeSelectInputs("standard_id", values.standard_id.title);
    onChangeSelectInputs(
      "users_jobtitles",
      values.users_jobtitles.user_id.first_name,
      values.users_jobtitles.job_title_id.id
    );
    setInitialValues({
      id: copy ? null : values.id,
      value: values.value,
      job_title_id: values.users_jobtitles.job_title_id,
      standard_id: values.standard_id,
      users_jobtitles: values.users_jobtitles,
    });
  };

  const setPayload = (values) => {
    const req = {
      id: values.id,
      file: null,
      origin: {
        data: {
          id: values.id,
          standard_id: values.standard_id.id,
          users_jobtitles: values.users_jobtitles.id,
          value: values.value,
        },
        table: "users_standards",
      },
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "usersStandards",
      },
    };
    setInitialValues(values);

    dispatch(addComplexDataAction(req));
  };

  useEffect(() => {
    if (updateHandlerVal) {
      onUpdateHandler(updateHandlerVal.name, updateHandlerVal.value, updateHandlerVal.isCopy);
    } else {
      getData(dataVal.oterData);
    }
    return () => {
      resetForm();
      dispatch(reset({ alias: "form", data: dataVal.oterData }));
    };
  }, []);

  useEffect(() => {
    if (formjobtitles && formstandards && formusersjobtitles) orgData();
  }, [formjobtitles, formstandards, formusersjobtitles]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      yupObj={{
        users_jobtitles: yup.mixed("Enter the user").required("required").nullable(),
        job_title_id: yup.mixed("Enter the jobtitle").required("jobtitle is required").nullable(),
        value: yup
          .number("must be number")
          .typeError("must be a number")
          .positive("must be greater than zero")
          .required("value is required")
          .nullable(),
      }}
      resetData={resetForm}
      name="research"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeSelectInputs}
      defaultInitialValues={dataVal.initialValues}
    />
  );
};

PageForm.propTypes = {
  updateHandlerVal: PropTypes.objectOf(PropTypes.any),
  pageNum: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

PageForm.defaultProps = {
  updateHandlerVal: null,
};
export default PageForm;
