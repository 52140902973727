import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import UpdateRecord from "helpers/compare";
import DataForm from "components/UI/form/DataForm";

import { addComplexDataAction, fetchDataAction, reset, setAlert } from "store/features/coreSlice";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import dataVal from "../../dataVal.json";

const PageForm = ({ resetData, updateHandlerVal, pageNum, userId, workTypeId, url }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    formprograms,
    formdepartments,
    formresearches,
    formunits,
    formtasks,
    formjobtitle,
    formcojobtitle,
    formusers,
    formcousers,
    limit,
    worksImgList,
    worksInTheSameDueDate,
  } = useSelector((state) => state.core);

  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);
  const [isNew, setIsNew] = useState(false);
  const [debt, setDebt] = useState(null);
  const [estimatedValue, setEstimatedValue] = useState(null);
  const [achievementPercentage, setAchievementPercentage] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [formUserId, setFormUserId] = useState(null);
  const [unitId, setUnitId] = useState(null);
  const [programId, setProgramId] = useState(null);
  const [jobtitleId, setJobtitleId] = useState(null);
  const [cojobtitleId, setCoJobtitleId] = useState(null);
  const [filesData, setFilesData] = useState([]);
  const [view, setView] = useState(true);
  const [noUpdate, setNoUpdate] = useState(false);
  const [coordinator, setCoordinator] = useState(false);
  const [workStatus, setWorkStatus] = useState(null);
  const status = [
    { id: "in_progress", title: t("inProgress"), num: 1 },
    { id: "in_review", title: t("inReview"), num: 2 },
    { id: "in_approval", title: t("inApproval"), num: 3 },
    { id: "in_paid", title: t("inPaid"), num: 4 },
    { id: "done", title: t("done"), num: 5 },
    { id: "canceled", title: t("canceled"), num: 6 },
  ];

  const rePrepareData = (items, values) => {
    console.log("rePrepareData", values);
    let newData = [];
    newData = items.map((x) => (x.num <= values.num ? { ...x, condition: true } : x));
    newData = items.map((x) =>
      x.num > values.num && x.condition ? { ...x, condition: false } : x
    );
    setData(newData.map((x) => (x.num <= values.num ? { ...x, condition: true } : x)));
  };

  const orgData = () => {
    const dataArr = [
      {
        type: "chk",
        name: "is_repeat",
        id: "is_repeat",
        label: "is_repeat",
        size: 6,
        title: "repeat",
        disable: coordinator,
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "work_status",
        id: "work_status",
        label: "work_Status",
        data:
          updateHandlerVal?.value.work_status === "done"
            ? status
            : status.filter((item) => item.id !== "done"),
        disable: noUpdate,
        size: 6,
        title: "status",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "program_id",
        id: "program_id",
        label: "program_id",
        data: formprograms,
        size: 3,
        title: "program",
        disable: coordinator,
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "research",
        id: "research",
        label: "research",
        data: formresearches,
        size: 3,
        title: "researches",
        disable: coordinator,
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "unit_id",
        id: "unit_id",
        label: "unit_id",
        data: formunits,
        size: 3,
        title: "unit",
        disable: coordinator,
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "department_id",
        id: "department_id",
        label: "department_id",
        data: formdepartments,
        size: 3,
        title: "department",
        disable: coordinator,
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "co_job_title_id",
        id: "co_job_title_id",
        label: "co_job_title_id",
        data: formcojobtitle,
        size: 3,
        title: "coJobTitleId",
        disable: coordinator,
      },
      {
        type: "Select",
        optionTitle: "first_name",
        name: "co_user_id",
        id: "co_user_id",
        label: "co_user_id",
        data: formcousers,
        size: 3,
        title: "fullName",
        disable: coordinator,
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "job_title_id",
        id: "job_title_id",
        label: "job_title_id",
        data: formjobtitle,
        disable: noUpdate || coordinator,
        size: 3,
        title: "jobTitle",
      },
      {
        type: "Select",
        optionTitle: "first_name",
        name: "user_id",
        id: "user_id",
        label: "user_id",
        disable: noUpdate || coordinator,
        data: formusers,
        size: 3,
        title: "fullName",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "task_id",
        id: "task_id",
        label: "task_id",
        data: formtasks,
        size: 3,
        title: "task",
        disable: coordinator,
      },
      {
        type: "TextField",
        name: "estimated_value",
        id: "estimated_value",
        label: "estimated_value",
        size: 3,
        title: "estimatedValue",
        disable: noUpdate || coordinator,
      },
      {
        type: "chk",
        name: "is_dolar",
        id: "is_dolar",
        label: "is_dolar",
        size: 4,
        title: "$",
        disable: noUpdate || coordinator,
      },
      {
        type: "TextField",
        name: "achievement_percentage",
        id: "achievement_percentage",
        label: "achievement_percentage",
        size: 3,
        disable: noUpdate || coordinator,
        title: "achievementPercentage",
        condition: false,
        num: 4,
      },
      {
        type: "TextField",
        name: "other_achievement_percentage",
        id: "other_achievement_percentage",
        label: "other_achievement_percentage",
        disable: !userId || coordinator,
        size: 3,
        title: "otherAchievementPercentage",
        condition: false,
        num: 3,
      },
      {
        type: "TextField",
        name: "evaluation_percentage",
        id: "evaluation_percentage",
        label: "evaluation_percentage",
        size: 3,
        title: "evaluationPercentage",
        disable: coordinator,
        condition: false,
        num: 3,
      },
      {
        type: "TextField",
        name: "debt",
        id: "debt",
        label: "debt",
        size: 3,
        disable: !!userId || noUpdate || coordinator,
        title: "wDebt",
        condition: false,
        num: 4,
      },
      {
        type: "TextField",
        name: "pages",
        id: "pages",
        label: "pages",
        size: 3,
        title: "pages",
        condition: false,
        num: 2,
      },
      {
        type: "Date",
        name: "commissioning_date",
        id: "commissioning_date",
        label: "commissioning_date",
        disable: coordinator,
        size: 3,
        title: "commissioningDate",
      },
      {
        type: "Date",
        name: "target_date",
        id: "target_date",
        label: "target_date",
        disable: coordinator,
        size: 3,
        title: "targetDate",
      },
      {
        type: "Date",
        name: "completion_date",
        id: "completion_date",
        label: "completion_date",
        size: 3,
        disable: noUpdate,
        title: "completionDate",
        condition: false,
        num: 2,
      },
      {
        type: "Date",
        name: "review_date",
        id: "review_date",
        label: "review_date",
        disable: coordinator,
        size: 3,
        title: "reviewDate",
        condition: false,
        num: 3,
      },
      {
        type: "Date",
        name: "accreditation_date",
        disable: coordinator,
        id: "accreditation_date",
        label: "accreditation_date",
        size: 3,
        title: "accreditationDate",
        condition: false,
        num: 4,
      },
      {
        type: "TextArea",
        name: "notes",
        id: "notes",
        label: "notes",
        maxRows: 4,
        minRows: 4,
        size: 6,
        disable: coordinator,
        title: "notes",
      },
      {
        type: "TextArea",
        name: "other_notes",
        disable: !userId || coordinator,
        id: "other_notes",
        label: "other_notes",
        maxRows: 4,
        minRows: 4,
        size: 6,
        title: "otherNotes",
      },
      {
        type: "MImg",
        name: "files",
        id: "files",
        label: "files",
        size: 12,
        title: "image",
      },
    ];

    if (userId) {
      delete dataArr[13];
      delete dataArr[23];
    }

    if (workStatus) rePrepareData(dataArr, workStatus);
    else if (updateHandlerVal)
      rePrepareData(
        dataArr,
        status.filter((item) => item.id === updateHandlerVal.value.work_status)[0]
      );
    else setData(dataArr);
  };

  const resetForm = () => {
    setDebt(null);
    setAchievementPercentage(null);
    setEstimatedValue(null);
    setDueDate(null);
    setFormUserId(null);
    setFilesData([]);
    setView(true);
    setCoordinator(false);
    setNoUpdate(false);
    orgData();
    resetData();
  };

  const setFieldSelectedValue = (name, values) => {
    if (name === "job_title_id") {
      setJobtitleId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/tasks?fields=id,title,job_titles.job_title_id.id&filter[job_titles.job_title_id.id]=${values.id}`,
          varName: "formtasks",
        })
      );
      dispatch(
        fetchDataAction({
          url: `users?fields=id,first_name,job_titles.job_title_id.id&filter[job_titles.job_title_id.id]=${values.id}`,
          varName: "formusers",
        })
      );
      setInitialValues({ task_id: null, user_id: null, ...initialValues });
    } else if (name === "unit_id") {
      setUnitId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/departments?fields=id,title,unit_id&filter[unit_id]=${values.id}`,
          varName: "formdepartments",
        })
      );
      setInitialValues({ ...initialValues, department_id: null });
    } else if (name === "program_id") {
      setProgramId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/researches?fields=id,title,program_id&filter[program_id]=${values.id}`,
          varName: "formresearches",
        })
      );
    } else if (name === "co_job_title_id") {
      setCoJobtitleId(values.id);
      dispatch(
        fetchDataAction({
          url: `users?fields=id,first_name,job_titles.job_title_id.id&filter[job_titles.job_title_id.id]=${values.id}`,
          varName: "formcousers",
        })
      );
    } else if (name === "user_id") {
      setFormUserId(values.id);
    } else if (name === "work_status") {
      setWorkStatus(values);
      rePrepareData(data, values);
    }
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      if (element === "users") {
        dispatch(
          fetchDataAction({
            url: `${element}?fields=id,first_name&limit=${limit}`,
            varName: `form${element}`,
          })
        );
      } else if (element === "cousers") {
        dispatch(
          fetchDataAction({
            url: `users?fields=id,first_name&limit=${limit}`,
            varName: `form${element}`,
          })
        );
      } else if (element === "jobtitle") {
        dispatch(
          fetchDataAction({
            url: `items/job_title?fields=id,title&limit=${limit}`,
            varName: `formjobtitle`,
          })
        );
      } else if (element === "cojobtitle") {
        dispatch(
          fetchDataAction({
            url: `items/job_title?fields=id,title&limit=${limit}`,
            varName: `formcojobtitle`,
          })
        );
      } else {
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,title&limit=${limit}`,
            varName: `form${element}`,
          })
        );
      }
    });
  };

  const onChangeSelectInputs = (name, text) => {
    let urlVal;
    if (name === "estimated_value") {
      setEstimatedValue(text);
      setIsNew(true);
    } else if (name === "achievement_percentage") {
      setAchievementPercentage(text);
      setIsNew(true);
    } else if (name === "completion_date") {
      setDueDate(text);
    } else if (name === "program_id") {
      dispatch(
        fetchDataAction({
          url: `items/programs?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "formprograms",
        })
      );
    } else if (name === "research") {
      urlVal = `items/researches?fields=id,title&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (programId) urlVal += `&filter[program_id]=${programId}`;

      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formresearches",
        })
      );
    } else if (name === "unit_id") {
      urlVal = `items/units?fields=id,title&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formunits",
        })
      );
    } else if (name === "department_id") {
      urlVal = `items/departments?fields=id,title&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (unitId) urlVal += `&filter[unit_id]=${unitId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formdepartments",
        })
      );
    } else if (name === "job_title_id") {
      dispatch(
        fetchDataAction({
          url: `items/job_title?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "formjobtitle",
        })
      );
    } else if (name === "co_job_title_id") {
      dispatch(
        fetchDataAction({
          url: `items/job_title?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "formcojobtitle",
        })
      );
    } else if (name === "user_id") {
      urlVal = `users?fields=id,first_name,job_titles.job_title_id.id&filter[first_name][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (jobtitleId) urlVal += `&filter[job_titles.job_title_id.id]=${jobtitleId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formusers",
        })
      );
    } else if (name === "co_user_id") {
      urlVal = `users?fields=id,first_name,job_titles.job_title_id.id&filter[first_name][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (cojobtitleId) urlVal += `&filter[job_titles.job_title_id.id]=${cojobtitleId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formcousers",
        })
      );
    } else if (name === "task_id") {
      urlVal = `items/tasks?fields=id,title,job_titles.job_title_id.id&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (jobtitleId) urlVal += `&filter[job_titles.job_title_id.id]=${jobtitleId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formtasks",
        })
      );
    }
  };

  const onUpdateHandler = (name, values, copy) => {
    if (values.work_status === "done" && !copy) setNoUpdate(true);
    if (userId && workTypeId === "user_id") setCoordinator(true);
    if (!copy)
      dispatch(
        fetchDataAction({
          url: `items/all_files?fields=id,collection,file_id.filesize,file_id.id,item_id,file_id,file_id.type,file_id.filename_download&filter[collection]=works&filter[item_id]=${values.id}`,
          varName: "worksImgList",
        })
      );

    onChangeSelectInputs("program_id", values.program_id?.title);
    onChangeSelectInputs("unit_id", values.unit_id?.title);
    onChangeSelectInputs("job_title_id", values.job_title_id.title);
    onChangeSelectInputs("co_job_title_id", values.co_job_title_id.title);
    if (values.program_id) setFieldSelectedValue("program_id", values.program_id);
    if (values.unit_id) setFieldSelectedValue("unit_id", values.unit_id);
    if (values.job_title_id) setFieldSelectedValue("job_title_id", values.job_title_id);
    if (values.co_job_title_id) setFieldSelectedValue("co_job_title_id", values.co_job_title_id);

    setIsNew(false);

    setEstimatedValue(values.estimated_value);
    setAchievementPercentage(values.achievement_percentage);
    setDueDate(values.due_date);
    setFormUserId(values.user_id?.id);
    setInitialValues({
      id: copy ? null : values.id,
      program_id: values.program_id,
      research: values.research,
      unit_id: values.unit_id,
      department_id: values.department_id,
      job_title_id: values.job_title_id,
      user_id: values.user_id,
      co_job_title_id: values.co_job_title_id,
      co_user_id: values.co_user_id,
      task_id: values.task_id,
      work_status: status.filter((item) => item.id === values.work_status)[0],
      estimated_value: values.estimated_value,
      achievement_percentage: values.achievement_percentage,
      other_achievement_percentage: values.other_achievement_percentage,
      evaluation_percentage: values.evaluation_percentage,
      debt: values.debt,
      pages: values.pages,
      notes: values.notes,
      other_notes: values.other_notes,
      commissioning_date: values.commissioning_date,
      target_date: values.target_date,
      completion_date: values.completion_date,
      review_date: values.review_date,
      accreditation_date: values.accreditation_date,
      is_dolar: values.is_dolar,
      is_repeat: values.is_repeat,
      due_date: values.due_date,
    });
  };

  const setPayload = ({ files, ...values }) => {
    if (
      (!updateHandlerVal || updateHandlerVal?.value.work_status !== "done") &&
      worksInTheSameDueDate?.length > 0
    ) {
      dispatch(
        setAlert({
          status: "error",
          content: t("oldPaid"),
        })
      );
    } else {
      if (values.due_date !== dueDate) {
        values.due_date = dueDate;
      }
      const payloadData = {};
      const lastData = values.id ? UpdateRecord(values, initialValues) : values;
      if (initialValues.work_status?.id !== values.work_status?.id || !values.id)
        payloadData.work_status = values.work_status.id;

      delete lastData.work_status;
      Object.keys(lastData).forEach((key) => {
        if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
        else if (values.id || lastData[key]) payloadData[key] = lastData[key];
      });

      const req = {
        id: values.id,
        file: files,
        origin: {
          data: Object.keys(payloadData).length === 0 ? null : payloadData,
          table: "works",
        },
        getInfo: {
          url: `${url}&meta=*&limit=${limit}&page=${pageNum}`,
          varName: "works",
        },
      };
      setInitialValues(values);
      dispatch(addComplexDataAction(req));
      resetForm();
    }
  };

  useEffect(() => {
    if (updateHandlerVal) {
      onUpdateHandler(updateHandlerVal.name, updateHandlerVal.value, updateHandlerVal.isCopy);
    } else {
      getData(dataVal.oterData);
    }
    return () => {
      resetForm();
      dispatch(reset({ alias: "form", data: dataVal.oterData }));
    };
  }, []);

  useEffect(() => {
    setFilesData(worksImgList);
  }, [worksImgList]);

  useEffect(() => {
    if (isNew) {
      setDebt({ name: "debt", val: (+estimatedValue * achievementPercentage) / 100 });
    }
  }, [achievementPercentage, estimatedValue]);

  useEffect(() => {
    if (dueDate) {
      dispatch(
        fetchDataAction({
          url: `items/works?fields=id&filter[due_date]=${dueDate}&filter[work_status]=done&filter[user_id]=${formUserId}`,
          varName: "worksInTheSameDueDate",
        })
      );
    }
  }, [dueDate, formUserId]);

  useEffect(() => {
    if (
      formprograms &&
      formresearches &&
      formunits &&
      formdepartments &&
      formtasks &&
      formjobtitle &&
      formcojobtitle &&
      formcousers &&
      formusers
    )
      orgData();
  }, [
    formprograms,
    formresearches,
    formdepartments,
    formunits,
    formtasks,
    formjobtitle,
    formcojobtitle,
    formusers,
    formcousers,
    debt,
  ]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      message={dueDate ? `${t("dueDate")}: ${dueDate}` : null}
      yupObj={{
        work_status: yup.mixed("required").required("required").nullable(),
        commissioning_date: yup
          .date("invalid date")
          .nullable()
          .when("work_status", {
            is: (x) =>
              x?.id === "in_progress" ||
              x?.id === "in_review" ||
              x?.id === "in_approval" ||
              x?.id === "in_paid" ||
              x?.id === "done",
            then: yup.date("invalid date").required("required").nullable(),
          }),
        target_date: yup
          .date("invalid date")
          .min(yup.ref("commissioning_date"), "Target date can't be before commissioning date")
          .nullable()
          .when("work_status", {
            is: (x) =>
              x?.id === "in_progress" ||
              x?.id === "in_review" ||
              x?.id === "in_approval" ||
              x?.id === "in_paid" ||
              x?.id === "done",
            then: yup.date("invalid date").required("required").nullable(),
          }),
        completion_date: yup
          .date("invalid date")
          .min(yup.ref("commissioning_date"), "Target date can't be before commissioning date")
          .nullable()
          .when("work_status", {
            is: (x) =>
              x?.id === "in_review" ||
              x?.id === "in_approval" ||
              x?.id === "in_paid" ||
              x?.id === "done",
            then: yup.date("invalid date").required("required").nullable(),
          }),
        review_date: yup
          .date("invalid date")
          .min(yup.ref("completion_date"), "Review date can't be before completion date")
          .nullable()
          .when("work_status", {
            is: (x) => x?.id === "in_approval" || x?.id === "in_paid" || x?.id === "done",
            then: yup.date("invalid date").required("required").nullable(),
          }),
        accreditation_date: yup
          .date("invalid date")
          .min(yup.ref("review_date"), "Accreditation date can't be before review date")
          .nullable()
          .when("work_status", {
            is: (x) => x?.id === "in_paid" || x?.id === "done",
            then: yup.date("invalid date").required("required").nullable(),
          }),
        task_id: yup
          .mixed("required")
          .nullable()
          .when("work_status", {
            is: (x) =>
              x?.id === "in_progress" ||
              x?.id === "in_review" ||
              x?.id === "in_approval" ||
              x?.id === "in_paid" ||
              x?.id === "done",
            then: yup.mixed("required").required("required").nullable(),
          }),
        job_title_id: yup
          .mixed("required")
          .nullable()
          .when("work_status", {
            is: (x) =>
              x?.id === "in_progress" ||
              x?.id === "in_review" ||
              x?.id === "in_approval" ||
              x?.id === "in_paid" ||
              x?.id === "done",
            then: yup.mixed("required").required("required").nullable(),
          }),
        co_user_id: yup
          .mixed("required")
          .nullable()
          .when("work_status", {
            is: (x) =>
              x?.id === "in_progress" ||
              x?.id === "in_review" ||
              x?.id === "in_approval" ||
              x?.id === "in_paid" ||
              x?.id === "done",
            then: yup.mixed("required").required("required").nullable(),
          }),
        user_id: yup
          .mixed("required")
          .nullable()
          .when("work_status", {
            is: (x) =>
              x?.id === "in_review" ||
              x?.id === "in_approval" ||
              x?.id === "in_paid" ||
              x?.id === "done",
            then: yup.mixed("required").required("required").nullable(),
          }),
        co_job_title_id: yup
          .mixed("required")
          .nullable()
          .when("work_status", {
            is: (x) =>
              x?.id === "in_progress" ||
              x?.id === "in_review" ||
              x?.id === "in_approval" ||
              x?.id === "in_paid" ||
              x?.id === "done",
            then: yup.mixed("required").required("required").nullable(),
          }),
        estimated_value: yup
          .number("must be number")
          .typeError("must be a number")
          .nullable()
          .when("work_status", {
            is: (x) =>
              x?.id === "in_progress" ||
              x?.id === "in_review" ||
              x?.id === "in_approval" ||
              x?.id === "in_paid" ||
              x?.id === "done",
            then: yup
              .number("must be number")
              .typeError("must be a number")
              .required("required")
              .nullable(),
          }),
        evaluation_percentage: yup
          .number("must be number")
          .typeError("must be a number")
          .nullable()
          .when("work_status", {
            is: (x) => x?.id === "in_approval" || x?.id === "in_paid" || x?.id === "done",
            then: yup
              .number("must be number")
              .typeError("must be a number")
              .required("required")
              .nullable(),
          }),
        achievement_percentage: yup
          .number("must be number")
          .typeError("must be a number")
          .nullable()
          .when("work_status", {
            is: (x) => x?.id === "in_paid" || x?.id === "done",
            then: yup
              .number("must be number")
              .typeError("must be a number")
              .required("required")
              .nullable(),
          }),
        program_id: yup.mixed("required").required("required").nullable(),
        unit_id: yup.mixed("required").required("required").nullable(),
        other_achievement_percentage: yup
          .number("must be number")
          .typeError("must be a number")
          .nullable(),
        pages: yup.number("must be number").typeError("must be a number").nullable(),
        debt: yup.number("must be number").typeError("must be a number").nullable(),
      }}
      view={view}
      resetData={resetForm}
      name="products"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeSelectInputs}
      debt={debt}
      imgList={filesData}
      defaultInitialValues={dataVal.initialValues}
    />
  );
};

PageForm.propTypes = {
  resetData: PropTypes.func.isRequired,
  updateHandlerVal: PropTypes.objectOf(PropTypes.any),
  pageNum: PropTypes.number.isRequired,
  userId: PropTypes.string,
  workTypeId: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

PageForm.defaultProps = {
  updateHandlerVal: null,
  userId: null,
};
export default PageForm;
