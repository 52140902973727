import { styled } from "@mui/material";
import MDTypography from "components/MDTypography";
import moment from "moment";
import React from "react";

function InvoiceMetaVersionA() {
  const StyledSpan = styled("span")(({ theme }) => ({
    background: theme.palette.secondary.main,
    padding: "4px 12px",
    textAlign: "center",
    width: 100,
    display: "inline-block",
  }));

  const StyledToal = styled("span")(({ theme }) => ({
    color: theme.palette.info.main,
    fontSize: 20,
    fontWeight: "bold",
  }));

  return (
    <div>
      <MDTypography variant="h2" mb={3}>
        Invoice
      </MDTypography>
      <MDTypography variant="body2">Invoice Number</MDTypography>
      <MDTypography variant="body1" fontWeight="bold">
        #00001
      </MDTypography>
      <MDTypography variant="body2">Date of Issue</MDTypography>
      <MDTypography variant="body1" fontWeight="bold">
        {moment(new Date()).format("DD/MM/YYYY")}
      </MDTypography>
      <StyledSpan>
        <StyledToal>$5700</StyledToal>
      </StyledSpan>
    </div>
  );
}

export default InvoiceMetaVersionA;
