import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
import Page from "components/UI/Page";
import { Card, Icon } from "@mui/material";
import { useLocation } from "react-router-dom";
import { fetchDataAction, deleteDataAction, setAlert } from "store/features/coreSlice";
import MDButton from "components/MDButton";
import DataModal from "components/UI/DataModal";
import DataTable from "components/UI/Table/DataTable";
import DeleteModal from "components/UI/DeleteModal";
import PagesCount from "helpers/pages-count";
import { useTranslation } from "react-i18next";
import Avatar from "components/UI/Table/avatarWithOutImg";
import MDAvatar from "components/MDAvatar";
import ImportDB from "components/UI/ImportDB";

import PageForm from "./components/PageForm";
import dataVal from "./dataVal.json";
import FilterForm from "./components/FilterForm";

const InsertBook = () => {
  const baseUrl = `items/books?fields=*,proposals.id,buyings.id,translators_id.translator_id.title,translators_id.translator_id.id,translators_id.id,reviewers_id.reviewer_id.title,reviewers_id.reviewer_id.id,reviewers_id.id,editors_id.editor_id.id,editors_id.editor_id.title,editors_id.id,publisher_id.title,publisher_id.id,investigators_id.investigator_id.title,investigators_id.investigator_id.id,investigators_id.id,authors_id.author_id.title,authors_id.author_id.id,authors_id.id,categories_id.category_id.title,categories_id.category_id.id,categories_id.id`;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.auth);
  const { booksMeta, books, limit, simbleLayout, baseURL } = useSelector((state) => state.core);
  const [url, setUrl] = useState(baseUrl);
  const [newArr, setNewArr] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [number, setNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const [route, setRoute] = useState(null);
  const [pageOptions, setPageOption] = useState([]);
  const [formContainer, setFormContainer] = useState(<h1>wait ...</h1>);

  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const setOpenModal = (container) => {
    setFormContainer(container);
    setOpen(true);
  };

  const handleOpen = () =>
    setOpenModal(<PageForm updateHandlerVal={null} pageNum={number} url={url} />);

  const onDeleteHandler = (values) => {
    if (values.buyings.length === 0 && values.proposals.length === 0) {
      setOpenDeleteModal(true);
      setDeleteItemId(values);
    } else {
      // alert("have buyings and proposals");
      dispatch(
        setAlert({
          status: "error",
          content: t("haveBuyingsAndProposals"),
        })
      );
    }
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+booksMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    if (books && books.length !== 0) {
      books.forEach((book) => {
        item = {};
        keys = Object.keys(book);
        keys.forEach((element) => {
          item[element] = book[element];
        });
        item.publisher = book.publisher_id.title;
        if (item.file)
          item.img = (
            <MDAvatar
              src={`${baseURL}assets/${item.file}?fit=cover&width=100&height=200&quality=80`}
              alt={item.title}
              name={item.title}
              size="lg"
              variant="rounded"
            />
          );
        item.title = <Avatar dir="ltr" name={item.title || ""} email={item.publisher} />;

        book.authors_id.forEach((author) => {
          item.authors = item.authors
            ? `${item.authors}, ${author.author_id.title}`
            : author.author_id.title;
        });

        book.categories_id.forEach((category) => {
          item.categories = item.categories
            ? `${item.categories}, ${category.category_id.title}`
            : category.category_id.title;
        });

        book.investigators_id.forEach((investigator) => {
          item.investigators = item.investigators
            ? `${item.investigators}, ${investigator.investigator_id.title}`
            : investigator.investigator_id.title;
        });

        book.translators_id.forEach((translator) => {
          item.translators = item.translators
            ? `${item.translators}, ${translator.translator_id.title}`
            : translator.translator_id.title;
        });

        book.reviewers_id.forEach((reviewer) => {
          item.reviewers = item.reviewers
            ? `${item.reviewers}, ${reviewer.reviewer_id.title}`
            : reviewer.reviewer_id.title;
        });

        book.editors_id.forEach((editor) => {
          item.editors = item.editors
            ? `${item.editors}, ${editor.editor_id.title}`
            : editor.editor_id.title;
        });

        item.action = (
          <>
            {route.update && (
              <MDTypography
                style={{ padding: 20, color: "#3b94ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => {
                  if (simbleLayout) {
                    setOpenModal(
                      <PageForm
                        updateHandlerVal={{ name: "book", value: book }}
                        pageNum={number}
                        url={url}
                      />
                    );
                  }
                }}
              >
                <Icon fontSize="small">edit</Icon>
              </MDTypography>
            )}
            {route.delete && (
              <MDTypography
                style={{ padding: 20, color: "#e9423f" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onDeleteHandler(book)}
              >
                <Icon fontSize="small">delete</Icon>
              </MDTypography>
            )}
            {route.update && (
              <MDTypography
                style={{ padding: 20, color: "#3b00ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => {
                  if (simbleLayout) {
                    setOpenModal(
                      <PageForm
                        updateHandlerVal={{ name: "book", value: book, isCopy: true }}
                        pageNum={number}
                        url={url}
                      />
                    );
                  }
                }}
              >
                <Icon fontSize="small">content_copy</Icon>
              </MDTypography>
            )}
          </>
        );
        Arr.push(item);
      });
      setNewArr(Arr);
    } else {
      setNewArr([]);
    }
  };

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `${url}&meta=*&limit=${limit}&page=${num}`,
          varName: "books",
        })
      );
    }
  };

  const handleConfirmDelete = async () => {
    const arra = [];
    deleteItemId.investigators_id.forEach((element) => {
      arra.push({
        url: "items/books_investigators",
        data: {
          id: element.id,
          status: "deleted",
        },
      });
    });
    deleteItemId.authors_id.forEach((element) => {
      arra.push({
        url: "items/books_authors",
        data: {
          id: element.id,
          status: "deleted",
        },
      });
    });
    deleteItemId.categories_id.forEach((element) => {
      arra.push({
        url: "items/books_categories",
        data: {
          id: element.id,
          status: "deleted",
        },
      });
    });
    deleteItemId.editors_id.forEach((element) => {
      arra.push({
        url: "items/books_editors",
        data: {
          id: element.id,
          status: "deleted",
        },
      });
    });
    deleteItemId.reviewers_id.forEach((element) => {
      arra.push({
        url: "items/books_reviewers",
        data: {
          id: element.id,
          status: "deleted",
        },
      });
    });
    deleteItemId.translators_id.forEach((element) => {
      arra.push({
        url: "items/books_translators",
        data: {
          id: element.id,
          status: "deleted",
        },
      });
    });
    const payload = {
      url: "items/books",
      data: {
        id: deleteItemId.id,
        status: "deleted",
      },
      related: arra,
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${number}`,
        varName: "books",
      },
    };
    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  const setSortByHandeller = (val) => {
    let urlVal = `${url}&meta=*&limit=${limit}&page=${number}`;
    if (val.id === "title") urlVal += `&sort=${val.desc ? "-" : ""}title`;

    dispatch(
      fetchDataAction({
        url: urlVal,
        varName: "books",
      })
    );
  };

  useEffect(() => {
    const routeItem = userInfo.routes.filter(
      (x) =>
        `${x.route_name}` === location.pathname.split("/")[location.pathname.split("/").length - 1]
    )[0];
    setRoute(routeItem);
  }, []);

  useEffect(() => {
    if (books && route) {
      prepareTableRows();
    }
  }, [books, route]);

  // useEffect(() => {
  //   console.log(newArr?.length);
  // }, [newArr]);

  // if (!newArr) {
  //   return null;
  // }

  return (
    <Page>
      <Card style={{ padding: 20, position: "relative" }}>
        <DataModal
          handleClose={() => {
            setOpen(false);
          }}
          open={open}
        >
          {formContainer}
        </DataModal>
        <DeleteModal
          handleClose={handleDeleteModalClose}
          handleConfirmDelete={handleConfirmDelete}
          openDeleteModal={openDeleteModal}
        />
        <FilterForm pageNum={number} baseUrl={baseUrl} setUrl={setUrl} setNumber={setNumber} />
        {simbleLayout && route?.create && (
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleOpen}
            style={{ float: "left" }}
            size="large"
          >
            <Icon>add</Icon>&nbsp; {t("add")}
          </MDButton>
        )}
        <DataTable
          table={{ columns: dataVal.columns, rows: newArr }}
          item={false}
          pageOptions={pageOptions}
          isSorted
          isPagination
          entriesPerPage
          showTotalEntries
          noEndBorder
          search
          setPageNum={setPageNumber}
          setSortByHandeller={setSortByHandeller}
          pageIndex={number - 1}
          allRowsCount={booksMeta ? +booksMeta.filter_count : 0}
        />
        <ImportDB
          payload={{
            url: `custom/importBooks`,
            varName: "importBooks",
            getInfo: {
              url: `${url}&meta=*&limit=${limit}&page=${number}`,
              varName: "books",
            },
          }}
        />
      </Card>
    </Page>
  );
};

export default InsertBook;
