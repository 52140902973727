import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import DataForm from "components/UI/form/DataForm";
import { addComplexDataAction, fetchDataAction, reset, setAlert } from "store/features/coreSlice";
import PropTypes from "prop-types";
import UpdateRecord from "helpers/compare";
import { useTranslation } from "react-i18next";
import dataVal from "../../dataVal.json";

const PageForm = ({ updateHandlerVal, pageNum, url }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { formbooks, formpublishers, limit, formlibraries, formdistributions } = useSelector(
    (state) => state.core
  );
  const { userInfo } = useSelector((state) => state.auth);

  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);
  const [publisherId, setPublisherId] = useState(null);
  const [bookId, setBookId] = useState(null);
  const [libraryId, setLibraryId] = useState(null);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "title",
        name: "publisher_id",
        id: "publisher_id",
        label: "publisher_id",
        data: formpublishers,
        size: 4,
        title: "publisher",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "book_id",
        id: "book_id",
        label: "book_id",
        data: formbooks,
        size: 4,
        title: "book",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "library_id",
        id: "library_id",
        label: "library_id",
        data: formlibraries,
        size: 4,
        title: "libraries",
      },
      {
        type: "Select",
        optionTitle: "distribution_date",
        optionTitle1: "notes",
        name: "distribution_id",
        id: "distribution_id",
        label: "distribution_id",
        data: formdistributions,
        size: 4,
        title: "distribution",
      },
      {
        type: "TextField",
        name: "count",
        id: "count",
        label: "count",
        size: 4,
        title: "count",
      },
      {
        type: "Date",
        name: "return_date",
        id: "return_date",
        label: "return_date",
        size: 4,
        title: "returnDate",
      },
      {
        type: "TextArea",
        name: "notes",
        id: "notes",
        label: "notes",
        maxRows: 4,
        minRows: 4,
        size: 12,
        title: "notes",
      },
    ]);
  };

  const resetForm = () => {
    setPublisherId(null);
    setBookId(null);
    setPublisherId(null);
    setLibraryId(null);
  };

  const setFieldSelectedValue = (name, values) => {
    if (name === "publisher_id") {
      setPublisherId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/books?fields=id,title,publisher_id&filter[publisher_id]=${values.id}`,
          varName: "formbooks",
        })
      );
    }

    let urlVal;
    if (name === "book_id") {
      setBookId(values.id);
      urlVal = `items/distributions?fields=id,count,distribution_date,notes,inventories.count,returns.count&filter[store_id.buying_id.book_id]=${values.id}`;
      if (libraryId) urlVal += `&filter[library_id]=${libraryId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formdistributions",
        })
      );
    } else if (name === "library_id") {
      setLibraryId(values.id);
      urlVal = `items/distributions?fields=id,count,distribution_date,notes,inventories.count,returns.count&filter[library_id]=${values.id}`;
      if (bookId) urlVal += `&filter[store_id.buying_id.book_id]=${bookId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formdistributions",
        })
      );
    }
  };

  const onChangeSelectInputs = (name, text) => {
    if (name === "publisher_id") {
      dispatch(
        fetchDataAction({
          url: `items/publishers?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "formpublishers",
        })
      );
    }

    let urlVal;
    if (name === "book_id") {
      urlVal = `items/books?fields=id,title&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (publisherId) urlVal += `&filter[publisher_id]=${publisherId}`;

      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formbooks",
        })
      );
    }

    if (name === "library_id") {
      urlVal = `items/libraries?fields=id,title&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (!userInfo.all_libraries) urlVal += `&filter[users][user_id][_eq]=${userInfo.id}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formlibraries",
        })
      );
    }

    if (name === "distribution_id") {
      urlVal = `items/distributions?fields=id,count,distribution_date,notes,inventories.count,returns.count&filter[distribution_date][_eq]=${text}&limit=${limit}`;
      if (bookId) urlVal += `&filter[store_id.buying_id.book_id]=${bookId}`;
      if (libraryId) urlVal += `&filter[library_id]=${libraryId}`;

      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formdistributions",
        })
      );
    }
  };

  const getData = (dataNeeded) => {
    let urlVal;
    dataNeeded.forEach((element) => {
      if (element === "proposal")
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,cost,discount_percentage,is_dolar&filter[status]=published&limit=${limit}`,
            varName: `form${element}`,
          })
        );
      else if (element === "libraries") {
        urlVal = `items/libraries?fields=id,title&limit=${limit}`;
        if (!userInfo.all_libraries) urlVal += `&filter[users][user_id][_eq]=${userInfo.id}`;
        dispatch(
          fetchDataAction({
            url: urlVal,
            varName: `form${element}`,
          })
        );
      } else if (element === "distributions")
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,distribution_date,notes,inventories.count,returns.count,returns.id,inventories.id,count,store_id.place_id.title,store_id.place_id.id&limit=${limit}&filter[library_id][users][user_id][_eq]=${userInfo.id}`,
            varName: `form${element}`,
          })
        );
      else
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,title&limit=${limit}`,
            varName: `form${element}`,
          })
        );
    });
  };

  const onUpdateHandler = (name, values, copy) => {
    if (values.distribution_id?.store_id?.buying_id?.book_id?.publisher_id) {
      setFieldSelectedValue(
        "publisher_id",
        values.distribution_id.store_id.buying_id.book_id.publisher_id
      );
      onChangeSelectInputs(
        "publisher_id",
        values.distribution_id.store_id.buying_id.book_id.publisher_id.title
      );
    }

    if (values.store_id?.buying_id?.book_id) {
      onChangeSelectInputs("book_id", values.distribution_id.store_id.buying_id.book_id.title);
    }

    if (values.distribution_id.library_id) {
      onChangeSelectInputs("library_id", values.distribution_id.library_id.title);
    }

    if (values.distribution_id) {
      onChangeSelectInputs("distribution_id", values.distribution_id.distribution_date);
    }

    setInitialValues({
      id: copy ? null : values.id,
      count: values.count,
      distribution_id: values.distribution_id,
      return_date: values.return_date,
      notes: values.notes,
      book_id: values.distribution_id.store_id.buying_id.book_id,
      publisher_id: values.distribution_id.store_id.buying_id.book_id.publisher_id,
      library_id: values.distribution_id.library_id,
    });
  };

  const setPayload = (values) => {
    let count = 0;
    values.distribution_id.inventories.forEach((element) => {
      count += element.count;
    });

    values.distribution_id.returns.forEach((element) => {
      if (element.id === values.id) {
        count -= element.count;
      }
      count += element.count;
    });
    console.log(
      values,
      values.distribution_id.count,
      +values.count,
      count,
      values.distribution_id.count - (+values.count + count)
    );
    if (values.return_date < values.distribution_id.distribution_date) {
      // alert("date problem");
      dispatch(
        setAlert({
          status: "error",
          content: t("dateError"),
        })
      );
    } else if (values.distribution_id.count - (+values.count + count) >= 0) {
      const payloadData = {};
      const lastData = values.id ? UpdateRecord(values, initialValues) : values;

      Object.keys(lastData).forEach((key) => {
        if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
        else if (values.id || lastData[key]) payloadData[key] = lastData[key];
      });

      delete payloadData.book_id;
      delete payloadData.library_id;
      delete payloadData.publisher_id;

      const req = {
        id: values.id,
        file: null,
        origin: {
          data: Object.keys(payloadData).length === 0 ? null : payloadData,
          table: "returns",
        },
        getInfo: {
          url: `${url}&meta=*&limit=${limit}&page=${pageNum}`,
          varName: "returns",
        },
      };
      setInitialValues(values);

      dispatch(addComplexDataAction(req));
    } else
      dispatch(
        setAlert({
          status: "error",
          content: t("countError"),
        })
      );
    // alert("count problem");
  };

  useEffect(() => {
    if (updateHandlerVal) {
      onUpdateHandler(updateHandlerVal.name, updateHandlerVal.value, updateHandlerVal.isCopy);
    } else {
      getData(dataVal.oterData);
    }
    return () => {
      resetForm();
      dispatch(reset({ alias: "form", data: dataVal.oterData }));
    };
  }, []);

  useEffect(() => {
    if (formbooks && formpublishers && formlibraries && formdistributions) {
      orgData();
    }
  }, [formbooks, formpublishers, formlibraries, formdistributions]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      yupObj={{
        distribution_id: yup.mixed("required").required("required").nullable(),
        return_date: yup.string("required").required("required").nullable(),
        count: yup
          .number("must be number")
          .required("required")
          .typeError("must be a number")
          .positive("must be greater than zero")
          .nullable(),
      }}
      resetData={resetForm}
      name="returns"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeSelectInputs}
      defaultInitialValues={dataVal.initialValues}
    />
  );
};

PageForm.propTypes = {
  updateHandlerVal: PropTypes.objectOf(PropTypes.any),
  pageNum: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

PageForm.defaultProps = {
  updateHandlerVal: null,
};
export default PageForm;
