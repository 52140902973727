import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import InstagramIcon from "@mui/icons-material/Instagram";

// Became Possible components
import MDBox from "components/MDBox";

// Became Possible example components
import ProfileInfoCard from "components/UI/Cards/InfoCards/ProfileInfoCard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import Invoices from "layouts/billing/components/Invoices";
// import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { getUserInfoAction } from "store/features/authSlice";
// Overview page components
// import OrdersOverview from "components/UI/OrdersOverview";
import Page from "components/UI/Page";
// import ProfilesList from "examples/Lists/ProfilesListNotification";
// import { fetchDataAction } from "store/features/coreSlice";
import Header from "./components/Header";
// import profilesListData from "./data/profilesListData";
import BasicInfo from "./components/BasicInfo";

function Overview() {
  const { userInfo, userInfoDetail } = useSelector((state) => state.auth);
  const coreRed = useSelector((state) => state.core);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [personalInfo, setPersonalInfo] = useState();
  const [educationInfo, setEducationInfo] = useState();
  const [jobTitleInfo, setJobTitleInfo] = useState([]);
  const [specialization, setSpecialization] = useState("");

  useEffect(() => {
    dispatch(getUserInfoAction(userInfo.id));
  }, []);

  useEffect(() => {
    if (userInfoDetail) {
      let r = "";
      userInfoDetail?.specializations.forEach((x) => {
        if (r) r = `${r} - ${x.specialization_id.title}`;
        else r = x.specialization_id.title;
      });
      setSpecialization(r);
      const personalInfoData = {};
      personalInfoData[t("name")] = `${userInfoDetail.first_name}`;
      // personalInfoData[t("forgnName")] = `${userInfoDetail.last_name} `;
      personalInfoData[""] = `${userInfoDetail.last_name} `;
      personalInfoData[t("phone")] = userInfoDetail.phone;
      personalInfoData[t("email")] = userInfoDetail.email;
      personalInfoData[t("country")] = userInfoDetail.country_id?.title;
      personalInfoData[t("residence")] = userInfoDetail.residence?.title;
      setPersonalInfo(personalInfoData);

      const educationInfoData = {};
      educationInfoData[t("univarsity")] = userInfoDetail.university_id?.title;
      let sTitle = "";
      userInfoDetail.specializations.forEach((f) => {
        sTitle = sTitle
          ? `${sTitle} - ${f.specialization_id?.title}`
          : `${f.specialization_id?.title}`;
      });

      educationInfoData[t("specializations")] = sTitle;
      educationInfoData[t("degree")] = `${userInfoDetail.degree_id?.title}`;
      setEducationInfo(educationInfoData);

      const jobTitleInfoData = [];
      let jobTitleInfoDataItem;
      userInfoDetail.job_titles.forEach((element, index) => {
        jobTitleInfoDataItem = {};
        jobTitleInfoDataItem[t("id")] = `${index + 1}`;
        jobTitleInfoDataItem[t("jobTitle")] = element.job_title_id.title;
        jobTitleInfoDataItem[t("jobDescription")] = element.job_title_id.description;
        jobTitleInfoData.push(jobTitleInfoDataItem);
      });
      setJobTitleInfo(jobTitleInfoData);
    }
  }, [userInfoDetail]);

  if (!userInfoDetail || !personalInfo) return null;

  return (
    <Page>
      <Grid item xs={12}>
        <MDBox mb={2} />
        <Header
          name={personalInfo[t("name")]}
          jobTitle={`${userInfoDetail.degree_id?.title} / ${specialization}`}
          img={
            userInfoDetail.avatar
              ? `${coreRed.baseURL}assets/${userInfoDetail.avatar}?fit=cover&width=100&height=200&quality=80`
              : null
          }
          id={userInfoDetail.id}
        >
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} xl={3} sx={{ display: "flex" }}>
                <ProfileInfoCard
                  title={t("personalInformation")}
                  description={userInfoDetail.detail}
                  info={personalInfo}
                  action={{ route: "", tooltip: "Edit Profile" }}
                  shadow={false}
                  edit={false}
                />
              </Grid>
              {educationInfo && (
                <Grid item xs={12} md={6} xl={3} sx={{ display: "flex" }}>
                  <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                  <ProfileInfoCard
                    title={t("educationInformation")}
                    description=""
                    info={educationInfo}
                    action={{ route: "", tooltip: "Edit Profile" }}
                    shadow={false}
                    edit={false}
                  />
                  <Divider orientation="vertical" sx={{ mx: 0 }} />
                </Grid>
              )}
              {jobTitleInfo.length > 0 && (
                <Grid container item spacing={1} md={6} xl={6} sx={{ display: "flex" }}>
                  {jobTitleInfo.map((jobTitle, index) => (
                    <Grid item xs={12} key={jobTitle[t("id")]}>
                      <ProfileInfoCard
                        title={index === 0 ? t("jobtitleInformation") : ""}
                        description=""
                        info={jobTitle}
                        action={{ route: "", tooltip: "Edit Profile" }}
                        shadow={false}
                        edit={false}
                      />
                      <Divider orientation="horizontal" sx={{ mt: -2, ml: 1 }} />
                    </Grid>
                  ))}
                </Grid>
              )}
              {/* <Grid item xs={12} lg={4}>
              {usersLogsDetail && <OrdersOverview title={t("activity")} data={usersLogsDetail} />}
            </Grid> */}
            </Grid>
          </MDBox>
          <MDBox mt={5} mb={3}>
            <BasicInfo />
          </MDBox>
        </Header>
      </Grid>
    </Page>
  );
}

export default Overview;
