import { IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";

function DrawerLinks({ anchorElNav, handleDrawerToggle, handleCloseNavMenu, pages }) {
  const { t } = useTranslation();

  return (
    <MDBox sx={{ display: { xs: "flex", md: "none" } }}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleDrawerToggle}
        color="white"
        edge="start"
      >
        <MenuIcon />
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        {pages.map(({ title, href }) => (
          <MenuItem key={Math.random().toString()} onClick={handleCloseNavMenu} href={href}>
            <MDTypography textAlign="center">{t(title)}</MDTypography>
          </MenuItem>
        ))}
      </Menu>
    </MDBox>
  );
}

DrawerLinks.defaultProps = {
  anchorElNav: null,
};

DrawerLinks.propTypes = {
  anchorElNav: PropTypes.element,
  handleDrawerToggle: PropTypes.func.isRequired,
  handleCloseNavMenu: PropTypes.func.isRequired,
  pages: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default DrawerLinks;
