import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MDInput from "components/MDInput";

// const { TextField } = require("@mui/material");

const TextFieldControl = ({
  name,
  setFieldValue,
  setFieldTouched,
  id,
  title,
  onChange,
  disable,
  value,
  debt,
}) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
    if (debt) setFieldValue(name, value);
  }, [value]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
    setFieldValue(name, event.target.value);
    setFieldTouched(name, event.target.value);
    onChange(name, event.target.value);
  };

  return (
    <MDInput
      variant="outlined"
      disabled={disable}
      label={title}
      fullWidth
      id={id}
      name={name}
      value={inputValue || ""}
      onChange={handleChange}
    />
  );
};

TextFieldControl.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.node,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  disable: PropTypes.bool,
  debt: PropTypes.bool,
};

TextFieldControl.defaultProps = {
  value: null,
  disable: false,
  debt: false,
};

export default TextFieldControl;
