import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./flip-card.css";

function FlipCard({ features }) {
  const { i18n } = useTranslation();

  return (
    <MDBox className="flip-card-container">
      <Grid container spacing={2} sx={{ alignItems: "center", justifyContent: "center" }}>
        {features.map(
          ({ [`${i18n.language}title`]: title, Icon, [`${i18n.language}body`]: body }) => (
            <Grid item xs={12} sm={6} lg={3} key={Math.random().toString()}>
              <div className="flip-card">
                <div className="face face1">
                  <div className="content">
                    <Icon color="info" className="icon" sx={{ width: 60, height: 60 }} />
                    <MDTypography variant="h5" color="white" className="title" noWrap mb={1}>
                      {title}
                    </MDTypography>
                  </div>
                </div>
                <div className="face face2">
                  <div className="content">
                    <MDTypography variant="body2" color="white" fontWeight="bold">
                      {body}
                    </MDTypography>
                  </div>
                </div>
              </div>
            </Grid>
          )
        )}
      </Grid>
    </MDBox>
  );
}

FlipCard.propTypes = {
  features: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

export default FlipCard;
