import { Container } from "@mui/material";
import MDBox from "components/MDBox";
import diwanLogo from "assets/images/logos/partenars/DarDiwan.png";
import rawasekhLogo from "assets/images/logos/partenars/Rawasekh.png";

function TrakibClients() {
  // const isXs = useMediaQuery("(max-width:359px)");

  return (
    <MDBox>
      <Container
        sx={
          {
            // position: "relative",
            // height: isXs ? 150 : 70,
          }
        }
      >
        <MDBox
          textAlign="center"
          sx={{
            // border: "1px solid blue",
            // position: "absolute",
            // top: -100,
            // left: 0,
            // width: "100%",
            // mb: 13,
            // height: "100%",
            // display: 'flex',
            pb: 8,
          }}
        >
          <MDBox
            component="img"
            src={diwanLogo}
            alt="Dar Diwan"
            width={100}
            height={100}
            sx={{ objectFit: "contain", mx: 5 }}
          />
          <MDBox
            component="img"
            src={rawasekhLogo}
            alt="Rawasekh"
            width={100}
            height={100}
            sx={{ objectFit: "contain", mx: 5 }}
          />
        </MDBox>
      </Container>
    </MDBox>
  );
}

export default TrakibClients;
