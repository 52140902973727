import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useSelector } from "react-redux";

function InvoideSenderVersionA() {
  const { settingsItem, baseURL } = useSelector((state) => state.core);

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <MDTypography>{settingsItem.title || "undefined name"}</MDTypography>
        <MDBox display="flex">
          <MDBox>
            <MDTypography variant="body2">st address</MDTypography>
            <MDTypography variant="body2">city, zip code</MDTypography>
            <MDTypography variant="body2">country</MDTypography>
          </MDBox>
          <MDBox mx={8}>
            <MDTypography variant="body2">555-555-5555</MDTypography>
            <MDTypography variant="body2">support@trakib.com</MDTypography>
            <MDTypography variant="body2">www.trakib.com</MDTypography>
          </MDBox>
        </MDBox>
      </Grid>
      <Grid item container xs={4} justifyContent="right">
        <MDBox
          component="img"
          src={`${baseURL}/assets/${settingsItem.logo}`}
          alt="Trakib"
          width={150}
          height={150}
        />
      </Grid>
    </Grid>
  );
}

export default InvoideSenderVersionA;
