import { Grid, Container } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { signInUrl } from "constants";
import { useTranslation } from "react-i18next";
import SectionContainer from "../SectionContainer";

const easyUISection = {
  entitle: "An Easy-to-use Business Management Software",
  artitle: "برنامج إدارة أعمال سهل الاستخدام",
  enbody:
    "Trakib software is built for both book publishers and business owners with no prior knowledge for using any softwares.",
  arbody: "تم تصميم برنامج تراكيب لناشري الكتب وأصحاب الأعمال دون معرفة مسبقة باستخدام أي برنامج.",
};

function EasyUI() {
  const { t, i18n } = useTranslation();

  return (
    <SectionContainer>
      <Container>
        <MDBox
          bgColor="secondary"
          sx={{ py: { xs: 5, lg: 8 }, px: { xs: 3, lg: 8 }, borderRadius: 7 }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={7} lg={6}>
              <MDTypography color="info" variant="h2" sx={{ fontSize: { xs: 22, lg: 40 } }} mb={4}>
                {easyUISection[`${i18n.language}title`]}
              </MDTypography>
              <MDTypography color="white" variant="body1" sx={{ fontSize: { xs: 18, lg: 20 } }}>
                {easyUISection[`${i18n.language}body`]}
              </MDTypography>
            </Grid>
            <Grid item container xs={12} md={5} lg={6} justifyContent="flex-end">
              <MDButton
                href={signInUrl}
                color="info"
                sx={{
                  width: { xs: "100%", lg: 300 },
                  height: 62,
                  fontSize: { xs: 17, lg: 20 },
                }}
              >
                {t("getStartedFree")}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Container>
    </SectionContainer>
  );
}

export default EasyUI;
