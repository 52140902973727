import MDBox from "components/MDBox";
import DashboardLayout from "components/UI/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/UI/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDSnackbar from "components/MDSnackbar";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Card, Grid, Alert } from "@mui/material";
import { useLocation } from "react-router-dom";
import MDTypography from "components/MDTypography";
import { closeAlert } from "store/features/coreSlice";
import { useTranslation } from "react-i18next";

const Page = ({ children, multi }) => {
  const { resStatus, loadingFiles, loading, messages } = useSelector((state) => state.core);
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [message1, setMessage] = useState("");

  useEffect(() => {
    if (messages) {
      const messageVal = messages.filter(
        (x) =>
          `${x.route_name}` ===
          location.pathname.split("/")[location.pathname.split("/").length - 1]
      )[0];
      if (messageVal) {
        setMessage(messageVal.text.split("-"));
      }
    }
  }, []);

  const [msgContent, setMsgContent] = useState(null);
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => {
    setSuccessSB(false);
    dispatch(closeAlert());
  };

  const renderSuccessSB = msgContent ? (
    <MDSnackbar
      color={msgContent.color}
      icon={msgContent.icon}
      title={msgContent.title}
      content={msgContent.content}
      dateTime={t("recently")}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      // bgWhite={msgContent.bgWhite}
    />
  ) : null;

  useEffect(() => {
    if (resStatus) {
      if (resStatus.status === "success") {
        setMsgContent({
          content: t(resStatus.content),
          color: "success",
          icon: "check",
          title: t("systemDashboard"),
          bgWhite: true,
        });
        setSuccessSB(true);
      } else {
        // setSuccessSB(false);
        // alert(resStatus);
        setMsgContent({
          content: t(resStatus.content),
          color: "error",
          icon: "error",
          title: t("systemDashboard"),
          bgWhite: false,
        });
        setSuccessSB(true);
      }
    }
  }, [resStatus, loadingFiles]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        {!multi ? (
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
              <Card style={{ padding: 20, position: "relative" }}>
                {message1 && message1.length > 0 && (
                  <Alert severity="warning">
                    {message1.map(
                      (n) => n && <MDTypography key={Math.random()}>- {n}</MDTypography>
                    )}
                  </Alert>
                )}
                <Backdrop
                  sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={loading || loadingFiles}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                {children}
              </Card>
            </Grid>
          </Grid>
        ) : (
          <MDBox>{children}</MDBox>
        )}
        {renderSuccessSB}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  multi: PropTypes.bool,
};

Page.defaultProps = {
  multi: false,
};

export default Page;
