import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ApiService from "../../services/api";

const initialState = {
  reportingStyle: null, // invoice/report
  reportingContent: null,
  reportingResponse: null,
  recipientInfo: null,
  // disableRecipientUI: false,
};

export const createReportAction = createAsyncThunk(
  "reporting/createReportAction",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.get(`items/invoices`, payload);

      return data.data;
    } catch (error) {
      console.log({ error });
      const message =
        error.response && error.response.data.message ? error.response.data.message : error.message;

      console.log("message", message);

      return rejectWithValue(message);
    }
  }
);

export const reportingSlice = createSlice({
  name: "reporting",
  initialState,
  reducers: {
    setReportingStyleAction: (state, { payload }) => {
      state.reportingStyle = payload;
    },

    setReportingContentAction: (state, { payload }) => {
      state.reportingContent = payload;
    },

    setReportingRecipientInfoAction: (state, { payload }) => {
      state.recipientInfo = payload;
    },

    // disableRecipientUIAction: (state, { payload }) => {
    //   state.disableRecipientUI = payload;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(createReportAction.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createReportAction.fulfilled, (state, { payload }) => {
      state.reportingResponse = payload;
      state.loading = false;
    });

    builder.addCase(createReportAction.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setReportingStyleAction, setReportingContentAction } = reportingSlice.actions;

export default reportingSlice.reducer;
