import { useField, useFormikContext } from "formik";
import PropTypes from "prop-types";

// import MDInput from "components/MDInput";
import styled from "@emotion/styled";
import { mainColor } from "constants";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const StyledInputContainer = styled.div`
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 10px 0;
  height: 55px;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
  align-items: center;

  ${({ error }) =>
    error
      ? `
   border: 2px solid red;

 `
      : null}

  &:focus-within {
    border: 2px solid ${mainColor};
  }

  @media (max-width: 300px) {
    height: 50px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;

  & .icon {
    line-height: 55px;
    color: #acacac;
    transition: 0.5s;
    width: 20px;
    height: 20px;
  }
`;

const StyledInput = styled.input`
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-family: Almarai;
  font-size: 1.1rem;
  color: #333;

  &::placeholder {
    color: #aaa;
    font-weight: 500;
  }

  @media (max-width: 400px) {
    font-size: 1rem;
  }

  @media (max-width: 300px) {
    font-size: 0.8rem;
  }
`;

function AuthFormikInput(props) {
  const { name, Icon, ...otherProps } = props;
  const [field, mata] = useField(name);
  const { errors, touched, setFieldTouched } = useFormikContext();
  const { authError } = useSelector((state) => state.auth);

  const configTextfield = {
    ...field,
    ...otherProps,
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  useEffect(() => {
    console.log("configTextfield = ", configTextfield);
    if (authError) {
      setFieldTouched(name, true, false);
      // setFieldError(name, authError);
      configTextfield.error = true;
      // configTextfield.helperText = mata.error;
    }
  }, [authError, configTextfield.error]);

  return (
    <StyledInputContainer {...configTextfield}>
      <IconContainer>
        {/* <Person /> */}
        <Icon className="icon" />
      </IconContainer>
      <StyledInput {...configTextfield} />

      {touched[name] && errors[name] && (
        <div
          style={{
            fontSize: 12,
            color: "red",
            position: "absolute",
            left: 30,
            bottom: -20,
          }}
        >
          {errors[name]}
        </div>
      )}
    </StyledInputContainer>
  );
}

AuthFormikInput.defaultProps = {
  Icon: null,
};

AuthFormikInput.propTypes = {
  name: PropTypes.string.isRequired,
  Icon: PropTypes.objectOf(PropTypes.any),
};

export default AuthFormikInput;
