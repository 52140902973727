const PagesCount = (totalCount, limit) => {
  try {
    const pageCount = Math.ceil(totalCount / limit);
    return pageCount;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export default PagesCount;
