/**
=========================================================
* Trakib System - v2.1.0
=========================================================

* Product Page: https://trakib.comproduct/trakib-system-react
* Copyright 2022 trakib (https://trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Dropzone components
import Dropzone from "dropzone";

// Dropzone styles
import "dropzone/dist/dropzone.css";

// Trakib System components
import MDBox from "components/MDBox";

// Custom styles for the MDDropzone
import MDDropzoneRoot from "components/MDDropzone/MDDropzoneRoot";

// Trakib System context
import { useMaterialUIController } from "context";

function MDDropzone({ options, name, setFieldValue, setFieldTouched }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const dropzoneRef = useRef();

  useEffect(() => {
    Dropzone.autoDiscover = false;

    function createDropzone() {
      const dropZoneItem = new Dropzone(dropzoneRef.current, {
        ...options,
      });

      dropZoneItem.on("complete", (file) => {
        console.log(file);
        setFieldValue(name, dropZoneItem.getAcceptedFiles());
        setFieldTouched(name, dropZoneItem.getAcceptedFiles());
      });

      dropZoneItem.on("removedfile", () => {
        setFieldValue(name, dropZoneItem.getAcceptedFiles());
        setFieldTouched(name, dropZoneItem.getAcceptedFiles());
      });

      return dropZoneItem;
    }

    function removeDropzone() {
      if (Dropzone.instances.length > 0) Dropzone.instances.forEach((dz) => dz.destroy());
    }

    createDropzone();

    return () => removeDropzone();
  }, [options]);

  return (
    <MDDropzoneRoot
      action="/"
      ref={dropzoneRef}
      className="form-control dropzone"
      ownerState={{ darkMode }}
    >
      <MDBox className="fallback" bgColor="transparent">
        <MDBox component="input" name="file" type="file" multiple />
      </MDBox>
    </MDDropzoneRoot>
  );
}

// Typechecking props for the MDDropzone
MDDropzone.propTypes = {
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default MDDropzone;
