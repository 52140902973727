import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import { useSelector } from "react-redux";

function InvoideSenderVersionB() {
  const { settingsItem, baseURL } = useSelector((state) => state.core);

  return (
    <div>
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <MDBox>
          <MDBox
            component="img"
            src={`${baseURL}/assets/${settingsItem.logo}`}
            alt={settingsItem.title || "undefined name"}
            width={80}
            height={80}
          />
          <MDTypography variant="h5" sx={{ textTransform: "capitalize" }}>
            {settingsItem.title || "undefined name"}
          </MDTypography>
        </MDBox>
        {/* <MDBox> */}
        <MDBox display="flex">
          <MDBox>
            <MDTypography variant="body2">{settingsItem.st_address}</MDTypography>
            <MDTypography variant="body2">{`${settingsItem.city_id?.title}, ${settingsItem.zip_code}`}</MDTypography>
            <MDTypography variant="body2">{settingsItem.city_id?.country_id?.title}</MDTypography>
          </MDBox>
          <MDBox mx={8}>
            <MDTypography variant="body2">{settingsItem.phone}</MDTypography>
            <MDTypography variant="body2">{settingsItem.email}</MDTypography>
            <MDTypography variant="body2">{settingsItem.website}</MDTypography>
          </MDBox>
        </MDBox>
        {/* </MDBox> */}
      </MDBox>
      <hr style={{ height: 1, border: "none", background: "#eee", margin: "10px 0" }} />

      <MDBox display="flex" justifyContent="space-between">
        <MDTypography
          sx={{
            // background: "#eef8fd",
            display: "inline-block",
            padding: "2px 8px",
            color: "#75799D",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 15,
          }}
        >
          Invoice No #
        </MDTypography>
        <MDBox display="flex">
          <MDTypography
            sx={{
              // background: "#eef8fd",
              display: "inline-block",
              padding: "2px 8px",
              color: "#75799D",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: 15,
            }}
          >
            Invoice Date:
          </MDTypography>
          <MDTypography variant="body2">{moment(new Date()).format("DD/MM/YYYY")}</MDTypography>
        </MDBox>
      </MDBox>
    </div>
  );
}

export default InvoideSenderVersionB;
