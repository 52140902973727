import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";
import * as yup from "yup";
import MDTypography from "components/MDTypography";

import {
  fetchDataAction,
  deleteDataAction,
  reset,
  setAlert,
  addComplexDataAction,
} from "store/features/coreSlice";
import { Icon } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
import { useTranslation } from "react-i18next";
import PagesCount from "helpers/pages-count";

const AccountItems = () => {
  const { t } = useTranslation();

  const columns = [
    { Header: "title", accessor: "title", width: "45%", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [pageOptions, setPageOption] = useState([]);
  const [number, setNumber] = useState(1);

  const [initialValues, setInitialValues] = useState({
    id: null,
    title: "",
  });
  const [newArr, setNewArr] = useState([]);
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);

  const { accountsItems, accountsItemsMeta, resStatus, limit } = useSelector((state) => state.core);
  const dispatch = useDispatch();
  const onUpdateHandler = (name, values) => {
    setInitialValues({
      id: values.id,
      title: values.title,
    });
    setOpen(true);
  };
  const onDeleteHandler = (name, values) => {
    if (values.accounts.length === 0) {
      setOpenDeleteModal(true);
      setDeleteItemId(values.id);
    } else {
      // alert("have accounts ");
      dispatch(
        setAlert({
          status: "error",
          content: t("hasAccounts"),
        })
      );
    }
  };
  useEffect(() => {
    const payload = {
      url: `items/accounts_items?fields=*,accounts.id&meta=*&limit=${limit}&page=1`,
      varName: "accountsItems",
    };
    dispatch(fetchDataAction(payload));
  }, [limit]);

  useEffect(
    () => () => {
      dispatch(reset(null));
    },
    []
  );

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `items/accounts_items?fields=*,accounts.id&meta=*&limit=${limit}&page=${num}`,
          varName: "accountsItems",
        })
      );
    }
  };

  const resetForm = () => {
    setInitialValues({
      id: null,
      title: "",
    });
  };

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      setSuccessSB(true);
      resetForm();
    }
  }, [resStatus]);

  useEffect(() => {
    let item = {};
    const Arr = [];

    if (accountsItems && accountsItems.length !== 0) {
      const d = [];
      for (let i = 0; i < PagesCount(+accountsItemsMeta.filter_count, limit); i += 1) {
        d.push(i);
      }
      setPageOption(d);
      accountsItems.forEach((itemVal) => {
        item = {};
        item.id = itemVal.id;
        item.title = itemVal.title;
        if (![1, 5, 6].includes(itemVal.id))
          item.action = (
            <>
              <MDTypography
                style={{ padding: 20, color: "#3b94ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onUpdateHandler("accountsItems", itemVal)}
              >
                <Icon fontSize="small">edit</Icon>
              </MDTypography>
              <MDTypography
                style={{ padding: 20, color: "#e9423f" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onDeleteHandler("accountsItems", itemVal)}
              >
                <Icon fontSize="small">delete</Icon>
              </MDTypography>
            </>
          );
        Arr.push(item);
      });
      setNewArr(Arr);
    }
  }, [accountsItems]);

  const data = [
    {
      type: "TextField",
      name: "title",
      id: "title",
      label: "title",
      size: 12,
      title: "item",
    },
  ];

  const setPayload = (values) => {
    const req = {
      id: values.id,
      origin: {
        data: values,
        table: "accounts_items",
      },
      getInfo: {
        url: `items/accounts_items?fields=*,accounts.id&meta=*&limit=${limit}&page=1`,
        varName: "accountsItems",
      },
    };
    setInitialValues(values);
    dispatch(addComplexDataAction(req));
  };

  const setOpenModal = () => {
    setInitialValues({
      id: null,
      title: "",
    });
    setOpen(!open);
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: `items/accounts_items`,
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      varName: "accountsItems",
      getInfo: {
        url: `items/accounts_items?fields=*,accounts.id&meta=*&limit=${limit}&page=1`,
        varName: "accountsItems",
      },
    };
    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  if (data.length === 0 || newArr === [] || !accountsItemsMeta) {
    return null;
  }
  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("systemDashboard")}
      content={t("successfulProcess")}
      dateTime={t("recently")}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );
  return (
    <>
      <Main
        count={pageOptions}
        layout="simple"
        pageIndex={number - 1}
        allRowsCount={+accountsItemsMeta.filter_count}
        setPageNum={setPageNumber}
        openDeleteModal={openDeleteModal}
        handleConfirmDelete={handleConfirmDelete}
        setOpenDeleteModal={setOpenDeleteModal}
        setOpen={setOpenModal}
        open={open}
        name="accountsItems"
        setPayload={setPayload}
        data={data}
        initialValues={initialValues}
        title={t("addItem")}
        columns={columns}
        rows={newArr}
        onUpdateHandler={onUpdateHandler}
        onDeleteHandler={onDeleteHandler}
        yupObj={{
          title: yup.string("Enter your Title").required(t("TitleIsRequired")).nullable(),
        }}
      />
      {renderSuccessSB}
    </>
  );
};

export default AccountItems;
