import { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, styled } from "@mui/material";
import { ContactPage } from "@mui/icons-material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import RecipientForm from "./RecipientForm";

const StyledRoot = styled(MDButton)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  // alignItems: "center",
  padding: "20px",
  border: "2px dashed #cccccc",
  borderRadius: 4,
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  transition: "all .2s ease-in-out",
  cursor: "pointer",
  minWidth: 300,
  "&:hover": {
    borderColor: theme.palette.info.main,
    backgroundColor: "#eef8fd",
  },
}));

function InvoiceRecipientVersionB() {
  const [modalOpen, setModalOpen] = useState(false);

  const [recipentInfo, setRecipentInfo] = useState({
    comp_name: "",
    st_address: "",
    zip_code: "",
    city: null,
  });

  const handleClose = () => setModalOpen(!modalOpen);
  const handleCancel = () => {
    setRecipentInfo({
      comp_name: "",
      st_address: "",
      zip_code: "",
      city: null,
    });
    handleClose();
  };

  return (
    <div>
      <MDTypography
        sx={{
          // background: "#eef8fd",
          display: "inline-block",
          padding: "2px 8px",
          color: "#75799D",
          textTransform: "uppercase",
          fontWeight: "bold",
          fontSize: 15,
        }}
      >
        Billed To
      </MDTypography>
      <StyledRoot onClick={handleClose}>
        {/* <MDBox> */}
        {recipentInfo.comp_name ? (
          <>
            <MDTypography variant="body2">{recipentInfo.comp_name}</MDTypography>
            <MDTypography variant="body2">{recipentInfo.st_address}</MDTypography>
            <MDTypography variant="body2">
              {recipentInfo.city?.title}, {recipentInfo.zip_code}
            </MDTypography>
            <MDTypography variant="body2">{recipentInfo.city?.country_id?.title}</MDTypography>
          </>
        ) : (
          <MDBox display="flex" alignItems="center">
            <ContactPage style={{ width: 40, height: 40 }} />
            <MDBox display="flex" alignItems="flex-start" flexDirection="column" mx={2}>
              <MDTypography variant="body1">Recipient</MDTypography>
              <MDTypography variant="body2">Add Invoice Recipient Details</MDTypography>
            </MDBox>
          </MDBox>
        )}
        {/* </MDBox> */}
      </StyledRoot>

      <Dialog onClose={handleClose} open={modalOpen} maxWidth="md" fullWidth>
        <DialogTitle>Invoice Recipient</DialogTitle>
        <DialogContent sx={{ py: 2 }}>
          <RecipientForm
            recipientInitalValues={recipentInfo}
            setRecipentInfo={setRecipentInfo}
            handleClose={handleClose}
          />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCancel}>Cancel</MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default InvoiceRecipientVersionB;
