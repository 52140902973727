/**
=========================================================
* Trakib System - v2.1.0
=========================================================

* Product Page: https://trakib.comproduct/trakib-system-react
* Copyright 2022 trakib (https://trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { useNavigate, Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Trakib System components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { signUpAction } from "store/features/authSlice";
import { setAlert } from "store/features/coreSlice";
import * as Yup from "yup";
import moment from "moment";
import { useEffect, useState } from "react";
import { signInUrl } from "constants";
import { useTranslation } from "react-i18next";

function Cover() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { loading } = useSelector((state) => state.auth);
  const [beginLoading, setBeginLoading] = useState(false);
  // const expiryDate = () => {
  const currentDate = moment();
  let futureMonth = moment(currentDate).add(1, "M");
  const futureMonthEnd = moment(futureMonth).endOf("month");

  if (
    currentDate.date() !== futureMonth.date() &&
    futureMonth.isSame(futureMonthEnd.format("YYYY-MM-DD"))
  ) {
    futureMonth = futureMonth.add(1, "d");
  }

  useEffect(() => {
    if (loading) setBeginLoading(true);
    else if (beginLoading) {
      // alert("successfully user created");
      dispatch(
        setAlert({
          status: "error",
          content: t("userSuccessCreate"),
        })
      );
      navigate(signInUrl);
    }
  }, [loading]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      title: "",
      first_name: "",
      last_name: "",
      expiry_date: futureMonth.format("YYYY-MM-DD"),
    },

    validationSchema: Yup.object({
      title: Yup.string().required("required field"),
      first_name: Yup.string().required("required field"),
      email: Yup.string().email("wrong email").required("required field"),
      password: Yup.string().required("required field"),
    }),

    onSubmit: (values) => {
      dispatch(signUpAction(values));
    },
  });

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Company Title"
                fullWidth
                id="title"
                name="title"
                form="title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
              />
            </MDBox>
            {formik.touched.title && formik.errors.title ? (
              <div
                style={{
                  position: "absolute",
                  margin: "-20px 75px",
                  color: "rgb(216, 23, 23)",
                  fontSize: "12px",
                }}
              >
                {formik.errors.title}
              </div>
            ) : null}

            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Admin user first name"
                fullWidth
                id="first_name"
                name="first_name"
                form="first_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
              />
            </MDBox>
            {formik.touched.first_name && formik.errors.first_name ? (
              <div
                style={{
                  position: "absolute",
                  margin: "-20px 75px",
                  color: "rgb(216, 23, 23)",
                  fontSize: "12px",
                }}
              >
                {formik.errors.first_name}
              </div>
            ) : null}

            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Admin user last name"
                fullWidth
                id="last_name"
                name="last_name"
                form="last_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.last_name}
              />
            </MDBox>
            {formik.touched.last_name && formik.errors.last_name ? (
              <div
                style={{
                  position: "absolute",
                  margin: "-20px 75px",
                  color: "rgb(216, 23, 23)",
                  fontSize: "12px",
                }}
              >
                {formik.errors.last_name}
              </div>
            ) : null}

            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Admin user email"
                fullWidth
                id="email"
                name="email"
                form="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
            </MDBox>
            {formik.touched.email && formik.errors.email ? (
              <div
                style={{
                  position: "absolute",
                  margin: "-20px 75px",
                  color: "rgb(216, 23, 23)",
                  fontSize: "12px",
                }}
              >
                {formik.errors.email}
              </div>
            ) : null}

            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                id="password"
                name="password"
                form="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
            </MDBox>
            {formik.touched.password && formik.errors.password ? (
              <div
                style={{
                  position: "absolute",
                  margin: "-20px 75px",
                  color: "rgb(216, 23, 23)",
                  fontSize: "12px",
                }}
              >
                {formik.errors.password}
              </div>
            ) : null}

            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                sign up
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to={signInUrl}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
