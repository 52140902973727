import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import localStoragePrefix from "helpers/config";
import { directusRoleId } from "constants";

import Api from "../../services/api";

const initialState = {
  userInfo: localStorage.getItem(`${localStoragePrefix}userInfo`)
    ? JSON.parse(localStorage.getItem(`${localStoragePrefix}userInfo`))
    : null,
  token: localStorage.getItem(`${localStoragePrefix}token`)
    ? localStorage.getItem(`${localStoragePrefix}token`)
    : null,
  message: null,
  authError: null,
  loading: false,
  userInfoDetail: null,
  usersLogsDetail: null,
};

export const createRoutes = async (payload) => {
  try {
    const { data } = await Api.get(
      `items/job_title_routes?fields=route_name,job_title_id.users_job_titles.id&filter[job_title_id][users_job_titles][id][_eq]=${payload}`
    );
    return data.data;
  } catch (error) {
    const message =
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message;
    if (message) {
      throw error;
    }
    return message;
  }
};

export const signUpAction = createAsyncThunk(
  "auth/signUpAction",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await Api.post("items/admins", {
        title: payload.title,
        expiry_date: payload.expiry_date,
        status: "published",
        comp_code: "https://trakib.com",
        type_id: 4,
      });

      payload.admin_id = data.data.id;
      payload.status = "active";
      payload.role = directusRoleId;
      delete payload.title;
      delete payload.expiry_date;

      const user = await Api.post("users", payload);
      const loginData = await Api.post("custom/login", payload);
      const { token } = loginData.data.data;
      await Api.post(
        "items/users_job_title",
        { user_id: user.data.data.id, job_title_id: 1 },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      window.location.reload();

      return data;
    } catch (error) {
      const message =
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message;
      console.log(error);
      if (message) {
        throw message;
      }

      return rejectWithValue(message);
    }
  }
);

export const loginAction = createAsyncThunk(
  "auth/loginAction",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const {
        core: { baseURL },
      } = getState();
      const respo = {};
      const { data } = await Api.post("custom/login", payload);
      const { user, token } = data.data;
      // const { origin } = window.location;

      const verified = await Api.post("utils/hash/verify", {
        // string: origin,
        string: "https://trakib.com",
        hash: data.data.comp_code,
      });

      localStorage.setItem(`${localStoragePrefix}token`, token);

      await Api.get(`custom/checkeExpiryDate`);

      const status = await Api.get(`items/admins/${user.admin_id}?fields=status`);
      if (status.data.data.status !== "published" || !verified.data.data) {
        localStorage.removeItem(`${localStoragePrefix}userInfo`);
        localStorage.removeItem(`${localStoragePrefix}token`);
        return rejectWithValue({
          error: { message: "subscriptionHasExpired" },
        });
      }

      const routes = await Api.get(
        `items/job_title_routes?fields=route_name,create,update,delete&filter[job_title_id][users_job_titles][user_id][id][_eq]=${user.id}`
      );
      // &filter[job_title_id][users_job_titles][user_id][admin_id][id][_eq]=${user.admin_id}
      // &filter[status][_eq]=published
      const r = [];
      routes.data.data.forEach((element) => {
        const y = r.filter((x) => x.route_name === element.route_name);
        if (y.length === 0) r.push(element);
      });

      let avatar = null;
      if (data.data.user.avatar) {
        avatar = await Api.get(`files/${data.data.user.avatar}`);
      }
      user.userAvatar = avatar
        ? `${baseURL}assets/${avatar.data.data.id}?fit=cover&width=100&height=200&quality=80`
        : null;
      user.routes = r;
      // user.features = features.data.data;
      localStorage.setItem(`${localStoragePrefix}userInfo`, JSON.stringify(user));
      respo.user = user;
      respo.token = token;
      return respo;
    } catch (error) {
      console.log("error = ", error);
      const message =
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message;
      if (message) {
        console.log("message = ", message);
        throw message;
      }

      return rejectWithValue(message);
    }
  }
);

export const getUserInfoAction = createAsyncThunk(
  "auth/getUserInfoAction",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await Api.get(
        `users/${payload}?fields=*,degree_id.title,university_id.title,type_id.title,country_id.title,residence.title,specializations.specialization_id.id,specializations.specialization_id.title,job_titles.job_title_id.title,job_titles.job_title_id.description,job_titles.job_title_id.id,avatar,job_titles.job_title_id.routes_id.route_name`
      );
      return data.data;
    } catch (error) {
      console.log(error);
      const message =
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message;
      console.log(error);
      if (message) {
        throw message;
      }

      return rejectWithValue(message);
    }
  }
);

export const getUserActivitiesAction = createAsyncThunk(
  "auth/getUserActivitiesAction",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { userInfo },
        core: { limit },
      } = getState();

      let url = `activity?fields=*,user.id,user.admin_id,user.first_name,user.last_name`;

      if (payload?.userId) url += `&filter[user][id][_eq]=${payload.userId}&limit=50`;
      else if (payload?.collection && userInfo.admin_id === 1)
        url += `&filter[collection]=${payload.collection}&limit=50`;
      else if (payload?.collection)
        url += `&filter[collection]=${payload.collection}&filter[user][admin_id][_eq]=${userInfo.admin_id}&limit=50`;
      else if (payload?.page)
        url += `&filter[user][admin_id][_eq]=${userInfo.admin_id}&limit=${limit}&page=${payload.page}&meta=*`;
      else if (payload?.url) url = payload.url;
      const { data } = await Api.get(`${url}&sort=timestamp`);
      return data.data;
    } catch (error) {
      console.log(error);
      const message =
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message;
      console.log(error);
      if (message) {
        throw message;
      }

      return rejectWithValue(message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutAction: () => {
      localStorage.removeItem(`${localStoragePrefix}userInfo`);
      localStorage.removeItem(`${localStoragePrefix}token`);
    },
  },
  extraReducers: {
    [loginAction.pending]: (state) => {
      state.loading = true;
      state.authError = null;
    },
    [loginAction.fulfilled]: (state, { payload }) => {
      // ...state,
      state.userInfo = payload.user;
      state.token = payload.token;
      state.authError = null;
      state.loading = false;
    },

    [loginAction.rejected]: (state, { payload }) => {
      // ...state,
      console.log("payload = ", payload);
      // state.message = payload ? payload.error.message : message;
      state.authError = "invalid email/password";
      state.loading = false;
    },
    [signUpAction.pending]: (state) => ({ ...state, loading: true }),
    [signUpAction.fulfilled]: (state) => ({
      ...state,
      loading: false,
    }),
    [signUpAction.rejected]: (state, { payload, error: { message } }) => ({
      ...state,
      message: payload ? payload.error.message : message,
      loading: false,
    }),
    [getUserInfoAction.pending]: (state) => ({ ...state, loading: true }),
    [getUserInfoAction.fulfilled]: (state, { payload }) => ({
      ...state,
      userInfoDetail: payload,
      loading: false,
    }),
    [getUserInfoAction.rejected]: (state, { error: { message } }) => ({
      ...state,
      message,
      loading: false,
    }),

    [getUserActivitiesAction.pending]: (state) => ({ ...state, loading: true }),
    [getUserActivitiesAction.fulfilled]: (state, { payload }) => ({
      ...state,
      usersLogsDetail: payload,
      loading: false,
    }),
    [getUserActivitiesAction.rejected]: (state, { error: { message } }) => ({
      ...state,
      message,
      loading: false,
    }),
  },
});

// Action creators are generated for each case reducer function
// export const {} = authSlice.actions;

export const { logoutAction } = authSlice.actions;

export default authSlice.reducer;
