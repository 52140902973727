import { Lock, Person } from "@mui/icons-material";
import { loginAction } from "store/features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import AuthFormikInput from "./AuthFormikInput";
// import AuthProviders from "./AuthProviders";
import "./auth-form.css";

const signinFormFields = [
  {
    name: "email",
    label: "email",
    Icon: Person,
    type: "email",
  },
  {
    name: "password",
    label: "password",
    type: "password",
    Icon: Lock,
  },
];

const loginInitialValues = {
  email: "",
  password: "",
};

const loginValidationSchema = Yup.object().shape({
  email: Yup.string().email("wrong email").required("required field"),
  password: Yup.string().required("required field"),
});

function LoginForm() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading, authError } = useSelector((state) => state.auth);

  const onSubmit = (values) => {
    // console.log("payload = ", values);
    dispatch(loginAction(values));
  };

  return (
    <Formik
      initialValues={loginInitialValues}
      validationSchema={loginValidationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      <Form noValidate className="sign-in-form">
        <h2 className="title">{t("signin")}</h2>
        {signinFormFields.map(({ label, ...field }) => (
          <AuthFormikInput placeholder={t(label)} {...field} key={label} />
        ))}

        {authError && (
          <div
            style={{
              fontSize: 14,
              color: "red",
              margin: "10px 0",
            }}
          >
            {authError}
          </div>
        )}
        <button type="submit" className={`btn ${loading && "disabled-btn"}`} disabled={loading}>
          {t("signin")}
        </button>
        {/* <p className="social-text">{t("signInWithSocailMedia")}</p> */}
        {/* <AuthProviders /> */}
      </Form>
    </Formik>
  );
}

export default LoginForm;
