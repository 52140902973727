import * as React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

// import MDTypography from "components/MDTypography";

function ccyFormat(num, isDollar) {
  const nFormat = new Intl.NumberFormat();
  const currency = isDollar ? "$" : "";
  return `${currency}${nFormat.format(num)}`;
}

function calcDiscountedPrice({ unit, discountPercentage, qty }) {
  return qty * unit * (discountPercentage / 100);
}

function priceRow({ unit, discountPercentage, qty }) {
  return qty * unit - calcDiscountedPrice({ unit, discountPercentage, qty });
}

function subtotal(items) {
  return items
    .map((item) => ({
      unit: item.cost,
      qty: item.count,
      discountPercentage: item.discount_percentage,
    }))
    .reduce(
      (sum, { unit, discountPercentage, qty }) => sum + priceRow({ unit, discountPercentage, qty }),
      0
    );
}
export default function ReportDetails({ rows, total }) {
  const invoiceSubtotal = subtotal(rows);
  const invoiceTotal = invoiceSubtotal;
  const { t } = useTranslation();
  const { reportTableFields } = useSelector((state) => state.invoiceReducer);
  const [colSpanCount, setColSpanCount] = useState(0);

  // console.log("myRows = ", rows);
  // console.log("invoiceSubtotal = ", invoiceSubtotal);
  // console.log("invoiceTotal = ", invoiceTotal);

  console.log("total = ", total);
  console.log(invoiceTotal);
  console.log(ccyFormat(23, 0));

  useEffect(() => {
    if (reportTableFields) {
      setColSpanCount(Object.keys(reportTableFields).length);
      console.log("colSpanCount = ", colSpanCount);
    }
  }, [reportTableFields]);

  return (
    <TableContainer>
      <Table sx={{ minWidth: 700 }}>
        <TableHead style={{ display: "table-header-group" }}>
          <TableRow>
            <TableCell>{t("tableId")}</TableCell>
            {reportTableFields?.map(
              (field) =>
                field.Header !== "action" && (
                  <TableCell key={field.accessor} style={{ whiteSpace: "nowrap" }}>
                    {t(field.Header)}
                  </TableCell>
                )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((item, index) => (
            <TableRow key={Math.random().toString()}>
              <TableCell>{index + 1}</TableCell>
              {reportTableFields.map(
                (field) =>
                  field.Header !== "action" && (
                    <TableCell
                      key={field.accessor}
                      style={{
                        whiteSpace: "nowrap",
                        // color: Number.isNaN(Number(item[field.accessor]))
                        //   ? item[field.accessor]
                        //   : +item[field.accessor] < 0 && "red",
                      }}
                    >
                      {Number.isNaN(Number(item[field.accessor]))
                        ? item[field.accessor]
                        : +item[field.accessor]}
                    </TableCell>
                  )
              )}
            </TableRow>
          ))}

          {/* {total && (
            <TableRow>
              <TableCell>{t("total")}</TableCell>
              {reportTableFields.map((field) => (
                <TableCell key={Math.random().toString()}>
                  {Number.isNaN(Number(total[field.accessor]))
                    ? total[field.accessor]
                    : +total[field.accessor]}
                </TableCell>
              ))}
            </TableRow>
          )} */}

          {total && (
            <TableRow>
              <TableCell rowSpan={colSpanCount} />
            </TableRow>
          )}

          {total &&
            Object.keys(total).map((item) => (
              <TableRow key={Math.random().toString()}>
                <TableCell colSpan={Math.floor(colSpanCount / 6)} />
                <TableCell
                  colSpan={colSpanCount - Math.floor(colSpanCount - 6)}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {t(item)}
                </TableCell>
                <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
                  {Number.isNaN(Number(total[item])) ? total[item.accessor] : +total[item]}
                </TableCell>
              </TableRow>
            ))}

          {/* <TableRow>
            <TableCell rowSpan={6} />
            <TableCell colSpan={17}>Subtotal</TableCell>
            <TableCell align="right">{ccyFormat(invoiceSubtotal)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={17}>Total</TableCell>
            <TableCell align="right">{ccyFormat(invoiceTotal)}</TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

ReportDetails.defaultProps = {
  total: null,
};

ReportDetails.propTypes = {
  rows: PropTypes.PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
  // tableFields: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
  total: PropTypes.objectOf(PropTypes.any),
};
