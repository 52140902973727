import { Download } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function FloatingDownloadBtn() {
  const { t } = useTranslation();

  return (
    <MDBox className="floating-btn" bgColor="info">
      <div className="sliding-arrows-container">
        <div className="sliding-arrows">
          <span />
          <span />
          <span />
        </div>
        <div className="icon-container">
          <IconButton
            color="secondary"
            href="/TRAKIB Portfolio.pdf"
            download="TRAKIB Portfolio"
            target="_blank"
          >
            <Download />
            <MDTypography fontWeight="bold" variant="body2" sx={{ textDecoration: "underline" }}>
              {t("downloadTrakibProfile")}
            </MDTypography>
          </IconButton>
        </div>
      </div>
    </MDBox>
  );
}

export default FloatingDownloadBtn;
