import {
  AssignmentOutlined,
  AttachMoney,
  Block,
  Workspaces,
  ManageSearch,
  TextSnippetOutlined,
  // Storage,
} from "@mui/icons-material";
import { Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";
import SpecificFeatures from "./SpecificFeatures";
import SectionContainer from "./SectionContainer";

const features = [
  {
    entitle: "Permissions",
    artitle: "الصلاحيات",

    Icon: Block,
    enbody:
      "You can control what can every employee see in every single page using role permissions.",
    arbody: "يمكنك التحكم في ما يمكن لكل موظف رؤيته في كل صفحة باستخدام الصلاحيات المختلفة.",
    color: "#e41a71",
  },
  {
    entitle: "Activities",
    artitle: "الأنشطة",
    Icon: ManageSearch,
    enbody: "Display all employees activities in a single screen with filters.",
    arbody: "عرض جميع أنشطة الموظفين في شاشة واحدة مع الفلاتر.",
    color: "#80c343",
  },
  {
    entitle: "Reports & Stats",
    artitle: "التقارير & الإحصائيات",
    Icon: AssignmentOutlined,
    enbody: "Workflow, Inventory, Pricing, Distribution, Returns.",
    arbody: "سير العمل، المخزون، التسعير، التوزيع، العوائد.",
    color: "#8c1ecb",
  },
  {
    entitle: "Dollarization",
    artitle: "الدولرة",
    Icon: AttachMoney,
    enbody: "aligning a country's currency with the US dollar.",
    arbody: "مواءمة عملة الدولة مع الدولار الأمريكي.",
    color: "#f37535",
  },
  {
    entitle: "Old Data",
    artitle: "البيانات القديمة",
    Icon: TextSnippetOutlined,
    enbody: "Importing your old data to keep using it.",
    arbody: "استيراد بياناتك القديمة للاستمرار في استخدامها.",
    color: "#01aeef",
  },
  {
    entitle: "Workflow",
    artitle: "دورة الأعمال",
    Icon: Workspaces,
    enbody: "Create work teams, Setting Tasks, Monitoring, Evaluation, Manage Payments.",
    arbody: "إنشاء فرق العمل وتحديد المهام والمراقبة والتقييم وإدارة المدفوعات.",
    color: "#37a592",
  },
];

function TrakibFeatures() {
  const { t, i18n } = useTranslation();

  return (
    <SectionContainer id="trakib-features">
      <Container sx={{ textAlign: "start" }}>
        <MDTypography
          variant="h2"
          color="secondary"
          mb={2}
          // sx={{ textAlign: { xs: "center", md: "start" } }}
        >
          {t("trakibFeatures")}
        </MDTypography>
        <MDTypography
          variant="subtitle1"
          color="secondary"
          mb={4}
          sx={{ width: { xs: "100%", md: "75%", lg: "50%" } }}
        >
          {t("trakibFeaturesSummary")}
        </MDTypography>
        <Grid container spacing={4} mt={4}>
          {features.map(
            ({ Icon, [`${i18n.language}title`]: title, [`${i18n.language}body`]: body, color }) => (
              <Grid item xs={12} md={6} key={Math.random().toString()}>
                <MDBox
                  display="flex"
                  alignItems="flex-start"
                  sx={{
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    p: 3,
                    // p: { xs: 0, lg: 3 },
                    height: 150,
                    borderRadius: 2,
                  }}
                >
                  <Icon sx={{ color, width: 30, height: 33 }} />
                  <MDBox sx={{ mx: 2 }}>
                    <MDTypography
                      variant="h4"
                      color="secondary"
                      mb={1}
                      sx={{ fontSize: { xs: 20, lg: 24 } }}
                    >
                      {title}
                    </MDTypography>
                    <MDTypography variant="body2">{body}</MDTypography>
                  </MDBox>
                </MDBox>
              </Grid>
            )
          )}
        </Grid>
        <MDBox sx={{ my: 8 }} />
        <SpecificFeatures />
      </Container>
    </SectionContainer>
  );
}

export default TrakibFeatures;
