import NavBar from "./NavBar";

function Header() {
  return (
    <div>
      <NavBar />
    </div>
  );
}

export default Header;
