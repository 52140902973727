/**
=========================================================
* Trakib System - v2.1.0
=========================================================

* Product Page: https://trakib.comproduct/trakib-system-react
* Copyright 2022 trakib (https://trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Anaytics dashboard components
// import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

import { Grid } from "@mui/material";
// import MDBox from "components/MDBox";
import Page from "components/UI/Page";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
// import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import PieChart from "examples/Charts/PieChart";
// import PolarChart from "examples/Charts/PolarChart";
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataAction } from "store/features/coreSlice";

function Analytics() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { publisherLibraryCharts, libraryCountryChart, inventoryAccounts } = useSelector(
    (state) => state.core
  );
  useEffect(() => {
    dispatch(
      fetchDataAction({
        url: `custom/publisherLibraryCharts`,
        varName: "publisherLibraryCharts",
      })
    );
    dispatch(
      fetchDataAction({
        url: `custom/libraryCountryChart`,
        varName: "libraryCountryChart",
      })
    );
    // dispatch(
    //   fetchDataAction({
    //     url: `custom/inventoryLibrary`,
    //     varName: "inventoryLibrary",
    //   })
    // );

    dispatch(
      fetchDataAction({
        url: `custom/inventoryAccounts?from=${moment()
          .startOf("year")
          .format("YYYY-MM-DD")}&to=${moment().endOf("year").format("YYYY-MM-DD")}`,
        varName: "inventoryAccounts",
      })
    );
  }, []);

  return (
    <Page>
      <Grid container spacing={3} mb={5}>
        <Grid item xs={12} md={12}>
          {publisherLibraryCharts && (
            <VerticalBarChart
              icon={{ component: "insights" }}
              title={t("publisherLibraryCharts")}
              description={t("publisherLibraryChartsDescription")}
              chart={publisherLibraryCharts}
            />
          )}
        </Grid>
      </Grid>
      {/* <Grid container spacing={3} mb={5}>
        <Grid item xs={12} md={12}>
          {inventoryLibrary && (
            <VerticalBarChart
              icon={{ color: "dark", component: "leaderboard" }}
              title={t("inventoryLibrary")}
              description={t("inventoryLibraryDescription")}
              chart={inventoryLibrary}
            />
          )}
        </Grid>
      </Grid> */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {inventoryAccounts && (
            <DefaultDoughnutChart
              icon={{ color: "dark", component: "leaderboard" }}
              title={t("inventoryAccounts")}
              description={t("inventoryAccountsDescription")}
              chart={inventoryAccounts}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {libraryCountryChart && (
            <PieChart
              icon={{ color: "success", component: "donut_small" }}
              title={t("libraryCountryChart")}
              description={t("libraryCountryChartDescription")}
              chart={libraryCountryChart}
            />
          )}
        </Grid>
      </Grid>
    </Page>
  );
}

export default Analytics;
