import PropTypes from "prop-types";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

// import logoSvg from "./img/log.svg";
// import trakibLogoAR from "assets/images/logos/trakib-logo-ar.svg";
// import trakibLogoEN from "assets/images/logos/trakib-logo-en.svg";
import MDBox from "components/MDBox";
import logoIconDarks from "assets/images/logos/logo-icon-dark.svg";
import logoIconWhite from "assets/images/logos/logo-icon-white.svg";
import StrokedLogoText from "./StrokedLogoText";

const content = {
  entitle: "New here ?",
  artitle: "هل أنت جديد؟",
  enbody: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis, ex ratione. Aliquid!",
  arbody: "مرحبا بك فرد من أفراد أسرة تراكيب",
};
function LeftPanel({ setSignUpMode }) {
  const { t, i18n } = useTranslation();
  const isSurface = useMediaQuery("(max-width:912px) and (min-height:1300px)");

  return (
    <MDBox className="panel left-panel">
      <MDBox className="content">
        <h3>{content[`${i18n.language}title`]}</h3>
        <p>{content[`${i18n.language}body`]}</p>
        <button
          type="button"
          className="btn transparent"
          id="sign-up-btn"
          onClick={() => setSignUpMode(true)}
        >
          {t("signup")}
        </button>
      </MDBox>
      {/* <img src={logoSvg} alt="" className="image" /> */}
      <MDBox className="image-container" dislay="flex" alignItems="center">
        <MDBox sx={{ width: "40%" }}>
          <MDBox
            component="img"
            src={isSurface ? logoIconWhite : logoIconDarks}
            alt=""
            className="image"
          />
        </MDBox>
        {/* <div> */}
        <StrokedLogoText />
        {/* </div> */}
      </MDBox>
    </MDBox>
  );
}

LeftPanel.propTypes = {
  setSignUpMode: PropTypes.func.isRequired,
};

export default LeftPanel;
