import { Divider, Grid } from "@mui/material";
import { useCallback, useState } from "react";

// Trakib System Examples
import ComplexProjectCard from "examples/Cards/ProjectCards/ComplexProjectCard";

// @mui material components
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import ProfilesList from "examples/Lists/ProfilesList";
import { useSelector } from "react-redux";

import ProfileInfoCard from "./Cards/InfoCards/ProfileInfoCard";

const CardList = ({ table: { rows, columns } }) => {
  const { baseURL } = useSelector((state) => state.core);
  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const dropdownMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else here</MenuItem>
    </Menu>
  );

  const renderDescription = useCallback(
    (item) => {
      const cardInfo = {};
      const profilesArr = [];
      columns.forEach((element) => {
        if (element.Header !== "action") cardInfo[element.Header] = item[element.accessor];
      });
      item.workItems.forEach((r) => {
        const obj = {
          image: `${baseURL}assets/${r.user_id.avatar}?fit=cover&width=100&height=200&quality=80`,
          name: `${r.job_title_id.title}/ ${r.user_id.first_name}`,
          description: r.task_id.title,
          action: r.action,
        };
        profilesArr.push(obj);
      });
      return (
        <>
          <ProfileInfoCard
            title=""
            description={item.action}
            info={cardInfo}
            action={{ route: "", tooltip: "Edit Profile" }}
            shadow={false}
            edit={false}
          />
          <Divider orientation="vertical" sx={{ mx: 0 }} />
          <ProfilesList title="categories" profiles={profilesArr} />
        </>
      );
    },
    [rows]
  );
  const renderMember = (x) => {
    const arr = [];
    arr.push(`${baseURL}assets/${x.user_id.avatar}?fit=cover&width=100&height=200&quality=80`);
    if (x.workItems.length > 0) {
      x.workItems.forEach((r) => {
        arr.push(`${baseURL}assets/${r.user_id.avatar}?fit=cover&width=100&height=200&quality=80`);
      });
    }
    return arr;
  };

  if (rows.length === 0) return null;

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ pt: 5 }}>
      {rows.map((x) => (
        <Grid item xs={4} key={x.id}>
          <ComplexProjectCard
            image={`${baseURL}assets/${x.user_id.avatar}?fit=cover&width=100&height=200&quality=80`}
            title="slack bot"
            description={renderDescription(x)}
            dateTime={x.due_date}
            members={renderMember(x)}
            dropdown={{
              action: openMenu,
              menu: dropdownMenu,
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

CardList.propTypes = {
  table: PropTypes.node.isRequired,
};

export default CardList;
