import { Container, Grid } from "@mui/material";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import SectionContainer from "./SectionContainer";

function ActionSection({ title, body, btnText, Icon }) {
  return (
    <SectionContainer>
      <Container>
        <MDBox
          bgColor="secondary"
          sx={{ py: { xs: 5, lg: 8 }, px: { xs: 3, lg: 8 }, borderRadius: 7 }}
          display="flex"
          alignItems="center"
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6}>
              <MDTypography
                color="info"
                variant="h2"
                sx={{ fontSize: { xs: 22, md: 27, lg: 40 }, mb: { xs: 3, lg: 4 } }}
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />

              <MDTypography
                color="white"
                variant="body1"
                sx={{ fontSize: { xs: 20, md: 18, lg: 20 } }}
              >
                {body}
                {/* {t("getTheFullManual")} */}
              </MDTypography>
            </Grid>
            <Grid item container xs={12} md={6} justifyContent="flex-end">
              <MDButton
                href="/TRAKIB Portfolio.pdf"
                download="TRAKIB Portfolio"
                target="_blank"
                startIcon={Icon}
                color="info"
                sx={{
                  width: { xs: "100%", md: "90%", lg: 360 },
                  height: 62,
                  fontSize: { xs: 15, lg: 16 },
                  px: { md: "10px", lg: "24px" },
                }}
              >
                {btnText}
                {/* {t("downloadTrakibManual")} */}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Container>
    </SectionContainer>
  );
}

ActionSection.defaultProps = {
  Icon: null,
};

ActionSection.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  Icon: PropTypes.objectOf(PropTypes.any),
};

export default ActionSection;
