import * as React from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
// import { MdImage } from "react-icons/md";
import Icon from "@mui/material/Icon";
import { InputLabel } from "@mui/material";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";

const Input = styled("input")({
  display: "none",
});
const UploadButton = ({ name, setFieldValue, setFieldTouched, id, value, title, onChange }) => {
  const [inputValue, setInputValue] = useState(null);

  useEffect(() => {
    setInputValue(value);
  }, [value]);
  const [file, setFile] = React.useState(false);
  console.log("value", value);
  const changeHandelar = (event) => {
    setFieldValue(name, event.target.files[0]);
    setFieldTouched(name, event.target.files[0]);
    setFile(true);
    onChange(name, event.target.files[0]);
  };

  return (
    <>
      <InputLabel id="demo-simple-select-label">{title}</InputLabel>
      <div
        style={{
          height: "200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <label
          htmlFor={id}
          style={{
            width: "150px",
            borderRadius: "15px",
            height: "200px",
            borderStyle: "solid",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
          }}
        >
          <Input accept="image/*" type="file" onChange={changeHandelar} id={id} name={name} />
          <IconButton color="primary" aria-label="upload picture" component="span">
            {inputValue && !file ? (
              <img
                style={{
                  width: "150px",
                  borderRadius: "15px",
                  height: "200px",
                  borderStyle: "solid",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fff",
                }}
                src={inputValue}
                alt="img"
              />
            ) : (
              <Box>
                <Icon fontSize="small" color={file ? "success" : "primary"}>
                  {file ? "done" : "add_a_photo"}
                </Icon>
                {file && <h6>suessfully added</h6>}
              </Box>
            )}
          </IconButton>
        </label>
      </div>
    </>
  );
};

// const isFile = function (props, propName) {
//   console.log(props, propName);
//   console.log(props[propName].name);
//   // return typeof props[propName].name === "string";
//   // // return new Error(`Invalid prop ${propName} supplied to ${componentName}. It is not a File.`);
// };

UploadButton.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.func || PropTypes.string,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

UploadButton.defaultProps = {
  value: null,
};

export default UploadButton;
