import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import LanguagesMenu from "../LanguagesMenu";

function NavLinks({ pages }) {
  const { t } = useTranslation();

  return (
    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
      {pages.map(({ title, href }) => (
        <Button key={title} href={href}>
          {t(title)}
        </Button>
      ))}
      <LanguagesMenu />
    </Box>
  );
}

NavLinks.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default NavLinks;
