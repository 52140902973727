import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAJ2tzfrhLfJouxI0ro2G1YXcWY8FRxW8o",
  authDomain: "trakib-9a538.firebaseapp.com",
  projectId: "trakib-9a538",
  storageBucket: "trakib-9a538.appspot.com",
  messagingSenderId: "308270814466",
  appId: "1:308270814466:web:d27c450b16784497dd9e1b",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

export const getTokenData = (setTokenFound, setToken) =>
  getToken(messaging, {
    vapidKey: process.env.REACT_APP_VAPIDKEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        setToken(currentToken);
        // setTokenFound("current token for client: ", currentToken);
        // showMsg(false);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        setTokenFound("No registration token available. Request permission to generate one.");
        // showMsg(true);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log(err);
      setTokenFound("An error occurred while retrieving token.");
      // showMsg(true);
      // catch error while creating client token
    });

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
