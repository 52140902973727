import { Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { FolderSpecialOutlined, Inventory2Outlined, StoreOutlined } from "@mui/icons-material";
// import userPermissions from "../../assets/user-permissions.svg";
import userPermissions from "../../assets/Artboard 10.png";
import SectionContainer from "../SectionContainer";

const roles = [
  {
    entitle: "Pages",
    artitle: "الصفحات",
    Icon: FolderSpecialOutlined,
    color: "secondary",
  },
  {
    entitle: "Libraries",
    artitle: "المكتبات",
    Icon: StoreOutlined,
    color: "secondary",
  },
  {
    entitle: "Inventories",
    artitle: "المخازن",
    Icon: Inventory2Outlined,
    color: "secondary",
  },
];

const permissionsSection = {
  entitle: "Various Roles and Permissions",
  artitle: "صلاحيات وأدوار متعددة",
  enbody:
    "Trakib Software enables you to create job titles and roles as you want, and set the specific operations for every role. for example for any page you can decide who can access it and even what does he can do. you can also allow specific employees to access some libraries or inventories.",
  arbody:
    "يمكّنك برنامج نراكيب من إنشاء المسميات الوظيفية والأدوار كما تريد، وتعيين العمليات المحددة لكل دور. على سبيل المثال، بالنسبة لأي صفحة، يمكنك تحديد من يمكنه الوصول إليها وحتى ما يمكنه فعله. يمكنك أيضًا السماح لموظفين محددين بالوصول إلى بعض المكتبات أو قوائم الجرد.",
};

function Permissions() {
  const { i18n } = useTranslation();

  return (
    <SectionContainer>
      <Container>
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
        >
          <Grid item xs={12} md={6}>
            <MDTypography color="secondary" variant="h2" mb={2}>
              {permissionsSection[`${i18n.language}title`]}
            </MDTypography>
            <MDTypography variant="body1" color="secondary" sx={{ width: "88%", mb: 4 }}>
              {permissionsSection[`${i18n.language}body`]}
            </MDTypography>
            {roles.map(({ [`${i18n.language}title`]: title, Icon, color }) => (
              <MDBox display="flex" alignItems="center" key={title} mb={1.3}>
                <Icon fontSize="medium" sx={{ color }} />
                <MDTypography variant="body2" color="secondary" sx={{ mx: 1, fontWeight: "bold" }}>
                  {title}
                </MDTypography>
              </MDBox>
            ))}
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox
              component="img"
              src={userPermissions}
              alt="Reporting System"
              width="100%"
              height="110%"
              sx={{
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </SectionContainer>
  );
}

export default Permissions;
