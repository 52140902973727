import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

export default function ControlledRadioButtonsGroup({
  setFieldValue,
  setFieldTouched,
  name,
  value,
  id,
  data,
}) {
  const [inputValue, setInputValue] = useState("female");

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (event) => {
    setInputValue(event.target.checked);
    setFieldValue(name, event.target.checked);
    setFieldTouched(name, event.target.checked);
  };

  return (
    <FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        value={inputValue}
        id={id}
        name={name}
        onChange={handleChange}
      >
        {data.map((x) => (
          <FormControlLabel value={x.value} control={<Radio />} label={x.title} />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
ControlledRadioButtonsGroup.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.node,
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
};

ControlledRadioButtonsGroup.defaultProps = {
  value: null,
};
