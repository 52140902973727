import { LoginOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import { signInUrl } from "constants";
import { useTranslation } from "react-i18next";

function AuthButton() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <IconButton
        href={signInUrl}
        color="info"
        sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}
      >
        <LoginOutlined
          sx={{
            transform: `scaleX(${i18n.dir() === "rtl" ? -1 : 1})`,
          }}
        />
      </IconButton>
      <MDButton
        sx={{ flexGrow: 1, display: { xs: "none", lg: "flex" } }}
        href={signInUrl}
        color="info"
        startIcon={
          i18n.dir() !== "rtl" && (
            <LoginOutlined
              sx={{
                transform: `scaleX(${i18n.dir() === "rtl" ? -1 : 1})`,
              }}
            />
          )
        }
        endIcon={
          i18n.dir() === "rtl" && (
            <LoginOutlined
              sx={{
                transform: `scaleX(${i18n.dir() === "rtl" ? -1 : 1})`,
              }}
            />
          )
        }
      >
        {t("signin")}
      </MDButton>
    </div>
  );
}

export default AuthButton;
