/**
=========================================================
* Trakib System - v2.1.0
=========================================================

* Product Page: https://trakib.comproduct/trakib-system-react
* Copyright 2022 trakib (https://trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
// import Grid from "@mui/material/Grid";
// import Autocomplete from "@mui/material/Autocomplete";

// Trakib System components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

import InsertUser from "layouts/sys-users/insert-user";
import { useSelector } from "react-redux";

function BasicInfo() {
  const { userInfo } = useSelector((state) => state.auth);

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      {/* <MDBox p={3}>
        <MDTypography variant="h5">Basic Info</MDTypography>
      </MDBox> */}
      <MDBox component="form" pb={3} px={3}>
        <InsertUser userId={userInfo.id} />
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
