import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataForm from "components/UI/form/DataForm";
import { fetchDataAction } from "store/features/coreSlice";
import PropTypes from "prop-types";
import dataVal from "../../dataVal.json";

const FilterForm = ({ setNumber, setUrl, baseUrl }) => {
  const dispatch = useDispatch();
  const { authors, categories, translators, reviewers, investigators, editors, publishers, limit } =
    useSelector((state) => state.core);
  const [data, setData] = useState([]);
  const [filteredValues, setFilteredValues] = useState(null);

  const [initialValues, setInitialValues] = useState(dataVal.initialValuesFilter);

  const orgData = () => {
    setData([
      {
        type: "TextField",
        name: "title",
        id: "title",
        label: "title",
        size: 4,
        title: "title",
      },
      // {
      //   type: "MSelect",
      //   optionTitle: "title",
      //   name: "translators_id",
      //   id: "translators_id",
      //   label: "translators_id",
      //   data: translators,
      //   size: 4,
      //   title: "translators",
      // },
      {
        type: "MSelect",
        optionTitle: "title",
        name: "authors_id",
        id: "authors_id",
        label: "authors_id",
        data: authors,
        size: 4,
        title: "authors",
      },
      {
        type: "MSelect",
        optionTitle: "title",
        name: "categories_id",
        id: "categories_id",
        label: "categories_id",
        data: categories,
        size: 4,
        title: "categories",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "publisher_id",
        id: "publisher_id",
        label: "publisher_id",
        data: publishers,
        size: 4,
        title: "publisher",
      },
    ]);
  };

  useEffect(() => {
    if (authors && translators && investigators && reviewers && editors && publishers && categories)
      orgData();
  }, [authors, translators, investigators, reviewers, editors, publishers, categories]);

  const setPayload = (values) => {
    setFilteredValues(values);
    setNumber(1);

    if (values) setInitialValues(values);
    let str = `${baseUrl}`;
    const keys = Object.keys(values);
    keys.forEach((element) => {
      if (values[element] && values[element] !== []) {
        if (Array.isArray(values[element])) {
          if (element === "authors_id") {
            values[element].forEach((element1) => {
              str += `&filter[${element}.author_id][in]=${element1.id}`;
            });
          } else if (element === "categories_id") {
            values[element].forEach((element1) => {
              str += `&filter[${element}.category_id][in]=${element1.id}`;
            });
          } else if (element === "translators_id") {
            values[element].forEach((element1) => {
              str += `&filter[${element}.translator_id][in]=${element1.id}`;
            });
          }
        } else if (typeof values[element] === "object") {
          str += `&filter[${element}.id]=${values[element].id}`;
        } else {
          str += `&filter[${element}][_contains]=${values[element]}`;
        }
      }
    });
    setUrl(str);

    dispatch(
      fetchDataAction({
        url: `${str}&meta=*&limit=${limit}&page=1`,
        varName: "books",
      })
    );
  };

  const getData = () => {
    dispatch(
      fetchDataAction({
        url: "items/authors?fields=id,title,type_id.title&limit=-1&filter[type_id.title][in]=مؤلف,الكل",
        varName: "authors",
      })
    );

    dispatch(
      fetchDataAction({
        url: "items/categories?fields=id,title",
        varName: "categories",
      })
    );

    dispatch(
      fetchDataAction({
        url: "items/authors?fields=id,title,type_id.title&limit=-1&filter[type_id.title][in]=مترجم,الكل",
        varName: "translators",
      })
    );

    dispatch(
      fetchDataAction({
        url: "items/authors?fields=id,title,type_id.title&limit=-1&filter[type_id.title][in]=مراجع,الكل",
        varName: "reviewers",
      })
    );

    dispatch(
      fetchDataAction({
        url: "items/authors?fields=id,title,type_id.title&limit=-1&filter[type_id.title][in]=محقق,الكل",
        varName: "investigators",
      })
    );

    dispatch(
      fetchDataAction({
        url: "items/authors?fields=id,title,type_id.title&limit=-1&filter[type_id.title][in]=محرر,الكل",
        varName: "editors",
      })
    );

    dispatch(
      fetchDataAction({
        url: "items/publishers?fields=id,title&limit=-1",
        varName: "publishers",
      })
    );
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setPayload(filteredValues || initialValues);
  }, [limit]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      filter
      yupObj={{}}
      name="researchFilter"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
    />
  );
};

FilterForm.propTypes = {
  setUrl: PropTypes.func.isRequired,
  setNumber: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};
export default FilterForm;
