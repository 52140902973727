import { useField } from "formik";
import PropTypes from "prop-types";

import MDInput from "components/MDInput";

function FormikInput(props) {
  const { name, ...otherProps } = props;
  const [field, mata] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  return <MDInput {...configTextfield} />;
}

FormikInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormikInput;
