import { memo, useEffect } from "react";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import { MenuItem, Autocomplete, TextField, styled } from "@mui/material";
import MDInput from "components/MDInput";
import { useTranslation } from "react-i18next";

// import BaseInput from "./BaseInput";

const StyledInput = styled(TextField)(({ theme }) => ({
  "& .MuiSelect-select": { height: 45 },
  "& input": {
    color: theme.palette.primary.main,
    padding: 10,
    fontSize: 15,
    // [theme.breakpoints.down('xs')]: {
    //   fontSize: 14,
    // },
  },
}));
const FormikSelect = ({ name, options, setCountryCode, autoComplete, ...otherProps }) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (_, value) => {
    // const { value } = evt.target;
    console.log("value = ", value);
    if (typeof value === "object") {
      setFieldValue(name, value);

      if (setCountryCode) setCountryCode(value);
    }
    // setCountryCode && setCountryCode(value);
    console.log("values ==== ", values);
  };

  useEffect(() => {
    console.log("re-rendered", values);
  }, [values]);

  const configSelect = {
    ...field,
    ...otherProps,
    select: "true",
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }

  const renderSelect = () => (
    <StyledInput {...configSelect}>
      {options?.map((option) => (
        <MenuItem key={Math.random().toString()} value={option.id || option}>
          <span>{option.title || option.IsoCode || option}</span>
        </MenuItem>
      ))}
    </StyledInput>
  );

  const renderAutoComplete = () => (
    <Autocomplete
      // disablePortal
      options={options}
      getOptionLabel={(option) => {
        console.log("option = ", option);
        return option && option.title ? option.title : "";
      }}
      // getOptionLabel={(option) => options.find((item) => item.id === option)?.title || option}
      // renderOption={(option) => <div>{option.title}</div>}
      value={options.find((option) => option.id === options[values[name]]) || ""}
      // value={values[title]}
      isOptionEqualToValue={(option, item) => (option && item ? option.id === item.id : "")}
      renderInput={(params) => <MDInput {...params} label={t("city")} />}
      {...configSelect}
    />
  );

  return 1 ? renderAutoComplete() : renderSelect();
};

FormikSelect.defaultProps = {
  autoComplete: true,
  setCountryCode: () => {},
};

FormikSelect.propTypes = {
  // title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
  setCountryCode: PropTypes.func,
  autoComplete: PropTypes.bool,
};

export default memo(FormikSelect);
