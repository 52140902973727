const UpdateRecord = (obj1, obj2) => {
  try {
    const payloadInfoChanged = {};
    Object.keys(obj1).forEach((key) => {
      if (Array.isArray(obj1[key])) {
        if (obj1[key] !== obj2[key]) {
          let add = [];
          let remove = [];
          const itemAdd = obj1[key].filter((field) => !obj2[key].includes(field));
          if (itemAdd) add = itemAdd;
          const itemRemove = obj2[key].filter((field) => !obj1[key].includes(field));
          if (itemRemove) remove = itemRemove;
          payloadInfoChanged[key] = { add, remove };
        }
      } else if (obj2[key] !== obj1[key]) {
        payloadInfoChanged[key] = obj1[key];
      }
    });
    return payloadInfoChanged;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export default UpdateRecord;
