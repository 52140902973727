import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import DataForm from "components/UI/form/DataForm";

import { addComplexDataAction, fetchDataAction, reset } from "store/features/coreSlice";
import PropTypes from "prop-types";
import dataVal from "../../dataVal.json";

const PageForm = ({ updateHandlerVal, pageNum, url }) => {
  const dispatch = useDispatch();

  const { formprograms, formrankings, limit } = useSelector((state) => state.core);

  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);
  const [programId, setProgramId] = useState(null);

  const orgData = () => {
    const dataArr = [
      {
        type: "Select",
        optionTitle: "title",
        name: "program_id",
        id: "program_id",
        label: "program_id",
        data: formprograms,
        size: 4,
        title: "program",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "ranking_id",
        id: "ranking_id",
        label: "ranking_id",
        data: formrankings,
        size: 4,
        title: "ranking",
      },
      {
        type: "TextField",
        name: "title",
        id: "title",
        label: "title",
        size: 4,
        title: "title",
      },
    ];
    setData(dataArr);
  };

  const resetForm = () => {
    setProgramId(null);
    orgData();
  };

  const setFieldSelectedValue = (name, values) => {
    if (name === "program_id") {
      setProgramId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/research_rankings?fields=id,title,programs.program_id.id&filter[programs.program_id.id]=${values.id}`,
          varName: "formrankings",
        })
      );
    }
  };

  const onChangeSelectInputs = (name, text) => {
    let urlVal;
    if (name === "program_id")
      dispatch(
        fetchDataAction({
          url: `items/programs?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "formprograms",
        })
      );

    if (name === "ranking_id") {
      urlVal = `items/research_rankings?fields=id,title,programs.program_id.id&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (programId) urlVal += `&filter[programs.program_id.id]=${programId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formrankings",
        })
      );
    }
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      if (element === "research_rankings")
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,title&limit=${limit}`,
            varName: `formrankings`,
          })
        );
      else
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,title&limit=${limit}`,
            varName: `form${element}`,
          })
        );
    });
  };

  const onUpdateHandler = (name, values, copy) => {
    onChangeSelectInputs("program_id", values.program_id.title);
    if (values.program_id) setFieldSelectedValue("program_id", values.program_id);

    setInitialValues({
      id: copy ? null : values.id,
      title: values.title,
      program_id: values.program_id,
      ranking_id: values.ranking_id,
    });
  };

  const setPayload = ({ files, ...values }) => {
    const req = {
      id: values.id,
      file: null,
      origin: {
        data: {
          id: values.id,
          ranking_id: values.ranking_id ? values.ranking_id.id : null,
          program_id: values.program_id.id,
          title: values.title,
        },
        table: "researches",
      },
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "research",
      },
    };
    setInitialValues(values);

    dispatch(addComplexDataAction(req));
    resetForm();
  };

  useEffect(() => {
    if (updateHandlerVal) {
      onUpdateHandler(updateHandlerVal.name, updateHandlerVal.value, updateHandlerVal.isCopy);
    } else {
      getData(dataVal.oterData);
    }
    return () => {
      resetForm();
      dispatch(reset({ alias: "form", data: ["programs", "rankings"] }));
    };
  }, []);

  useEffect(() => {
    if (formprograms && formrankings) orgData();
  }, [formprograms, formrankings]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      yupObj={{
        program_id: yup.mixed("Enter the program").required("jobtitle is required").nullable(),
        title: yup.string("Enter your value").required("value is required").nullable(),
      }}
      resetData={resetForm}
      name="research"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeSelectInputs}
      defaultInitialValues={dataVal.initialValues}
    />
  );
};

PageForm.propTypes = {
  updateHandlerVal: PropTypes.objectOf(PropTypes.any),
  pageNum: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

PageForm.defaultProps = {
  updateHandlerVal: null,
};
export default PageForm;
