import {
  AccessTimeOutlined,
  BrowserUpdatedOutlined,
  CloudDoneOutlined,
  EmojiEventsOutlined,
  PrivacyTipOutlined,
  SentimentSatisfiedAltOutlined,
  SupportAgentOutlined,
  VerifiedUserOutlined,
} from "@mui/icons-material";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import trakibLogo from "assets/images/logos/trakib-logo.svg";

import SectionContainer from "../../SectionContainer";
import FlipCard from "./FlipCard";
// import AnimatedLines from "./AnimatedLines";

const features = [
  {
    entitle: "Free and Quick Support",
    artitle: "دعم مجاني وسريع",
    Icon: SupportAgentOutlined,
    enbody:
      "Trakib support is equipped to meet your every inquiry, round the clock, to satisfaction.",
    arbody:
      "إن فريق دعم تراكيب جاهز لتلبية جميع استفساراتكم، على مدار الساعة، بما يحقق حالة الرضي لديكم.",
  },
  {
    entitle: "Safe and Secure",
    artitle: "الأمان والضمان",
    Icon: VerifiedUserOutlined,
    arbody:
      "تتم استضافة تراكيب على خوادم آمنة، مع تقنية تشفير SSL 256 بت، وكذلك بياناتك كاملة وخاصة ومحمية، مع نسخ احتياطية تلقائية مستمرة لزيادة الأمان.",
    enbody:
      "Trakib is hosted on secure servers, with 256-bit SSL encryption technology, so is your data in full, private, protected, with constant auto-backups for increased security.",
  },
  {
    entitle: "Privacy",
    artitle: "الخصوصية",
    Icon: PrivacyTipOutlined,
    enbody:
      "ًe take into account your privacy and security. When you purchase the system, you have the freedom to manage and host on your own servers.",
    arbody:
      "نحن فى تراكيب نراعي خصوصيك وأمانك، فعند شرائك للنظام لك حرية الادارة والاستضافة على خوادمك الخاصة.",
  },
  {
    entitle: "Save Time and Effort",
    artitle: "وفر الوقت والجهد",
    Icon: AccessTimeOutlined,
    arbody:
      "واجهة المستخدم التفاعلية لدينا، المدعومة بالأتمتة، مخصصة لخدمة أصحاب الأعمال الجادين الذين ليس لديهم وقت ضائع.",
    enbody:
      "Our intuitive user interface, backed by automation, is dedicated to serving serious business owners who have no time to waste.",
  },
  {
    entitle: "Remote, Yes. Disconnected, Never",
    artitle: "من أي مكان وفي أي وقت",
    Icon: CloudDoneOutlined,
    arbody:
      "تراكيب قائم على الخدمات السحابية، عملك ليس بعيدًا عن متناول اليد أبدًا. يمكنك الوصول إلى أعمالك وإدارتها في أي مكان وفي أي وقت ومن أي جهاز، ويتم تحديثها باستمرار ومحمية.",
    enbody:
      "Trakib is cloud-based, your business is never out of reach. Access and manage your business, anywhere, anytime, from any device, consistently updated and intact.",
  },
  {
    entitle: "Free Lifetime Updates",
    artitle: "تحديثات مجانية مدى الحياة",
    Icon: BrowserUpdatedOutlined,
    arbody:
      "تراكيب يتطور باستمرار لزيادة دعمكم. يتم تضمين التحديثات المستمرة مجانًا، مهما كانت الخطة التي تحددها، إلى الأبد.",
    enbody:
      "Trakib is constantly growing to expand supporting you. Ongoing updates are included for free, whatever plan you select, forever.",
  },
  {
    entitle: "One Time Purchase",
    artitle: "الشراء مرة واحدة",
    Icon: EmojiEventsOutlined,
    enbody:
      "You will not need annual subscription renewals. Once you purchase, you own it for life.",
    arbody:
      "يتفرد نظام تراكيب بميزة أخري وهي أنك لن تحتاج إلي تجديدات اشتراكات سنوية، فبمجردالشراء تتملكه مدي الحياة.",
  },
  {
    entitle: "Simple and Easy",
    artitle: "السهولة والبساطة",
    Icon: SentimentSatisfiedAltOutlined,
    enbody:
      "Unlike the usual administrative systems that target many fields, which makes them complex to use, Tarakeeb targets book publishers and distributors only, which makes the system easy to understand and use.",
    arbody:
      "بخلاف النظم الادارية المعتادة التي تستهدف العديد من المجالات ما يجعلها معقدة الاستخدام، فإن تراكيب يستهدف فئة ناشري وموزعي الكتب فقط مما يجعل النظام سهل الفهم والاستخدام.",
  },
];

const whyTrakibSection = {
  entitle: "Promises as Your Business Partner",
  artitle: "لماذا يُعَدُّ تراكيب شريكًا مثاليًا لأعمالك؟",
  enbody: "Find out why Daftra has been the choice for you.",
  arbody: "اكتشف كيف يساعدك نظام دفترة في إدارة أعمالك بكفاءة وفعالية.",
};

function WhyTrakib() {
  const { t, i18n } = useTranslation();

  return (
    <SectionContainer id="why-trakib">
      <Container>
        {i18n.language === "en" ? (
          <MDTypography variant="h3">
            <MDBox component="span" color="info">
              {t("trakib")}{" "}
            </MDBox>
            {whyTrakibSection[`${i18n.language}title`]}
          </MDTypography>
        ) : (
          <MDTypography variant="h3">
            لماذا يُعَدُّ{" "}
            <MDBox component="span" color="info">
              {t("trakib")}{" "}
            </MDBox>
            شريكًا مثاليًا لأعمالك؟
          </MDTypography>
        )}

        <MDTypography variant="body1">{whyTrakibSection[`${i18n.language}body`]}</MDTypography>
        <FlipCard features={features} />
        {/* <AnimatedLines features={features} /> */}
      </Container>
    </SectionContainer>
  );
}

export default WhyTrakib;
