import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
// import { useField } from "formik";
import { useDropzone } from "react-dropzone";
import { CloudUpload } from "@mui/icons-material";
import PropTypes from "prop-types";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

import SingleFileUploadWithProgress from "./SingleFileUploadWithProgress";
import UploadError from "./UploadError";

let currentId = 0;
const acceptedFileTypes = {
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template": [".xltx"],
  "application/vnd.ms-excel.sheet.macroEnabled.12": [".xlsm"],
  "application/vnd.ms-excel.template.macroEnabled.12": [".xltm"],
  "application/vnd.ms-excel.addin.macroEnabled.12": [".xlam"],
  "application/vnd.ms-excel.sheet.binary.macroEnabled.12": [".xlsb"],
  // "text/plain": [".csv"],
  "text/csv": [".csv"],
  "application/csv": [".csv"],
};

function getNewId() {
  // we could use a fancier solution instead of a sequential ID :)
  return ++currentId;
}

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  border: "2px dashed #293845",
  borderRadius: 5,
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  transition: "border .3s ease-in-out",
  cursor: "pointer",
};

// { name, approveSubmit }

export default function MultipleFileUpload({ payload }) {
  // const [_, __, helpers] = useField(name);

  const [files, setFiles] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState();

  const onDrop = useCallback((accFiles, rejFiles) => {
    const mappedAcc = accFiles.map((file) => ({
      file,
      errors: [],
      id: getNewId(),
    }));

    const mappedRej = rejFiles.map((r) => ({ ...r, id: getNewId() }));
    setFiles((curr) => [...curr, ...mappedAcc, ...mappedRej]);
  }, []);

  useEffect(() => {
    setFilesToUpload(files.map((file) => file.file));
    // helpers.setValue(files.map((file) => file.file));

    console.log("filesToUpload = ", filesToUpload);
  }, [files]);

  const onUpload = (file, url) => {
    setFiles((curr) =>
      curr.map((fw) => {
        if (fw.file === file) {
          return { ...fw, url };
        }
        return fw;
      })
    );
  };

  const onDelete = (file) => {
    setFiles((curr) => curr.filter((fw) => fw.file !== file));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    noDrag: true,

    // maxSize: 4 * 1024 * 1024, // 2MB
    // maxSize: 300 * 1024, // 300KB
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  return (
    <>
      <Grid item xs={12}>
        <MDTypography variant="h2" textAlign="center" my={4}>
          Upload your old Data
        </MDTypography>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />

          <CloudUpload
            sx={{ width: 100, height: 100, color: (theme) => theme.palette.info.main }}
          />
          <MDTypography variant="body2">Click to select your Excel/CSV files</MDTypography>
          <em>(you can select only excel/csv files.)</em>
        </div>
      </Grid>

      {files.map((fileWrapper) => (
        <Grid item xs={12} key={Math.random().toString()}>
          {fileWrapper.errors.length ? (
            <UploadError file={fileWrapper.file} errors={fileWrapper.errors} onDelete={onDelete} />
          ) : (
            <SingleFileUploadWithProgress
              onDelete={onDelete}
              onUpload={onUpload}
              file={fileWrapper.file}
              payload={payload}
              // approveSubmit={approveSubmit}
            />
          )}
        </Grid>
      ))}

      {/* <MDButton onClick={() => console.log("parent clicked")}>Click Me</MDButton> */}
    </>
  );
}

MultipleFileUpload.defaultProps = {
  payload: null,
};

MultipleFileUpload.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
};
