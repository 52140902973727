import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import DataForm from "components/UI/form/DataForm";
import UpdateRecord from "helpers/compare";
import { addComplexDataAction, fetchDataAction, reset } from "store/features/coreSlice";
import PropTypes from "prop-types";
import dataVal from "../../dataVal.json";

const PageForm = ({ updateHandlerVal, pageNum, url }) => {
  const dispatch = useDispatch();

  const { formcountries, formcities, limit } = useSelector((state) => state.core);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);
  const [countryId, setCountryId] = useState(null);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "title",
        name: "country_id",
        id: "country_id",
        label: "country_id",
        data: formcountries,
        size: 3,
        title: "country",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "city_id",
        id: "city_id",
        label: "city_id",
        data: formcities,
        size: 3,
        title: "city",
      },
      {
        type: "TextField",
        name: "title",
        id: "title",
        label: "title",
        size: 3,
        title: "name",
      },
      {
        type: "TextField",
        name: "phone",
        id: "phone",
        label: "phone",
        size: 3,
        title: "phone",
      },
      {
        type: "TextField",
        name: "address",
        id: "address",
        label: "address",
        size: 3,
        title: "address",
      },
      {
        type: "TextField",
        name: "res_name",
        id: "res_name",
        label: "res_name",
        size: 3,
        title: "resName",
      },
      {
        type: "TextField",
        name: "res_phone",
        id: "res_phone",
        label: "res_phone",
        size: 3,
        title: "resPhone",
      },
      {
        type: "TextField",
        name: "email",
        id: "email",
        label: "email",
        size: 3,
        title: "email",
      },
      {
        type: "TextField",
        name: "website",
        id: "website",
        label: "website",
        size: 3,
        title: "website",
      },
      {
        type: "TextArea",
        name: "notes",
        id: "notes",
        label: "notes",
        maxRows: 4,
        minRows: 4,
        size: 9,
        title: "notes",
      },
    ]);
  };

  const resetForm = () => {
    setCountryId(null);
    orgData();
  };

  const setFieldSelectedValue = (name, values) => {
    if (name === "country_id") {
      setCountryId(values);
      dispatch(
        fetchDataAction({
          url: `items/cities?fields=id,title&filter[country_id]=${values.id}`,
          varName: "formcities",
        })
      );
    }
  };

  const onChangeSelectInputs = (name, text) => {
    if (name === "country_id")
      dispatch(
        fetchDataAction({
          url: `items/countries?fields=id,title&filter[title][${
            text ? `_contains]=${text}` : "_nempty]=true"
          }&limit=${limit}`,
          varName: "formcountries",
        })
      );

    if (name === "city_id") {
      let urlVal = `items/cities?fields=id,title&filter[title][${
        text ? `_contains]=${text}` : "_nempty]=true"
      }&limit=${limit}`;
      if (countryId) urlVal += `&filter[country_id]=${countryId.id}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formcities",
        })
      );
    }
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      dispatch(
        fetchDataAction({
          url: `items/${element}?fields=id,title&limit=${limit}`,
          varName: `form${element}`,
        })
      );
    });
  };

  const onUpdateHandler = (name, values, copy) => {
    if (values.country_id) {
      setFieldSelectedValue("country_id", values.country_id);
      onChangeSelectInputs("country_id", values.country_id.title);
    }
    setInitialValues({
      id: copy ? null : values.id,
      country_id: values.country_id,
      city_id: values.city_id,
      title: values.title,
      phone: values.phone,
      address: values.address,
      res_phone: values.res_phone,
      res_name: values.res_name,
      email: values.email,
      website: values.website,
      notes: values.notes,
    });
  };

  const setPayload = (values) => {
    const payloadData = {};
    const lastData = values.id ? UpdateRecord(values, initialValues) : values;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });

    const req = {
      id: values.id,
      file: null,
      origin: {
        data: Object.keys(payloadData).length === 0 ? null : payloadData,
        table: "libraries",
      },
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "libraries",
      },
    };
    setInitialValues(values);
    dispatch(addComplexDataAction(req));
    resetForm();
  };

  useEffect(() => {
    if (updateHandlerVal) {
      onUpdateHandler(updateHandlerVal.name, updateHandlerVal.value, updateHandlerVal.isCopy);
    } else {
      getData(dataVal.oterData);
    }
    return () => {
      resetForm();
      dispatch(reset({ alias: "form", data: ["countries", "cities"] }));
    };
  }, []);

  useEffect(() => {
    if (formcountries && formcities) orgData();
  }, [formcountries, formcities]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      yupObj={{
        title: yup.string("required").required("required").nullable(),
        phone: yup
          .string("Enter your title")
          .matches(phoneRegExp, "Phone number is not valid")
          .nullable(),
        email: yup.string("required").email("wrong email").nullable(),
        country_id: yup.mixed("required").required("required").nullable(),
        city_id: yup.mixed("required").required("required").nullable(),
      }}
      resetData={resetForm}
      name="libraries"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeSelectInputs}
      defaultInitialValues={dataVal.initialValues}
    />
  );
};

PageForm.propTypes = {
  updateHandlerVal: PropTypes.objectOf(PropTypes.any),
  pageNum: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

PageForm.defaultProps = {
  updateHandlerVal: null,
};
export default PageForm;
