import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";
import * as yup from "yup";
import MDTypography from "components/MDTypography";
import { Icon } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
import {
  fetchDataAction,
  deleteDataAction,
  reset,
  addComplexDataAction,
  setAlert,
} from "store/features/coreSlice";
import { useTranslation } from "react-i18next";
import PagesCount from "helpers/pages-count";

const Cities = () => {
  const { t } = useTranslation();
  const { cities, citiesMeta, formcountries, resStatus, limit } = useSelector(
    (state) => state.core
  );

  const dispatch = useDispatch();

  const columns = [
    { Header: "title", accessor: "title", width: "45%", align: "center" },
    { Header: "country", accessor: "relatedCount", width: "45%", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [newArr, setNewArr] = useState([]);
  const [data, setData] = useState([]);
  const [number, setNumber] = useState(1);
  const [pageOptions, setPageOption] = useState([]);

  const [initialValues, setInitialValues] = useState({
    id: null,
    title: "",
    country_id: null,
  });
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);

  const onUpdateHandler = (name, values) => {
    setInitialValues({
      id: values.id,
      title: values.title,
      country_id: values.country_id,
    });
    setOpen(true);
  };

  const onDeleteHandler = (name, values) => {
    if (values.libraries.length === 0) {
      setOpenDeleteModal(true);
      setDeleteItemId(values.id);
    } else {
      // alert("have libraries");
      dispatch(
        setAlert({
          status: "error",
          content: t("haveLibraries"),
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      fetchDataAction({
        url: "items/countries?fields=id,title",
        varName: "formcountries",
      })
    );
    return () => {
      dispatch(reset(null));
    };
  }, []);

  useEffect(() => {
    const payload = {
      url: `items/cities?fields=id,title,country_id.id,country_id.title,libraries.id&meta=*&limit=${limit}&page=${number}`,
      varName: "cities",
    };
    dispatch(fetchDataAction(payload));
  }, [limit]);

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `items/cities?fields=id,title,country_id.id,country_id.title,libraries.id&meta=*&limit=${limit}&page=${num}`,
          varName: "cities",
        })
      );
    }
  };

  useEffect(() => {
    let item = {};
    const Arr = [];
    if (cities && cities.length !== 0 && citiesMeta) {
      const d = [];
      for (let i = 0; i < PagesCount(+citiesMeta.filter_count, limit); i += 1) {
        d.push(i);
      }
      setPageOption(d);
      cities.forEach((city) => {
        item = {};
        item.id = city.id;
        item.title = city.title;
        item.country_id = city.country_id;
        item.relatedCount = city.libraries.length;
        item.action = (
          <>
            <MDTypography
              style={{ padding: 20, color: "#3b94ee" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => onUpdateHandler("cities", city)}
            >
              <Icon fontSize="small">edit</Icon>
            </MDTypography>
            <MDTypography
              style={{ padding: 20, color: "#e9423f" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => onDeleteHandler("cities", city)}
            >
              <Icon fontSize="small">delete</Icon>
            </MDTypography>
          </>
        );
        Arr.push(item);
      });
      setNewArr(Arr);
    }
  }, [cities, citiesMeta]);

  useEffect(() => {
    if (formcountries)
      setData([
        {
          type: "TextField",
          name: "title",
          id: "title",
          label: "title",
          size: 6,
          title: "city",
        },
        {
          type: "Select",
          optionTitle: "title",
          name: "country_id",
          id: "country_id",
          label: "country_id",
          data: formcountries,
          size: 6,
          title: "country",
        },
      ]);
  }, [formcountries]);

  const resetForm = () => {
    dispatch(
      fetchDataAction({
        url: "items/countries?fields=id,title",
        varName: "formcountries",
      })
    );
    setInitialValues({
      id: null,
      title: null,
      country_id: null,
    });
  };

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      setSuccessSB(true);
      resetForm();
    }
  }, [resStatus]);

  const setPayload = (values) => {
    const req = {
      id: values.id,
      origin: {
        data: values,
        table: "cities",
      },
      getInfo: {
        url: `items/cities?fields=id,title,country_id.id,country_id.title,libraries.id&meta=*&limit=${limit}&page=${number}`,
        varName: "cities",
      },
    };

    setInitialValues(values);
    dispatch(addComplexDataAction(req));
  };

  const setOpenModal = () => {
    if (open) resetForm();
    setOpen(!open);
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: `items/cities`,
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      varName: "cities",
      getInfo: {
        url: `items/cities?fields=id,title,country_id.id,country_id.title,libraries.id&meta=*&limit=${limit}&page=${number}`,
        varName: "cities",
      },
    };

    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };
  if (data.length === 0 || newArr === [] || !citiesMeta) {
    return null;
  }
  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("systemDashboard")}
      content={t("successfulProcess")}
      dateTime={t("recently")}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  return (
    <>
      <Main
        layout="simple"
        report={false}
        count={pageOptions}
        pageIndex={number - 1}
        allRowsCount={+citiesMeta.filter_count}
        setPageNum={setPageNumber}
        openDeleteModal={openDeleteModal}
        handleConfirmDelete={handleConfirmDelete}
        setOpen={setOpenModal}
        setOpenDeleteModal={setOpenDeleteModal}
        open={open}
        name="cities"
        setPayload={setPayload}
        data={data}
        initialValues={initialValues}
        title={t("addCity")}
        columns={columns}
        rows={newArr}
        onUpdateHandler={onUpdateHandler}
        onDeleteHandler={onDeleteHandler}
        yupObj={{
          title: yup.string("required").required("required").nullable(),
          country_id: yup.mixed("required").required("required").nullable(),
        }}
      />
      {renderSuccessSB}
    </>
  );
};

export default Cities;
